import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Sidebar } from "primereact/sidebar";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import { Paginator } from "primereact/paginator";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests } from "../auth/AuthRequests";
import moment from "moment";
import sortIcon from "../../assets/images/structure/sortIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { Tooltip } from "primereact/tooltip";

const CustomerListing = () => {
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [pdf, setPdf] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedOrderType, setSelectedOrderType] = useState(null);
  const [statusType, setStatusType] = useState("");
  const [orderType, setOrderType] = useState("");
  const Status = [
    { name: "All", code: "" },
    { name: "Shipped", code: "shipped" },
    { name: "Confirmed", code: "confirmed" },
    { name: "Delivered", code: "delivered" },
    { name: "Cancelled", code: "canceled" },
    { name: "Returned", code: "returned" },
    { name: "Picked Up", code: "pickup" },
  ];
  const orderTypes = [
    { name: "All", code: "" },
    { name: "Prepaid", code: "card" },
    { name: "COD", code: "cash" },
  ];
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visibleRight, setVisibleRight] = useState(false);
  const [data, setData] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectList, setSelectList] = useState({ name: "10", code: "10" });
  const [rows, setRows] = useState(10);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [orderBy, setOrderBy] = useState("DESC");
  const Lists = [
    { name: "10", code: "10" },
    { name: "15", code: "15" },
    { name: "50", code: "50" },
    { name: "100", code: "100" },
  ];

  const menuAction = useRef(null);
  const itemsAction = [
    {
      label: "Download",
      command: () => {
        window.open(`${pdf}`, "_blank");
      },
    },
    {
      label: "Edit",
      command: () => {
        // navigate(`/order-edit/${selectedOrder}`, "_blank");
        const url = `/order-edit/${selectedOrder}`;
        window.open(url, "_blank");
      },
    },
  ];

  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const sortFunction = (sortType) => {
    setSortBy(sortType);
    if (orderBy == "ASC") {
      setOrderBy("DESC");
    } else {
      setOrderBy("ASC");
    }
  };

  const getOrdersHandler = useCallback(async () => {
    let object = {
      limit: selectList.code,
      page: page,
      status: statusType,
      search: search,
      sortBy: sortBy,
      orderBy: orderBy,
      start_date: startdate ? moment(startdate).format("YYYY-MM-DD") : "",
      end_date: enddate ? moment(enddate).format("YYYY-MM-DD") : "",
      paymentType: orderType,
    };
    dispatch(changeLoader(true));
    const objString = "?" + new URLSearchParams(object).toString();
    await globalRequestAxios("get", {}, AuthRequests.GET_ALL_ORDERS(objString))
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setData(res.data);
          setTotalRecords(res.count);
          setHasValueChange(false);
        } else {
          setData([]);
          setTotalRecords(0);
          setHasValueChange(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setHasValueChange(false);
        dispatch(changeLoader(false));
      });
  }, [
    selectList,
    page,
    limit,
    orderType,
    statusType,
    search,
    sortBy,
    orderBy,
    startdate,
    enddate,
    rows,
  ]);

  //debouncing for search
  const [hasValueChanged, setHasValueChange] = useState(false);
  const debounceTimeout = useRef(null);
  useEffect(() => {
    setHasValueChange(true);
  }, [
    selectList,
    page,
    limit,
    orderType,
    statusType,
    search,
    sortBy,
    orderBy,
    startdate,
    enddate,
    rows,
  ]);

  useEffect(() => {
    if (hasValueChanged) {
      clearTimeout(debounceTimeout.current);
      debounceTimeout.current = setTimeout(() => {
        getOrdersHandler();
      }, 500);
    }
  }, [hasValueChanged, getOrdersHandler]);

  const onPageChange = (event) => {
    setPage(event.page + 1);
    setFirst(event.first);
    setRows(event.rows);
  };

  const toggleRow = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const ShowStatus = ({ status }) => {
    return <span className={`chips ${status}`}>{status}</span>;
  };
  const ProductStatus = ({ orderDetails, rowData }) => {
    return (
      <>
        {orderDetails?.is_partially === 1 ? (
          <>
            {orderDetails?.status === "confirmed" ? (
              <>
                {orderDetails?.erp_order_update === 1 &&
                orderDetails?.delivery_type == "pickup" ? (
                  rowData?.approveQty > 0 ? (
                    <ShowStatus status={"confirmed"} />
                  ) : (
                    <ShowStatus status={"cancelled"} />
                  )
                ) : (
                  <ShowStatus status={"confirmed"} />
                )}
              </>
            ) : orderDetails?.status === "canceled" ? (
              <>
                <ShowStatus status={"cancelled"} />
              </>
            ) : orderDetails?.status === "pickup" ? (
              <>
                {rowData?.approveQty > 0 ? (
                  <ShowStatus status={"pickup"} />
                ) : (
                  <ShowStatus status={"cancelled"} />
                )}
              </>
            ) : orderDetails?.status === "delivered" ? (
              <>
                {rowData?.approveQty > 0 ? (
                  <ShowStatus status={"delivered"} />
                ) : (
                  <ShowStatus status={"cancelled"} />
                )}
              </>
            ) : orderDetails?.status === "returned" ? (
              <>
                <ShowStatus status={"returned"} />
              </>
            ) : orderDetails?.status === "shipped" ? (
              <>
                {rowData?.approveQty > 0 ? (
                  <ShowStatus status={"shipped"} />
                ) : (
                  <ShowStatus status={"cancelled"} />
                )}
              </>
            ) : null}
          </>
        ) : (
          <>
            <ShowStatus status={orderDetails?.status} />
          </>
        )}
      </>
    );
  };

  return (
    <>
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="tablefilterheader pb-1">
            <h4 className="h4 mb-0">Orders</h4>
          </div>
          <div className="tablefilterSubheader">
            <div className="tableHeader">
              <div className="tableFilterRow" style={{ marginLeft: "0" }}>
                <div
                  className="tableFilterCol sortList"
                  style={{ marginLeft: "0" }}
                >
                  <Dropdown
                    value={selectList}
                    onChange={(e) => {
                      setSelectList(e.value);
                      setLimit(e.value.code);
                      setRows(+e.value.code);
                      setPage(0);
                      setFirst(0);
                    }}
                    options={Lists}
                    optionLabel="name"
                    placeholder="10"
                    className="filterDropdown"
                  />
                </div>
              </div>
              <div className="tableFilterRow">
                <div className="tableFilterCol">
                  <Dropdown
                    value={selectedStatus}
                    onChange={(e) => {
                      setSelectedStatus(e.value);
                      setStatusType(e.value.code);
                    }}
                    options={Status}
                    optionLabel="name"
                    placeholder="Status"
                    className="filterDropdown"
                  />
                </div>
                <div className="tableFilterCol">
                  <Dropdown
                    value={selectedOrderType}
                    onChange={(e) => {
                      setSelectedOrderType(e.value);
                      setOrderType(e.value.code);
                    }}
                    options={orderTypes}
                    optionLabel="name"
                    placeholder="Order Type"
                    className="filterDropdown"
                  />
                </div>
                <div
                  className="tableFilterCol dateFilter"
                  style={{
                    minWidth: "auto",
                    maxWidth: "fit-content",
                    width: "auto",
                  }}
                >
                  <div className="combDateBox">
                    <span className="c-label">Date</span>
                    <Calendar
                      value={startdate}
                      maxDate={enddate}
                      onChange={(e) => setStartDate(e.value)}
                      showIcon
                      placeholder="Start Date"
                    />
                    <span>-</span>
                    <Calendar
                      value={enddate}
                      minDate={startdate}
                      onChange={(e) => setEndDate(e.value)}
                      showIcon
                      placeholder="End Date"
                    />
                  </div>
                </div>

                <div className="tableFilterCol">
                  <div
                    className="p-inputgroup searchFilter"
                    style={{ width: "380px" }}
                  >
                    <InputText
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value.trimStart());
                        setPage(1);
                        setFirst(0);
                      }}
                      placeholder="Search by Order ID & Product Name"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid adminTableContent">
            <div className="tableContent">
              <table>
                <thead>
                  <tr>
                    <th>
                      <p className="tbleheading">
                        Order Id
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("id")}
                        >
                          {" "}
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Order Date
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("createdAt")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Customer Name
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("name")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Country
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("country")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Shipping Address
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("address")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Shipping Company
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("shipping_company")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Total Order Amount
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("total_amount")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Delivery Type
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("delivery_type")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">Order Type </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">Order Status </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">QUANTITY </p>
                    </th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {data?.map((item, index) => {
                    let total_product_quantity = item?.order_details?.length;
                    return (
                      <>
                        <tr
                          key={item?.createdAt + item?.name}
                          className={`${
                            expandedRows.includes(index) ? "bg-gray" : ""
                          }`}
                        >
                          <td>
                            <a
                              className="tbaBlue"
                              onClick={(e) => {
                                setVisibleRight(true);

                                setSelectedItem(item);
                              }}
                            >
                              #{item?.order_id}
                            </a>
                          </td>
                          <td>
                            <b>
                              {moment(item?.createdAt).format("DD MMM YYYY")}
                            </b>
                          </td>
                          <td className="capitalize-first-letter">
                            {item?.name}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item?.country}
                          </td>
                          <td className="tDaddredd">{item?.address}</td>
                          <td>
                            {item?.shipping_company
                              ? item?.shipping_company
                              : "N/A"}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item?.currency}{" "}
                            {parseFloat(item?.total_amount).toFixed(2)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item?.delivery_type.charAt(0).toUpperCase() +
                              item?.delivery_type.slice(1).toLowerCase()}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item?.payment_type == "card" ? "Prepaid" : "COD"}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <span
                              className={[
                                "chips",
                                item?.status.toLowerCase(),
                              ].join(" ")}
                            >
                              {item?.status}
                            </span>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {total_product_quantity
                              ? total_product_quantity
                              : "-"}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="tdAction">
                              <Menu
                                model={itemsAction}
                                popup
                                ref={menuAction}
                              />
                              <Button
                                icon="pi pi-ellipsis-v"
                                className="actionbtn TdICon"
                                onClick={(e) => {
                                  menuAction.current.toggle(e);
                                  setSelectedOrder(item?.id);
                                  setPdf(item?.invoice);
                                }}
                              />
                              <a
                                className="TdICon"
                                onClick={() => toggleRow(index)}
                              >
                                {expandedRows.includes(index) ? (
                                  <i className="pi pi-minus-circle AcIcon"></i>
                                ) : (
                                  <i className="pi pi-plus-circle AcIcon"></i>
                                )}
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr
                          key={item?.createdAt}
                          className={`${
                            expandedRows.includes(index)
                              ? "animated-height auto"
                              : "animated-height"
                          }`}
                        >
                          {expandedRows.includes(index) && (
                            <td colSpan="12" className="bg-gray">
                              <table>
                                <thead>
                                  <tr>
                                    <th>
                                      <p className="tbleheading">Product</p>
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      <p className="tbleheading">
                                        Total Quantity
                                      </p>
                                    </th>
                                    {/* <th style={{ textAlign: "center" }}>
                                        <p className="tbleheading">
                                          Available Quantity
                                        </p>
                                      </th> */}
                                    <th style={{ textAlign: "right" }}>
                                      <p className="tbleheading">Status</p>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {item?.order_details?.length > 0 &&
                                    item?.order_details?.map(
                                      (product, eleIndex) => {
                                        let quantity = product?.approveQty;
                                        if (item?.is_partially === 1) {
                                          if (item?.status === "confirmed") {
                                            if (item?.erp_order_update === 1) {
                                              quantity = product?.approveQty;
                                            } else {
                                              quantity = product?.quantity;
                                            }
                                          } else if (
                                            item?.status == "canceled"
                                          ) {
                                            if (item?.erp_order_update === 1) {
                                              quantity = product?.approveQty;
                                            } else {
                                              quantity = product?.quantity;
                                            }
                                          }
                                        } else {
                                          if (
                                            item?.status == "canceled" &&
                                            item?.erp_order_update === 1
                                          ) {
                                            quantity = product?.approveQty;
                                          } else {
                                            quantity = product?.quantity;
                                          }
                                        }
                                        return (
                                          <tr
                                            key={
                                              product?.id +
                                              product?.product_title +
                                              product?.id
                                            }
                                          >
                                            <td>
                                              <div className="flex align-items-center">
                                                <img
                                                  src={
                                                    product?.product
                                                      ?.product_images[0]
                                                      ?.image_url
                                                  }
                                                  alt="..."
                                                  className="product-40"
                                                />
                                                <p className="product-title">
                                                  {product?.product_title}
                                                </p>
                                              </div>
                                            </td>
                                            {/* <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {product?.quantity}
                                              </td> */}
                                            <td style={{ textAlign: "center" }}>
                                              <div className="flex align-items-center justify-content-center">
                                                <span>{quantity}</span>
                                                {quantity <
                                                product?.quantity ? (
                                                  <div>
                                                    <Tooltip
                                                      target={`.knob${eleIndex}${item?.id}${item?.order_id}`}
                                                      className="custom-tooltip"
                                                      content={`Only ${quantity} quantity out of ${product?.quantity} was available in the stock. Refund of the remaining product has been initiated to the same payment source`}
                                                    />
                                                    <i
                                                      className={`pi pi-info-circle mx-1 knob${eleIndex}${item?.id}${item?.order_id}`}
                                                      style={{
                                                        color: "#E93D3D",
                                                      }}
                                                    ></i>
                                                  </div>
                                                ) : null}
                                              </div>
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              <ProductStatus
                                                orderDetails={item}
                                                rowData={product}
                                              />
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </td>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {data?.length === 0 && !loader ? (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: "20px",
                }}
              >
                <h4 className="h4 mb-0"> {"No Order Found"}</h4>
              </div>
            ) : null}
            {totalRecords > 0 && totalRecords > +selectList.code && (
              <Paginator
                first={first}
                rows={rows}
                onPageChange={onPageChange}
                totalRecords={totalRecords}
                className="mt-2"
              />
            )}
          </div>
        </div>
      </section>

      {/* order detail popup */}
      <Sidebar
        className="detailSiderbar"
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h4 className="mt-0 sidebarTitleHd">Order Detail</h4>

        <div className="sidebarContentBody">
          <div className="detailTxtRow">
            <div className="detailTxtColLeft">
              Customer Name <span>:</span>
            </div>
            <div className="detailTxtColRight capitalize-first-letter">
              {selectedItem?.name}
            </div>
          </div>
          <div className="detailTxtRow">
            <div className="detailTxtColLeft">
              Order ID <span>:</span>
            </div>
            <div className="detailTxtColRight capitalize-first-letter">
              #{selectedItem?.order_id}
            </div>
          </div>
          <div className="detailTxtRow">
            <div className="detailTxtColLeft">
              Shipping Address <span>:</span>
            </div>
            <div className="detailTxtColRight">
              {selectedItem?.address},
              {selectedItem.address2 ? `, ${selectedItem.address2}` : ""}
              {selectedItem?.city}, {selectedItem?.state},
              {selectedItem?.country}{" "}
              {selectedItem?.postal_code
                ? `, ${selectedItem?.postal_code}`
                : ""}
            </div>
          </div>
          <div className="detailTxtRow">
            <div className="detailTxtColLeft">
              Shipping Company <span>:</span>
            </div>
            <div className="detailTxtColRight">
              {selectedItem?.shipping_company
                ? selectedItem?.shipping_company
                : "N/A"}
            </div>
          </div>
          <div className="detailTxtRow">
            <div className="detailTxtColLeft">
              Shipping charges <span>:</span>
            </div>
            <div className="detailTxtColRight">
              {selectedItem?.shipping_charge
                ? `${selectedItem?.currency} ${parseFloat(
                    selectedItem?.shipping_charge
                  ).toFixed(2)}`
                : "N/A"}
            </div>
          </div>
          {selectedItem?.status !== "confirmed" && (
            <div className="detailTxtRow">
              <div className="detailTxtColLeft">
                Tracking No <span>:</span>
              </div>
              <div className="detailTxtColRight">
                {selectedItem?.tracking_id ? selectedItem?.tracking_id : "N/A"}{" "}
              </div>
            </div>
          )}

          <div className="detailTxtRow">
            <div className="detailTxtColLeft">
              Total Discount Amt <span>:</span>
            </div>
            <div className="detailTxtColRight">
              {selectedItem?.currency}{" "}
              {parseFloat(selectedItem?.discount_amount).toFixed(2)}
            </div>
          </div>

          <div className="detailTxtRow">
            <div className="detailTxtColLeft">
              Total Taxable <span>:</span>
            </div>
            <div className="detailTxtColRight">
              {selectedItem?.currency}{" "}
              {parseFloat(selectedItem?.tax_amount).toFixed(2)}{" "}
            </div>
          </div>

          <div className="detailTxtRow">
            <div className="detailTxtColLeft">
              Total Net <span>:</span>
            </div>
            <div className="detailTxtColRight">
              {selectedItem?.currency}{" "}
              {parseFloat(selectedItem?.total_amount).toFixed(2)}{" "}
            </div>
          </div>
          {selectedItem?.is_partially === 1 &&
          selectedItem?.erp_order_update === 1 ? (
            <div className="detailTxtRow">
              <div className="detailTxtColLeft">
                {selectedItem?.payment_type == "card" ? "Net Pay" : "To Pay"}{" "}
                <span>:</span>
              </div>
              <div className="detailTxtColRight">
                {selectedItem?.currency}{" "}
                {parseFloat(selectedItem?.net_amount).toFixed(2)}{" "}
              </div>
            </div>
          ) : null}
          <div className="detailTxtRow">
            <div className="detailTxtColLeft">
              Order Status <span>:</span>
            </div>
            <div className="detailTxtColRight">
              {" "}
              <span className={["chips", selectedItem?.status].join(" ")}>
                {selectedItem?.status}
              </span>{" "}
            </div>
          </div>
          <div className="detailTxtRow">
            <div className="detailTxtColLeft">
              Order Type <span>:</span>
            </div>
            <div className="detailTxtColRight">
              {" "}
              {selectedItem?.payment_type == "card" ? "Prepaid" : "COD"}
            </div>
          </div>
          <div></div>

          <div className="prRow">
            <div className="prCol">
              <h6>Product Name</h6>
            </div>

            <div className="prCol">
              <h6>Unit ID</h6>
            </div>
            <div className="prCol">
              <h6>Quantity</h6>
            </div>
            <div className="prCol">
              <h6>Price</h6>
            </div>
          </div>

          {selectedItem?.order_details?.length > 0 &&
            selectedItem?.order_details?.map((item, index) => {
              return (
                <div key={index + item?.product?.title + item?.product_id}>
                  <div className="prRow">
                    <div className="prCol">
                      <p>{item?.product?.title}</p>
                    </div>

                    <div className="prCol">
                      <p>{item?.product_id}</p>
                    </div>
                    <div className="prCol">
                      <p>{item?.quantity}</p>
                    </div>
                    <div className="prCol">
                      <p>
                        {data[0].currency} {""}
                        {item?.product_price}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Sidebar>
    </>
  );
};

export default CustomerListing;
