/** @format */
import { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import React from "react";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import sortIcon from "../../assets/images/structure/sortIcon.png";
import { AuthRequests } from "../auth/AuthRequests";
import { useDispatch, useSelector } from "react-redux";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";

const NotifyMe = () => {
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectList, setSelectList] = useState(null);
  const Lists = [
    { name: "10", code: "10" },
    { name: "15", code: "15" },
    { name: "50", code: "50" },
    { name: "100", code: "100" },
  ];

  //states
  const [newsLetterList, setNewsLetterList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("id");
  const [orderBy, setOrderBy] = useState("DESC");

  //get contact us list
  const getNewsLetterList = () => {
    dispatch(changeLoader(true));
    let object = {
      limit: limit,
      page: page,
      search: search,
      sortBy: sortBy,
      orderBy: orderBy,
    };
    const objString = "?" + new URLSearchParams(object).toString();
    globalRequestAxios("get", {}, AuthRequests.NOTIFY_ME_LIST + objString)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setNewsLetterList(res?.data || []);
          setTotal(res?.count);
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  };
  useEffect(() => {
    getNewsLetterList();
  }, [limit, page, sortBy, orderBy, search]);

  //sort function
  const sortFunction = (sortType) => {
    setSortBy(sortType);
    if (orderBy == "ASC") {
      setOrderBy("DESC");
    } else {
      setOrderBy("ASC");
    }
  };

  ///pagination function
  const onPageChange = (event) => {
    setPage(event.page + 1);
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <>
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="tablefilterheader pb-2">
            <div className="tableHeader">
              <h4 className="h4 mb-0">Notify Me</h4>
            </div>
          </div>
          <div className="tablefilterSubheader">
            <div className="tableHeader">
              <div className="tableFilterRow" style={{ marginLeft: "0" }}>
                <div
                  className="tableFilterCol sortList"
                  style={{ marginLeft: "0" }}
                >
                  <Dropdown
                    value={selectList}
                    onChange={(e) => {
                      setSelectList(e.value);
                      setLimit(e.value.code);
                      setPage(1);
                      setFirst(0);
                    }}
                    options={Lists}
                    optionLabel="name"
                    placeholder="10"
                    className="filterDropdown"
                  />
                </div>
              </div>
              <div className="tableFilterRow">
                <div className="tableFilterCol">
                  <div className="p-inputgroup searchFilter">
                    <InputText
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value.trimStart());
                        setPage(1);
                        setFirst(0);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid adminTableContent">
            <div className="tableContent">
              <table>
                <thead>
                  <tr>
                    <th>
                      <p className="tbleheading">
                        Email Address
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("email");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Product Name
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {newsLetterList.length > 0 &&
                    newsLetterList?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.email}</td>
                          <td style={{ marginRight: "20px" }}> {item?.product?.title}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {total == 0 && !loader && (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: "20px",
                }}
              >
                <h4 className="h4 mb-0">
                  {"No Data Found!"}
                </h4>
              </div>
            )}
            {total > limit && (
              <Paginator
                first={first}
                rows={rows}
                totalRecords={total}
                className="mt-2"
                onPageChange={onPageChange}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default NotifyMe;
