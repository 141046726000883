import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import addDeleteGetLocalStorage from "../../../globalModules/addDeleteGetLocalStorage ";
import globalRequestAxios from "../../../globalModules/globalRequestAxios";
import { AuthRequests, LOCAL_STORAGE_KEYS } from "../AuthRequests";
import {
  validateEmail,
  validatePassword,
} from "../../../Helpers/HelperFunctions";
import { InputNumber } from "primereact/inputnumber";
import { changeLoader } from "../../../redux/reducers/loader";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import { decodedToken } from "../../../globalModules/tokenDecode";

export const Login = ({ setAlert }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectDatabse, setSelectDatabse] = useState(null);
  const [emailForReset, setEmailForReset] = useState("");
  const [otpForReset, setOtpForReset] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingforgotPassword, setLoadingforgotPassword] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [invalid_credentialErr, setInvalid_credentialErr] = useState("");
  //error
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [selectDatabseErr, setSelectDatabseErr] = useState(null);

  const [emailForResetErr, setEmailForResetErr] = useState("");
  const [otpForResetErr, setOtpForResetErr] = useState("");
  const [newPasswordErr, setNewPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [visibleForgotPass, setVisibleForgotPass] = useState(false);
  const [visibleOtp, setVisibleOtp] = useState(false);
  const [visibleSetPass, setVisibleSetPass] = useState(false);

  //show pass
  const [show_NPass, setShowNPass] = useState(true);
  const [show_CPass, setShowCPass] = useState(true);
  const [show_pass, setShowPass] = useState(true);

  //coundown timer
  const [countdown, setCountdown] = useState(30);
  const [countdownIntervalId, setCountdownIntervalId] = useState(null);

  //verify email for login admin (mfa)
  const [verified, setVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const [popup_open, setPopup_open] = useState(false);
  const [sended_otp, setSended_otp] = useState(false);

  const Countdown_handler = () => {
    setCountdown(30);
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    setCountdownIntervalId(intervalId);
  };

  const stopCountdown = () => {
    clearInterval(countdownIntervalId);
    setCountdownIntervalId(null);
    setCountdown(30);
  };

  // navigate to permission first page if user is already logged in
  const navigateToPermission = (token = null) => {
    if (token) {
      const decoded = decodedToken(token);
      if (!decoded) return false;
      if (decoded?.access === "admin") navigate("/dashboard");
      else if (decoded?.access === "subadmin") {
        let permissions = decoded?.rolepermissions;
        if (permissions?.length) {
          if (permissions.includes("dashboard")) navigate("/dashboard");
          else if (permissions.includes("customers"))
            navigate("/customer-listing");
          else if (permissions.includes("category"))
            navigate("/category-listing");
          else if (permissions.includes("product")) navigate("/product-list");
          else if (permissions.includes("order"))
            navigate("/order-history-listing");
          else if (permissions.includes("coupons"))
            navigate("/coupon-codes-list");
          else if (permissions.includes("bundle")) navigate("/bundle-listing");
          else if (permissions.includes("sub_admin"))
            navigate("/roles-listing");
          else if (permissions.includes("cms")) navigate("/shipping-policy");
          else if (permissions.includes("currency_tax"))
            navigate("/currency-conversion");
          else if (permissions.includes("newsletter_subscription"))
            navigate("/newsletter-list");
          else if (permissions.includes("contact_us_list"))
            navigate("/contact-us");
          else if (permissions.includes("system_settings"))
            navigate("/pickup-address");
        }
      }
    }
  };

  useEffect(() => {
    if (countdown === 0) {
      clearInterval(countdownIntervalId);
      setCountdownIntervalId(null);
    }
    const AdminAccess = addDeleteGetLocalStorage(
      LOCAL_STORAGE_KEYS?.USER_TOKEN,
      {},
      "get"
    );
    if (AdminAccess) {
      navigateToPermission(AdminAccess);
    }
  }, [countdown, countdownIntervalId]);

  const cities = [
    { name: "UAE", code: "dubai" },
    { name: "IRAQ", code: "baghdad" },
  ];

  const handleLogin = () => {
    let error = false;
    // if(!verified) {
    //   setEmailErr("Please verify email");
    //   error = true;
    // }
    if (!validateEmail(email)) {
      setEmailErr("Please enter valid email");
      error = true;
    }
    if (!email) {
      setEmailErr("Email is required");
      error = true;
    }
    if (!password) {
      setPasswordErr("Password is required");
      error = true;
    }
    if (!selectDatabse) {
      setSelectDatabseErr("Please select database");
      error = true;
    }
    if (error) return;
    else {
      let data = { email, password, Location: selectDatabse.code };
      setLoading(true);
      globalRequestAxios("post", data, AuthRequests.ADMIN_LOGIN).then((res) => {
        if (res?.ack == 0) {
          setLoading(false);
          if (res?.errMsg) {
            res?.errMsg.forEach((element) => {
              for (const [key, value] of Object.entries(element)) {
                if (key === "email") {
                  setEmailErr(value);
                }
                if (key === "selectDatabse") {
                  setSelectDatabseErr(value);
                }
              }
            });
          } else {
            setInvalid_credentialErr(res?.msg);
            // if (res?.msg === "Invalid email or password.") {
            //   setInvalid_credentialErr("Invalid credentials");
            // }
          }
        } else {
          setAlert("Admin has logged in successfully.", "success");
          addDeleteGetLocalStorage(
            LOCAL_STORAGE_KEYS?.USER_TOKEN,
            res?.token,
            "add",
            "single"
          );
          addDeleteGetLocalStorage(
            LOCAL_STORAGE_KEYS?.DB_LOCATION,
            res?.Location,
            "add",
            "single"
          );
          navigateToPermission(res?.token);
          setLoading(false);
        }
      });
    }
  };

  const handleforgotPassword = () => {
    let error = false;
    if (!emailForReset) {
      setEmailForResetErr("Email is required");
      error = true;
    }
    if (error) return;
    else {
      let dataPost = { email: emailForReset };
      setLoadingforgotPassword(true);
      globalRequestAxios("post", dataPost, AuthRequests.ADMIN_FORGOT_PASSWORD)
        .then((response) => {
          const data = response;
          if (data.ack == 1) {
            setVisibleForgotPass(false);
            // setVisibleOtp(true); //we are sending change password link instead of otp
            setAlert(data?.msg, "success");
            Countdown_handler();
            setLoadingforgotPassword(false);
          } else {
            if (data?.ack == 0) {
              setLoadingforgotPassword(false);
              if (data?.errMsg) {
                data?.errMsg.forEach((element) => {
                  for (const [key, value] of Object.entries(element)) {
                    if (key === "email") {
                      setEmailForResetErr(value);
                    }
                  }
                });
              } else {
                setEmailForResetErr(data?.msg);
              }
            }
          }
        })
        .catch((e) => {});
    }
  };

  const handleOtp = () => {
    let error = false;
    if (!otpForReset) {
      setOtpForResetErr("OTP is required");
      error = true;
    }
    if (error) return;
    else {
      let data = { otp: otpForReset ? otpForReset : "", email: emailForReset };
      setLoadingOtp(true);
      globalRequestAxios("post", data, AuthRequests.ADMIN_VERIFY_OTP)
        .then((response) => {
          const data = response;
          if (data.ack == 1) {
            setVisibleForgotPass(false);
            setVisibleOtp(false);
            setVisibleSetPass(true);
            setAlert(data?.msg, "success");
          } else {
            if (data?.ack == 0) {
              setLoadingOtp(false);
              if (data?.errMsg) {
                data?.errMsg.forEach((element) => {
                  for (const [key, value] of Object.entries(element)) {
                    if (key === "otp") {
                      setOtpForResetErr(value);
                    }
                  }
                });
              } else {
                setOtpForResetErr(data?.msg);
              }
            }
          }
        })
        .catch((e) => {});
    }
  };

  const handleChangePassword = () => {
    let error = false;
    if (!validatePassword(newPassword)) {
      setNewPasswordErr(
        "Password should be with uppercase, lowercase, number, symbol and least 8 characters"
      );
      error = true;
    }
    if (!confirmPassword) {
      setConfirmPasswordErr("Confirm Password is required");
      error = true;
    }
    if (!newPassword) {
      setNewPasswordErr("Password is required");
      error = true;
    }
    if (confirmPassword) {
      if (newPassword !== confirmPassword) {
        setConfirmPasswordErr("Password not match");
        error = true;
      }
      if (!password) {
        if (!validatePassword(confirmPassword) && !password) {
          setConfirmPasswordErr(
            "Password should be with uppercase, lowercase, number, symbol and least 8 characters"
          );
          error = true;
        }
      }
    }
    if (error) return;

    let data = {
      otp: otpForReset ? otpForReset : "",
      email: emailForReset,
      password: confirmPassword,
    };
    setLoadingChangePassword(true);
    globalRequestAxios(
      "post",
      data,
      AuthRequests.ADMIN_FORGOT_PASSWORD_CHANGE
    ).then((response) => {
      setLoadingChangePassword(false);
      const data = response;
      if (data.ack == 1) {
        setVisibleForgotPass(false);
        setVisibleOtp(false);
        setVisibleSetPass(false);
        setAlert(data?.msg, "success");
      } else {
        if (data?.ack == 0) {
          setLoadingChangePassword(false);
          if (data?.errMsg) {
            data?.errMsg.forEach((element) => {
              for (const [key, value] of Object.entries(element)) {
                if (key === "password") {
                  setConfirmPasswordErr(value);
                }
              }
            });
          } else {
            setConfirmPasswordErr(data?.msg);
          }
        }
      }
    });
  };

  //send otp to verify email
  const handleSendOtp = useCallback(() => {
    setOtpErr("");
    setInvalid_credentialErr("");
    setOtp();
    let error = false;
    if (!validateEmail(email)) {
      setEmailErr("Enter valid email address");
      error = true;
    }
    if (!email) {
      setEmailErr("Email is required");
      error = true;
    }
    if (!password) {
      setPasswordErr("Password is required");
      error = true;
    }
    if (!selectDatabse) {
      setSelectDatabseErr("Please select database");
      error = true;
    }
    if (error) return;
    dispatch(changeLoader(true));
    let data = { email };
    globalRequestAxios("post", data, AuthRequests.ADMIN_MFA_SEND_OTP)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setSended_otp(true);
          setPopup_open(true);
          setEmailErr("");
          Countdown_handler();
        } else {
          setEmailErr(res?.msg ? res.msg : "Something went wrong");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: "Something went wrong",
          })
        );
      });
  });
  //verify email
  const handleVerifyEmail = useCallback(() => {
    if (!otp) {
      setOtpErr("OTP is required");
      return;
    }
    dispatch(changeLoader(true));
    let data = { email, otp };
    globalRequestAxios("post", data, AuthRequests.ADMIN_MFA_VERIFY_OTP)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          // setVerified(true);
          setPopup_open(false);
          handleLogin();
        } else {
          if (res?.msg == "Invalid OTP.") {
            setOtpErr("Invalid OTP");
          } else {
            setOtpErr(res.msg);
          }
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            state: "error",
            message: "Something went wrong",
          })
        );
      });
  });

  return (
    <>
      <div className="Login-page">
        <div className="LoginformContent">
          <div className="grid">
            <div
              className="lg:col-4 md:col-4 sm:col-10 col-12"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            >
              <div className="LoginForm">
                <h3 className="h3 mb-2" style={{ textAlign: "center" }}>
                  Admin Login
                </h3>
                <p className="p2 mb-5" style={{ textAlign: "center" }}>
                  Please fill in all the required information
                </p>
                <div className="grid">
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          style={{ borderColor: emailErr ? "#f55e71" : "" }}
                          value={email}
                          onChange={(e) => {
                            setEmailErr("");
                            if (!validateEmail(e.target.value.trimStart())) {
                              setEmailErr("Enter valid email address");
                            }
                            if (!e.target.value.trimStart().length) {
                              setEmailErr("Email is required");
                            }
                            setEmail(e.target.value.trimStart());
                          }}
                          id="Email"
                          placeholder={t("Email")}
                          readOnly={verified}
                        />
                        <label htmlFor="OTP"></label>
                        <label htmlFor="Email">
                          {t("Email")} <span className="req-star">*</span>
                        </label>
                      </span>
                      {emailErr && <div className="p-error">{t(emailErr)}</div>}
                    </div>
                  </div>
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          id="Password"
                          value={password}
                          style={{ borderColor: passwordErr ? "#f55e71" : "" }}
                          type={!show_pass ? "text" : "password"}
                          onChange={(e) => {
                            setPassword(e.target.value.trimStart());
                            setPasswordErr("");
                          }}
                          autoComplete="off"
                        />
                        <label htmlFor="Password">Password</label>
                        <i
                          className={
                            !show_pass
                              ? "pi pi-eye passwordIcon"
                              : "pi pi-eye-slash passwordIcon"
                          }
                          onClick={() => {
                            setShowPass(!show_pass);
                          }}
                        ></i>
                        {passwordErr && (
                          <div className="p-invalid">{passwordErr}</div>
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    className="lg:col-12 md:col-12 sm:col-12 col-12 pt-0 pb-0"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <p
                      className="forgotpassword"
                      onClick={() => {
                        setVisibleForgotPass(true);
                        setEmailForResetErr(false);
                        setEmailForReset("");
                        setLoadingforgotPassword(false);
                        setLoadingChangePassword(false);
                        setLoadingOtp(false);
                      }}
                    >
                      Forgot Password
                    </p>
                  </div>
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <Dropdown
                          value={selectDatabse}
                          onChange={(e) => {
                            setSelectDatabse(e.value);
                            setSelectDatabseErr(false);
                          }}
                          options={cities}
                          optionLabel="name"
                          placeholder="Select Database"
                          className="filterDropdown"
                        />
                        {selectDatabseErr && (
                          <div className="p-invalid">{selectDatabseErr}</div>
                        )}
                        <label htmlFor="DiscountType">Select Database</label>
                      </span>
                    </div>
                  </div>
                  {invalid_credentialErr && (
                    <div className="p-error ml-2">
                      {t(invalid_credentialErr)}
                    </div>
                  )}
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <Button
                      disabled={loading}
                      onClick={() => {
                        //  handleLogin();
                        handleSendOtp();
                      }}
                      className="btn btn-blue"
                    >
                      Verify Email
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* forgot password popup */}
      <Dialog
        header="Forgot Password"
        className="dialogWidth400 headetPadding"
        visible={visibleForgotPass}
        onHide={() => setVisibleForgotPass(false)}
        draggable={false}
      >
        <div className="addformDialog pt-0">
          <p className="p2 mb-4 mt-0" style={{ textAlign: "left" }}>
            No worries, we’ll send you reset instructions.
          </p>
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="p-float-label">
                  <InputText
                    id="tile"
                    autoComplete="off"
                    onChange={(e) => {
                      setEmailForReset(e.target.value.trimStart());
                      setEmailForResetErr(false);
                    }}
                  />
                  {emailForResetErr && (
                    <div className="p-invalid">{emailForResetErr}</div>
                  )}
                  <label htmlFor="tile">
                    Email <span className="requiredstar">*</span>
                  </label>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <Button
                disabled={loadingforgotPassword}
                onClick={() => {
                  handleforgotPassword();
                }}
                className="btn btn-blue"
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      {/* we are sending url for change password that's why below code is not required */}
      {/* <Dialog
        header="Verify Email"
        className="dialogWidth400 headetPadding"
        visible={visibleOtp}
        style={{ width: "50vw" }}
        onHide={() => {
          setVisibleOtp(false);
          stopCountdown();
        }}
        draggable={false}
      >
        <div className="addformDialog">
          <p className="p2 mb-4 mt-0" style={{ textAlign: "left" }}>
            We will send a verification code on your email.
          </p>
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="p-float-label">
                  <InputNumber
                    //  className={otpForResetErr ? "p-invalid" : null}
                    value={otpForReset}
                    onChange={(e) => {
                      setOtpForReset(e.value);
                      setOtpForResetErr(false);
                    }}
                    useGrouping={false}
                    id="tile"
                    placeholder={"Enter OTP"}
                  />
                  <label htmlFor="tile">
                    OTP <span className="requiredstar">*</span>
                  </label>

                  {countdown !== 0 && (
                    <span className="passwordIcon">
                      00:{countdown > 9 ? countdown : "0" + countdown}
                    </span>
                  )}
                  {countdown == 0 && (
                    <div
                      className="passwordIcon"
                      onClick={() => {
                        handleforgotPassword();
                        setOtpForResetErr(false);
                        setOtpForReset();
                      }}
                    >
                      <span
                        style={{
                          color: "#F9B000",
                          position: "relative",
                          top: "-4px",
                        }}
                      >
                        {"Resend OTP"}
                      </span>
                    </div>
                  )}

                  {otpForResetErr && (
                    <div className="p-invalid">{otpForResetErr}</div>
                  )}
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <Button
                disabled={loadingOtp}
                onClick={() => {
                  handleOtp();
                  setConfirmPasswordErr(false);
                }}
                className="btn btn-blue"
              >
                Verify
              </Button>
            </div>
          </div>
        </div>
      </Dialog> */}
      {/* <Dialog
        header="Reset Password"
        className="dialogWidth400 headetPadding"
        visible={visibleSetPass}
        style={{ width: "50vw" }}
        onHide={() => {
          setVisibleSetPass(false);
          setOtpForReset();
          stopCountdown();
        }}
      >
        <div className="addformDialog">
          <p className="p2 mb-4 mt-0" style={{ textAlign: "left" }}>
            No worries, you can change the password any time.
          </p>
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="p-float-label">
                  <InputText
                    style={{ borderColor: newPasswordErr ? "#f55e71" : "" }}
                    type={!show_NPass ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => {
                      setNewPasswordErr("");
                      setNewPassword(e.target.value.trimStart());
                    }}
                    id="Nnewpassword"
                    autoComplete="off"
                  />
                  <label htmlFor="Nnewpassword">
                    New Password <span className="requiredstar">*</span>
                  </label>
                  <i
                    className={
                      !show_NPass
                        ? "pi pi-eye passwordIcon"
                        : "pi pi-eye-slash passwordIcon"
                    }
                    onClick={() => {
                      setShowNPass(!show_NPass);
                    }}
                  ></i>
                </span>
                {newPasswordErr && (
                  <div
                    className="p-invalid"
                    style={{ position: "relative", bottom: "-3px" }}
                  >
                    {newPasswordErr}
                  </div>
                )}
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="p-float-label">
                  <InputText
                    value={confirmPassword}
                    style={{ borderColor: newPasswordErr ? "#f55e71" : "" }}
                    type={!show_CPass ? "text" : "password"}
                    onChange={(e) => {
                      setConfirmPasswordErr("");
                      setConfirmPassword(e.target.value.trimStart());
                    }}
                    id="cPassword"
                    autoComplete="off"
                  />
                  <label htmlFor="cPassword">
                    Confirm Password <span className="requiredstar">*</span>
                  </label>
                  <i
                    className={
                      !show_CPass
                        ? "pi pi-eye passwordIcon"
                        : "pi pi-eye-slash passwordIcon"
                    }
                    onClick={() => {
                      setShowCPass(!show_CPass);
                    }}
                  ></i>
                </span>
                {confirmPasswordErr && (
                  <div
                    className="p-invalid"
                    style={{ position: "relative", bottom: "-3px" }}
                  >
                    {confirmPasswordErr}
                  </div>
                )}
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <Button
                disabled={loadingChangePassword}
                onClick={() => {
                  handleChangePassword();
                }}
                className="btn btn-yellow mt-4"
              >
                Save Password
              </Button>
            </div>
          </div>
        </div>
      </Dialog> */}

      {/* VERIFY EMAIL POPUP for login  */}
      <Dialog
        visible={popup_open}
        draggable={false}
        resizable={false}
        closable={false}
        showHeader={false}
        className="dialog-407"
      >
        <button
          className="modal-close"
          onClick={() => {
            setPopup_open(false);
            setOtpErr("");
            setOtp();
            stopCountdown();
          }}
        >
          &times;
        </button>
        <h3 className="h3 mb-2 mt-4">{t("Verify Email")}</h3>
        <p className="p2 mb-4">
          {t("We have sent the verification code to your mail")}
        </p>
        {sended_otp && (
          <div className="input-parent formField mb-4">
            <span className="p-float-label">
              <InputText
                value={otp ? otp : ""}
                onChange={(e) => {
                  let value = e.target.value.trimStart();
                  setOtpErr("");
                  if (!value) {
                    setOtpErr("OTP is required");
                  }
                  if (isNaN(value)) {
                    setOtp(otp);
                  } else {
                    setOtp(value);
                  }
                }}
                useGrouping={false}
                id="Otp"
                placeholder={t("OTP")}
                style={{ width: "100%" }}
              />
              <label htmlFor="OTP">
                {t("OTP")} <span className="req-star">*</span>
              </label>
              {countdown !== 0 && (
                <span className="otp-input-text">
                  00:{countdown > 9 ? countdown : "0" + countdown}
                </span>
              )}
              {countdown == 0 && (
                <div
                  className="otp-input-text"
                  onClick={() => {
                    handleSendOtp();
                  }}
                >
                  {t("Resend OTP")}
                </div>
              )}
            </span>
            {otpErr ? <div className="p-error">{t(otpErr)}</div> : null}
          </div>
        )}
        <Button
          // disabled={countdown==0}
          style={{ marginBottom: "10px" }}
          onClick={(e) => {
            if (!sended_otp) {
              handleSendOtp();
            } else {
              handleVerifyEmail();
            }
          }}
          className="btn btn-blue w-full"
          label={t(sended_otp ? t("Login") : t("SendOTP"))}
        />
      </Dialog>
    </>
  );
};
