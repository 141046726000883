import { useState, useEffect } from "react";
import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import "primeicons/primeicons.css";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests, LOCAL_STORAGE_KEYS } from "../auth/AuthRequests";
import addDeleteGetLocalStorage from "../../globalModules/addDeleteGetLocalStorage ";
import { reduce_image_file_size } from "../../Helpers/UploadIMGHelper";
import { InputSwitch } from "primereact/inputswitch";
import { ShowImage } from "../../popups/ShowImage/ShowImage";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Editor } from "primereact/editor";
import { imageBaseUrl } from "../../Helpers/HelperFunctions";
import noImage from "../../assets/images/products/noImage.webp";
import { InputTextarea } from "primereact/inputtextarea";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";

export const ProductEdit = ({ setAlert }) => {
  const location = addDeleteGetLocalStorage(
    LOCAL_STORAGE_KEYS?.DB_LOCATION,
    {},
    "get"
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);

  const Discount = [
    { name: "Fixed Amount", code: "fixed_amount" },
    { name: "Percentage", code: "percentage" },
  ];
  const [selectedCurrency, setSelectedCurrency] = useState(
    location == "dubai"
      ? { name: "AED", code: "AED" }
      : { name: "USD", code: "USD" }
  );
  const Currencies = [
    { name: "AED", code: "AED" },
    { name: "USD", code: "USD" },
  ];
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState(false);

  //Edit customer State
  //Product Information State
  const [title, setTitle] = useState("");
  const [short_description, setShortDescription] = useState("");
  const [long_description, setLongDescription] = useState("");
  const [model_number, setModelNumber] = useState("");
  const [cost_price, setCostPrice] = useState("");
  const [selling_price, setSellingPrice] = useState("");
  const [discount_price, setDiscountPrice] = useState("");
  const [mainCategory, setMainCategory] = useState("");
  const [sub_Category, setSub_Category] = useState("");
  const [specificationsAdnValues, setSpecificationsAdnValues] = useState([]);
  const [productDetails, setProductDetails] = useState({});
  const [existingImages, setExistingImages] = useState([]);
  const [deleteimages, setDeleteimages] = useState([]); // to store images to be deleted
  const [deletewholesellprice, setDeletewholesellprice] = useState([]); // to store wholesale price to be deleted
  const [publishSatus, setPublishSatus] = useState("inactive");
  const [isTodaysOffer, setIsTodaysOffer] = useState(false);
  const [showImgUrl, setShowImgUrl] = useState("");
  const [wholesalePriceArr, setWholesalePriceArr] = useState([]);
  const [save_wholeSale_priceArr, setSave_wholeSale_priceArr] = useState([]); // to store wholesale price to be saved
  const [productWeight, setProductWeight] = useState("");
  const [productHeight, setProductHeight] = useState("");
  const [productWidth, setProductWidth] = useState("");
  const [productLength, setProductLength] = useState("");
  ///states for image cropper
  const [select_img, setselect_img] = useState(null);
  const cropperRef = useRef(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState("");
  const [checkedFeature, setCheckedFeature] = useState(false);

  //Product Information Err State
  const [titleErr, setTitleErr] = useState("");
  const [shortDescriptionErr, setShortDescriptionErr] = useState("");
  const [longDescriptionErr, setLongDescriptionErr] = useState("");
  const [modelNumberErr, setModelNumberErr] = useState("");
  const [sellingPriceErr, setSellingPriceErr] = useState();
  const [discountPriceErr, setDiscountPriceErr] = useState("");
  const [mainCategoryErr, setMainCategoryErr] = useState("");
  const [sub_CategoryErr, setSub_CategoryErr] = useState("");
  const [isRecommended, setIsRecommended] = useState(false);
  const [productWeightErr, setProductWeightErr] = useState("");
  const [showEditer, setShowEditer] = useState(false);
  const [productLengthErr, setProductLengthErr] = useState("");
  const [productWidthErr, setProductWidthErr] = useState("");
  const [productHeightErr, setProductHeightErr] = useState("");

  //Get Product Details id
  const { id } = useParams();
  const getProductDetails = async () => {
    dispatch(changeLoader(true));
    await globalRequestAxios("get", {}, AuthRequests.GET_SINGLE_PRODUCT + id)
      .then((res) => {
        dispatch(changeLoader(false));
        setShowEditer(true);
        if (res?.ack == 1) {
          if (res.data == null) return navigate("/product-list");
          const product = res?.data;
          setProductDetails(product);
          setModelNumber(product?.model_number);
          setIsRecommended(product?.is_recommended == 1 ? true : false);
          setCostPrice(product?.cost_price);
          setSellingPrice(product?.selling_price);
          setDiscountPrice(product?.discount_price);
          setTitle(product?.title);
          setShortDescription(product?.short_description);
          setLongDescription(product?.long_description);
          setChecked(product?.is_wholesale == 1 ? true : false);
          setIsTodaysOffer(product?.is_todays_offer == 1 ? true : false);
          setCheckedFeature(product?.is_featured == 1 ? true : false);
          setPublishSatus(product?.status);
          setProductWeight(product?.weight);
          setProductHeight(product?.height);
          setProductWidth(product?.width);
          setProductLength(product?.length);
          //images
          setExistingImages(product?.product_images);
          //categories and subcategories
          setMainCategory(product?.category_details?.name_en);
          setSub_Category(product?.subcategory_details?.name_en);
          //specifications
          let specifications = [];
          if (res?.data?.product_specification.length > 0) {
            res?.data?.product_specification.map((item) => {
              specifications.push({
                id: item.id,
                name: item.name,
                product_specification_value:
                  item.product_specification_value !== null
                    ? {
                        id: item?.product_specification_value?.id,
                        specification_value:
                          item?.product_specification_value
                            ?.specification_value,
                      }
                    : {
                        id: 0,
                        specification_value: "",
                      },
              });
            });
          }

          setSpecificationsAdnValues(specifications);
          // setSpecificationsAdnValues(res?.data?.product_specification);
          //wholesale price
          let wholesalearr = [];
          if (res?.data?.price_ranges?.length) {
            // if there is wholesale price then only add existing wholesale price else add empty array
            res?.data?.price_ranges?.map((item) => {
              wholesalearr.push({
                start_range: item.start_range,
                end_range: item.end_range,
                discounted_price: item.discounted_price.toString(),
                wholesale_price: item.wholesale_price,
                discountType: {
                  name:
                    item.discounted_type == "fixed_amount"
                      ? "Fixed Amount"
                      : "Percentage",
                  code: item?.discounted_type,
                },
                selling_price: item.selling_price,
                id: item.id,
              });
            });
          } else {
            wholesalearr.push({
              start_range: 1,
              end_range: "",
              discounted_price: "",
              wholesale_price: "",
              discountType: { name: "Fixed Amount", code: "fixed_amount" },
              selling_price: selling_price,
              id: 0,
            });
          }
          setWholesalePriceArr(wholesalearr);
          let deepArr = JSON.parse(JSON.stringify(wholesalearr));
          setSave_wholeSale_priceArr([...deepArr]);
        } else {
          //if product not found then redirect to product list
          if (res?.msg == "Something went wrong") {
            navigate("/product-list");
          }
        }
      })
      .catch((err) => {
        setShowEditer(true);
        dispatch(changeLoader(false));
        navigate("/product-list");
      });
  };
  useEffect(() => {
    getProductDetails();
  }, []);

  //handle crop images
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImageUrl(cropper.getCroppedCanvas().toDataURL());
    // setCroppedImageUrl(
    //   cropper.getCroppedCanvas({ width: 500, height: 500 }).toDataURL()
    // );
  };

  //save unsave wholeSale price
  const manageWholeSalePrice = (type) => {
    if (type == "unsave") {
      if (save_wholeSale_priceArr.length > 0) {
        setWholesalePriceArr(save_wholeSale_priceArr);
      }
      setVisible(false);
    } else {
      let error = false;
      let arr = [...wholesalePriceArr];
      wholesalePriceArr.map((item, index) => {
        //check start range and end range
        if (item?.start_range && item?.end_range) {
          if (+item?.start_range >= +item?.end_range) {
            arr[index].end_rangeErr =
              "Maximum Quantity should be greater than Minimum Quantity";
            error = true;
          }
        }
        if (!item?.start_range) {
          arr[index].start_rangeErr = "Please enter minimum quantity";
          error = true;
        }
        if (!item?.end_range) {
          arr[index].end_rangeErr = "Please enter maximum quantity";
          error = true;
        }
        if (
          item?.discounted_price &&
          item?.discountType?.code == "fixed_amount"
        ) {
          if (+item?.discounted_price > +selling_price) {
            arr[index].discounted_priceErr =
              "Discounted price should be less then or equal the selling price";
            error = true;
          }
        }
        if (!item?.discounted_price) {
          arr[index].discounted_priceErr = "Please enter discounted price";
          error = true;
        }
      });
      setWholesalePriceArr(arr);
      if (error) {
        setVisible(true);
        return;
      }
      let deepArr = JSON.parse(JSON.stringify(arr));
      setSave_wholeSale_priceArr([...deepArr]);
      setVisible(false);
    }
  };

  //Product edit
  const update_Product = async () => {
    let error = false;
    if (!productLength) {
      setProductLengthErr("Please enter product length");
      error = true;
    }
    if (!productWidth) {
      setProductWidthErr("Please enter product width");
      error = true;
    }
    if (!productHeight) {
      setProductHeightErr("Please enter product height");
      error = true;
    }
    if (!productWeight) {
      setProductWeightErr("Please enter product weight");
      error = true;
    }
    if (title) {
      if (title.length > 250) {
        setTitleErr("Title should be less than 250 characters");
        error = true;
      }
    }
    if (selling_price) {
      if (+selling_price < +cost_price) {
        setSellingPriceErr("Selling price must be greater than cost price");
        error = true;
      }
    }
    if (discount_price) {
      if (+discount_price > +selling_price) {
        setDiscountPriceErr(
          "Discounted price should be equal to or less than the selling price"
        );
        error = true;
      }
    }
    if (short_description) {
      if (
        short_description.replace(/<[^>]+>/g, "").replace(/\s{2,}/g, " ")
          .length > 124
      ) {
        setShortDescriptionErr(
          "Short description should be less than 124 characters"
        );
        error = true;
      }
    }
    if (long_description) {
      if (
        long_description.replace(/<[^>]+>/g, "").replace(/\s{2,}/g, " ")
          .length > 1000
      ) {
        setLongDescriptionErr(
          "Long description should be less than 1000 characters"
        );
        error = true;
      }
    }
    if (!title) {
      setTitleErr("Please enter title");
      error = true;
    }
    if (!model_number) {
      setModelNumberErr("Please enter model number");
      error = true;
    }
    if (selling_price == null && selling_price != 0) {
      setSellingPriceErr("Please enter selling price");
      error = true;
    }
    if (discount_price == null && discount_price != 0) {
      setDiscountPriceErr("Please enter discount price");
      error = true;
    }
    if (existingImages.length == 0) {
      setAlert("Please add at least one image", "error");
      error = true;
    }
    // if (specificationsAdnValues.length > 0) {
    //   specificationsAdnValues.map((item, index) => {
    //     if (!item?.product_specification_value?.specification_value) {
    //       let arr = [...specificationsAdnValues];
    //       arr[index].specification_valueErr = "Please enter value";
    //       setSpecificationsAdnValues(arr);
    //       error = true;
    //     }
    //   });
    // }
    if (checked && !error) {
      let arr = [...wholesalePriceArr];
      wholesalePriceArr.map((item, index) => {
        //check start range and end range
        if (item?.start_range && item?.end_range) {
          if (+item?.start_range >= +item?.end_range) {
            arr[index].start_rangeErr =
              "Minimum Quantity should be less than Maximum Quantity";
            error = true;
          }
        }
        if (!item?.start_range) {
          arr[index].start_rangeErr = "Please enter minimum quantity";
          error = true;
        }
        if (!item?.end_range) {
          arr[index].end_rangeErr = "Please enter maximum quantity";
          error = true;
        }
        if (
          item?.discounted_price &&
          item?.discountType?.code == "fixed_amount"
        ) {
          if (+item?.discounted_price > +selling_price) {
            arr[index].discounted_priceErr =
              "Discounted price should be less then or equal the selling price";
            error = true;
          }
        }
        if (!item?.discounted_price) {
          arr[index].discounted_priceErr = "Please enter discounted price";
          error = true;
        }
      });
      setWholesalePriceArr(arr);
      if (error) {
        setVisible(true);
      }
    }
    if (error) {
      window.scrollTo({ behavior: "smooth", top: 300 });
      return;
    }
    //create send data object
    let wholesalePriceArrSend = [];
    // let del_whole_id = [];
    wholesalePriceArr.map((item) => {
      wholesalePriceArrSend.push({
        startRange: item?.start_range,
        endRange: item?.end_range,
        discountedPrice: item?.discounted_price,
        wholesalePrice: item?.wholesale_price,
        discountedType: item?.discountType?.code,
        sellingPrice: selling_price, //selling price is same for all wholesale price
        id: item?.id,
      });
      //store wholesale price id to delete
      // if(item?.id!=0) del_whole_id.push(item?.id);
    });
    let specificationSendArr = [];
    specificationsAdnValues.map((item) => {
      specificationSendArr.push({
        specification_id: item?.id,
        specification_value:
          item?.product_specification_value?.specification_value,
        id: item?.product_specification_value?.id,
      });
    });
    let imagesArrSend = [];
    existingImages.map((item) => {
      imagesArrSend.push({
        isFeature: item?.is_featured,
        imageUrl: item?.image_url,
        id: item?.id,
      });
    });

    let data = {
      id: id,
      categoryId: productDetails?.category_id.toString(),
      subCategoryId: productDetails?.subcategory_id.toString(),
      // code: productDetails?.code ? productDetails?.code : "",
      title: title,
      shortDescription: short_description,
      longDescription: long_description,
      discountPrice: discount_price.toString(),
      sellingPrice: selling_price.toString(),
      costPrice: productDetails?.cost_price.toString(),
      wholesellprice: JSON.stringify(wholesalePriceArrSend),
      specifications: JSON.stringify(specificationSendArr),
      isWholesale: checked ? "1" : "0",
      saveStatus: publishSatus,
      modelNumber: model_number,
      images: JSON.stringify(imagesArrSend),
      deleteimages: JSON.stringify(deleteimages),
      deletewholesellprice: JSON.stringify(deletewholesellprice),
      isRecommended: isRecommended ? "1" : "0",
      weight: productWeight.toString(),
      isOffer: isTodaysOffer ? "1" : "0",
      length: productLength.toString(),
      width: productWidth.toString(),
      height: productHeight.toString(),
      isFeature: checkedFeature ? "1" : "0",
    };
    dispatch(changeLoader(true));
    await globalRequestAxios("post", data, AuthRequests.UPDATE_PRODUCT)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res?.ack == 1) {
          setAlert("Product updated successfully", "success");
          navigate("/product-list");
        } else {
          setAlert("Something went wrong", "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  const handleUploadImage = async (file) => {
    // const original = await image_to_base64(file);
    const original = file; //already base64
    //compressed image file
    let compressed = await reduce_image_file_size(original);
    //find the size of the original and compressed file
    const binaryDataCompressed = atob(compressed.split(",")[1]);
    const fileSizeMBCompressed = (
      binaryDataCompressed?.length /
      (1024 * 1024)
    ).toFixed(2);
    if (fileSizeMBCompressed <= 5) {
      //set base64 image
      let singleImgObj = {
        id: 0,
        image_url: compressed,
        is_featured: 0,
      };
      await setExistingImages([...existingImages, singleImgObj]);
      setselect_img(null);
    } else {
      setAlert(
        "Invalid file, please upload a JPEG or PNG image within the size limit of 5MB.",
        "error"
      );
    }
  };
  // delete image
  function handleDeleteImage(index) {
    const updatedExistingImages = [...existingImages]; // make a copy of the array
    updatedExistingImages.splice(index, 1); // remove the image at the specified index
    setExistingImages(updatedExistingImages); // update the state

    let arr = [...deleteimages];
    if (existingImages[index].id != 0) {
      arr.push(existingImages[index].id);
    }
    setDeleteimages(arr);
  }

  //check one image is featured prsent or not in the array if not then set first image as featured
  useEffect(() => {
    let is_ = false;
    existingImages.map((item, index) => {
      if (item.is_featured == 1) {
        is_ = true;
      }
    });
    if (!is_) {
      if (existingImages.length > 0) {
        let arr = [...existingImages];
        arr[0].is_featured = 1;
        setExistingImages(arr);
      }
    }
  }, [existingImages]);

  //update wholesale prices
  useEffect(() => {
    let arr = [...wholesalePriceArr];
    arr.map((item, index) => {
      if (item?.discountType?.code == "percentage") {
        item.wholesale_price = (
          +selling_price -
          (+selling_price * +item?.discounted_price) / 100
        ).toFixed(2);
      }
      if (item?.discountType?.code == "fixed_amount") {
        item.wholesale_price = (
          +selling_price - +item?.discounted_price
        ).toFixed(2);
      }
    });
    setWholesalePriceArr(arr);
  }, [selling_price]);

  return (
    <>
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="formHeader">
            <h4 className="h4 mb-2">Product edit</h4>
          </div>
          <div className="contentBox-w">
            <div className="grid">
              <div className="lg:col-10 md:col-10 sm:col-12 col-12">
                <div className="grid">
                  <div className="lg:col-12 md:col- sm:col-12 col-12">
                    <p className="p1 mb-2">Categories Info</p>
                  </div>
                  <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          id="modelno"
                          autoComplete="off"
                          disabled
                          value={mainCategory}
                          onChange={(e) => {
                            setMainCategory(e.target.value);
                            setMainCategoryErr("");
                          }}
                          style={{
                            borderColor: mainCategoryErr ? "#f55e71" : "",
                          }}
                        />
                        <label htmlFor="modelno">Main Category</label>
                        {mainCategoryErr && (
                          <div className="p-error">{mainCategoryErr}</div>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          id="modelno1"
                          autoComplete="off"
                          disabled
                          value={sub_Category}
                          onChange={(e) => {
                            setSub_Category(e.target.value);
                            setSub_CategoryErr("");
                          }}
                          style={{
                            borderColor: sub_CategoryErr ? "#f55e71" : "",
                          }}
                        />
                        <label htmlFor="modelno1">Sub Category</label>
                        {sub_CategoryErr && (
                          <div className="p-error">{sub_CategoryErr}</div>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          id="modelno2"
                          autoComplete="off"
                          value={model_number}
                          onChange={(e) => {
                            setModelNumber(e.target.value);
                            setModelNumberErr("");
                          }}
                          style={{
                            borderColor: modelNumberErr ? "#f55e71" : "",
                          }}
                        />
                        <label htmlFor="modelno2">Model Number*</label>
                      </span>
                      {modelNumberErr && (
                        <div className="p-error">{modelNumberErr}</div>
                      )}
                    </div>
                  </div>
                  <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          id="modelno3"
                          autoComplete="off"
                          value={productWeight}
                          min={0}
                          useGrouping={false}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          onChange={(e) => {
                            setProductWeightErr("");
                            setProductWeight(e.value);
                          }}
                          style={{
                            borderColor: productWeightErr ? "#f55e71" : "",
                          }}
                        />
                        <label htmlFor="modelno2">Product Weight (kg)*</label>
                      </span>
                      {productWeightErr && (
                        <div className="p-error">{productWeightErr}</div>
                      )}
                    </div>
                  </div>
                  <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          id="modelno3"
                          autoComplete="off"
                          value={productHeight}
                          min={0}
                          useGrouping={false}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          onChange={(e) => {
                            setProductHeightErr("");
                            setProductHeight(e.value);
                          }}
                          style={{
                            borderColor: productHeightErr ? "#f55e71" : "",
                          }}
                        />
                        <label htmlFor="modelno2">Product Height (cm)*</label>
                      </span>
                      {productHeightErr && (
                        <div className="p-error">{productHeightErr}</div>
                      )}
                    </div>
                  </div>
                  <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          id="modelno3"
                          autoComplete="off"
                          value={productWidth}
                          min={0}
                          useGrouping={false}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          onChange={(e) => {
                            setProductWidthErr("");
                            setProductWidth(e.value);
                          }}
                          style={{
                            borderColor: productWidthErr ? "#f55e71" : "",
                          }}
                        />
                        <label htmlFor="modelno2">Product Width (cm)*</label>
                      </span>
                      {productWidthErr && (
                        <div className="p-error">{productWidthErr}</div>
                      )}
                    </div>
                  </div>
                  <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          id="modelno3"
                          autoComplete="off"
                          value={productLength}
                          min={0}
                          useGrouping={false}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          onChange={(e) => {
                            setProductLengthErr("");
                            setProductLength(e.value);
                          }}
                          style={{
                            borderColor: productLengthErr ? "#f55e71" : "",
                          }}
                        />
                        <label htmlFor="modelno2">Product Length (cm)*</label>
                      </span>
                      {productLengthErr && (
                        <div className="p-error">{productLengthErr}</div>
                      )}
                    </div>
                  </div>

                  <div
                    className="lg:col-1 md:col-1 sm:col-12 col-12"
                    style={{ minWidth: "16%", maxWidth: "16%" }}
                  >
                    <div className="formField">
                      <span className="pfloatlabel">
                        <Dropdown
                          disabled
                          id="currency"
                          value={selectedCurrency}
                          onChange={(e) => setSelectedCurrency(e.value)}
                          options={Currencies}
                          optionLabel="name"
                          placeholder="AED"
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    className="lg:col-4 md:col-4 sm:col-12 col-12"
                    style={{ minWidth: "28%", maxWidth: "28%" }}
                  >
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          inputId="minmaxfraction"
                          disabled
                          value={cost_price}
                          onValueChange={(e) => setCostPrice(e.value)}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          useGrouping={false}
                        />
                        <label htmlFor="Costprice">Cost price</label>
                      </span>
                    </div>
                  </div>
                  <div
                    className="lg:col-4 md:col-4 sm:col-12 col-12"
                    style={{ minWidth: "28%", maxWidth: "28%" }}
                  >
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          inputId="minmaxfraction"
                          className={sellingPriceErr ? "p-outline-error" : null}
                          value={selling_price}
                          min={0}
                          onChange={(e) => {
                            const NewSellingPrice = e.value;
                            setSellingPriceErr("");
                            if (cost_price) {
                              if (+NewSellingPrice < +cost_price) {
                                setSellingPriceErr(
                                  "Selling price should be equal to or higher than the cost price"
                                );
                              }
                              if (+NewSellingPrice >= +cost_price) {
                                setSellingPriceErr("");
                              }
                            }
                            if (discount_price) {
                              if (+NewSellingPrice < +discount_price) {
                                setSellingPriceErr(
                                  "Selling price should be equal to or higher than the discounted price"
                                );
                              }
                              if (+NewSellingPrice >= +discount_price) {
                                setDiscountPriceErr("");
                              }
                            }
                            if (
                              NewSellingPrice == null &&
                              NewSellingPrice !== 0
                            ) {
                              setSellingPriceErr("selling price is required");
                            }
                            setSellingPrice(NewSellingPrice);
                          }}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          useGrouping={false}
                        />
                        <label htmlFor="Sellingprice">Selling price*</label>
                      </span>
                      {sellingPriceErr && (
                        <div className="p-error">{sellingPriceErr}</div>
                      )}
                    </div>
                  </div>
                  <div
                    className="lg:col-3 md:col-3 sm:col-12 col-12"
                    style={{ minWidth: "28%", maxWidth: "28%" }}
                  >
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          inputId="minmaxfraction"
                          className={
                            discountPriceErr ? "p-outline-error" : null
                          }
                          value={discount_price}
                          min={0}
                          onChange={(e) => {
                            const NewDiscountedPrice = e.value;
                            setDiscountPriceErr("");
                            if (+NewDiscountedPrice <= +selling_price) {
                              setSellingPriceErr("");
                            } else {
                              setDiscountPriceErr(
                                "Discounted price should be less then or equal the selling price"
                              );
                            }
                            if (
                              NewDiscountedPrice == null &&
                              NewDiscountedPrice !== 0
                            ) {
                              setDiscountPriceErr(
                                "Discounted price is required"
                              );
                            }
                            setDiscountPrice(NewDiscountedPrice);
                          }}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          useGrouping={false}
                        />
                        <label htmlFor="Sellingprice">Discounted price*</label>
                      </span>
                      {discountPriceErr && (
                        <div className="p-error">{discountPriceErr}</div>
                      )}
                    </div>
                    {/*p-inputnumber p-component p-inputwrapper p-inputwrapper-filled p-error */}
                  </div>
                  <div className="lg:col-12 md:col- sm:col-12 col-12">
                    <p className="p1 mb-2 mt-2">Product Information</p>
                  </div>
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          id="tile"
                          autoComplete="off"
                          value={title}
                          onChange={(e) => {
                            setTitleErr("");
                            if (e.target.value.length > 250) {
                              setTitleErr(
                                "Title should be less than 250 characters"
                              );
                            }
                            setTitle(e.target.value);
                          }}
                          style={{ borderColor: titleErr ? "#f55e71" : "" }}
                        />
                        <label htmlFor="tile">Title*</label>
                      </span>
                      {titleErr && <div className="p-error">{titleErr}</div>}
                    </div>
                  </div>
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <div className="lg:col-12 md:col- sm:col-12 col-12">
                        <p className="p1 mb-2 mt-2">Short Description</p>
                      </div>
                      <InputTextarea
                        autoResize
                        id="Description"
                        rows={2}
                        cols={30}
                        value={short_description}
                        onChange={(e) => {
                          setShortDescription(e.target.value);
                          setShortDescriptionErr("");
                          if (e.target.value.length > 124) {
                            setShortDescriptionErr(
                              "Short description should be less than 124 characters"
                            );
                          }
                        }}
                        style={{
                          borderColor: shortDescriptionErr ? "#f55e71" : "",
                        }}
                      />
                      {shortDescriptionErr && (
                        <div className="p-error">{shortDescriptionErr}</div>
                      )}
                      {/* </span> */}
                    </div>
                  </div>
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField product-editor">
                      <div className="lg:col-12 md:col- sm:col-12 col-12">
                        <p className="p1 mb-2 mt-2">Long Description</p>
                      </div>
                      {showEditer && (
                        <Editor
                          value={long_description}
                          className={longDescriptionErr ? "errer-border" : null}
                          onTextChange={(e) => {
                            if (e.htmlValue == null) {
                              setLongDescription("");
                              return;
                            }
                            setLongDescription(e.htmlValue);
                            setLongDescriptionErr("");
                            if (
                              e.htmlValue
                                .replace(/<[^>]+>/g, "") //remove html tag
                                .replace(/\s{2,}/g, " ").length > 1000 //remove extra space
                            ) {
                              setLongDescriptionErr(
                                "Long description should be less than 1000 characters"
                              );
                            }
                          }}
                          style={{ height: "150px" }}
                        />
                      )}
                      {longDescriptionErr && (
                        <div className="p-error">{longDescriptionErr}</div>
                      )}
                    </div>
                  </div>
                  {/* shwo specifications */}
                  {specificationsAdnValues.length > 0 && (
                    <div className="lg:col-12 md:col- sm:col-12 col-12">
                      <p className="p1 mb-2 mt-2">Specifications</p>
                    </div>
                  )}
                  {specificationsAdnValues.length > 0 &&
                    specificationsAdnValues.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                            <div className="formField">
                              <span className="p-float-label">
                                <InputText
                                  disabled
                                  id="Brand"
                                  autoComplete="off"
                                  placeholder="Enter Brand"
                                  value={item.name}
                                  onChange={(e) => {
                                    let arr = [...specificationsAdnValues];
                                    arr[index].name = e.target.value;
                                    setSpecificationsAdnValues(arr);
                                  }}
                                  style={{
                                    borderColor: item.specificationErr
                                      ? "#f55e71"
                                      : "",
                                  }}
                                />
                                {item.specificationErr && (
                                  <div className="p-error">
                                    {item.specificationErr}
                                  </div>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                            <div className="formField">
                              <span className="p-float-label">
                                <InputText
                                  id="BrandValue"
                                  autoComplete="off"
                                  placeholder="Enter value"
                                  value={
                                    item.product_specification_value
                                      .specification_value
                                  }
                                  onChange={(e) => {
                                    let arr = [...specificationsAdnValues];
                                    arr[
                                      index
                                    ].product_specification_value.specification_value =
                                      e.target.value.trimStart();
                                    setSpecificationsAdnValues(arr);
                                  }}
                                  style={{
                                    borderColor: item.specification_valueErr
                                      ? "#f55e71"
                                      : "",
                                  }}
                                />
                                {item.specification_valueErr && (
                                  <div className="p-error">
                                    {item.specification_valueErr}
                                  </div>
                                )}
                              </span>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}

                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="flex align-items-center justify-content-end">
                      <div className="formField">
                        <span className="mr-3">
                          <label
                            htmlFor="reccP"
                            style={{
                              display: "inline-block",
                              marginRight: "15px",
                              marginBottom: "2px",
                              fontFamily: "PublicSans-Medium",
                            }}
                          >
                            Select, To show wholesale price
                          </label>
                          <Checkbox
                            id="reccP"
                            onChange={(e) => setChecked(e.checked)}
                            checked={checked}
                          ></Checkbox>
                        </span>
                      </div>
                      <Button
                        className="btn btn-blue"
                        onClick={() => setVisible(true)}
                      >
                        Wholesale Price
                      </Button>
                    </div>
                  </div>
                  {/* show images */}
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <div className="uploadImageRow">
                        {existingImages.length >= 5 ? null : (
                          <div className="uploadImageCol uploadImageBox">
                            <input
                              accept="image/jpeg, image/png"
                              type="file"
                              id="clearFile"
                              onChange={(e) => {
                                if (
                                  e.target.files[0].size > 5 * 1024 * 1024 ||
                                  (e.target.files[0].type !== "image/jpeg" &&
                                    e.target.files[0].type !== "image/png")
                                ) {
                                  setAlert(
                                    "Invalid file, please upload a JPEG or PNG image within the size limit of 5MB.",
                                    "error"
                                  );
                                  return;
                                }
                                setselect_img(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                // handleUploadImage(e);
                                let file = document.getElementById("clearFile");
                                file.value = "";
                              }}
                            />
                            <div>
                              <i className="pi pi-upload uploadIcon"></i>
                              <p className="p2 mb-0 mt-2">
                                Upload Image Size should be 130 : 130 px, Only
                                JPEG or PNG should be uploaded
                              </p>
                            </div>
                          </div>
                        )}
                        {existingImages.map((image, index) => (
                          <div
                            className="uploadImageCol uploadImagePreview"
                            key={index}
                          >
                            <Tooltip target=".custom-target-icon" />
                            <i
                              className="pi pi-times deleteICon"
                              onClick={() => handleDeleteImage(index)}
                            ></i>
                            <i
                              className={`custom-target-icon pi pi-star${
                                image.is_featured == 1 ? "-fill" : ""
                              } featureIcon p-text-secondary p-overlay-badge`}
                              data-pr-tooltip="Marked as featured"
                              data-pr-position="top"
                              data-pr-at="left+15 top-30"
                              data-pr-my="center"
                              onClick={() => {
                                //remove previous selected featured image
                                let arr = [...existingImages];
                                arr.map((item, index1) => {
                                  if (index1 !== index) {
                                    arr[index1].is_featured = 0;
                                  }
                                });
                                //set current image as featured
                                arr[index].is_featured = 1;
                                setExistingImages(arr);
                              }}
                            ></i>
                            {image.id !== 0 ? (
                              <img
                                onClick={() => {
                                  setShowImgUrl(image.image_url);
                                }}
                                src={image?.image_url}
                                alt=""
                                className="productImage"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = noImage;
                                }}
                              />
                            ) : (
                              <img
                                onClick={() => {
                                  setShowImgUrl(image.image_url);
                                }}
                                src={image.image_url}
                                alt=""
                                className="productImage"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = noImage;
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <div style={{ width: "100%", textAlign: "left" }}>
                      <div className="lg:col-12 md:col- sm:col-12 col-12">
                        <p className="p1 mb-2 mt-2"> Recommended :</p>
                      </div>
                      <InputSwitch
                        checked={isRecommended}
                        onChange={(e) => {
                          setIsRecommended(!isRecommended);
                        }}
                      />
                    </div>
                    <div style={{ width: "100%", textAlign: "left" }}>
                      <div className="lg:col-12 md:col- sm:col-12 col-12">
                        <p className="p1 mb-2 mt-2"> Today's Offer :</p>
                      </div>
                      <InputSwitch
                        checked={isTodaysOffer}
                        onChange={(e) => {
                          setIsTodaysOffer(!isTodaysOffer);
                        }}
                      />
                    </div>
                    <div style={{ width: "100%", textAlign: "left" }}>
                      <div className="lg:col-12 md:col- sm:col-12 col-12">
                        <p className="p1 mb-2 mt-2"> Publish / Unpublish :</p>
                      </div>
                      <InputSwitch
                        checked={publishSatus == "active" ? true : false}
                        onChange={(e) =>
                          setPublishSatus(
                            publishSatus == "active" ? "inactive" : "active"
                          )
                        }
                      />
                    </div>
                    <div style={{ width: "100%", textAlign: "left" }}>
                      <div className="lg:col-12 md:col- sm:col-12 col-12">
                        <p className="p1 mb-2 mt-2"> Featured Product :</p>
                      </div>
                      <InputSwitch
                        checked={checkedFeature}
                        onChange={(e) => setCheckedFeature(e.value)}
                      />
                    </div>
                  </div>

                  <div
                    className="lg:col-12 md:col-12 sm:col-12 col-12 mt-3"
                    style={{ width: "100%", textAlign: "right" }}
                  >
                    <Button
                      className="btn btn-gray mr-3"
                      onClick={() => {
                        navigate("/product-list");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn btn-blue"
                      onClick={() => update_Product()}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* show wholesale prices */}
      <Dialog
        header="Add Wholesale Pricing"
        className="addWholsaleDialog"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => {
          setVisible(false);
          // manageWholeSalePrice("unsave");
        }}
      >
        <div className="addformDialog">
          <div className="grid wsaleRow">
            {wholesalePriceArr.map((item, index) => {
              return (
                <div key={index} style={{ display: "flex" }}>
                  <div className="wsaleCol MinQuantityCol">
                    <div className="formField">
                      {/* readonlyfield */}
                      <span className="p-float-label">
                        <InputText
                          id="MinimumQuantity"
                          autoComplete="off"
                          disabled={index == 0 ? false : true}
                          style={{
                            borderColor: item?.start_rangeErr ? "#f55e71" : "",
                          }}
                          value={item?.start_range}
                          onChange={(e) => {
                            if (
                              isNaN(+e.target.value) ||
                              e.target.value.includes(".")
                            ) {
                              return;
                            }
                            if (+e.target.value > 9999999999) {
                              return;
                            }
                            let arr = [...wholesalePriceArr];
                            arr[index].start_rangeErr = "";
                            arr[index].start_range = e.target.value;
                            if (e.target.value == "")
                              arr[index].start_rangeErr =
                                "Minimum Quantity is required";
                            if (
                              item?.end_range &&
                              +e.target.value >= +item?.end_range
                            )
                              arr[index].start_rangeErr =
                                "Minimum Quantity should be less than Maximum Quantity";
                            setWholesalePriceArr(arr);
                          }}
                        />
                        <label htmlFor="MinimumQuantity">
                          Minimum Quantity*
                        </label>
                      </span>
                      {item?.start_rangeErr && (
                        <div className="p-error">{item?.start_rangeErr}</div>
                      )}
                    </div>
                  </div>
                  <div className="wsaleCol MaxQuantityCol">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          id="MaximumQuantity1"
                          autoComplete="off"
                          style={{
                            borderColor: item?.end_rangeErr ? "#f55e71" : "",
                          }}
                          value={item?.end_range}
                          onChange={(e) => {
                            if (
                              isNaN(+e.target.value) ||
                              e.target.value.includes(".")
                            ) {
                              return;
                            }
                            if (+e.target.value > 9999999999) {
                              return;
                            }
                            let arr = [...wholesalePriceArr];
                            arr[index].end_rangeErr = "";
                            arr[index].end_range = e.target.value;
                            if (wholesalePriceArr[index + 1]?.start_range) {
                              if (
                                +e.target.value + 1 !==
                                +wholesalePriceArr[index + 1]?.start_range
                              ) {
                                arr[index + 1].start_range = (
                                  +e.target.value + 1
                                ).toString();
                              }
                            }
                            if (
                              item?.start_range &&
                              +e.target.value <= +item?.start_range
                            )
                              arr[index].end_rangeErr =
                                "Maximum Quantity should be greater than Minimum Quantity";
                            if (e.target.value == "")
                              arr[index].end_rangeErr =
                                "Maximum Quantity is required";
                            if (item?.start_range) {
                              if (+e.target.value > +item?.start_range)
                                arr[index].start_rangeErr = "";
                            }
                            setWholesalePriceArr(arr);
                          }}
                        />
                        <label htmlFor="MaximumQuantity">
                          Maximum Quantity*
                        </label>
                      </span>
                      {item?.end_rangeErr && (
                        <div className="p-error">{item?.end_rangeErr}</div>
                      )}
                    </div>
                  </div>
                  <div className="wsaleCol WholesalePriceCol">
                    <div className="formField readonlyfield">
                      <span className="p-float-label">
                        <InputNumber
                          id="AmountRange"
                          autoComplete="off"
                          value={selling_price}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          useGrouping={false}
                        />
                        <label htmlFor="AmountRange">Selling Price</label>
                      </span>
                    </div>
                  </div>
                  <div className="wsaleCol DiscounttypeCol">
                    <div className="formField">
                      <span className="p-float-label">
                        <Dropdown
                          style={{
                            borderColor: item?.nameErr ? "#f55e71" : "",
                          }}
                          options={Discount}
                          value={item?.discountType}
                          onChange={(e) => {
                            let arr = [...wholesalePriceArr];
                            if (e.value.code == "percentage") {
                              if (item?.discounted_price >= 100) {
                                arr[index].wholesale_price = 0;
                                arr[index].discounted_price = "";
                              } else {
                                let wholesale_price =
                                  selling_price -
                                  (selling_price * +item?.discounted_price) /
                                    100;
                                arr[index].wholesale_price = wholesale_price;
                              }
                            }
                            if (e.value.code == "fixed_amount") {
                              let wholesale_price =
                                selling_price - +item?.discounted_price;
                              arr[index].wholesale_price = wholesale_price;
                            }
                            arr[index].discountType = e.value;
                            setWholesalePriceArr(arr);
                          }}
                          optionLabel="name"
                          placeholder="Discount"
                        />
                        <label htmlFor="tile">Discount type* </label>
                      </span>
                      {item?.nameErr && (
                        <div className="p-error">{item?.nameErr}</div>
                      )}
                    </div>
                  </div>
                  <div className="wsaleCol SellingPriceCol">
                    <div className="formField">
                      <div className="formField fieldLeftCaption">
                        <div className="currencycapstiondd">
                          {location == "dubai"
                            ? item?.discountType.code == "percentage"
                              ? "%"
                              : "AED"
                            : item?.discountType.code == "percentage"
                            ? "%"
                            : "USD"}
                        </div>
                        <span className="p-float-label">
                          <InputText
                            id="Discountvalue"
                            autoComplete="off"
                            style={{
                              borderColor: item?.discounted_priceErr
                                ? "#f55e71"
                                : "",
                            }}
                            value={item?.discounted_price}
                            onChange={(e) => {
                              if (isNaN(+e.target.value)) return;
                              //for 2 decimal places
                              if (
                                e.target.value.includes(".") &&
                                e.target.value.split(".")[1].length > 2
                              )
                                return;
                              let val = +e.target.value;
                              let arr = [...wholesalePriceArr];
                              if (item?.discountType.code == "percentage") {
                                if (val > 100) {
                                  return;
                                }
                                let wholesale_price =
                                  selling_price - (selling_price * val) / 100;
                                arr[index].wholesale_price = wholesale_price;
                              } else if (
                                item?.discountType?.code == "fixed_amount"
                              ) {
                                if (val > selling_price) {
                                  return;
                                }
                                let wholesale_price = selling_price - val;
                                arr[index].wholesale_price = wholesale_price;
                              }
                              if (val > 0) arr[index].discounted_priceErr = "";
                              arr[index].discounted_price = e.target.value;
                              setWholesalePriceArr(arr);
                            }}
                          />
                          <label htmlFor="Discountvalue">Discount value*</label>
                        </span>
                      </div>
                      {item.discounted_priceErr && (
                        <div className="p-error">
                          {item.discounted_priceErr}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="wsaleCol SellingPriceCol">
                    <div className="formField readonlyfield">
                      <div className="formField fieldLeftCaption">
                        <div className="currencycapstiondd">
                          {location == "dubai" ? "AED" : "USD"}
                        </div>
                        <span className="p-float-label">
                          <InputNumber
                            id="Sellingprice"
                            autoComplete="off"
                            style={{
                              borderColor: item.wholesale_priceErr
                                ? "#f55e71"
                                : "",
                            }}
                            value={item.wholesale_price}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            useGrouping={false}
                          />
                          <label htmlFor="Sellingprice">Wholesale Price*</label>
                        </span>
                      </div>
                      {item.wholesale_priceErr && (
                        <div className="p-error">{item.wholesale_priceErr}</div>
                      )}
                    </div>
                  </div>
                  <div className="wsaleCol WSaleBntCol">
                    {index == 0 ? (
                      <Button
                        className="btn addmorCircleBtn"
                        onClick={() => {
                          let arr = [...wholesalePriceArr];
                          if (
                            +wholesalePriceArr[wholesalePriceArr.length - 1]
                              .end_range == null ||
                            +wholesalePriceArr[wholesalePriceArr.length - 1]
                              .end_range == ""
                          ) {
                            arr[wholesalePriceArr.length - 1].end_rangeErr =
                              "Maximum Quantity is required to add more";
                            setWholesalePriceArr(arr);
                            return;
                          }
                          if (
                            +wholesalePriceArr[wholesalePriceArr.length - 1]
                              .end_range <=
                            +wholesalePriceArr[wholesalePriceArr.length - 1]
                              .start_range
                          ) {
                            arr[wholesalePriceArr.length - 1].end_rangeErr =
                              "Maximum Quantity should be greater than Minimum Quantity";
                            setWholesalePriceArr(arr);
                            return;
                          }
                          arr.push({
                            start_range:
                              +wholesalePriceArr[wholesalePriceArr.length - 1]
                                .end_range + 1,
                            end_range: "",
                            discounted_price: "",
                            wholesale_price: "",
                            discountType: {
                              name: "Fixed Amount",
                              code: "fixed_amount",
                            },
                            selling_price: selling_price,
                            id: 0,
                          });
                          setWholesalePriceArr(arr);
                        }}
                      >
                        <i className="pi pi-plus uploadIcon"></i>
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          let arr = [...wholesalePriceArr];
                          arr.splice(index, 1);
                          setWholesalePriceArr(arr);
                          //store the deleted id in array
                          if (item.id !== 0) {
                            let arr = [...deletewholesellprice];
                            arr.push(item.id);
                            setDeletewholesellprice(arr);
                          }
                        }}
                        className="btn removeCircleBtn"
                      >
                        <i className="pi pi-minus uploadIcon"></i>
                      </Button>
                    )}
                  </div>
                  {/* <div className="divider"></div> */}
                </div>
              );
            })}
          </div>
          <div className="dialogFooter">
            <div className="btnsRow">
              <Button
                type="button"
                className="btn btn-gray"
                onClick={() => {
                  manageWholeSalePrice("unsave");
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="btn btn-blue"
                onClick={() => {
                  manageWholeSalePrice("save");
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      {/* modal for crop images */}
      <Dialog
        className="headetPadding"
        header="Crop Image"
        visible={select_img ? true : false}
        style={{ maxWidth: "600px" }}
        onHide={() => {
          setselect_img(null);
        }}
      >
        <div className="addformDialog">
          {select_img && (
            <Cropper
              src={select_img}
              aspectRatio={1}
              // guides={false}
              crop={onCrop}
              ref={cropperRef}
              zoomable={false}
            />
          )}
          <div
            className="p2 dilogbtns"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Button
              className="btn btn-gray"
              onClick={() => {
                setselect_img(null);
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-yellow"
              onClick={() => {
                handleUploadImage(croppedImageUrl);
              }}
            >
              Crop
            </Button>
          </div>
        </div>
      </Dialog>

      {/* show img big */}
      {showImgUrl && (
        <ShowImage
          showImgUrl={showImgUrl}
          setShowImgUrl={setShowImgUrl}
          view={true}
        />
      )}
    </>
  );
};
