import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dialog } from "primereact/dialog";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests } from "../auth/AuthRequests";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { Tooltip } from "primereact/tooltip";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OrderTracking, ReturnOrderTracking } from "./OrderTracking";
import { setSnackbar } from "../../redux/reducers/snackbar";
import noImage from "../../assets/images/products/noImage.webp";
import { Checkbox } from "primereact/checkbox";
import { ReturnOrderLogs } from "./ReturnOrderLogs";

const OrderDetailNew = ({ setAlert }) => {
  let { id } = useParams();
  const loader = useSelector(currentLoader);
  const dispatch = useDispatch();
  const [VisibleCancelreason, setVisibleCancelreason] = useState(false);
  //================================//=============================================//
  //states
  const [orderTracking, setOrderTracking] = useState([]);
  const [data, setData] = useState({});
  const [refundErr, setRefundErr] = useState("");
  const [reason, setReason] = useState("");
  const [returnCollectedPrice, setReturnCollectedPrice] = useState("");
  const [returnCollectedPriceError, setReturnCollectedPriceError] =
    useState("");
  const [refundAmountErr, setRefundAmountErr] = useState("");
  const [refundAmount, setRefundAmount] = useState("");
  const [orderTrackingDates, setOrderTrackingDates] = useState({
    confirmed: "",
    shipped: "",
    delivered: "",
    cancelled: "",
    returned: "",
  });

  //popups
  const [returnCollected, setReturnCollected] = useState(false);
  const [VisibleReturnreason, setVisibleReturnreason] = useState(false);
  const [visiblePickup, setVisiblePickup] = useState(false);
  const [returnOrderList, setReturnOrderList] = useState(null);

  //get order details
  const getOrdersHandler = async () => {
    dispatch(changeLoader(true));
    await globalRequestAxios("get", {}, AuthRequests.GET_ORDER_DETAILS(id))
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack === 1) {
          const key = "status";
          let details = res.data;

          setOrderTracking(details.order_status_logs || []);
          details.order_timeline = [
            ...new Map(
              details.order_status_logs.map((item) => [item[key], item])
            ).values(),
          ];
          setData(details);
          setReturnOrderList(details?.return_request);

          console.log("hhhh", details);
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "Something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
      });
  };

  // handle errors
  const handleErrors = (status) => {
    let total = data?.total_amount;
    let total_error = "Refund amount should less than total amount";
    if (status === "returned" && data?.is_partially === 1) {
      total = data.net_amount;
      total_error = `Refund amount should less than ${
        data?.paymentType == "cash" ? "To Pay" : "Net Pay"
      } amount`;
    }
    let error = false;
    if (!reason) {
      setRefundErr("Please enter reason");
      error = true;
    }
    if (reason.length > 1000) {
      setRefundErr("Reason should be less than 1000 characters");
      error = true;
    }
    if (!refundAmount) {
      setRefundAmountErr("Please enter refund amount");
      error = true;
    }
    if (Number(refundAmount) > Number(total)) {
      setRefundAmountErr(total_error);
      error = true;
    }
    return error;
  };

  // update order status
  const updateOrderStatus = async (status) => {
    let payload = {};
    if (status === "pickup") {
      payload = {
        status: status,
      };
    } else if (status === "shipped") {
      payload = {
        status: status,
      };
    } else {
      let error = handleErrors(status);
      if (error) return false;
      payload = {
        status: status,
        refundAmount,
        reason,
      };
    }
    dispatch(changeLoader(true));
    await globalRequestAxios(
      "put",
      payload,
      AuthRequests.UPDATE_ORDER_STATUS(data.id)
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack === 1) {
          setPopupFalse();
          getOrdersHandler();
          showMessage();
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: res?.msg,
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        console.error("res2", err);
        dispatch(changeLoader(false));
      });
  };

  //return collected submit
  const returnCollectedSubmit = async (status) => {
    let total = data?.total_amount;
    let total_error = "Refund amount should less than total amount";
    if (status === "returned" && data?.is_partially === 1) {
      total = data.net_amount;
      total_error = `Refund amount should less than ${
        data?.paymentType == "cash" ? "To Pay" : "Net Pay"
      } amount`;
    }
    let error = false;
    if (!returnCollectedPrice) {
      setReturnCollectedPriceError("Please enter refund amount");
      error = true;
    }
    if (Number(returnCollectedPrice) > Number(total)) {
      setReturnCollectedPriceError(total_error);
      error = true;
    }
    if (error) return;
    let payload = {
      refundAmount: +returnCollectedPrice,
    };
    dispatch(changeLoader(true));
    await globalRequestAxios(
      "put",
      payload,
      AuthRequests.REFUND_ORDER_STATUS(data.id)
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack === 1) {
          setPopupFalse();
          getOrdersHandler();
          showMessage();
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "Something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        console.error("res2", err);
        dispatch(changeLoader(false));
      });
  };

  // set popup false
  const setPopupFalse = () => {
    setVisibleReturnreason(false);
    setVisibleCancelreason(false);
    setReturnCollected(false);
    setVisiblePickup(false);
    setRefundAmount("");
    setRefundAmountErr("");
    setReason("");
    setRefundErr("");
    setReturnCollectedPriceError("");
  };

  // show update successfully
  const showMessage = () => {
    setAlert("Update successfully", "success");
  };

  //order tracking status
  useEffect(() => {
    const statusMapping = {
      confirmed: "confirmed",
      shipped: "shipped",
      delivered: "delivered",
      canceled: "cancelled",
      returned: "returned",
      pickup: "pickup",
    };

    const updatedOrderTrackingDates = {};
    orderTracking?.forEach((item) => {
      const { status, createdAt } = item || {};

      if (status && statusMapping[status]) {
        updatedOrderTrackingDates[statusMapping[status]] = moment(
          createdAt
        ).format("DD MMM YYYY, hh:mm A");
      }
    });

    setOrderTrackingDates((prev) => ({
      ...prev,
      ...updatedOrderTrackingDates,
    }));
  }, [orderTracking]);

  useEffect(() => {
    getOrdersHandler();
  }, []);

  //================================//=============================================//

  const productIndex = (rowData, obj) => {
    return <>{obj?.rowIndex + 1}</>;
  };

  const productBodyTemplate = (rowData) => {
    return (
      <div className="order-outline-box flex flex-column sm:flex-row sm:align-items-start justify-content-between px-0 py-0">
        <div className="flex align-items-start justify-content-between order-dtl-productLftCol">
          <img
            src={rowData?.product?.product_images[0]?.image_url}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = noImage;
            }}
            alt="product image"
            className="product-40 mr-3  "
          />
          <div className="hand-on">
            <p className="p2 font-bold mb-1 line-height-3">
              {rowData?.product_title}{" "}
              <span className="p2 ml-2">{` X ${rowData?.quantity}`}</span>
            </p>
            <p className="p2 wrapcontent w-210">
              {rowData?.product?.short_description}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const pricePerUnit = (rowData) => {
    return (
      <>
        {data.currency} {parseFloat(rowData?.product_price).toFixed(2)}
      </>
    );
  };
  const AvailableBodyTemplate = (rowData, obj) => {
    let quantity = rowData?.approveQty;
    if (data?.is_partially === 1) {
      if (data?.status === "confirmed") {
        if (data?.erp_order_update === 1) {
          quantity = rowData?.approveQty;
        } else {
          quantity = rowData?.quantity;
        }
      } else if (data?.status == "canceled") {
        if (data?.erp_order_update === 1) {
          quantity = rowData?.approveQty;
        } else {
          quantity = rowData?.quantity;
        }
      }
    } else {
      if (data?.status == "canceled" && data?.erp_order_update === 1) {
        quantity = rowData?.approveQty;
      } else {
        quantity = rowData?.quantity;
      }
    }
    return (
      <div className="flex align-items-center justify-content-center">
        <span>{quantity}</span>
        {quantity < rowData?.quantity ? (
          <>
            <Tooltip
              target={`.knob${obj?.rowIndex}`}
              className="custom-tooltip"
              content={`Only ${quantity} quantity out of ${rowData?.quantity} was available in the stock. Refund of the remaining product has been initiated to the same payment source`}
            />
            <i
              className={`pi pi-info-circle mx-1 knob${obj?.rowIndex}`}
              style={{ color: "#E93D3D" }}
            ></i>
          </>
        ) : null}
      </div>
    );
  };
  const ShowStatus = ({ status }) => {
    return <span className={`chips ${status}`}>{status}</span>;
  };
  const ChipsBodyTemplate = (rowData) => {
    return (
      <>
        {data?.is_partially === 1 ? (
          <>
            {data?.status === "confirmed" ? (
              <>
                {data?.erp_order_update === 1 &&
                data?.delivery_type == "pickup" ? (
                  rowData?.approveQty > 0 ? (
                    <ShowStatus status={"confirmed"} />
                  ) : (
                    <ShowStatus status={"cancelled"} />
                  )
                ) : (
                  <ShowStatus status={"confirmed"} />
                )}
              </>
            ) : data?.status === "canceled" ? (
              <>
                <ShowStatus status={"cancelled"} />
              </>
            ) : data?.status === "pickup" ? (
              <>
                {rowData?.approveQty > 0 ? (
                  <ShowStatus status={"pickup"} />
                ) : (
                  <ShowStatus status={"cancelled"} />
                )}
              </>
            ) : data?.status === "delivered" ? (
              <>
                {rowData?.approveQty > 0 ? (
                  <ShowStatus status={"delivered"} />
                ) : (
                  <ShowStatus status={"cancelled"} />
                )}
              </>
            ) : data?.status === "returned" ? (
              <>
                <ShowStatus status={"returned"} />
              </>
            ) : data?.status === "shipped" ? (
              <>
                {rowData?.approveQty > 0 ? (
                  <ShowStatus status={"shipped"} />
                ) : (
                  <ShowStatus status={"cancelled"} />
                )}
              </>
            ) : null}
          </>
        ) : (
          <>
            <ShowStatus status={data?.status} />
          </>
        )}
      </>
    );
  };

  const productSubTotal = (rowData) => {
    let quantity = rowData?.approveQty;
    if (data?.is_partially === 1) {
      if (data?.status === "confirmed") {
        if (data?.erp_order_update === 1) {
          quantity = rowData?.approveQty;
        } else {
          quantity = rowData?.quantity;
        }
      } else if (data?.status == "canceled") {
        if (data?.erp_order_update === 1) {
          quantity = rowData?.approveQty;
        } else {
          quantity = rowData?.quantity;
        }
      }
    } else {
      if (data?.status == "canceled" && data?.erp_order_update === 1) {
        quantity = rowData?.approveQty;
      } else {
        quantity = rowData?.quantity;
      }
    }
    return (
      <>
        {data.currency}{" "}
        {parseFloat(rowData?.product_price * quantity).toFixed(2)}
      </>
    );
  };

  return (
    <>
      <section className="admin-content-wrapper">
        <div className="adminContent ">
          <div className="tablefilterSubheader">
            <div className="tableHeader justify-content-between">
              <div className=" pb-1">
                <h4 className="h4 mb-2">
                  <b style={{ color: "#000000" }}>Order Edit</b>
                </h4>
                <h4 className="h4 mb-0">Order ID: #{data.order_id}</h4>
              </div>
              <div className="tableFilterRow" style={{ marginLeft: "0" }}>
                <div className="tableFilterCol">
                  <Button
                    disabled={loader}
                    className="btn"
                    text
                    style={{ color: "#EFB34C" }}
                    onClick={() => {
                      window.open(`${data?.invoice}`, "_blank");
                    }}
                  >
                    <i className="pi pi-download mr-2"></i> Download
                  </Button>
                  {/* pickup btn */}
                  {data?.delivery_type === "pickup" &&
                  data?.status === "confirmed" ? (
                    <>
                      {data?.is_partially === 1 ? (
                        <>
                          {data?.erp_order_update === 1 ? (
                            <Button
                              disabled={loader}
                              className="btn btn-orange"
                              onClick={() => {
                                setVisiblePickup(true);
                              }}
                            >
                              <i className="pi pi-download mr-2"></i> Picked Up
                            </Button>
                          ) : null}
                        </>
                      ) : (
                        <Button
                          disabled={loader}
                          className="btn btn-orange"
                          onClick={() => {
                            setVisiblePickup(true);
                          }}
                        >
                          <i className="pi pi-download mr-2"></i> Picked Up
                        </Button>
                      )}
                    </>
                  ) : null}
                  {/* return collected btn */}
                  {/* {data?.status === "returned" &&
                  // data?.payment_type == "card" &&
                  data?.payment_status == "RefundedPending" ? (
                    <Button
                      disabled={loader}
                      className="btn btn-orange"
                      onClick={() => {
                        setReturnCollected(true);
                        setReturnCollectedPrice(data?.total_amount?.toFixed(2));
                        //if is_partial and called erp api then
                        if (
                          data?.is_partially === 1 &&
                          data?.erp_order_update === 1
                        ) {
                          setReturnCollectedPrice(data?.net_amount?.toFixed(2));
                        }
                      }}
                    >
                      Return Collected
                    </Button>
                  ) : null} */}
                  {data.status != "delivered" &&
                  data.status != "returned" &&
                  data.status != "canceled" &&
                  data.status != "shipped" &&
                  data.status != "pickup" ? (
                    <Button
                      disabled={loader}
                      className="btn btn-gray mr-3 ml-2"
                      onClick={() => {
                        setRefundAmount(data?.total_amount?.toFixed(2));
                        setVisibleCancelreason(true);
                        //order pickup => with card => erp_call => cancel => net_amount
                        if (
                          data?.is_partially === 1 &&
                          data?.erp_order_update === 1 &&
                          data?.payment_type === "card" &&
                          data?.delivery_type === "pickup"
                        ) {
                          setRefundAmount(data?.net_amount?.toFixed(2));
                        }
                      }}
                    >
                      Cancel Order
                    </Button>
                  ) : null}
                  {/* {data.status == "delivered" && (
                    <Button
                      disabled={loader}
                      className="btn btn-orange"
                      onClick={() => {
                        setRefundAmount(data?.total_amount?.toFixed(2));
                        setVisibleReturnreason(true);
                        if (
                          data?.is_partially === 1 &&
                          data?.erp_order_update === 1
                        ) {
                          setRefundAmount(data?.net_amount?.toFixed(2));
                        }
                      }}
                    >
                      Return Order
                    </Button>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid adminTableContent">
            <div className="px-4 py-5">
              <div className="grid">
                <div className="lg:col-3 md:col-3 sm:col-6 col-12">
                  <Card className="new-card" title="Customer">
                    <p className="mt-0 mb-1 p2 capitalize-first-letter">
                      {data.user_details?.first_name +
                        " " +
                        data?.user_details?.last_name}
                    </p>
                    <p className="mt-0 mb-1 p2">{data?.user_details?.email}</p>
                    <p className="mt-0 mb-1 p2">
                      {data?.user_details?.mobile_no
                        ? data?.user_details?.mobile_no
                        : ""}
                    </p>
                  </Card>
                </div>
                <div className="lg:col-3 md:col-3 sm:col-6 col-12">
                  <Card
                    className="new-card"
                    title={
                      data?.delivery_type === "pickup"
                        ? "Pickup Address"
                        : "Delivery Address"
                    }
                  >
                    <p className="mt-0 mb-1 p2 capitalize-first-letter">
                      {data?.customer_name}
                    </p>
                    <p className="mt-0 mb-1 p2">
                      <span className="capitalize-first-letter">
                        {data?.address_name ? `${data?.address_name} ,` : ""}
                      </span>
                      {data?.address ? `${data?.address}` : ""}
                      {data?.address2 ? `, ${data?.address2}` : ""}
                      {data?.city ? `, ${data?.city}` : ""}
                      {data?.state ? `, ${data?.state}` : ""}
                      {data?.country ? `, ${data?.country}` : ""}
                      {data?.postal_code ? `, ${data?.postal_code}` : ""}
                    </p>
                    <p className="mt-0 mb-1 p2">P: {data?.mobile}</p>
                  </Card>
                </div>
                <div className="lg:col-3 md:col-3 sm:col-6 col-12">
                  <Card className="new-card" title="Payment Method">
                    <p className="mt-0 mb-1 p2">
                      {data?.payment_type === "card"
                        ? `Paid by Card ending in xxxx ${data?.card_number
                            ?.toString()
                            ?.slice(-4)}`
                        : "Cash On Delivery"}
                    </p>
                    {/* <p className="mt-0 mb-1 p2">
                      Transaction ID: #345678989798
                    </p> */}
                  </Card>
                </div>
                <div className="lg:col-3 md:col-3 sm:col-6 col-12">
                  <Card className="new-card" title="Order Date">
                    <p className="mt-0 mb-1 p2">
                      {moment(data.createdAt).format("DD MMM YYYY, hh:mm A")}
                    </p>
                  </Card>
                </div>
              </div>

              <h6 className="h6 mt-5 mb-3">Product Summary</h6>
              {data?.is_partially === 1 ? (
                <div
                  className={`mt-4 mb-3 read-only-no-cursor`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <Checkbox
                    style={{ margin: "4px" }}
                    disabled={true}
                    checked={data?.is_partially === 1 ? true : false}
                  /> */}
                  <p className="p3 mb-0">
                    You confirm the receipt of partial items, and if any item is
                    unavailable, the corresponding amount will be refunded to
                    the payment source.
                  </p>
                </div>
              ) : null}
              <DataTable
                value={data?.order_details ? data?.order_details : []}
                className="detail-table"
              >
                <Column field="id" header="#" body={productIndex}></Column>
                <Column
                  field="Product"
                  header="Product"
                  body={productBodyTemplate}
                ></Column>
                <Column
                  header="Price/Unit"
                  className="align-center"
                  body={pricePerUnit}
                ></Column>
                {/* <Column
                  field="quantity"
                  header="Total Quantity"
                  className="align-center"
                ></Column> */}
                <Column
                  field="TotalQuantity"
                  header="Total Quantity"
                  className="justify-content-center"
                  body={AvailableBodyTemplate}
                ></Column>
                <Column
                  field="Status"
                  header="Status"
                  className="align-center"
                  body={ChipsBodyTemplate}
                ></Column>
                <Column
                  field="SubTotal"
                  header="Sub Total"
                  className="align-center"
                  body={productSubTotal}
                ></Column>
              </DataTable>

              <div className="tracking-box">
                <h6 className="h6 mt-0 mb-3">
                  <span className="font-bold">Order Tracking </span>{" "}
                </h6>
                <h6 className="h6 mt-0 mb-3">
                  {data?.tracking_id && data?.status !== "confirmed" ? (
                    <>
                      <span className="font-bold">Tracking ID:</span>{" "}
                      {data?.tracking_id}
                    </>
                  ) : null}
                </h6>
                <div className="order-tracking-block">
                  <OrderTracking
                    orderStatus={data?.status}
                    deliveryType={data?.delivery_type}
                    orderTrackingDates={orderTrackingDates}
                  />
                </div>
              </div>
              <div className="tracking-box py-3 mt-5 mb-3">
                <h6 className="h6 m-0">Total Order Summary</h6>
              </div>
              <div className="flex align-items-start justify-content-between gap-4">
                {/* company details */}
                {data?.company_name ? (
                  <div
                    className="flex align-items-start flex-column justify-content-between gap-4"
                    style={{ minWidth: "40%" }}
                  >
                    <div className="px-4 border-to-the-box w-full">
                      <h6 className="p2 mb-1">Company Detail</h6>
                      <p className="p2 mb-1">
                        Company Name: {data?.company_name}{" "}
                      </p>
                      <p className="p2">TRN Number: {data?.trn_number}</p>
                    </div>
                  </div>
                ) : null}
                {/* refund details */}
                {data.status === "returned" || data.status === "canceled" ? (
                  <>
                    <div
                      className="flex align-items-start flex-column justify-content-between gap-4"
                      style={{ minWidth: "40%" }}
                    >
                      {data?.refund_amount > 0 || data?.reason ? (
                        <div className="px-4 border-to-the-box w-full">
                          {data?.payment_type == "card" ? (
                            <>
                              {data?.refund_amount > 0 ? (
                                <>
                                  <h6 className="p2 mb-1">Refund Details</h6>
                                  <p className="p2 mb-1">
                                    Refund of{" "}
                                    <span className="yellow">
                                      {data?.currency}{" "}
                                      {data?.refund_amount?.toFixed(2)}
                                    </span>{" "}
                                    Credited to the initial payment source.
                                  </p>
                                  {data?.refund_ref_no ? (
                                    <p className="p2 mb-1">
                                      {" "}
                                      Reference No : {data?.refund_ref_no}{" "}
                                    </p>
                                  ) : null}
                                </>
                              ) : null}
                            </>
                          ) : null}

                          {/* bank details */}
                          {data?.status === "returned" &&
                          data?.payment_type === "cash" &&
                          data?.bank_details?.bank_number ? (
                            <>
                              <h6 className="p2 mb-1">Refund Bank Details</h6>
                              {data?.refund_amount > 0 &&
                              data?.payment_status === "Refunded" ? (
                                <p className="p2 pb-1">
                                  Refund of{" "}
                                  <b>
                                    {data?.currency +
                                      " " +
                                      data?.refund_amount?.toFixed(2)}{" "}
                                  </b>
                                  Credited to the account
                                </p>
                              ) : null}

                              <p className="p2 mb-1">
                                Account Number:{" "}
                                {data?.bank_details?.bank_number}{" "}
                              </p>
                              <p className="p2">
                                {" "}
                                IBAN Number: {data?.bank_details?.iban}
                              </p>
                              <p className="p2">
                                {" "}
                                Name Registered On Bank:{" "}
                                {data?.bank_details?.name_on_bank}
                              </p>
                            </>
                          ) : null}

                          {data?.reason ? (
                            <p className="p2">
                              {" "}
                              {data.status === "returned"
                                ? "Return"
                                : "Cancellation"}{" "}
                              Reason: {data?.reason}
                            </p>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}

                <div className="px-4 py-3 border-box">
                  <div className="flex align-items-center justify-content-between mb-2">
                    <p className="p2">Subtotal</p>
                    <p className="p2">
                      {data?.currency}{" "}
                      {parseFloat(data?.subtotal_amount).toFixed(2)}
                    </p>
                  </div>
                  <div className="flex align-items-center justify-content-between mb-2">
                    <p className="p2">
                      Coupon Discount{" "}
                      {data?.coupon_name ? (
                        <span style={{ color: "#EFB34C" }}>
                          {" "}
                          ({data?.coupon_name})
                        </span>
                      ) : null}
                    </p>
                    <p className="p2 yellow">
                      - {data?.currency}{" "}
                      {parseFloat(data?.discount_amount).toFixed(2)}
                    </p>
                  </div>
                  <div className="flex align-items-center justify-content-between mb-2">
                    <p className="p2">
                      Shipping{" "}
                      {data?.shipping_company && (
                        <span
                          className="capitalize-first-letter"
                          style={{ color: "#EFB34C" }}
                        >
                          {" "}
                          ({data?.shipping_company})
                        </span>
                      )}
                    </p>
                    <p className="p2">
                      {" "}
                      {data?.currency}{" "}
                      {parseFloat(data?.shipping_charge).toFixed(2)}
                    </p>
                  </div>
                  <div className="flex align-items-center justify-content-between mb-2">
                    <p className="p2">
                      Tax{" "}
                      {data?.tax_percentage > 0
                        ? ` (${data?.tax_percentage}%)`
                        : ""}
                    </p>
                    <p className="p2">
                      {data?.currency} {parseFloat(data?.tax_amount).toFixed(2)}
                    </p>
                  </div>
                  {data?.payment_type === "cash" && data?.cod_charge > 0 ? (
                    <div className="flex align-items-center justify-content-between mb-2">
                      <p className="p2">COD Charges</p>
                      <p className="p2">
                        {" "}
                        {data?.currency}{" "}
                        {parseFloat(data?.cod_charge).toFixed(2)}
                      </p>
                    </div>
                  ) : null}
                  <Divider className="mx-0 my-3" />
                  <div className="flex align-items-center justify-content-between mb-2">
                    <p className="p2">Total</p>
                    <p className="p2">
                      {data?.currency}{" "}
                      {parseFloat(data?.total_amount).toFixed(2)}
                    </p>
                  </div>
                  {data?.erp_order_update === 1 && data?.is_partially === 1 ? (
                    <>
                      <div className="flex align-items-center justify-content-between mb-2">
                        <p className="p2 yellow">
                          {data?.payment_type === "cash"
                            ? "Cancellation Deduction"
                            : "Refund"}
                        </p>
                        <p className="p2">
                          <span style={{ color: "#EFB34C" }}>
                            - {data?.currency}{" "}
                            {parseFloat(data?.refund_amount).toFixed(2)}
                          </span>
                        </p>
                      </div>
                      <Divider className="mx-0 my-3" />
                      <div className="flex align-items-center justify-content-between mb-2">
                        <p className="p2 font-bold">
                          {data?.paymentType == "cash" ? "To Pay" : "Net Pay"}
                        </p>
                        <p className="p2 font-bold">
                          {data?.currency}{" "}
                          {parseFloat(data?.net_amount).toFixed(2)}
                        </p>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              {/* return order flow */}
              {/* <div className="flex align-items-start justify-content-end gap-2 mt-6">
                <Button
                  disabled={loader}
                  className="btn btn-orange"
                  onClick={() => {
                    setVisibleCancelreason(true);
                  }}
                >
                  Process Refund
                </Button>
                <Button
                  disabled={loader}
                  className="btn btn-orange"
                  onClick={() => {
                    setReturnCollected(true);
                  }}
                >
                  Return Collected
                </Button>
              </div> */}
              {returnOrderList?.length && (
                <ReturnOrderLogs
                  orderDetails={data}
                  returnOrderList={returnOrderList}
                  callOrderApi={getOrdersHandler}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      {/*cancel order  */}
      <Dialog
        header="Reason for cancellation"
        className=""
        returnCollected
        visible={VisibleCancelreason}
        onHide={() => setPopupFalse()}
        draggable={false}
      >
        <div className="addformDialog">
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <label className="sepLable">
                  Reason
                  <span style={{ color: "red" }}>*</span>
                </label>
                <span className="p-float-label">
                  <InputTextarea
                    autoResize
                    id="DeliveryAddress"
                    value={reason}
                    contentEditable
                    rows={2}
                    placeholder="Enter Reason"
                    onChange={(e) => {
                      let value = e.target.value.trimStart();
                      if (value.length > 1000) return;
                      setReason(value.trimStart());
                      setRefundErr("");
                    }}
                    style={{
                      borderColor: refundErr ? "#ff6b68" : "",
                    }}
                  />
                  {refundErr && <div className="p-invalid">{refundErr}</div>}
                </span>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={{ color: reason?.length < 1000 ? "green" : "red" }}
                  >
                    {1000 - (reason?.length || 0)} / 1000
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <label className="sepLable">
                  Refund Amount <span style={{ color: "red" }}>*</span>
                </label>
                <span className="p-float-label">
                  <InputText
                    id="MinimumQuantity"
                    value={refundAmount}
                    placeholder="Enter Amount"
                    autoComplete="off"
                    onChange={(e) => {
                      let value = e.target.value.trimStart();
                      if (isNaN(value)) return;
                      setRefundAmount(e.target.value.trimStart());
                      setRefundAmountErr("");
                    }}
                    style={{
                      borderColor: refundAmountErr ? "#ff6b68" : "",
                    }}
                  />
                  {refundAmountErr && (
                    <div className="p-invalid">{refundAmountErr}</div>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="dialogFooter">
            <div className="btnsRow">
              {/* <Button
                disabled={loader}
                className="btn btn-gray"
                onClick={() => setPopupFalse()}
              >
                Cancel
              </Button> */}
              <Button
                className="btn btn-blue"
                disabled={!(refundAmount && reason)}
                onClick={() => {
                  updateOrderStatus("canceled");
                }}
              >
                Cancel Order
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      {/* return order */}
      <Dialog
        header="Reason for return"
        className=""
        visible={VisibleReturnreason}
        onHide={() => setPopupFalse()}
        draggable={false}
      >
        <div className="addformDialog">
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <label className="sepLable">
                  Reason <span style={{ color: "red" }}>*</span>
                </label>
                <span className="p-float-label">
                  <InputTextarea
                    autoResize
                    id="DeliveryAddress"
                    contentEditable
                    value={reason}
                    rows={2}
                    placeholder="Enter Reason"
                    onChange={(e) => {
                      let value = e.target.value.trimStart();
                      if (value.length > 1000) return;
                      setReason(value.trimStart());
                      setRefundErr("");
                    }}
                  />
                  {refundErr && <div className="p-invalid">{refundErr}</div>}
                </span>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={{ color: reason?.length < 1000 ? "green" : "red" }}
                  >
                    {1000 - (reason?.length || 0)} / 1000
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <label className="sepLable">
                  Refund Amount <span style={{ color: "red" }}>*</span>
                </label>
                <span className="p-float-label">
                  <InputText
                    id="MinimumQuantity"
                    value={refundAmount}
                    placeholder="Enter Amount"
                    autoComplete="off"
                    onChange={(e) => {
                      let value = e.target.value.trimStart();
                      if (isNaN(value)) return;
                      setRefundAmount(e.target.value.trimStart());
                      setRefundAmountErr("");
                    }}
                    style={{
                      borderColor: refundAmountErr ? "#ff6b68" : "",
                    }}
                  />
                  {refundAmountErr && (
                    <div className="p-invalid">{refundAmountErr}</div>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="dialogFooter">
            <div className="btnsRow">
              <Button
                disabled={loader}
                className="btn btn-gray"
                onClick={() => setPopupFalse()}
              >
                Cancel
              </Button>
              <Button
                disabled={loader}
                className="btn btn-blue"
                onClick={() => updateOrderStatus("returned")}
              >
                Refund
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      {/* picked product */}
      <Dialog
        className="dialogWidth400 headetPadding"
        header="Picked up"
        visible={visiblePickup}
        onHide={() => {
          setVisiblePickup(false);
        }}
        draggable={false}
      >
        <div className="addformDialog">
          <p className="p2 mb-4 mt-0" style={{ textAlign: "left" }}>
            Are you sure want to change the status pickup?
          </p>
          <div className="p2 dilogbtns">
            <Button
              disabled={loader}
              className="btn btn-gray"
              onClick={() => {
                setPopupFalse();
              }}
            >
              No
            </Button>
            <Button
              disabled={loader}
              className="btn btn-blue"
              onClick={() => {
                updateOrderStatus("pickup");
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
      {/* return collected popup */}
      <Dialog
        header="Refund Amount"
        className=""
        visible={returnCollected}
        onHide={() => setPopupFalse()}
        draggable={false}
      >
        <div className="addformDialog">
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <label className="sepLable">
                  Return Collected Amount{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <span className="p-float-label">
                  <InputText
                    id="MinimumQuantity"
                    value={returnCollectedPrice}
                    placeholder="Enter Amount"
                    autoComplete="off"
                    onChange={(e) => {
                      let value = e.target.value.trimStart();
                      if (isNaN(value)) return;
                      setReturnCollectedPrice(e.target.value.trimStart());
                      setReturnCollectedPriceError("");
                    }}
                    style={{
                      borderColor: returnCollectedPriceError ? "#ff6b68" : "",
                    }}
                  />
                  {returnCollectedPriceError && (
                    <div className="p-invalid">{returnCollectedPriceError}</div>
                  )}
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <label className="sepLable">
                  Enter Your Reason <span>(Optional)</span>
                </label>
                <span className="p-float-label">
                  <InputTextarea
                    autoResize
                    id=""
                    contentEditable
                    value={reason}
                    rows={2}
                    placeholder="Enter Reason"
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="dialogFooter">
            <div className="btnsRow">
              <Button
                disabled={loader}
                className="btn btn-blue"
                onClick={() => returnCollectedSubmit("returned")}
              >
                Refund
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default OrderDetailNew;
