/** @format */
import { useEffect, useState } from "react";
import React from "react";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import { Editor } from "primereact/editor";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { AuthRequests } from "../auth/AuthRequests";
import globalRequestAxios from "../../globalModules/globalRequestAxios";

// images

const TermsConditions = () => {
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  const [textEnglish, setTextEnglish] = useState("");
  const [textEnglishErr, setTextEnglishErr] = useState("");
  const [textArabic, setTextArabic] = useState("");
  const [textArabicErr, setTextArabicErr] = useState("");
  const [showEditer, setShowEditer] = useState(false);

  //get Terms and Conditions
  const getTermsAndConditions = () => {
    dispatch(changeLoader(true));
    globalRequestAxios("get", {}, AuthRequests.GET_CMS_DETAILS+`?page=terms_and_conditions`)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setTextEnglish(res?.data?.description_en);
          setTextArabic(res?.data?.description_ar);
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
        setShowEditer(true);
      })
      .catch((err) => {
        setShowEditer(true);
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  };
  useEffect(() => {
    getTermsAndConditions();
  }, []);

  //error handling
  const handleErrors = () => {
    let error = false;
    if (!textEnglish) {
      setTextEnglishErr("Please enter Terms and Conditions");
      error = true;
    }
    if (!textArabic) {
      setTextArabicErr("Please enter Terms and Conditions");
      error = true;
    }
    return error;
  };

  //on submit function
  const onSubmit = () => {
    if (handleErrors()) {
      return;
    }
    const data = {
      description_en:textEnglish,
      description_ar:textArabic,
      page:"terms_and_conditions"
    };
    dispatch(changeLoader(true));
    globalRequestAxios("put", data, AuthRequests.UPDATE_CMS_DETAILS)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "Terms and Conditions updated successfully",
              state: "success",
            })
          );
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  };

  return (
    <>
      {/* <LeftSidebar /> */}
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="formHeader">
            <h4 className="h4 mb-2">Terms and Conditions</h4>
          </div>
          <div className="contentBox-w">
            <div className="grid">
              <div className="lg:col-10 md:col-10 sm:col-12 col-12">
                <div className="grid">
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <label className="frmLabel">
                        Terms and Conditions (English)
                      </label>
                      {showEditer && 
                      <Editor
                        value={textEnglish}
                        style={{ height: "200px" }}
                        onTextChange={(e) => {
                          if (e.htmlValue == null) {
                            setTextEnglish("");
                            return;
                          }
                          setTextEnglish(e.htmlValue);
                          setTextEnglishErr("");
                        }}
                      />
                      }
                      {textEnglishErr && (
                        <div className="p-error">{textEnglishErr}</div>
                      )}
                    </div>
                    <div className="formField">
                      <label className="frmLabel">
                        Terms and Conditions (Arabic)
                      </label>
                      {showEditer && 
                      <Editor
                        value={textArabic}
                        style={{ height: "200px" }}
                        onTextChange={(e) => {
                          if (e.htmlValue == null) {
                            setTextArabic("");
                            return;
                          }
                          setTextArabic(e.htmlValue);
                          setTextArabicErr("");
                        }}
                      />
                      }
                      {textArabicErr && (
                        <div className="p-error">{textArabicErr}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid mt-2">
                  <div
                    className="lg:col-12 md:col-12 sm:col-12 col-12 mt-3"
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      disabled={loader}
                      className="btn btn-yellow"
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsConditions;
