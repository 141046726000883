/**
 * @description this file used to store common helper functions here
 */

import { BASE_URL } from "../BaseUrl";

/**
 * @description email validation
 * @param {*} email
 * @returns
 */
export const validateEmail = (email) => {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

/**
 * @description phone number validation
 * @param {*} phoneNumber
 * @returns
 */
export const validatePhoneNumber = (phoneNumber) => {
  const phoneNumberRegex = /^\d{13}$/;
  return phoneNumberRegex.test(phoneNumber);
};

/**
 * @description validate Password based on regex pattern
 * @should contain at least 8 characters and at most 20
 *  characters and must contain at least one numeric digit and any string of characters and (@, #, $, *, _,)
 * @param {*} password
 * @returns boolean
 */
export const validatePassword = (password) => {
  // const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9@#$*_]+){8,20}$/;

  // Check password length
  if (password.length < 8) {
    return false;
  }
  // Check if password has a mix of uppercase, lowercase letters, numbers and symbols
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
};

/**
 * @description function to get last 4 digits of card number
 * @param {*} cardNumber
 * @returns last 4 digits of card number
 * @example getLastFourDigitsOfCardNumber('1234567890123456') => '3456'
 */
export const getLastFourDigitsOfCardNumber = (cardNumber) => {
  return cardNumber.slice(-4);
};

//remove "-" from phone number
export const removeHyphens = (phoneNumber) => {
  return phoneNumber.replace(/-/g, "");
};

//add "-" to phone number
export const addHyphens = (phoneNumber) => {
  let formatted = "";
  if (phoneNumber.length > 0) {
    formatted += phoneNumber.substring(0, 3);
  }
  if (phoneNumber.length > 3) {
    formatted += "-" + phoneNumber.substring(3, 6);
  }
  if (phoneNumber.length > 6) {
    formatted += "-" + phoneNumber.substring(6, 9);
  }
  if (phoneNumber.length > 9) {
    formatted += "-" + phoneNumber.substring(9, 13);
  }
  return formatted;
};

///date format in dd/mm/yyyy
export const dateFormat = (str) => {
  const dateString = str;
  const date = new Date(dateString);
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = date
    .toLocaleDateString("en-US", options)
    .replace(",", "");
  let tem = formattedDate.split(" ");
  let day = +tem[1] > 9 ? tem[1] : "0" + tem[1];
  return day + "/" + tem[0] + "/" + tem[2]; //DD/MONTH/YYYY
};
///date format in yyyy-mm-dd
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  return formattedDate;
};

/**
 * @description This is the base url for the images
 */
export const imageBaseUrl = (image) => BASE_URL + image;
