import { Accordion, AccordionTab } from "primereact/accordion";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { ReturnOrderTracking } from "./OrderTracking";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import moment from "moment";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests } from "../auth/AuthRequests";
import { setSnackbar } from "../../redux/reducers/snackbar";

export const ReturnOrderLogs = ({
  returnOrderList,
  orderDetails,
  callOrderApi,
}) => {
  const loader = useSelector(currentLoader);
  const dispatch = useDispatch();
  //states
  const [returnCollected, setReturnCollected] = useState(false);
  const [refundProcess, setRefundProcess] = useState(false);
  const [refundPrice, setRefundPrice] = useState("");
  const [refundPriceError, setRefundPriceError] = useState("");
  const [reason, setReason] = useState("");
  const [requestData, setRequestData] = useState({
    expectedAmount: "",
    requestId: "",
  });

  //set all popup false
  const setPopupFalse = () => {
    setRefundProcess(false);
    setReturnCollected(false);
    setRequestData({
      expectedAmount: "",
      requestId: "",
    });
    setReason("");
    setRefundPriceError("");
    setRefundPrice("");
  };

  // return collected api
  const returnCollectedSubmit = async (status) => {
    let total_error = "Refund amount should less than expected amount";
    let error = false;

    let payload = {
      status: status,
      requestId: requestData?.requestId,
      orderId: orderDetails?.id,
    };
    if (status === "refunded") {
      if (!refundPrice) {
        setRefundPriceError("Please enter refund amount");
        error = true;
      }
      if (Number(refundPrice) > Number(requestData?.expectedAmount)) {
        setRefundPriceError(total_error);
        error = true;
      }
      if (error) return;

      payload = {
        ...payload,
        refundAmount: +refundPrice,
        remark: reason,
      };
    }

    dispatch(changeLoader(true));
    await globalRequestAxios("put", payload, AuthRequests.RETURN_COLLECTED)
      .then((res) => {
        if (res.ack === 1) {
          callOrderApi();
          setPopupFalse();
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "Something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        console.error("err", err);
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  };
  const productBodyTemplate = (rowData) => {
    return (
      <div className="order-outline-box flex flex-column sm:flex-row sm:align-items-start justify-content-between px-0 py-0">
        <div className="flex align-items-start justify-content-between order-dtl-productLftCol">
          <div className="hand-on">
            <p className="p2 font-bold mb-1 line-height-3">
              {rowData?.product_title}{" "}
              {/* <span className="p2 ml-2">{` X ${rowData?.quantity}`}</span> */}
            </p>
            <p className="p2 wrapcontent w-210">
              {rowData?.product?.short_description}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const productPrice = (rowData) => {
    return (
      <>
        {orderDetails?.currency}{" "}
        {parseFloat(rowData?.expected_return_amount).toFixed(2)}
      </>
    );
  };
  const showIndex = (rowData, obj) => {
    return <>#{obj?.rowIndex + 1}</>;
  };
  return (
    <>
      <Accordion className="order-detail-accordion" activeIndex={0}>
        {returnOrderList?.map((item, index) => {
          const array = item?.request_status_log;

          let hasPickedUp = false;
          let hasReturnCollected = false;
          let hasRefunded = false;

          if (array) {
            array.forEach((item) => {
              if (item.status === "picked_up") {
                hasPickedUp = true;
              } else if (item.status === "return_collected") {
                hasReturnCollected = true;
              } else if (item.status === "refunded") {
                hasRefunded = true;
              }
            });
          }

          return (
            <AccordionTab
              key={index}
              header={
                <div className="flex align-items-center justify-content-between">
                  <p className="p2 ">
                    <span className="font-semibold">Return ID</span> - #{" "}
                    {item?.id}
                  </p>
                  <p className="p2 ">
                    <span className="font-semibold">Return Date</span> -{" "}
                    {moment(item?.createdAt).format("DD MMM YYYY, hh:mm A")}
                  </p>
                </div>
              }
            >
              <div className="m-0">
                {hasPickedUp && !hasReturnCollected && !hasRefunded ? (
                  <div className="flex align-items-start justify-content-end gap-2 mb-4">
                    <Button
                      disabled={loader}
                      className="btn btn-orange"
                      onClick={() => {
                        setRefundProcess(true);
                        setRequestData({
                          expectedAmount: item?.expected_return_amount,
                          requestId: item?.id,
                        });
                      }}
                    >
                      Initiate Refund
                    </Button>

                    <Button
                      disabled={loader}
                      className="btn btn-orange"
                      onClick={() => {
                        setReturnCollected(true);
                        setRequestData({
                          expectedAmount: item?.expected_return_amount,
                          requestId: item?.id,
                        });
                      }}
                    >
                      Return Collected
                    </Button>
                  </div>
                ) : hasReturnCollected && !hasRefunded ? (
                  <div className="flex align-items-start justify-content-end gap-2 mb-4">
                    <Button
                      disabled={loader}
                      className="btn btn-orange"
                      onClick={() => {
                        setRefundProcess(true);
                        setRequestData({
                          expectedAmount: item?.expected_return_amount,
                          requestId: item?.id,
                        });
                      }}
                    >
                      Process Refund
                    </Button>
                  </div>
                ) : hasRefunded && !hasReturnCollected ? (
                  <div className="flex align-items-start justify-content-end gap-2 mb-4">
                    <Button
                      disabled={loader}
                      className="btn btn-orange"
                      onClick={() => {
                        setReturnCollected(true);
                        setRequestData({
                          expectedAmount: item?.expected_return_amount,
                          requestId: item?.id,
                        });
                      }}
                    >
                      Return Collected
                    </Button>
                  </div>
                ) : null}
                <DataTable value={item?.request_detail}>
                  <Column field="id" body={showIndex} header="#"></Column>
                  <Column field="product_title" header="Product" body={productBodyTemplate}></Column>
                  <Column
                    field="return_quantity"
                    header="Return Quantity"
                  ></Column>
                  <Column field="return_reason" header="Reason"></Column>
                  <Column
                    field="expected_return_amount"
                    body={productPrice}
                    header="Price"
                  ></Column>
                </DataTable>
                {hasRefunded && (
                  <div className="flex align-items-start justify-content-between gap-2 mt-3">
                    <h5 className="h5">
                      <span className="font-semibold">Refund ID</span> - #{" "}
                      {item?.reference_number}
                    </h5>
                    <h5 className="h5">
                      <span className="font-semibold">Amount received</span> -{" "}
                      {orderDetails?.currency}{" "}
                      {parseFloat(item?.refund_amount).toFixed(2)}{" "}
                    </h5>
                  </div>
                )}
                <div className="flex align-items-start justify-content-between gap-2 mt-3">
                  {item?.admin_remark ? (
                    <h5 className="h5">
                      <span className="font-semibold">Admin Reason </span> -
                      {item?.admin_remark}{" "}
                    </h5>
                  ) : (
                    <h5 className="h5"></h5>
                  )}
                  {hasRefunded ? (
                    <h5 className="h5">
                      <span className="font-semibold">Refund date</span> -{" "}
                      {moment(item?.updatedAt).format("DD MMM YYYY, hh:mm A")}{" "}
                    </h5>
                  ) : (
                    <h5 className="h5"></h5>
                  )}
                </div>
              </div>
              <div className="tracking-box">
                <h6 className="h6 mt-0 mb-3">
                  <span className="font-bold">Return Tracking </span>{" "}
                </h6>
                <div className="order-tracking-block">
                  <ReturnOrderTracking
                    trackingLogs={item?.request_status_log}
                  />
                </div>
              </div>
            </AccordionTab>
          );
        })}
      </Accordion>

      {/* refund process popup */}
      <Dialog
        header="Refund Amount"
        className=""
        visible={refundProcess}
        onHide={() => setPopupFalse()}
        draggable={false}
      >
        <div className="addformDialog">
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <label className="sepLable">
                  Return Collected Amount{" "}
                  <span style={{ color: "red" }}>*</span>
                  <span
                    style={{
                      marginLeft: "20px",
                      fontWeight: 700,
                      fontSize: "10px",
                    }}
                  >
                    {" "}
                    Expected Amount:{orderDetails?.currency}{" "}
                    {parseFloat(requestData?.expectedAmount).toFixed(2)}
                  </span>
                </label>
                <span className="p-float-label">
                  <InputText
                    id="MinimumQuantity"
                    value={refundPrice}
                    placeholder="Enter Amount"
                    autoComplete="off"
                    onChange={(e) => {
                      let value = e.target.value.trimStart();
                      if (isNaN(value)) return;
                      setRefundPrice(e.target.value.trimStart());
                      setRefundPriceError("");
                    }}
                    style={{
                      borderColor: refundPriceError ? "#ff6b68" : "",
                    }}
                  />
                  {refundPriceError && (
                    <div className="p-invalid">{refundPriceError}</div>
                  )}
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <label className="sepLable">
                  Enter Your Reason <span>(Optional)</span>
                </label>
                <span className="p-float-label">
                  <InputTextarea
                    autoResize
                    id=""
                    value={reason}
                    rows={2}
                    placeholder="Enter Reason"
                    onChange={(e) => {
                      setReason(e.target.value.trimStart());
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="dialogFooter">
            <div className="btnsRow">
              <Button
                disabled={loader}
                className="btn btn-blue"
                onClick={() => returnCollectedSubmit("refunded")}
              >
                Refund
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      {/* return collected popup */}
      <Dialog
        header="Return collected"
        className=""
        visible={returnCollected}
        draggable={false}
        closable={false}
      >
        <div className="addformDialog">
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                Are you sure you want to change the status as Return Collected?{" "}
                <span className="p-float-label"></span>
              </div>
            </div>
          </div>

          <div className="dialogFooter">
            <div className="btnsRow">
              <Button
                disabled={loader}
                className="btn btn-gray mr-3 ml-2"
                onClick={setPopupFalse}
              >
                Cancel
              </Button>
              <Button
                disabled={loader}
                className="btn btn-blue"
                onClick={() => returnCollectedSubmit("return_collected")}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
