/** @format */
import { useState, useEffect } from "react";
import React from "react";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests } from "../auth/AuthRequests";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { validateEmail } from "../../Helpers/HelperFunctions";

// images

const ContactInformation = () => {
  const dispatch = useDispatch();

  //states
  const [companyDetails, setCompanyDetails] = useState("");
  const [companyDetailsError, setCompanyDetailsError] = useState(false);
  const [contactNumber, setContactNumber] = useState([{ value: "" }]);
  const [email, setEmail] = useState([{ value: "" }]);

  //get previous data
  const getPreviousData = () => {
    dispatch(changeLoader(true));
    globalRequestAxios("get", {}, AuthRequests.GET_CONTACT_DETAILS)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setCompanyDetails(res?.data?.company_details?.value || "");
          let numbers = res?.data?.mobile_numbers || [];
          let emails = res?.data?.emails || [];
          let tempNumbers = [];
          let tempEmails = [];
          for (let i = 0; i < numbers.length; i++) {
            if (numbers[i]?.value) {
              tempNumbers.push({
                value: numbers[i]?.value || "",
              });
            }
          }
          for (let i = 0; i < emails.length; i++) {
            if (emails[i]?.value) {
              tempEmails.push({
                value: emails[i]?.value || "",
              });
            }
          }
          setContactNumber(tempNumbers);
          setEmail(tempEmails);
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  };

  //add number and email in array
  const handleAdd = (type) => {
    if (type === "number") {
      setContactNumber([...contactNumber, { value: "" }]);
    } else if (type === "email") {
      setEmail([...email, { value: "" }]);
    }
  };

  //removed number and email from array
  const handleRemove = (type, index) => {
    if (type === "number") {
      let temp = [...contactNumber];
      temp.splice(index, 1);
      setContactNumber(temp);
    } else if (type === "email") {
      let temp = [...email];
      temp.splice(index, 1);
      setEmail(temp);
    }
  };

  //error handling
  const errorHandling = () => {
    let isError = false;

    if (!companyDetails) {
      setCompanyDetailsError("Please enter company details");
      isError = true;
    }
    if (contactNumber.length > 0) {
      let numberErrors = [...contactNumber];
      for (let i = 0; i < contactNumber.length; i++) {
        if (!contactNumber[i].value) {
          numberErrors[i].contactNumberError = "Please enter contact number";
          isError = true;
        }
      }
      setContactNumber([...numberErrors]);
    }
    if (email.length > 0) {
      let emailErrors = [...email];
      for (let i = 0; i < email.length; i++) {
        if (!validateEmail(email[i].value)) {
          emailErrors[i].emailError = "Please enter valid email";
          isError = true;
        }
        if (!email[i].value) {
          emailErrors[i].emailError = "Please enter email";
          isError = true;
        }
      }
      setEmail([...emailErrors]);
    }
    return isError;
  };

  //submit form
  const submitForm = () => {
    if (errorHandling()) {
      return;
    }
    const data = {
      companyDetails: companyDetails,
      contactNumbers1: contactNumber[0]?.value || "",
      contactNumbers2: contactNumber[1]?.value || "",
      contactNumbers3: contactNumber[2]?.value || "",
      emailAddress1: email[0]?.value || "",
      emailAddress2: email[1]?.value || "",
      emailAddress3: email[2]?.value || "",
    };
    dispatch(changeLoader(true));
    globalRequestAxios("put", data, AuthRequests.POST_CONTACT_DETAILS).then(
      (res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "Contact Information Updated Successfully",
              state: "success",
            })
          );
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      }
    );
  };

  useEffect(() => {
    getPreviousData();
  }, []);

  return (
    <>
      {/* <LeftSidebar /> */}
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="formHeader">
            <h4 className="h4 mb-2">Contact Information</h4>
          </div>
          <div className="contentBox-w">
            <div className="grid">
              <div className="lg:col-8 md:col-10 sm:col-12 col-12">
                <div className="grid">
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <label className="frmLabel">Company details</label>
                      <span className="p-float-label">
                        <InputTextarea
                          value={companyDetails}
                          onChange={(e) => {
                            setCompanyDetails(e.target.value.trimStart());
                          }}
                          autoResize
                          id="Description"
                          rows={2}
                          cols={30}
                          style={{
                            borderColor: companyDetailsError ? "red" : "",
                          }}
                        />
                        <label htmlFor="Description">
                          Enter company details{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                      </span>
                      {companyDetailsError && (
                        <div
                          style={{ color: companyDetailsError ? "red" : "" }}
                        >
                          {companyDetailsError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="divider mb-4 mt-2"></div>
                {/* contact number */}
                <label className="frmLabel">Contact Numbers</label>
                {contactNumber?.length > 0 &&
                  contactNumber?.map((number, index) => {
                    return (
                      <div key={index} className="grid">
                        <div className="lg:col-11 md:col-12 sm:col-12 col-12">
                          <div className="formField">
                            <span className="p-float-label">
                              <InputText
                                value={number?.value}
                                onChange={(e) => {
                                  let temp = [...contactNumber];
                                  temp[index].value =
                                    e.target.value.trimStart();
                                  temp[index].contactNumberError = "";
                                  setContactNumber(temp);
                                }}
                                id="Instagram"
                                autoComplete="off"
                                placeholder="Enter here"
                                style={{
                                  borderColor: number?.contactNumberError
                                    ? "red"
                                    : "",
                                }}
                              />
                              <label htmlFor="Instagram">
                                Enter Contact Number - {index + 1}{" "}
                                <span style={{ color: "red" }}>*</span>{" "}
                              </label>
                            </span>
                            {number?.contactNumberError && (
                              <div
                                style={{
                                  color: number?.contactNumberError
                                    ? "red"
                                    : "",
                                }}
                              >
                                {number?.contactNumberError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="lg:col-1 md:col-12 sm:col-12 col-12">
                          <div className="p-2" style={{ marginTop: "-10px" }}>
                            {index === 0 && contactNumber?.length < 3 ? (
                              <Button
                                onClick={() => {
                                  handleAdd("number");
                                }}
                                className="btn addmorCircleBtn"
                              >
                                <i className="pi pi-plus uploadIcon"></i>
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  handleRemove("number", index);
                                }}
                                className="btn removeCircleBtn"
                              >
                                <i className="pi pi-minus uploadIcon"></i>
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <div className="divider mb-4 mt-2"></div>
                {/* emails */}
                <label className="frmLabel">Email Address</label>
                {email?.length > 0 &&
                  email?.map((mail, index) => {
                    return (
                      <div key={index} className="grid">
                        <div className="lg:col-11 md:col-12 sm:col-12 col-12">
                          <div className="formField">
                            <span className="p-float-label">
                              <InputText
                                value={mail?.value}
                                onChange={(e) => {
                                  let temp = [...email];
                                  temp[index].value =
                                    e.target.value.trimStart();
                                  temp[index].emailError = "";
                                  setEmail(temp);
                                }}
                                id="Instagram"
                                autoComplete="off"
                                placeholder="Enter here"
                                style={{
                                  borderColor: mail?.emailError ? "red" : "",
                                }}
                              />
                              <label htmlFor="Instagram">
                                Enter Email - {index + 1}{" "}
                                <span style={{ color: "red" }}>*</span>{" "}
                              </label>
                            </span>
                            {mail?.emailError && (
                              <div
                                style={{
                                  color: mail?.emailError ? "red" : "",
                                }}
                              >
                                {mail?.emailError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="lg:col-1 md:col-12 sm:col-12 col-12">
                          <div className="p-2" style={{ marginTop: "-10px" }}>
                            {index === 0 && email?.length < 3 ? (
                              <Button
                                onClick={() => {
                                  handleAdd("email");
                                }}
                                className="btn addmorCircleBtn"
                              >
                                <i className="pi pi-plus uploadIcon"></i>
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  handleRemove("email", index);
                                }}
                                className="btn removeCircleBtn"
                              >
                                <i className="pi pi-minus uploadIcon"></i>
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div className="divider mb-4 mt-2"></div>
                {/* submit */}
                <div className="grid mt-2">
                  <div
                    className="lg:col-12 md:col-12 sm:col-12 col-12 mt-3"
                    style={{ textAlign: "right" }}
                  >
                    <Button onClick={submitForm} className="btn btn-yellow">
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactInformation;
