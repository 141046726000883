/** @format */
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { AuthRequests } from "../auth/AuthRequests";
import globalRequestAxios from "../../globalModules/globalRequestAxios";

const SocialMediaLinks = () => {
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);

  //states
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  //erroes states
  const [instagramErr, setInstagramErr] = useState("");
  const [facebookErr, setFacebookErr] = useState("");
  const [twitterErr, setTwitterErr] = useState("");
  const [youtubeErr, setYoutubeErr] = useState("");

  //get Social Media Links
  const getSocialMediaLinks = () => {
    dispatch(changeLoader(true));
    globalRequestAxios("get", {}, AuthRequests.GET_SOCIAL_MEDIA_LINKS)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setInstagram(res?.data[0]?.instagram_link);
          setFacebook(res?.data[0]?.facebook_link);
          setTwitter(res?.data[0]?.twitter_link);
          setYoutube(res?.data[0]?.youtube_link);
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  };
  useEffect(() => {
    getSocialMediaLinks();
  }, []);

  //error handling
  const handleErrors = () => {
    let error = false;
    if (!validateSocialLinks(facebook, "facebook")) {
      setFacebookErr("Please enter a valid Facebook link");
      error = true;
    }
    if (!validateSocialLinks(instagram, "instagram")) {
      setInstagramErr("Please enter a valid Instagram link");
      error = true;
    }
    if (!validateSocialLinks(twitter, "twitter")) {
      setTwitterErr("Please enter a valid Twitter link");
      error = true;
    }
    if (!validateSocialLinks(youtube, "youtube")) {
      setYoutubeErr("Please enter a valid youtube link");
      error = true;
    }
    if (!instagram) {
      setInstagramErr("Please enter Instagram link");
      error = true;
    }
    if (!facebook) {
      setFacebookErr("Please enter Facebook link");
      error = true;
    }
    if (!twitter) {
      setTwitterErr("Please enter Twitter link");
      error = true;
    }
    if (!youtube) {
      setYoutubeErr("Please enter youtube link");
      error = true;
    }
    return error;
  };

  //validate social media links
  const validateSocialLinks = (link, type) => {
    if(!link) return false;
    const regexMap = {
      facebook: /^(https?:\/\/)?(www\.)?facebook\.com\/(profile\.php\?id=)?[A-Za-z0-9_.-]+$/,
      twitter: /^(https?:\/\/)?(www\.)?twitter\.com\/([a-zA-Z0-9_]+)\/?$/,
      instagram: /^(https?:\/\/)?(www\.)?instagram\.com\/([a-zA-Z0-9_\-]+)\/?$/,
      linkedin: /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/([a-zA-Z0-9_\-]+)\/?$/,
      youtube: /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_\-]+)$/,
    };

    return regexMap[type].test(link);
  };


  //submit form
  const handleSubmit = () => {
    let error = handleErrors();
    if (error) return;
    let data = {
      instagram_link: instagram,
      facebook_link: facebook,
      twitter_link:twitter,
      youtube_link:youtube,
    };

    dispatch(changeLoader(true));
    globalRequestAxios("put", data, AuthRequests.UPDATE_SOICAL_MEDIA_LINKS)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "Social Media Links updated successfully",
              state: "success",
            })
          );
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  };

  return (
    <>
      {/* <LeftSidebar /> */}
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="formHeader">
            <h4 className="h4 mb-2">Social Media Links</h4>
          </div>
          <div className="contentBox-w">
            <div className="grid">
              <div className="lg:col-8 md:col-10 sm:col-12 col-12">
                <div className="grid">
                  <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          style={{ borderColor: instagramErr ? "red" : "" }}
                          id="Instagram"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={instagram}
                          onChange={(e) => {
                            setInstagramErr("");
                            setInstagram(e.target.value.trimStart());
                          }}
                        />
                        <label htmlFor="Instagram">Instagram</label>
                      </span>
                      {instagramErr && (
                        <div className="p-error">{instagramErr}</div>
                      )}
                    </div>
                  </div>
                  <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          style={{ borderColor: facebookErr ? "red" : "" }}
                          id="Facebook"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={facebook}
                          onChange={(e) => {
                            setFacebookErr("");
                            setFacebook(e.target.value.trimStart());
                          }}
                        />
                        <label htmlFor="Facebook">Facebook</label>
                      </span>
                      {facebookErr && (
                        <div className="p-error">{facebookErr}</div>
                      )}
                    </div>
                  </div>
                  <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          style={{ borderColor: twitterErr ? "red" : "" }}
                          id="Twitter"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={twitter}
                          onChange={(e) => {
                            setTwitterErr("");
                            setTwitter(e.target.value.trimStart());
                          }}
                        />
                        <label htmlFor="Twitter">Twitter</label>
                      </span>
                      {twitterErr && (
                        <div className="p-error">{twitterErr}</div>
                      )}
                    </div>
                  </div>
                  <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          style={{ borderColor: youtubeErr ? "red" : "" }}
                          id="Youtube"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={youtube}
                          onChange={(e) => {
                            setYoutubeErr("");
                            setYoutube(e.target.value.trimStart());
                          }}
                        />
                        <label htmlFor="Youtube">Youtube</label>
                      </span>
                      {youtubeErr && (
                        <div className="p-error">{youtubeErr}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid mt-2">
                  <div
                    className="lg:col-12 md:col-12 sm:col-12 col-12 mt-3"
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      disabled={loader}
                      onClick={handleSubmit}
                      className="btn btn-yellow"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SocialMediaLinks;
