import { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { Chart } from "primereact/chart";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests, LOCAL_STORAGE_KEYS } from "../auth/AuthRequests";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { useCallback } from "react";
import MyMapComponent from "./MapComponent";
import addDeleteGetLocalStorage from "../../globalModules/addDeleteGetLocalStorage ";
import { GOOGLE_MAP_KEY } from "../../BaseUrl";

const dashboard = () => {
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [totalOrder, setTotalOrder] = useState(null);
  const location = addDeleteGetLocalStorage(
    LOCAL_STORAGE_KEYS?.DB_LOCATION,
    {},
    "get"
  );
  //top sold products
  const [topSoldProducts, setTopSoldProducts] = useState([]);
  const [startDate2, setStartDate2] = useState("");
  const [endDate2, setEndDate2] = useState("");

  //for map
  const [markers, setMarkers] = useState([]);

  //update chart
  const updateChart = useCallback((obj) => {
    const data = {
      datasets: [
        {
          data: [
            obj?.countconfiremd,
            obj?.countshipped,
            obj?.countdeliverd,
            obj?.countcanceled,
            obj?.countpickedup,
            obj?.countreturned,
          ],
          backgroundColor: [
            "#27AAE2",
            "#EE9C16",
            "#59AA00",
            "#D23D50",
            "#80BCBD",
            "#F05941",
          ],
          hoverBackgroundColor: [
            "#27AAE2",
            "#EE9C16",
            "#59AA00",
            "#D23D50",
            "#80BCBD",
            "#F05941",
          ],
        },
      ],
      labels: [
        "Confirmed",
        "Shipped",
        "Delivered",
        "Cancelled",
        "Picked Up",
        "Returned",
      ],
    };
    const options = {
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
          },
        },
      },
    };
    setChartData(data);
    setChartOptions(options);
  });

  //get contact us list
  const getOrderCountApi = useCallback(() => {
    let object = {
      start_date: startdate,
      end_date: enddate,
    };
    dispatch(changeLoader(true));
    const objString = "?" + new URLSearchParams(object).toString();
    globalRequestAxios("get", {}, AuthRequests.GET_ORDER_COUNTS + objString)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          let obj = res?.data;
          setTotalOrder(obj?.total_count);
          updateChart(obj);
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  });

  //get top 10 sold products
  const getTopSoldProductsApi = useCallback(() => {
    let object = {
      start_date: startDate2,
      end_date: endDate2,
    };
    dispatch(changeLoader(true));
    const objString = "?" + new URLSearchParams(object).toString();
    globalRequestAxios("get", {}, AuthRequests.GET_TOP_SOLD_PRODUCT + objString)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          let obj = res?.data;
          setTopSoldProducts(obj);
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  });

  //get map data lat long
  const getMapDataApi = useCallback(() => {
    dispatch(changeLoader(true));
    globalRequestAxios("get", {}, AuthRequests.GET_MAP_DATA)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          let arr = res?.data || [];
          let newArr = [];
          for (let obj of arr) {
            let newObj = {
              id: obj?.id,
              name: `${obj?.address_name}`,
              position: { lat: +obj?.latitude, lng: +obj?.longitude },
            };
            newArr.push(newObj);
          }
          setMarkers(newArr);
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  });

  useEffect(() => {
    getOrderCountApi();
  }, [startdate, enddate]);

  useEffect(() => {
    getTopSoldProductsApi();
  }, [startDate2, endDate2]);

  useEffect(() => {
    getMapDataApi();
  }, []);

  return (
    <>
      <section className="admin-content-wrapper Dashboardpage">
        <div
          className="adminContent"
          style={{ background: "transparent", boxShadow: "none" }}
        >
          <h4 className="h4 mb-3">Dashboard</h4>
          <div className="contentBox-w">
            <div className="tableHeader mb-3">
              <h4 className="h4 mb-0">Order Details and Status</h4>
              <div className="tableFilterRow">
                <div
                  className="tableFilterCol dateFilter"
                  style={{
                    minWidth: "auto",
                    maxWidth: "fit-content",
                    width: "auto",
                  }}
                >
                  <div className="combDateBox">
                    <span className="c-label">Date</span>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      maxDate={enddate}
                      // minDate={new Date()}
                      value={startdate}
                      onChange={(e) => {
                        setStartDate(e.value);
                      }}
                      showIcon
                      placeholder="Start Date"
                    />
                    <span>-</span>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      minDate={startdate}
                      value={enddate}
                      onChange={(e) => {
                        setEndDate(e.value);
                      }}
                      showIcon
                      placeholder="End Date"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid mt-4">
              <div className="lg:col-5 md:col-5 sm:col-12 col-12 mx-auto">
                {totalOrder > 0 ? (
                  <Chart
                    type="pie"
                    data={chartData}
                    options={chartOptions}
                    className="orderstatusChart"
                  />
                ) : null}
                {totalOrder == 0 && !loader ? (
                  <div className="totalOrderBox">
                    <h2 className="h2 mb-0 text-center">No Order Received</h2>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="contentBox-w mt-5">
            <div className="tableHeader mb-3">
              <h4 className="h4 mb-0">Top 10 sold products</h4>
              <div className="tableFilterRow">
                <div
                  className="tableFilterCol dateFilter"
                  style={{
                    minWidth: "auto",
                    maxWidth: "fit-content",
                    width: "auto",
                  }}
                >
                  <div className="combDateBox">
                    <span className="c-label">Date</span>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      maxDate={endDate2}
                      value={startDate2}
                      onChange={(e) => {
                        setStartDate2(e.value);
                      }}
                      showIcon
                      placeholder="Start Date"
                    />
                    <span>-</span>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      minDate={startDate2}
                      value={endDate2}
                      onChange={(e) => {
                        setEndDate2(e.value);
                      }}
                      showIcon
                      placeholder="End Date"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid adminTableContent">
              <div className="tableContent">
                <table>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Product Name</th>
                      <th>Short Description</th>
                      <th>Category</th>
                      <th>Sub Category</th>
                      <th>Quantity</th>
                      <th>Selling Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topSoldProducts?.length > 0 &&
                      topSoldProducts?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.name}</td>
                            <td>{item?.short_description}</td>
                            <td>{item?.category_details?.name_en}</td>
                            <td>{item?.subcategory_details?.name_en}</td>
                            <td>{item?.quantitySum}</td>
                            <td>
                              {location == "dubai" ? "AED " : "USD "}
                              {item?.selling_price}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {topSoldProducts?.length == 0 && !loader && (
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      padding: "20px",
                    }}
                  >
                    <h4 className="h4 mb-0">{"No Products Found"}</h4>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="contentBox-w mt-5">
            <div className="tableHeader mb-3">
              <h4 className="h4 mb-0">Google Map</h4>
              <div className="tableFilterRow"></div>
            </div>
            <div className="container-fluid adminTableContent">
              {markers?.length > 0 && (
                <MyMapComponent
                  lat={24.2016} //uae lat
                  lng={53.802} //uae lng
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=places`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `500px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  markers={markers}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default dashboard;
