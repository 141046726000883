/** @format */
import { useEffect, useState } from "react";
import React from "react";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import { Editor } from "primereact/editor";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { AuthRequests } from "../auth/AuthRequests";
import globalRequestAxios from "../../globalModules/globalRequestAxios";

// images

const ShippingPolicy = () => {
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  const [textEnglish, setTextEnglish] = useState("");
  const [textEnglishErr, setTextEnglishErr] = useState("");
  const [textArabic, setTextArabic] = useState("");
  const [textArabicErr, setTextArabicErr] = useState("");
  const [showEditer, setShowEditer] = useState(false);

  //get shipping policy
  const getShippingPolicy = () => {
    dispatch(changeLoader(true));
    globalRequestAxios("get", {}, AuthRequests.GET_CMS_DETAILS+`?page=shipping_policy`)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setTextEnglish(res?.data?.description_en);
          setTextArabic(res?.data?.description_ar);
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
        setShowEditer(true);
      })
      .catch((err) => {
        setShowEditer(true);
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  };
  useEffect(() => {
    getShippingPolicy();
  }, []);

  //error handling
  const handleErrors = () => {
    let error = false;
    if (!textEnglish) {
      setTextEnglishErr("Please enter shipping policy");
      error = true;
    }
    if (!textArabic) {
      setTextArabicErr("Please enter shipping policy");
      error = true;
    }
    if (textEnglish) {
      if (
        textEnglish
          .replace(/<[^>]+>/g, "") //remove html tag
          .replace(/\s{2,}/g, " ").length > 2000 //remove extra space
      ) {
        setTextEnglishErr(
          "Shipping policy should be less than 2000 characters"
        );
        error = true;
      }
    }
    if (textArabic) {
      if (
        textArabic
          .replace(/<[^>]+>/g, "") //remove html tag
          .replace(/\s{2,}/g, " ").length > 2000 //remove extra space
      ) {
        setTextArabicErr("Shipping policy should be less than 2000 characters");
        error = true;
      }
    }
    return error;
  };

  //on submit function
  const onSubmit = () => {
    if (handleErrors()) {
      return;
    }
    const data = {
      description_en:textEnglish,
      description_ar:textArabic,
      page:"shipping_policy"
    };
    dispatch(changeLoader(true));
    globalRequestAxios("put", data, AuthRequests.UPDATE_CMS_DETAILS)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "Shipping Policy updated successfully",
              state: "success",
            })
          );
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  };

  return (
    <>
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="formHeader">
            <h4 className="h4 mb-2">Shipping Policy</h4>
          </div>
          <div className="contentBox-w">
            <div className="grid">
              <div className="lg:col-10 md:col-10 sm:col-12 col-12">
                <div className="grid">
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <label className="frmLabel">
                        Shipping Policy (English){" "}
                      </label>
                      {showEditer && 
                      <Editor
                        value={textEnglish}
                        style={{ height: "200px" }}
                        onTextChange={(e) => {
                          if (e.htmlValue == null) {
                            setTextEnglish("");
                            return;
                          }
                          setTextEnglish(e.htmlValue);
                          setTextEnglishErr("");
                          if (
                            e.htmlValue
                              .replace(/<[^>]+>/g, "") //remove html tag
                              .replace(/\s{2,}/g, " ").length > 2000 //remove extra space
                          ) {
                            setTextEnglishErr(
                              "Shipping policy should be less than 2000 characters"
                            );
                          }
                        }}
                      />
                      }
                      {textEnglishErr && (
                        <div className="p-error">{textEnglishErr}</div>
                      )}
                    </div>
                    <div className="formField">
                      <label className="frmLabel">
                        Shipping Policy (Arabic){" "}
                      </label>
                      {showEditer && 
                      <Editor
                        value={textArabic}
                        style={{ height: "200px" }}
                        onTextChange={(e) => {
                          if (e.htmlValue == null) {
                            setTextArabic("");
                            return;
                          }
                          setTextArabic(e.htmlValue);
                          setTextArabicErr("");
                          if (
                            e.htmlValue
                              .replace(/<[^>]+>/g, "") //remove html tag
                              .replace(/\s{2,}/g, " ").length > 2000 //remove extra space
                          ) {
                            setTextArabicErr(
                              "Shipping policy should be less than 2000 characters"
                            );
                          }
                        }}
                      />
                      }
                      {textArabicErr && (
                        <div className="p-error">{textArabicErr}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid mt-2">
                  <div
                    className="lg:col-12 md:col-12 sm:col-12 col-12 mt-3"
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      disabled={loader}
                      className="btn btn-yellow"
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShippingPolicy;
