/** @format */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import React from "react";
import { Paginator } from "primereact/paginator";
import { Calendar } from "primereact/calendar";
import { Sidebar } from "primereact/sidebar";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import moment from "moment/moment";

// images
import sortIcon from "../../assets/images/structure/sortIcon.png";
import editIcon from "../../assets/images/structure/edit-32.png";
import shareIcon from "../../assets/images/structure/share-24.png";
import deleteIcon from "../../assets/images/structure/delete-32.png";
import { AuthRequests, LOCAL_STORAGE_KEYS } from "../auth/AuthRequests";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import addDeleteGetLocalStorage from "../../globalModules/addDeleteGetLocalStorage ";
import { Dialog } from "primereact/dialog";
import { dateFormat, formatDate } from "../../Helpers/HelperFunctions";
import Loaders from "../../Loaders";
import { currentLoader } from "../../redux/reducers/loader";
import { useSelector } from "react-redux";

// Simple debounce function
let timeout1 = null;
const debounce = (func, delay) => {
  return function (...args) {
    clearTimeout(timeout1);
    timeout1 = setTimeout(() => func.apply(this, args), delay);
  };
};

export const CouponCodesList = ({ setAlert }) => {
  const location = addDeleteGetLocalStorage(
    LOCAL_STORAGE_KEYS?.DB_LOCATION,
    {},
    "get"
  );
  const [selecDiscountType, setSelecDiscountType] = useState(null);
  const loader = useSelector(currentLoader);
  const [shared, setShared] = useState("");
  const [editType, setEditType] = useState("");
  const DiscountCoupn = [
    { name: "All", code: "" },
    { name: "Fixed Amount", code: "fixed_amount" },
    { name: "Percentage", code: "percentage" },
  ];
  const cType = [
    { name: "All", code: "" },
    { name: "voucher", code: "voucher" },
    { name: "coupon", code: "coupon" },
  ];
  const navigate = useNavigate();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleAddCoupon, setVisibleAddCoupon] = useState(false);
  const [visibleEditCoupon, setVisibleEditCoupon] = useState(false);
  const [visibleDeleteDialog, setDeleteDialog] = useState(false);
  const [selectList, setSelectList] = useState({ name: "10", code: "10" });
  const Lists = [
    { name: "10", code: "10" },
    { name: "15", code: "15" },
    { name: "50", code: "50" },
    { name: "100", code: "100" },
  ];
  const DiscountCoupn1 = [
    { name: "Fixed Amount", code: "fixed_amount" },
    { name: "Percentage", code: "percentage" },
  ];
  const [checked1, setChecked1] = useState(false);
  //states
  const [couponSelectName, setCouponSelectName] = useState("");
  const [couponCodeName, setCouponCodeName] = useState("");
  const [couponCodeNameCoupon, setCouponCodeNameCoupon] = useState("");
  const [selectDiscountType1, setSelectDiscountType1] = useState(
    visibleEditCoupon ? null : { name: "Fixed Amount", code: "fixed_amount" }
  );
  const [discountValue, setDiscountValue] = useState("");
  const [enterAmount, setEnterAmount] = useState("");
  const [startdate1, setStartDate1] = useState(null);
  const [enddate1, setEndDate1] = useState(null);
  const [numberOfCouponsUsed, setNumberOfCouponsUsed] = useState();
  const [usageLimit, setUsageLimit] = useState();
  //errors
  const [couponCodeNameErr, setCouponCodeNameErr] = useState("");
  const [discountTypeErr, setDiscountTypeErr] = useState("");
  const [discountValueErr, setDiscountValueErr] = useState("");
  const [enterAmountErr, setEnterAmountErr] = useState("");
  const [startDateErr, setStartDateErr] = useState("");
  const [endDateErr, setEndDateErr] = useState("");
  const [numberOfCouponsUsedErr, setNumberOfCouponsUsedErr] = useState("");
  const [usageLimitErr, setUsageLimitErr] = useState("");
  const [couponSelectNameErr, setCouponSelectNameErr] = useState("");
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  ///listing states
  const [editId, setEditId] = useState("");
  const [coupon_list, setCoupon_list] = useState(null);
  const [search_coupon, setSearch_coupon] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [discount_type, setDiscount_type] = useState("");
  const [selectType, setSelectType] = useState({ name: "All", code: "" });
  const [startdate, setStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);
  const [total_list, setTotal_list] = useState(0);
  const [sortBy, setSortBy] = useState("id");
  const [orderBy, setOrderBy] = useState("DESC");
  const [loading_list, setLoading_list] = useState(false);
  const [select_id, setSelect_id] = useState(null);
  const [usage, setUsage] = useState("");
  const [usageNum, setUsageNum] = useState("");
  const disabled = "disabled";
  //sort function
  const sortFunction = (sortType) => {
    setSortBy(sortType);
    if (orderBy == "ASC") {
      setOrderBy("DESC");
    } else {
      setOrderBy("ASC");
    }
  };

  const get_coupon_list = async () => {
    setLoading_list(true);
    let object = {
      limit: limit,
      page: page,
      status: status,
      search: search_coupon,
      is_type: selectType.code ? selectType.code : "",
      sortBy: sortBy,
      orderBy: orderBy,
      coupon_type: discount_type,
      start_date: startdate ? moment(startdate).format("YYYY-MM-DD") : "",
      end_date: enddate ? moment(enddate).format("YYYY-MM-DD") : "",
    };
    const objString = "?" + new URLSearchParams(object).toString();
    const res = await globalRequestAxios(
      "get",
      {},
      AuthRequests.GET_ALL_COUPONS + objString
    );
    if (res.ack == 1) {
      setLoading_list(false);
      if (res.data.length === 0) {
        setFirst(first - 10);
      }
      setCoupon_list(res.data);
      setTotal_list(res.count);
    } else {
      setCoupon_list([]);
      setLoading_list(false);
    }
  };

  const callFunction = () => {
    get_coupon_list();
    AlphaNumber();
    setCouponSelectName("coupon");
    setUsageLimit(1);
    setNumberOfCouponsUsed(1);
  };
  // Create a debounced version of getProductList
  const debouncedGetProductList = debounce(callFunction, 300);

  useEffect(() => {
    debouncedGetProductList();
  }, [
    page,
    limit,
    status,
    search_coupon,
    sortBy,
    orderBy,
    discount_type,
    startdate,
    selectType,
    enddate,
    visibleAddCoupon,
    shared,
  ]);

  const navigated = (id) => {
    navigate(`/share-list/${id}`);
  };

  // handle common input change errors
  const handleErrors = () => {
    let error = false;
    if (!couponSelectName) {
      setCouponSelectNameErr("Please Select coupon code ");
      error = true;
    }

    if (!couponCodeNameCoupon) {
      if (couponCodeName.length > 1 && couponSelectName == "voucher") {
        setCouponCodeNameErr("");
        error = false;
      } else {
        setCouponCodeNameErr("Please enter coupon code name");
        error = true;
      }
    }

    if (!selectDiscountType1) {
      setDiscountTypeErr("Please select discount type");
      error = true;
    }
    if (!discountValue) {
      setDiscountValueErr("Please enter discount value");
      error = true;
    }
    if (!enterAmount) {
      setEnterAmountErr("Please enter amount");
      error = true;
    }

    if (!enterAmount) {
      if (selectDiscountType1.name == "Fixed Amount") {
        setEnterAmountErr("");
        error = false;
      } else {
        setEnterAmountErr("Please enter amount");
        error = true;
      }
    }
    if (!startdate1) {
      setStartDateErr("Please select start date");
      error = true;
    }
    if (!enddate1) {
      setEndDateErr("Please select end date");
      error = true;
    }
    return error;
  };

  //show coupon details states
  const [coupon_details, setCoupon_details] = useState(null);
  //GET SINGLE COUPON DETAIS
  const get_single_coupon = async (id, show) => {
    setSelect_id(id);
    setLoading_list(true);
    const res = await globalRequestAxios(
      "get",
      {},
      AuthRequests.GET_SINGLE_COUPON + `${id}`
    );
    setEditId(res.data.code);
    setEditType(res.data.is_type);

    setUsage(res.data.usage_limits);
    setUsageNum(res.data.max_user_apply);

    if (res.ack == 1) {
      setLoading_list(false);
      if (show) {
        setVisibleRight(true);
      } else {
        setVisibleEditCoupon(true);
        setVisibleAddCoupon(true);
      }
      setCouponCodeName(res.data.code);
      setCouponCodeNameCoupon(res.data.code);
      setSelectDiscountType1({
        name:
          res.data.coupon_type == "percentage" ? "Percentage" : "Fixed Amount",
        code:
          res.data.coupon_type == "percentage" ? "percentage" : "fixed_amount",
      });
      setDiscountValue(res.data.amount);
      setEnterAmount(res.data.max_amount);
      setStartDate1(new Date(res.data.start_date));
      setEndDate1(new Date(res.data.expiry_date));
      setNumberOfCouponsUsed(res.data.max_user_apply);
      setUsageLimit(res.data.usage_limits);
      setChecked1(res.data.is_public == 1 ? true : false);
      setCouponSelectName(res.data.is_type);
      setCoupon_details(res.data);
    } else {
      setLoading_list(false);
    }
  };
  ///ADD COUPON
  const handleSave = async () => {
    let error = handleErrors();
    if (error) return false;

    const data = {
      isType: couponSelectName,
      code:
        couponSelectName == "coupon" ? couponCodeNameCoupon : couponCodeName,
      couponType: selectDiscountType1.code,
      amount: discountValue,
      maxAmount: enterAmount,
      startDate: startdate1 ? moment(startdate1).format("YYYY-MM-DD") : "",
      expiryDate: enddate1 ? moment(enddate1).format("YYYY-MM-DD") : "",
      maxUserApply: numberOfCouponsUsed.toString()
        ? numberOfCouponsUsed.toString()
        : "0",

      // usageLimits: usageLimit.toString() ? usageLimit.toString() : "0",
      usageLimits: editType
        ? usage.toString()
          ? usage.toString()
          : "0"
        : usageLimit.toString()
        ? usageLimit.toString()
        : "0",
      isPublic: checked1 ? "1" : "0",
    };
    setLoading_list(true);
    const res = await globalRequestAxios(
      "post",
      data,
      AuthRequests.ADD_SINGLE_COUPON
    );

    if (res.ack == 1) {
      setLoading_list(false);
      setVisibleAddCoupon(false);
      setEditType("");
      resetAll();
      get_coupon_list();
      if (res.data.is_type == "voucher") {
        setAlert("Voucher added successfully", "success");
      } else {
        setAlert("coupon added successfully", "success");
      }
    } else {
      setLoading_list(false);
      if (res.msg == "Coupon already exist.") {
        setAlert("Coupon already exist.", "error");
      } else {
        setAlert("Something went wrong", "error");
      }
    }
  };
  ///EDIT COUPON
  const handleUpdate = async () => {
    let error = handleErrors();
    if (error) return false;
    const data = {
      id: select_id.toString(),
      code: couponCodeName,
      code: couponCodeNameCoupon,
      couponType: selectDiscountType1.code,
      amount: discountValue.toString(),
      maxAmount: enterAmount.toString(),
      startDate: formatDate(startdate1),
      expiryDate: formatDate(enddate1),
      maxUserApply: usageNum?.toString() ? usageNum?.toString() : "0",

      usageLimits: usage?.toString() ? usage?.toString() : "0",

      isPublic: checked1 ? "1" : "0",
    };
    setLoading_list(true);
    const res = await globalRequestAxios(
      "put",
      data,
      AuthRequests.UPDATE_SINGLE_COUPON
    );
    if (res.ack == 1) {
      setLoading_list(false);
      setVisibleEditCoupon(false);
      resetAll();
      get_coupon_list();
      setAlert("Updated successfully", "success");
    } else {
      setLoading_list(false);
      setAlert("Something went wrong", "error");
    }
  };
  ///DELETE COUPON
  const handleDelete = async () => {
    if (!select_id) return false;
    const res = await globalRequestAxios(
      "delete",
      {},
      AuthRequests.DELETE_SINGLE_COUPON + `${select_id}`
    );
    if (res.ack == 1) {
      setDeleteDialog(false);
      if (coupon_list?.length == 1) {
        if (page > 1) {
          setPage(page - 1);
        } else {
          setPage(1);
        }
      }
      get_coupon_list();
      setAlert("Deleted successfully", "success");
      setDeleteDialog(false);
    } else {
      setAlert("Something went wrong", "error");
    }
  };

  const resetAll = () => {
    setVisibleAddCoupon(false);
    setVisibleEditCoupon(false);
    setVisibleRight(false);
    setChecked1(false);
    setCouponCodeName("");
    setCouponCodeNameCoupon("");
    setSelectDiscountType1("");
    setDiscountValue("");
    setEnterAmount("");
    setStartDate1("");
    setEndDate1("");
    setNumberOfCouponsUsed("");
    setDiscountTypeErr("");
    setDiscountValueErr("");
    setEnterAmountErr("");
    setStartDateErr("");
    setEndDateErr("");
    setNumberOfCouponsUsedErr("");
    setUsageLimitErr("");
    setCouponCodeNameErr("");
    setCouponSelectName("");
    setUsageLimit("");
    setCouponSelectNameErr("");
    setEditType("");
  };

  ///pagination function
  const onPageChange = (event) => {
    setPage(event.page + 1);
    setFirst(event.first);
    setRows(event.rows);
  };
  const AlphaNumber = () => {
    const randomString = (length, chars) => {
      var mask = "";

      if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      if (chars.indexOf("#") > -1) mask += "0123456789";

      var result = "";
      for (var i = length; i > 0; --i)
        result += mask[Math.floor(Math.random() * mask.length)];
      return result;
    };

    const alpha = randomString(6, "A#");
    setCouponCodeName(alpha);
  };

  useEffect(() => {
    setDiscountTypeErr("");
    setDiscountValueErr("");
    setEnterAmountErr("");
    setStartDateErr("");
    setEndDateErr("");
    setCouponCodeNameErr("");
    setUsageLimit(1);
    setNumberOfCouponsUsed(1);
  }, [couponSelectName]);
  useEffect(() => {
    setEnterAmountErr("");
  }, [selectDiscountType1]);
  return (
    <>
      {loading_list && <Loaders />}

      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="tablefilterheader pb-2">
            <div className="tableHeader">
              <h4 className="h4 mb-0">Coupons And Vouchers</h4>
              <div className="tableFilterRow">
                <div className="tableFilterCol" style={{ width: "auto" }}>
                  <Button
                    className="btn btn-blue"
                    onClick={() => setVisibleAddCoupon(true)}
                  >
                    <i className="pi pi-plus mr-2"></i> Create Coupon
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="tablefilterSubheader">
            <div className="tableHeader">
              <div className="tableFilterRow" style={{ marginLeft: "0" }}>
                <div
                  className="tableFilterCol sortList"
                  style={{ marginLeft: "0" }}
                >
                  <Dropdown
                    value={selectList}
                    onChange={(e) => {
                      setSelectList(e.value);
                      setLimit(e.value.code);
                      setRows(+e.value.code);
                      setPage(0);
                      setFirst(0);
                    }}
                    options={Lists}
                    optionLabel="name"
                    placeholder="10"
                    className="filterDropdown"
                  />
                </div>
              </div>
              <div className="tableFilterRow">
                <div className="tableFilterCol">
                  <Dropdown
                    value={selectType}
                    onChange={(e) => {
                      setSelectType(e.value);
                    }}
                    options={cType}
                    optionLabel="name"
                    placeholder="Type"
                    className="filterDropdown"
                  />
                </div>
                <div className="tableFilterCol">
                  <Dropdown
                    value={selecDiscountType}
                    onChange={(e) => {
                      setSelecDiscountType(e.value);
                      setDiscount_type(e.value.code);
                      setPage(1);
                      setFirst(0);
                    }}
                    options={DiscountCoupn}
                    optionLabel="name"
                    placeholder="Discount Type"
                    className="filterDropdown"
                  />
                </div>
                <div
                  className="tableFilterCol dateFilter"
                  style={{
                    minWidth: "auto",
                    maxWidth: "fit-content",
                    width: "auto",
                  }}
                >
                  <div className="combDateBox">
                    <span className="c-label">Date</span>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      maxDate={enddate}
                      value={startdate}
                      onChange={(e) => {
                        setStartDate(e.value);
                      }}
                      showIcon
                      placeholder="Start Date"
                    />
                    <span>-</span>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      minDate={startdate}
                      value={enddate}
                      onChange={(e) => {
                        setEndDate(e.value);
                      }}
                      showIcon
                      placeholder="End Date"
                    />
                  </div>
                </div>
                <div className="tableFilterCol">
                  <div className="p-inputgroup searchFilter">
                    <InputText
                      value={search_coupon}
                      onChange={(e) =>
                        setSearch_coupon(e.target.value.trimStart().trimStart())
                      }
                      placeholder="Search Coupon Code"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid adminTableContent">
            <div className="tableContent">
              <table>
                <thead>
                  <tr>
                    <th>
                      <p className="tbleheading">
                        Coupon code
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("code")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Discount Type
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("coupon_type")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Discount Value
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("amount")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Type
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("is_type")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Max Discount<br></br> Amount
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("max_amount")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Valid Period
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("start_date")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        No of coupon<br></br> to be used
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("max_user_apply")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Public
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("is_public")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {coupon_list?.map((item, i) => (
                    <tr key={i}>
                      <td onClick={() => get_single_coupon(item?.id, true)}>
                        <a className="tbaBlue">{item?.code}</a>
                      </td>
                      <td onClick={() => get_single_coupon(item?.id, true)}>
                        {item?.coupon_type
                          ? item?.coupon_type
                              .replace(/_/g, " ")
                              .replace(/\b\w/g, (c) => c.toUpperCase())
                          : ""}
                      </td>
                      <td
                        style={{ textAlign: "center" }}
                        onClick={() => get_single_coupon(item?.id, true)}
                      >
                        {item?.coupon_type == "percentage"
                          ? item?.amount + "%"
                          : location == "dubai"
                          ? "AED" + " " + item?.amount.toFixed(2)
                          : "USD" + " " + item?.amount.toFixed(2)}
                      </td>
                      <td style={{ textAlign: "center" }}>{item.is_type}</td>

                      <td onClick={() => get_single_coupon(item?.id, true)}>
                        {location == "dubai" ? "AED" : "USD"}{" "}
                        {item?.max_amount?.toFixed(2)}
                      </td>
                      <td onClick={() => get_single_coupon(item?.id, true)}>
                        {dateFormat(item?.start_date) +
                          " - " +
                          dateFormat(item?.expiry_date)}
                      </td>
                      <td
                        style={{ textAlign: "center" }}
                        onClick={() => get_single_coupon(item?.id, true)}
                      >
                        {item?.max_user_apply}
                      </td>
                      <td
                        style={{ textAlign: "center" }}
                        onClick={() => get_single_coupon(item?.id, true)}
                      >
                        {item?.is_public == 1 ? "Yes" : "No"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <div className="tdAction">
                          <a className="TdICon">
                            {" "}
                            <img
                              src={shareIcon}
                              alt=""
                              className="AcIcon"
                              onClick={() => {
                                navigated(item?.id);
                              }}
                            />{" "}
                          </a>
                          <a
                            className="TdICon"
                            onClick={() => {
                              get_single_coupon(item?.id);
                            }}
                          >
                            {" "}
                            <img
                              src={editIcon}
                              alt=""
                              className="AcIcon"
                            />{" "}
                          </a>
                          <a
                            className="TdICon"
                            onClick={() => {
                              setSelect_id(item?.id);
                              setDeleteDialog(true);
                            }}
                          >
                            {" "}
                            <img
                              src={deleteIcon}
                              alt=""
                              className="AcIcon"
                            />{" "}
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {coupon_list?.length == 0 && !loading_list ? (
              <div
                style={{ textAlign: "center", width: "100%", padding: "20px" }}
              >
                <h4 className="h4 mb-0">{"No Coupon Found"}</h4>
              </div>
            ) : null}
            {total_list > 0 && total_list > +selectList.code && (
              <Paginator
                first={first}
                rows={rows}
                totalRecords={total_list}
                onPageChange={onPageChange}
                className="mt-2"
              />
            )}
          </div>
        </div>
      </section>

      {/* show details */}

      <Sidebar
        className="detailSiderbar"
        visible={visibleRight}
        position="right"
        onHide={() => resetAll()}
      >
        <h4 className="mt-0 sidebarTitleHd">
          {coupon_details?.is_type.charAt(0).toUpperCase() +
            coupon_details?.is_type.slice(1).toLowerCase()}{" "}
          Details
        </h4>

        <div className="sidebarContentBody">
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Coupon Code Name</label>
                  <InputText value={coupon_details?.code} />
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Discount Type</label>
                  <InputText
                    value={coupon_details?.coupon_type
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                  />
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Discount</label>
                  <InputText
                    value={
                      coupon_details?.coupon_type == "percentage"
                        ? coupon_details?.amount + "%"
                        : location == "dubai"
                        ? "AED" + " " + coupon_details?.amount.toFixed(2)
                        : "USD" + " " + coupon_details?.amount.toFixed(2)
                    }
                  />
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Max Discount Amount</label>
                  <InputText
                    value={
                      location == "dubai"
                        ? "AED " + coupon_details?.max_amount?.toFixed(2)
                        : "USD " + coupon_details?.max_amount?.toFixed(2)
                    }
                  />
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Start Date</label>
                  <InputText value={dateFormat(coupon_details?.start_date)} />
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">End Date</label>
                  <InputText value={dateFormat(coupon_details?.expiry_date)} />
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Usage Limits</label>
                  <InputText value={coupon_details?.usage_limits} />
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">No Of Coupon To Be Used</label>
                  <InputText value={coupon_details?.max_user_apply} />
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">No of Coupon Used</label>
                  <InputText value={coupon_details?.used_count} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>

      {/* add/edit  details */}
      <Sidebar
        className="detailSiderbar"
        visible={visibleAddCoupon}
        position="right"
        onHide={() => {
          resetAll();
        }}
      >
        <h4 className="mt-0 sidebarTitleHd">
          {visibleEditCoupon
            ? `Edit ${
                editType.charAt(0).toUpperCase() +
                editType.slice(1).toLowerCase()
              }`
            : `Add ${
                couponSelectName.charAt(0).toUpperCase() +
                couponSelectName.slice(1).toLowerCase()
              }`}
        </h4>

        <div className="sidebarContentBody">
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="grid">
                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  {visibleEditCoupon ? (
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={couponSelectName}
                        onChange={(e) => {
                          setCouponSelectName(e.target.value);
                        }}
                      >
                        <h2>
                          {editType.charAt(0).toUpperCase() +
                            editType.slice(1).toLowerCase()}
                        </h2>
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={couponSelectName}
                        onChange={(e) => {
                          setCouponSelectName(e.target.value);
                          setCouponSelectNameErr("");
                        }}
                        style={{
                          borderColor: couponSelectNameErr ? "#ff6b68" : "",
                        }}
                      >
                        {" "}
                        <FormControlLabel
                          value="voucher"
                          control={<Radio />}
                          label="Voucher"
                        />
                        <FormControlLabel
                          value="coupon"
                          control={<Radio />}
                          label="Coupon"
                        />
                        {couponSelectNameErr && (
                          <div className="p-invalid">{couponSelectNameErr}</div>
                        )}
                      </RadioGroup>
                    </FormControl>
                  )}
                </div>

                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  <div className="formField">
                    <span className="pfloatlabel">
                      <label className="sepLable">
                        Coupon Code Name <span className="requiredstar">*</span>
                      </label>

                      {visibleEditCoupon ? (
                        editType == "voucher" ? (
                          <>
                            <InputText
                              autoComplete="off"
                              disabled={disabled}
                              onChange={(e) => {
                                setCouponCodeName(e.target.value.trimStart());
                              }}
                              value={editId}
                            />
                          </>
                        ) : (
                          <>
                            <InputText
                              autoComplete="off"
                              onChange={(e) => {
                                let value = e.target.value.trimStart();
                                value = value.toUpperCase();
                                setCouponCodeNameCoupon(value);
                                setCouponCodeNameErr("");
                              }}
                              value={couponCodeNameCoupon}
                              placeholder="Enter Coupon Code Name"
                              style={{
                                borderColor: couponCodeNameErr ? "#ff6b68" : "",
                              }}
                            />
                            {couponCodeNameErr && (
                              <div className="p-invalid">
                                {couponCodeNameErr}
                              </div>
                            )}
                          </>
                        )
                      ) : couponSelectName === "voucher" ? (
                        <InputText
                          autoComplete="off"
                          disabled={disabled}
                          onChange={(e) => {
                            setCouponCodeName(e.target.value.trimStart());
                          }}
                          value={couponCodeName}
                        />
                      ) : (
                        <>
                          <InputText
                            autoComplete="off"
                            onChange={(e) => {
                              let value = e.target.value.trimStart();
                              value = value.toUpperCase();
                              setCouponCodeNameCoupon(value);
                              setCouponCodeNameErr("");
                            }}
                            value={couponCodeNameCoupon}
                            placeholder="Enter Coupon Code Name"
                            style={{
                              borderColor: couponCodeNameErr ? "#ff6b68" : "",
                            }}
                          />
                          {couponCodeNameErr && (
                            <div className="p-invalid">{couponCodeNameErr}</div>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                </div>
                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  <div className="formField">
                    <span className="pfloatlabel">
                      <label className="sepLable">
                        Discount Type <span className="requiredstar">*</span>
                      </label>
                      <Dropdown
                        value={selectDiscountType1}
                        onChange={(e) => {
                          if (e.value.code == "percentage") {
                            setDiscountValue("");
                            setEnterAmount("");
                            setDiscountTypeErr("");
                            setEnterAmountErr("");
                          } else
                            setDiscountValue(enterAmount ? enterAmount : "");
                          setSelectDiscountType1(e.value);
                          setDiscountTypeErr("");
                        }}
                        options={DiscountCoupn1}
                        optionLabel="name"
                        style={{
                          borderColor: discountTypeErr ? "#ff6b68" : "",
                        }}
                        placeholder="Discount Type"
                        className="filterDropdown"
                      />
                      {discountTypeErr && (
                        <div className="p-invalid">{discountTypeErr}</div>
                      )}
                    </span>
                  </div>
                </div>
                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  <div className="formField fieldLeftCaption">
                    <span className="pfloatlabel" style={{ width: "100%" }}>
                      <label className="sepLable">
                        Discount Value <span className="requiredstar">*</span>
                      </label>
                      <div className="flex align-items-center capstioninSd">
                        <div className="currencycapstiondd">
                          {/* <Dropdown
                            value={selectedCurrency}
                            onChange={(e) => setSelectedCurrency(e.value)}
                            options={Currencies}
                            optionLabel="name"
                            placeholder="AED"
                          /> */}
                          {location == "dubai"
                            ? selectDiscountType1?.code == "percentage"
                              ? "%"
                              : "AED"
                            : selectDiscountType1?.code == "percentage"
                            ? "%"
                            : "USD"}
                        </div>
                        <InputText
                          autoComplete="off"
                          value={discountValue}
                          onChange={(e) => {
                            let num = +e.target.value.trimStart();
                            if (isNaN(num)) return;
                            setDiscountValueErr("");
                            if (
                              selectDiscountType1?.code == "percentage" &&
                              num > 100
                            )
                              return;
                            if (selectDiscountType1?.code != "percentage") {
                              setEnterAmount(e.target.value.trimStart());
                            }
                            setDiscountValue(e.target.value.trimStart());
                          }}
                          placeholder="Enter Discount Value"
                          style={{
                            borderColor: discountValueErr ? "#ff6b68" : "",
                          }}
                        />
                        {discountValueErr && (
                          <div className="p-invalid">{discountValueErr}</div>
                        )}
                      </div>
                    </span>
                  </div>
                </div>
                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  <div className="formField fieldLeftCaption">
                    <span className="pfloatlabel" style={{ width: "100%" }}>
                      <label className="sepLable">
                        Maximum Discount Amount{" "}
                        <span className="requiredstar">*</span>
                      </label>
                      <div className="flex align-items-center capstioninSd">
                        <div className="currencycapstiondd">
                          {location == "dubai" ? "AED" : "USD"}
                        </div>
                        <InputText
                          autoComplete="off"
                          value={enterAmount}
                          onChange={(e) => {
                            let num = +e.target.value.trimStart();
                            if (isNaN(num)) return;
                            setEnterAmountErr("");

                            if (selectDiscountType1?.code != "percentage") {
                              setDiscountValue(e.target.value.trimStart());
                            }

                            setEnterAmount(e.target.value.trimStart());
                          }}
                          style={{
                            borderColor: enterAmountErr ? "#ff6b68" : "",
                          }}
                          placeholder="Enter Amount"
                        />
                        {enterAmountErr && (
                          <div className="p-invalid">{enterAmountErr}</div>
                        )}
                      </div>
                    </span>
                  </div>
                </div>
                <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                  <div className="formField">
                    <span className="pfloatlabel">
                      <label className="sepLable">
                        Start Date <span className="requiredstar">*</span>
                      </label>
                      <Calendar
                        dateFormat="dd/mm/yy"
                        value={startdate1}
                        className={startDateErr ? "new-error" : null}
                        maxDate={enddate1}
                        minDate={new Date()}
                        onChange={(e) => {
                          setStartDate1(e.value);
                          setStartDateErr("");
                        }}
                        showIcon
                        disabled={false}
                        readOnlyInput={false}
                        placeholder="Start Date"
                      />
                      {startDateErr && (
                        <div className="p-invalid">{startDateErr}</div>
                      )}
                    </span>
                  </div>
                </div>
                <div className="lg:col-6 md:col-6 sm:col-12 col-12">
                  <div className="formField">
                    <span className="pfloatlabel">
                      <label className="sepLable">
                        End Date <span className="requiredstar">*</span>
                      </label>
                      <Calendar
                        dateFormat="dd/mm/yy"
                        value={enddate1}
                        minDate={startdate1}
                        className={endDateErr ? "new-error" : null}
                        onChange={(e) => {
                          setEndDate1(e.value);
                          setEndDateErr("");
                        }}
                        showIcon
                        placeholder="End Date"
                      />
                      {endDateErr && (
                        <div className="p-invalid">{endDateErr}</div>
                      )}
                    </span>
                  </div>
                </div>

                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  <div className="formField">
                    <span className="pfloatlabel">
                      <label className="sepLable">
                        Usage limits for guest{" "}
                        <span className="requiredstar">*</span>
                      </label>

                      <InputNumber
                        inputId="stacked-buttons"
                        className={usageLimitErr ? "new-error" : null}
                        value={editType ? usage : usageLimit}
                        useGrouping={false}
                        disabled={
                          editType === "voucher"
                            ? true
                            : couponSelectName == "voucher"
                            ? true
                            : false
                        } //if voucher then disable
                        onChange={(e) => {
                          setUsageLimit(e.value);
                          setUsage(e.value);
                          setUsageLimitErr("");
                          if (e.value < 0) {
                            setUsageLimit();
                            setUsage();
                            return;
                          }
                        }}
                        style={{
                          borderColor: usageLimitErr ? "#ff6b68" : "",
                        }}
                        showButtons
                        min={0}
                      />
                      {usageLimitErr && (
                        <div className="p-invalid">{usageLimitErr}</div>
                      )}
                    </span>
                  </div>
                </div>

                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  <div className="formField">
                    <span className="pfloatlabel">
                      <label className="sepLable">
                        No of coupon to be used{" "}
                        <span className="requiredstar">*</span>
                      </label>
                      <InputNumber
                        inputId="stacked-buttons"
                        className={numberOfCouponsUsedErr ? "new-error" : null}
                        value={editType ? usageNum : numberOfCouponsUsed}
                        useGrouping={false}
                        // disabled={couponSelectName === "voucher" || editType}
                        onChange={(e) => {
                          setNumberOfCouponsUsed(e.value);
                          setUsageNum(e.value);
                          setNumberOfCouponsUsedErr("");
                          if (e.value < 0) {
                            setNumberOfCouponsUsed();
                            setUsageNum();
                            return;
                          }
                        }}
                        style={{
                          borderColor: numberOfCouponsUsedErr ? "#ff6b68" : "",
                        }}
                        showButtons
                        min={0}
                      />
                      {numberOfCouponsUsedErr && (
                        <div className="p-invalid">
                          {numberOfCouponsUsedErr}
                        </div>
                      )}
                    </span>
                  </div>
                </div>

                {visibleEditCoupon && (
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <span className="pfloatlabel onlyReadForm">
                        <label className="sepLable">No of Coupon Used</label>
                        <InputText
                          disabled
                          value={coupon_details?.used_count}
                        />
                      </span>
                    </div>
                  </div>
                )}

                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  <div className="card flex justify-content-start">
                    <Checkbox
                      onChange={(e) => setChecked1(e.checked)}
                      style={{
                        borderColor: numberOfCouponsUsedErr ? "#ff6b68" : "",
                      }}
                      checked={checked1}
                    ></Checkbox>
                    {numberOfCouponsUsedErr && (
                      <div className="p-invalid">{numberOfCouponsUsedErr}</div>
                    )}
                    <p className="p2 ml-2">
                      Check if this coupon is for Public
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebarFooter">
          <div className="btnsRow">
            <Button className="btn btn-gray" onClick={() => resetAll()}>
              Cancel
            </Button>
            <Button
              // disabled={loading_list}
              className="btn btn-blue"
              onClick={() => {
                if (visibleEditCoupon) handleUpdate();
                else handleSave();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Sidebar>

      {/* delete confirmation */}
      <Dialog
        className="dialogWidth400 headetPadding"
        header="Delete"
        visible={visibleDeleteDialog}
        style={{ width: "50vw" }}
        onHide={() => setDeleteDialog(false)}
      >
        <div className="addformDialog">
          <p className="p2 mb-4 mt-0" style={{ textAlign: "left" }}>
            Are you sure want to delete this?
          </p>
          <div className="p2 dilogbtns">
            <Button
              className="btn btn-gray"
              onClick={() => {
                setDeleteDialog(false);
              }}
            >
              No
            </Button>
            <Button
              onClick={() => {
                handleDelete();
              }}
              className="btn btn-blue"
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
