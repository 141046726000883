/** @format */
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import React from "react";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
// images
import sortIcon from "../../assets/images/structure/sortIcon.png";
import { AuthRequests } from "../auth/AuthRequests";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";

const NewsletterList = () => {
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectList, setSelectList] = useState({ name: "10", code: "10" });
  const Lists = [
    { name: "10", code: "10" },
    { name: "15", code: "15" },
    { name: "50", code: "50" },
    { name: "100", code: "100" },
  ];

  //states
  const [contactUsList, setContactUsList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("id");
  const [orderBy, setOrderBy] = useState("DESC");

  //get contact us list
  const getContactUsList = () => {
    dispatch(changeLoader(true));
    let object = {
      limit: limit,
      page: page,
      search: search,
      sortBy: sortBy,
      orderBy: orderBy,
    };
    const objString = "?" + new URLSearchParams(object).toString();
    globalRequestAxios("get", {}, AuthRequests.GET_CONTACT_US_LIST + objString)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setContactUsList(res?.data?.data || []);
          setTotal(res?.data?.count);
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  };
  useEffect(() => {
    getContactUsList();
  }, [limit, page, sortBy, orderBy, search]);

  //sort function
  const sortFunction = (sortType) => {
    setSortBy(sortType);
    if (orderBy == "ASC") {
      setOrderBy("DESC");
    } else {
      setOrderBy("ASC");
    }
  };

  ///pagination function
  const onPageChange = (event) => {
    setPage(event.page + 1);
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <>
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="tablefilterheader pb-2">
            <div className="tableHeader">
              <h4 className="h4 mb-0">Contact List</h4>
            </div>
          </div>
          <div className="tablefilterSubheader">
            <div className="tableHeader">
              <div className="tableFilterRow" style={{ marginLeft: "0" }}>
                <div
                  className="tableFilterCol sortList"
                  style={{ marginLeft: "0" }}
                >
                  <Dropdown
                    value={selectList}
                    onChange={(e) => {
                      setSelectList(e.value);
                      setLimit(e.value.code);
                      setPage(1);
                      setFirst(0);
                    }}
                    options={Lists}
                    optionLabel="name"
                    placeholder="10"
                    className="filterDropdown"
                  />
                </div>
              </div>
              <div className="tableFilterRow">
                <div className="tableFilterCol">
                  <div className="p-inputgroup searchFilter" style={{width:'330px'}}>
                    <InputText
                      placeholder="Search by name, email & mobile number"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value.trimStart());
                        setPage(1);
                        setFirst(0);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid adminTableContent">
            <div className="tableContent">
              <table>
                <thead>
                  <tr>
                    <th>
                      <p className="tbleheading">
                        Full name
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("full_name");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Email Address
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("email");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        MOBILE NUMBER
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("phone");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Message
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("message");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {contactUsList.length > 0 &&
                    contactUsList?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.full_name}</td>
                          <td>{item?.email}</td>
                          <td>
                            <span className="roleSpan roleBlue">
                              <i className="pi pi-phone"></i>
                            </span>{" "}
                            {item?.phone && item?.phone > 5
                              ? "+" + item?.phone
                              : "N/A"}
                          </td>
                          <td className="messageTd">{item?.message}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {total == 0 && !loader && (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: "20px",
                }}
              >
                <h4 className="h4 mb-0">{"No contact request found"}</h4>
              </div>
            )}

            {total > limit && (
              <Paginator
                first={first}
                rows={rows}
                totalRecords={total}
                className="mt-2"
                onPageChange={onPageChange}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsletterList;
