import { useState, useEffect, useCallback } from "react";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import "primeicons/primeicons.css";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import ProductComponent from "./ProductComponent";
// images
import { Editor } from "primereact/editor";
import addDeleteGetLocalStorage from "../../globalModules/addDeleteGetLocalStorage ";
import { Dialog } from "primereact/dialog";
import { imageBaseUrl } from "../../Helpers/HelperFunctions";
import noImage from "../../assets/images/products/noImage.webp";
import { ShowImage } from "../../popups/ShowImage/ShowImage";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { reduce_image_file_size } from "../../Helpers/UploadIMGHelper";
import { InputSwitch } from "primereact/inputswitch";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests, LOCAL_STORAGE_KEYS } from "../auth/AuthRequests";

const BundletAdd = () => {
  const location = addDeleteGetLocalStorage(
    LOCAL_STORAGE_KEYS?.DB_LOCATION,
    {},
    "get"
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    location == "dubai"
      ? { name: "AED", code: "AED" }
      : { name: "USD", code: "USD" }
  );
  const Currencies = [
    { name: "AED", code: "AED" },
    { name: "USD", code: "USD" },
  ];

  //states
  const [name, setName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [costPrice, setCostPrice] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [existingImages, setExistingImages] = useState([]);
  const [showImgUrl, setShowImgUrl] = useState("");
  const [publishSatus, setPublishSatus] = useState("active");
  //errors
  const [nameErr, setNameErr] = useState("");
  const [shortDescriptionErr, setShortDescriptionErr] = useState("");
  const [longDescriptionErr, setLongDescriptionErr] = useState("");
  const [costPriceErr, setCostPriceErr] = useState("");
  const [sellingPriceErr, setSellingPriceErr] = useState("");
  const [discountedPriceErr, setDiscountedPriceErr] = useState("");
  const [imgError, setImgError] = useState("");

  ///states for image cropper
  const [select_img, setselect_img] = useState(null);
  const cropperRef = useRef(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState("");

  ///manage image cropper
  const handleUploadImage = async (file) => {
    const original = file; //already base64
    //compressed image file
    let compressed = await reduce_image_file_size(original);
    //find the size of the original and compressed file
    const binaryDataCompressed = atob(compressed.split(",")[1]);
    const fileSizeMBCompressed = (
      binaryDataCompressed?.length /
      (1024 * 1024)
    ).toFixed(2);
    if (fileSizeMBCompressed <= 5) {
      //set base64 image
      let singleImgObj = {
        id: 0,
        imageUrl: compressed,
        isFeature: 0,
      };
      await setExistingImages([...existingImages, singleImgObj]);
      setselect_img(null);
    } else {
      dispatch(
        setSnackbar({
          isOpen: true,
          message:
            "Invalid file, please upload a JPEG or PNG image within the size limit of 5MB.",
          state: "error",
        })
      );
    }
  };
  // delete image
  function handleDeleteImage(index) {
    const updatedExistingImages = [...existingImages]; // make a copy of the array
    updatedExistingImages.splice(index, 1); // remove the image at the specified index
    setExistingImages(updatedExistingImages); // update the state
  }

  //check one image is featured prsent or not in the array if not then set first image as featured
  useEffect(() => {
    let is_ = false;
    existingImages.map((item, index) => {
      if (item.isFeature == 1) {
        is_ = true;
      }
    });
    if (!is_) {
      if (existingImages.length > 0) {
        let arr = [...existingImages];
        arr[0].isFeature = 1;
        setExistingImages(arr);
      }
    }
  }, [existingImages]);

  //handle crop images
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImageUrl(cropper.getCroppedCanvas().toDataURL());
    // setCroppedImageUrl(
    //   cropper.getCroppedCanvas({ width: 500, height: 500 }).toDataURL()
    // );
  };

  //handel error function
  const handelError = () => {
    let error = false;
    if (name.length === 0) {
      setNameErr("Bundle name is required");
      error = true;
    }
    if (name?.length > 100) {
      setNameErr("Bundle name should be less than 100 characters");
      error = true;
    }
    if (shortDescription.length === 0) {
      setShortDescriptionErr("Short description is required");
      error = true;
    }
    if (longDescription.length === 0) {
      setLongDescriptionErr("Long description is required");
      error = true;
    }
    if (existingImages.length === 0) {
      setImgError("Image is required");
      error = true;
    }
    // if ((+costPrice) <= 0) {
    //   setCostPriceErr("Cost price is required");
    //   error = true;
    // }
    if (+sellingPrice <= 0) {
      setSellingPriceErr("Selling price is required");
      error = true;
    }
    if (+sellingPrice < +costPrice) {
      setSellingPriceErr("Selling price should be greater than cost price");
      error = true;
    }
    if (+discountedPrice <= 0) {
      setDiscountedPriceErr("Discounted price is required");
      error = true;
    }
    if (selectedProducts.length > 0) {
      selectedProducts.map((item, index) => {
        let arr = [...selectedProducts];
        if (!item?.category?.id) {
          arr[index].errors.category = "Category is required";
          error = true;
        }
        if (!item?.subCategory?.id) {
          arr[index].errors.subCategory = "Subcategory is required";
          error = true;
        }
        if (!item?.product?.id) {
          arr[index].errors.product = "Product is required";
          error = true;
        }
        if (item?.product?.stock == null && item?.product?.id) {
          arr[index].errors.quantity = "Product is out of stock";
          error = true;
        }
        if (item?.product?.stock?.stock < item.quantity) {
          arr[
            index
          ].errors.quantity = `Quantity is greater than stock (${item?.product?.stock?.stock})`;
          error = true;
        }
        if (item?.quantity <= 0) {
          arr[index].errors.quantity = "Min. 1 required";
          error = true;
        }
        setSelectedProducts(arr);
      });
    }

    return error;
  };

  // onsubmit function
  const onSaveForm = useCallback(() => {
    let error = handelError();
    let products = [];
    selectedProducts.map((item, index) => {
      if (item.product?.id) {
        products.push({
          productId: item.product?.id,
          quantity: item.quantity,
        });
      }
    });
    let images = [];
    existingImages.map((item, index) => {
      images.push({
        isFeature: item.isFeature,
        imageUrl: item.imageUrl,
      });
    });

    let data = {
      name: name,
      shortDescription: shortDescription,
      longDescription: longDescription,
      costPrice: costPrice,
      sellingPrice: sellingPrice,
      discountedPrice: discountedPrice,
      productsId: JSON.stringify(products), //[{"id":1,"quantity":1}]
      images: JSON.stringify(images), //[{"isFeature":0,"imageUrl":"base64url"}]
      status: publishSatus,
    };
    if (error) return;
    dispatch(changeLoader(true));
    globalRequestAxios("post", data, AuthRequests.ADD_BUNDLE_PRODUCT)
      .then((res) => {
        if (res?.ack == 1) {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "Bundle created successfully",
              state: "success",
            })
          );
          navigate("/bundle-listing");
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "Something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "Something went wrong",
            state: "error",
          })
        );
        console.error("err", err);
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  });

  ///new component
  const [selectedProducts, setSelectedProducts] = useState([
    {
      category: {},
      subCategory: {},
      product: {},
      quantity: 0,
      errors: {},
    },
  ]);

  //get category list
  const getAllCategories = () => {
    globalRequestAxios("get", {}, AuthRequests.GET_CATEGORIES_TO_SELECT)
      .then((res) => {
        if (res?.ack == 1) {
          setCategoryList(res?.data);
        } else {
          setCategoryList([]);
        }
      })
      .catch((err) => {
        setCategoryList([]);
        console.error("err", err);
      });
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const addMoreRows = () => {
    let obj = {
      category: {},
      subCategory: {},
      product: {},
      quantity: 0,
      errors: {},
    };
    setSelectedProducts([...selectedProducts, obj]);
  };
  const removeRow = (index) => {
    let arr = [...selectedProducts];
    arr.splice(index, 1);
    setSelectedProducts(arr);
  };

  //set cost price with respect to the selected products by multiplying the quantity of each product with its cost price
  useEffect(() => {
    let costPrice = 0;
    selectedProducts.map((item, index) => {
      if (item.product?.id) {
        costPrice += item.product?.cost_price * item.quantity;
      }
    });
    setCostPrice(costPrice);
  }, [selectedProducts]);

  return (
    <>
      {/* <LeftSidebar /> */}
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="formHeader">
            <h4 className="h4 mb-2">Create Bundle</h4>
          </div>
          <div className="contentBox-w">
            <div className="grid">
              <div className="lg:col-10 md:col-10 sm:col-12 col-12">
                <div className="grid">
                  <div className="lg:col-12 md:col- sm:col-12 col-12">
                    <p className="p1 mb-2">Bundle Info</p>
                  </div>
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          id="BundleName"
                          autoComplete="off"
                          placeholder="Enter name of bundle product"
                          value={name}
                          onChange={(e) => {
                            let value = e.target?.value?.trimStart();
                            setName(value);
                            setNameErr("");
                            if (value?.length > 100) {
                              setNameErr(
                                "Bundle name should be less than 100 characters"
                              );
                            }
                          }}
                        />
                        <label htmlFor="BundleName">
                          Bundle Name<span style={{ color: "red" }}>*</span>{" "}
                        </label>
                      </span>
                      {nameErr && <div className="p-error">{nameErr}</div>}
                    </div>
                  </div>
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <div className="lg:col-12 md:col- sm:col-12 col-12">
                        <p className="p1 mb-2 mt-2">
                          Short Description
                          <span style={{ color: "red" }}>*</span> (max 1000)
                        </p>
                      </div>
                      <InputTextarea
                        autoResize
                        id="Description"
                        rows={2}
                        cols={30}
                        value={shortDescription}
                        onChange={(e) => {
                          setShortDescription(e.target.value);
                          setShortDescriptionErr("");
                          if (e.target.value.length > 1000) {
                            setShortDescriptionErr(
                              "Short description should be less than 1000 characters"
                            );
                          }
                        }}
                        style={{
                          borderColor: shortDescriptionErr ? "#f55e71" : "",
                        }}
                      />
                      {shortDescriptionErr && (
                        <div className="p-error">{shortDescriptionErr}</div>
                      )}
                    </div>
                  </div>
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField product-editor">
                      <div className="lg:col-12 md:col- sm:col-12 col-12">
                        <p className="p1 mb-2 mt-2">
                          Long Description
                          <span style={{ color: "red" }}>*</span> (max 1000)
                        </p>
                      </div>
                      <Editor
                        value={longDescription}
                        className={longDescriptionErr ? "errer-border" : null}
                        onTextChange={(e) => {
                          if (e.htmlValue == null) {
                            setLongDescription("");
                            return;
                          }
                          setLongDescription(e.htmlValue);
                          setLongDescriptionErr("");
                          if (
                            e.htmlValue
                              .replace(/<[^>]+>/g, "") //remove html tag
                              .replace(/\s{2,}/g, " ").length > 1000 //remove extra space
                          ) {
                            setLongDescriptionErr(
                              "Long description should be less than 1000 characters"
                            );
                          }
                        }}
                        style={{ height: "150px" }}
                      />
                      {longDescriptionErr && (
                        <div className="p-error">{longDescriptionErr}</div>
                      )}
                    </div>
                  </div>
                  <div
                    className="lg:col-1 md:col-1 sm:col-12 col-12"
                    style={{ minWidth: "16%", maxWidth: "16%" }}
                  >
                    <div className="formField">
                      <span className="pfloatlabel">
                        <Dropdown
                          disabled
                          id="currency"
                          value={selectedCurrency}
                          onChange={(e) => setSelectedCurrency(e.value)}
                          options={Currencies}
                          optionLabel="name"
                          placeholder="AED"
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    className="lg:col-4 md:col-4 sm:col-12 col-12"
                    style={{ minWidth: "28%", maxWidth: "28%" }}
                  >
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          disabled
                          id="Costprice"
                          className={costPriceErr ? "p-outline-error" : null}
                          autoComplete="off"
                          placeholder="0.00"
                          value={costPrice}
                          onChange={(e) => {
                            setCostPrice(e.target.value);
                            setCostPriceErr("");
                            if (e.target.value < 0) {
                              setCostPriceErr("Price should be greater than 0");
                            }
                          }}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          useGrouping={false}
                        />
                        <label htmlFor="Costprice">Cost price</label>
                      </span>
                      {costPriceErr && (
                        <div className="p-error">{costPriceErr}</div>
                      )}
                    </div>
                  </div>
                  <div
                    className="lg:col-4 md:col-4 sm:col-12 col-12"
                    style={{ minWidth: "28%", maxWidth: "28%" }}
                  >
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          inputId="minmaxfraction"
                          className={sellingPriceErr ? "p-outline-error" : null}
                          value={sellingPrice}
                          onChange={(e) => {
                            let value = e.value;
                            setSellingPrice(value);
                            setSellingPriceErr("");
                            if (value < 0) {
                              setSellingPriceErr(
                                "Price should be greater than 0"
                              );
                            }
                          }}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          useGrouping={false}
                          placeholder="0.00"
                        />
                        <label htmlFor="Sellingprice">
                          Bundle Selling price
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      </span>
                      {sellingPriceErr && (
                        <div className="p-error">{sellingPriceErr}</div>
                      )}
                    </div>
                  </div>
                  <div
                    className="lg:col-3 md:col-3 sm:col-12 col-12"
                    style={{ minWidth: "28%", maxWidth: "28%" }}
                  >
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          inputId="minmaxfraction"
                          className={
                            discountedPriceErr ? "p-outline-error" : null
                          }
                          value={discountedPrice}
                          onChange={(e) => {
                            let value = e.value;
                            setDiscountedPrice(value);
                            setDiscountedPriceErr("");
                            if (value < 0) {
                              setDiscountedPriceErr(
                                "Price should be greater than 0"
                              );
                            }
                          }}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          useGrouping={false}
                          placeholder="0.00"
                        />
                        <label htmlFor="Discountedprice">
                          Discounted price
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      </span>
                      {discountedPriceErr && (
                        <div className="p-error">{discountedPriceErr}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid mt-2">
                  <div className="lg:col-12 md:col- sm:col-12 col-12">
                    <p className="p1 mb-2">
                      Select products<span style={{ color: "red" }}>*</span>
                    </p>
                  </div>

                  {selectedProducts
                    ? selectedProducts.map((row, index) => (
                        <ProductComponent
                          allData={selectedProducts}
                          index={index}
                          key={`row-${index}`}
                          addMoreRows={addMoreRows}
                          removeRow={() => removeRow(index)}
                          rowData={row}
                          Categories={categoryList}
                          updateOldValue={(value) => {
                            let arr = [...selectedProducts];
                            arr[index].category = value.category;
                            arr[index].subCategory = value.subCategory;
                            arr[index].product = value.product;
                            arr[index].quantity = value.quantity;
                            arr[index].errors = value.errors;
                            console.log("selectedProducts", arr);
                            setSelectedProducts(arr);
                          }}
                        />
                      ))
                    : null}

                  {/* show images */}
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <div className="uploadImageRow">
                        {existingImages.length < 5 && (
                          <div className="uploadImageCol uploadImageBox">
                            <input
                              accept="image/jpeg, image/png"
                              type="file"
                              id="clearFile"
                              onChange={(e) => {
                                setImgError("");
                                if (
                                  e.target.files[0].size > 5 * 1024 * 1024 ||
                                  (e.target.files[0].type !== "image/jpeg" &&
                                    e.target.files[0].type !== "image/png")
                                ) {
                                  dispatch(
                                    setSnackbar({
                                      isOpen: true,
                                      message:
                                        "Invalid file, please upload a JPEG or PNG image within the size limit of 5MB.",
                                      state: "error",
                                    })
                                  );
                                  return;
                                }
                                setselect_img(
                                  URL.createObjectURL(e.target.files[0])
                                );
                                // handleUploadImage(e);
                                let file = document.getElementById("clearFile");
                                file.value = "";
                              }}
                            />
                            <div>
                              <i className="pi pi-upload uploadIcon"></i>
                              <p className="p2 mb-0 mt-2">
                                Upload Image Size should be 130 : 130 px, Only
                                JPEG or PNG should be uploaded
                              </p>
                            </div>
                          </div>
                        )}

                        {existingImages.map((image, index) => (
                          <div
                            className="uploadImageCol uploadImagePreview"
                            key={index}
                          >
                            <Tooltip target=".custom-target-icon" />
                            <i
                              className="pi pi-times deleteICon"
                              onClick={() => handleDeleteImage(index)}
                            ></i>
                            <i
                              className={`custom-target-icon pi pi-star${
                                image.isFeature == 1 ? "-fill" : ""
                              } featureIcon p-text-secondary p-overlay-badge`}
                              data-pr-tooltip="Marked as featured"
                              data-pr-position="top"
                              data-pr-at="left+15 top-30"
                              data-pr-my="center"
                              onClick={() => {
                                //remove previous selected featured image
                                let arr = [...existingImages];
                                arr.map((item, index1) => {
                                  if (index1 !== index) {
                                    arr[index1].isFeature = 0;
                                  }
                                });
                                //set current image as featured
                                arr[index].isFeature = 1;
                                setExistingImages(arr);
                              }}
                            ></i>
                            {image.id !== 0 ? (
                              <img
                                onClick={() => {
                                  setShowImgUrl(image?.imageUrl);
                                }}
                                src={image?.imageUrl}
                                alt=""
                                className="productImage"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = noImage;
                                }}
                              />
                            ) : (
                              <img
                                onClick={() => {
                                  setShowImgUrl(image.imageUrl);
                                }}
                                src={image.imageUrl}
                                alt=""
                                className="productImage"
                              />
                            )}
                          </div>
                        ))}

                        {/* <div className="uploadImageCol uploadImagePreview">
                        <Tooltip target=".custom-target-icon" />
                        <i className="pi pi-times deleteICon"></i>
                        <i className="custom-target-icon pi pi-star-fill featureIcon p-text-secondary p-overlay-badge" data-pr-tooltip="Marked as featured" data-pr-position="top" data-pr-at="left+15 top-30" data-pr-my="center" ></i>
                        <img src={productImageOne} alt='' className="productImage" />
                      </div>
                      <div className="uploadImageCol uploadImagePreview">
                      <Tooltip target=".custom-target-icon" />
                        <i className="pi pi-times deleteICon"></i>
                        <i className="custom-target-icon pi pi-star featureIcon p-text-secondary p-overlay-badge" data-pr-tooltip="Marked as featured" data-pr-position="top" data-pr-at="left+15 top-30" data-pr-my="center" ></i>
                        <img src={productImageTwo} alt='' className="productImage" />
                      </div> */}
                      </div>
                      {imgError && <div className="p-error">{imgError}</div>}
                    </div>
                  </div>
                  <div
                    className="lg:col-12 md:col-12 sm:col-12 col-12 mt-3"
                    style={{ width: "100%", textAlign: "left" }}
                  >
                    <div className="lg:col-12 md:col- sm:col-12 col-12">
                      <p className="p1 mb-2 mt-2"> Publish / Unpublish :</p>
                    </div>
                    <InputSwitch
                      checked={publishSatus == "active" ? true : false}
                      onChange={(e) =>
                        setPublishSatus(
                          publishSatus == "active" ? "inactive" : "active"
                        )
                      }
                    />
                  </div>
                  <div
                    className="lg:col-12 md:col-12 sm:col-12 col-12 mt-3"
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      className="btn btn-gray mr-2"
                      onClick={() => {
                        navigate("/bundle-listing");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button className="btn btn-blue" onClick={onSaveForm}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* modal for crop images */}
      <Dialog
        className="headetPadding"
        header="Crop Image"
        visible={select_img ? true : false}
        style={{ maxWidth: "600px" }}
        onHide={() => {
          setselect_img(null);
        }}
      >
        <div className="addformDialog">
          {select_img && (
            <Cropper
              src={select_img}
              aspectRatio={1}
              // guides={false}
              crop={onCrop}
              ref={cropperRef}
              zoomable={false}
            />
          )}
          <div
            className="p2 dilogbtns"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Button
              className="btn btn-gray"
              onClick={() => {
                setselect_img(null);
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-yellow"
              onClick={() => {
                handleUploadImage(croppedImageUrl);
              }}
            >
              Crop
            </Button>
          </div>
        </div>
      </Dialog>

      {/* show img big */}
      {showImgUrl && (
        <ShowImage
          showImgUrl={showImgUrl}
          setShowImgUrl={setShowImgUrl}
          view={true}
        />
      )}
    </>
  );
};

export default BundletAdd;
