/** @format */
import { useState, useEffect } from "react";
import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import "primeicons/primeicons.css";
import "cropperjs/dist/cropper.css";
import { AuthRequests } from "../auth/AuthRequests";
import globalRequestAxios from "../../globalModules/globalRequestAxios";

// Simple debounce function
let timeout1 = null;
const debounce = (func, delay) => {
  return function (...args) {
    clearTimeout(timeout1);
    timeout1 = setTimeout(() => func.apply(this, args), delay);
  };
};

const ProductComponent = ({
  allData,
  index,
  addMoreRows,
  removeRow,
  rowData,
  Categories,
  updateOldValue,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [valueQnt, setValueQnt] = useState(0);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [hasValueChanged, setHasValueChange] = useState(false);

  ///=======================================
  const debouncedUpdateValue = debounce(() => {
    const data = {
      category: selectedCategory,
      subCategory: selectedSubCategory,
      product: selectedProduct,
      quantity: valueQnt ? valueQnt : 0,
      errors: {},
    };
    updateOldValue(data);
    setHasValueChange(false);
  }, 200);
  ///=======================================

  const getSubCategories = (value) => {
    setSelectedSubCategory(null);
    setSelectedProduct(null);
    setValueQnt(0);
    setSelectedCategory(value);
    globalRequestAxios(
      "get",
      {},
      AuthRequests.GET_SUB_CATEGORIES_BY_CATEGORY_ID + value?.id
    )
      .then((res) => {
        if (res?.ack == 1) {
          setSubCategoryList(res?.data);
        } else {
          setSubCategoryList([]);
        }
      })
      .catch((err) => {
        setSubCategoryList([]);
      });
  };

  const getProductList = (value) => {
    setSelectedProduct(null);
    setValueQnt(0);
    setSelectedSubCategory(value);
    globalRequestAxios(
      "get",
      {},
      AuthRequests.GET_PRODUCTS_BY_SUBCATEGORY_ID + value?.id
    )
      .then((res) => {
        if (res?.ack == 1) {
          setProductList(res?.data);
        } else {
          setProductList([]);
        }
      })
      .catch((err) => {
        setProductList([]);
      });
  };

  // to call update value function on change of any value
  useEffect(() => {
    setHasValueChange(true);
  }, [selectedCategory, selectedSubCategory, selectedProduct, valueQnt]);

  useEffect(() => {
    if (hasValueChanged) {
      // Use the debounced version of the function here
      debouncedUpdateValue();
    }
  }, [hasValueChanged, selectedCategory, debouncedUpdateValue]);

  useEffect(() => {
    if (rowData) {
      if (rowData?.category?.id) {
        getSubCategories(rowData?.category);
      } else {
        setSelectedCategory(rowData.category);
      }
      if (rowData?.subCategory?.id) {
        getProductList(rowData?.subCategory);
      } else {
        setSelectedSubCategory(rowData.subCategory);
      }
      setSelectedProduct(rowData.product);
      setValueQnt(rowData.quantity);
    }
  }, [rowData]);

  const selectedProducs = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.title}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const ProductTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.title}</div>
      </div>
    );
  };

  return (
    <>
      <div className="lg:col-3 md:col-3 sm:col-12 col-12">
        <div className="formField">
          <span className="p-float-label">
            <Dropdown
              id="MainCategory"
              value={selectedCategory}
              options={Categories}
              optionLabel="name_en"
              placeholder="Status"
              onChange={(e) => {
                getSubCategories(e.value);
              }}
            />
            <label htmlFor="MainCategory">Main Category</label>
          </span>
          {allData[index]?.errors?.category && (
            <div className="p-error">{allData[index]?.errors?.category}</div>
          )}
        </div>
      </div>

      <div className="lg:col-3 md:col-3 sm:col-12 col-12">
        <div className="formField">
          <span className="p-float-label">
            <Dropdown
              id="SubCategory"
              value={selectedSubCategory}
              options={subCategoryList}
              onChange={(e) => {
                getProductList(e.value);
              }}
              optionLabel="name_en"
              placeholder="Status"
            />
            <label htmlFor="SubCategory">Sub Category</label>
          </span>
          {allData[index]?.errors?.subCategory && (
            <div className="p-error">{allData[index]?.errors?.subCategory}</div>
          )}
        </div>
      </div>
      <div className="lg:col-3 md:col-3 sm:col-12 col-12">
        <div className="formField">
          <span className="p-float-label">
            <Dropdown
              value={selectedProduct}
              options={productList}
              optionLabel="title"
              placeholder="Select Product"
              onChange={(e) => {
                setSelectedProduct(e.value);
              }}
              filter
              valueTemplate={selectedProducs}
              itemTemplate={ProductTemplate}
            />
            <label htmlFor="SelectProduct">Select Product</label>
          </span>
          {allData[index]?.errors?.product && (
            <div className="p-error">{allData[index]?.errors?.product}</div>
          )}
        </div>
      </div>

      <div className="lg:col-2 md:col-2 sm:col-12 col-12 addquantityCol">
        <div className="formField">
          <span className="p-float-label">
            <InputNumber
              id="Quantity"
              inputId="stacked-buttons"
              value={valueQnt == 0 ? null : valueQnt}
              showButtons
              min={0}
              onChange={(e) => {
                console.log(e.value);
                setValueQnt(e.value);
              }}
            />
            <label htmlFor="Quantity">Quantity</label>
          </span>
          {allData[index]?.errors?.quantity && (
            <div className="p-error">{allData[index]?.errors?.quantity}</div>
          )}
        </div>
      </div>
      <div className="p-2">
        {index == 0 ? (
          <Button onClick={addMoreRows} className="btn addmorCircleBtn">
            <i className="pi pi-plus uploadIcon"></i>
          </Button>
        ) : (
          <Button onClick={removeRow} className="btn addmorCircleBtn">
            <i className="pi pi-minus uploadIcon"></i>
          </Button>
        )}
      </div>
    </>
  );
};

export default ProductComponent;
