import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./LeftSidebar.scss";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import addDeleteGetLocalStorage from "../../../globalModules/addDeleteGetLocalStorage ";
import { decodedToken } from "../../../globalModules/tokenDecode";
import { LOCAL_STORAGE_KEYS } from "../../auth/AuthRequests";

export const LeftSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen_cms, setIsOpen_cms] = useState(false);
  const [isOpen_Cancellation, setIsOpen_Cancellation] = useState(false);
  const [isOpen_Sytem, setIsOpen_Sytem] = useState(false);
  const [toggleClass, setToggleClass] = useState("");
  const [toggleClass_cms, setToggleClass_cms] = useState("");
  const [toggleClass_Cancellation, setToggleClass_Cancellation] = useState("");
  const [toggleClass_Sytem, setToggleClass_Sytem] = useState("");
  const [visibleConfirmation, setVisibleConfirmation] = useState(false);
  const [path, setPath] = useState("#");

  const navigate = useNavigate();
  const handleClick = (drop) => {
    if (drop === "cms") {
      setIsOpen_cms(!isOpen_cms);
      setToggleClass_cms(toggleClass_cms === "" ? "open" : "");
    }
    if (drop === "order") {
      setIsOpen(!isOpen);
      setToggleClass(toggleClass === "" ? "open" : "");
    }
    if (drop === "cancellation") {
      setIsOpen_Cancellation(!isOpen_Cancellation);
      setToggleClass_Cancellation(
        toggleClass_Cancellation === "" ? "open" : ""
      );
    }
    if (drop === "system") {
      setIsOpen_Sytem(!isOpen_Sytem);
      setToggleClass_Sytem(toggleClass_Sytem === "" ? "open" : "");
    }
  };

  const currentUrl = window.location.href.split("/");
  //leave form page handler
  const leavePageHandler = (path) => {
    const active_link = `${currentUrl[currentUrl.length - 2]}`;
    const active_link1 = `${currentUrl[currentUrl.length - 1]}`;
    if (
      active_link === "product-edit" ||
      active_link === "bundle-edit" ||
      active_link1 === "bundle-add"
    ) {
      setVisibleConfirmation(true);
      setPath(path);
    } else {
      navigate(path);
    }
  };

  //for higlight active link
  const active_link = `${currentUrl[currentUrl.length - 1]}`;
  const active_link1 = `${currentUrl[currentUrl.length - 2]}`;
  const active_link2 = `${currentUrl[currentUrl.length - 3]}`;

  //sub-admin-permissions
  const isPermited = (permissionName) => {
    let userToken = addDeleteGetLocalStorage(
      LOCAL_STORAGE_KEYS?.USER_TOKEN,
      {},
      "get"
    );
    const decoded = decodedToken(userToken);
    if (!decoded) return false;
    if (decoded?.access === "admin") return true;
    else if (decoded?.access === "subadmin") {
      const permissions = decoded?.rolepermissions;
      const isPermited = permissions?.includes(permissionName) ? true : false;
      return isPermited;
    }
    return false;
  };

  return (
    <>
      <div className="leftSidebar" style={{ overflow: "scroll" }}>
        {/* <Menubar model={items} /> */}
        <div className="leftSidebar__menu">
          <ul>
            {isPermited("dashboard") && (
              <li>
                <div
                  className={
                    active_link == "dashboard"
                      ? "sidebar-a activeLink"
                      : "sidebar-a"
                  }
                  onClick={() => {
                    leavePageHandler("/dashboard");
                  }}
                >
                  <i className="pi pi-table"></i>
                  <span>Dashboard</span>
                </div>
              </li>
            )}
            {isPermited("customers") && (
              <li>
                <div
                  className={
                    active_link == "customer-listing"
                      ? "sidebar-a activeLink"
                      : "sidebar-a"
                  }
                  onClick={() => {
                    leavePageHandler("/customer-listing");
                  }}
                >
                  <i className="pi pi-users"></i>
                  <span>Customers</span>
                </div>
              </li>
            )}
            {isPermited("category") && (
              <li>
                <div
                  className={
                    active_link == "category-listing"
                      ? "sidebar-a activeLink"
                      : "sidebar-a"
                  }
                  onClick={() => {
                    leavePageHandler("/category-listing");
                  }}
                >
                  <i className="pi pi-sitemap"></i>
                  <span>Category</span>
                </div>
              </li>
            )}
            {isPermited("product") && (
              <li>
                <div
                  className={
                    active_link == "product-list"
                      ? "sidebar-a activeLink"
                      : "sidebar-a"
                  }
                  onClick={() => {
                    leavePageHandler("/product-list");
                  }}
                >
                  <i className="pi pi-shopping-bag"></i>
                  <span>Product</span>
                </div>
              </li>
            )}
            {isPermited("order") && (
              <li>
                <div
                  onClick={() => {
                    leavePageHandler("/order-history-listing");
                  }}
                  className={
                    active_link == "order-history-listing"
                      ? "sidebar-a activeLink"
                      : "sidebar-a"
                  }
                >
                  <i className="pi pi-book"></i>
                  <span>Order</span>
                </div>
              </li>
            )}
            {isPermited("coupons") && (
              <li>
                <div
                  className={
                    active_link == "coupon-codes-list"
                      ? "sidebar-a activeLink"
                      : "sidebar-a"
                  }
                  onClick={() => {
                    leavePageHandler("/coupon-codes-list");
                  }}
                >
                  <i className="pi pi-credit-card"></i>
                  <span>Coupons and Vouchers</span>
                </div>
              </li>
            )}
            {isPermited("bundle") && (
              <li>
                <div
                  className={
                    active_link == "bundle-listing"
                      ? "sidebar-a activeLink"
                      : "sidebar-a"
                  }
                  onClick={() => {
                    leavePageHandler("/bundle-listing");
                  }}
                >
                  <i className="pi pi-database"></i>
                  <span>Bundle</span>
                </div>
              </li>
            )}
            {isPermited("sub_admin") && (
              <li>
                {/**sub-admin-listing */}
                <div
                  className={
                    active_link == "roles-listing"
                      ? "sidebar-a activeLink"
                      : "sidebar-a"
                  }
                  onClick={() => {
                    leavePageHandler("/roles-listing");
                  }}
                >
                  <i className="pi pi-user"></i>
                  <span>Sub Admin</span>
                </div>
              </li>
            )}
            {isPermited("cms") && (
              <li>
                <div
                  className={"sidebar-a" + " " + toggleClass_cms}
                  onClick={() => {
                    handleClick("cms");
                  }}
                >
                  <i className="pi pi-server"></i>
                  <span>CMS</span>
                  <i
                    className={
                      isOpen_cms
                        ? "ml-auto pi pi-angle-up"
                        : "ml-auto pi pi-angle-down"
                    }
                  ></i>
                </div>
                {isOpen_cms && (
                  <div className="child-menu">
                    <div
                      className={
                        active_link == "shipping-policy"
                          ? "sidebar-a activeLink"
                          : "sidebar-a"
                      }
                      onClick={() => {
                        leavePageHandler("/shipping-policy");
                      }}
                    >
                      <span>Shipping Policy</span>
                    </div>
                    <div
                      className={
                        active_link == "terms-conditions"
                          ? "sidebar-a activeLink"
                          : "sidebar-a"
                      }
                      onClick={() => {
                        leavePageHandler("/terms-conditions");
                      }}
                    >
                      <span>Terms & Conditions</span>
                    </div>
                    <div
                      className={
                        active_link == "privacy-policy"
                          ? "sidebar-a activeLink"
                          : "sidebar-a"
                      }
                      onClick={() => {
                        leavePageHandler("/privacy-policy");
                      }}
                    >
                      <span>Privacy Policy</span>
                    </div>
                    <div
                      className={
                        active_link == "cookie-policy"
                          ? "sidebar-a activeLink"
                          : "sidebar-a"
                      }
                      onClick={() => {
                        leavePageHandler("/cookie-policy");
                      }}
                    >
                      <span>Cookie Policy</span>
                    </div>
                    <div
                      className={
                        active_link == "return-policy"
                          ? "sidebar-a activeLink"
                          : "sidebar-a"
                      }
                      onClick={() => {
                        leavePageHandler("/return-policy");
                      }}
                    >
                      <span>Return Policy</span>
                    </div>
                    <div
                      className={
                        active_link == "delivery-policy"
                          ? "sidebar-a activeLink"
                          : "sidebar-a"
                      }
                      onClick={() => {
                        leavePageHandler("/delivery-policy");
                      }}
                    >
                      <span>Delivery Policy</span>
                    </div>
                    <div
                      className={
                        active_link == "warranty-policy"
                          ? "sidebar-a activeLink"
                          : "sidebar-a"
                      }
                      onClick={() => {
                        leavePageHandler("/warranty-policy");
                      }}
                    >
                      <span>Warranty Policy</span>
                    </div>
                    <div
                      className={
                        active_link == "social-media-links"
                          ? "sidebar-a activeLink"
                          : "sidebar-a"
                      }
                      onClick={() => {
                        leavePageHandler("/social-media-links");
                      }}
                    >
                      <span>Social Media Links</span>
                    </div>
                    <div
                      className={
                        active_link == "about-us"
                          ? "sidebar-a activeLink"
                          : "sidebar-a"
                      }
                      onClick={() => {
                        leavePageHandler("/about-us");
                      }}
                    >
                      <span>About Us</span>
                    </div>
                    {/* <div className={(active_link=="contact-us") ? "sidebar-a activeLink":"sidebar-a"}
                onClick={()=>{leavePageHandler("/contact-us")}}
                >                  
                <span>Contact Us</span>
                </div> */}
                  </div>
                )}
              </li>
            )}
            {isPermited("currency_tax") && (
              <li>
                <div
                  className={
                    active_link == "currency-conversion"
                      ? "sidebar-a activeLink"
                      : "sidebar-a"
                  }
                  onClick={() => {
                    leavePageHandler("/currency-conversion");
                  }}
                >
                  <i className="pi pi-dollar"></i>
                  <span>Currency & Tax</span>
                </div>
              </li>
            )}
            {isPermited("newsletter_subscription") && (
              <li>
                <div
                  className={
                    active_link == "newsletter-list"
                      ? "sidebar-a activeLink"
                      : "sidebar-a"
                  }
                  onClick={() => {
                    leavePageHandler("/newsletter-list");
                  }}
                >
                  <i className="pi pi-envelope"></i>
                  <span>Newsletter subscription</span>
                </div>
              </li>
            )}
            {isPermited("notify_me") && (
              <li>
                <div
                  className={
                    active_link == "notify-me"
                      ? "sidebar-a activeLink"
                      : "sidebar-a"
                  }
                  onClick={() => {
                    leavePageHandler("/notify-me");
                  }}
                >
                  <i className="pi pi-bell"></i>
                  <span>Notify Me</span>
                </div>
              </li>
            )}
            {isPermited("contact_us_list") && (
              <li>
                <div
                  className={
                    active_link == "contact-us"
                      ? "sidebar-a activeLink"
                      : "sidebar-a"
                  }
                  onClick={() => {
                    leavePageHandler("/contact-us");
                  }}
                >
                  <i className="pi pi-comments"></i>
                  <span>Contact-Us List </span>
                </div>
              </li>
            )}
            {isPermited("system_settings") && (
              <li>
                <div
                  className={"sidebar-a" + " " + toggleClass_Sytem}
                  onClick={() => {
                    handleClick("system");
                  }}
                >
                  <i className="pi pi-book"></i>
                  <span>System Settings</span>
                  <i
                    className={
                      isOpen_Sytem
                        ? "ml-auto pi pi-angle-up"
                        : "ml-auto pi pi-angle-down"
                    }
                  ></i>
                </div>
                {isOpen_Sytem && (
                  <div className="child-menu">
                    <div
                      className={
                        active_link == "pickup-address"
                          ? "sidebar-a activeLink"
                          : "sidebar-a"
                      }
                      onClick={() => {
                        leavePageHandler("/pickup-address");
                      }}
                    >
                      <span>Pickup Address</span>
                    </div>
                    <div
                      className={
                        active_link == "contact-information"
                          ? "sidebar-a activeLink"
                          : "sidebar-a"
                      }
                      onClick={() => {
                        leavePageHandler("/contact-information");
                      }}
                    >
                      <span>Contact Information</span>
                    </div>
                  </div>
                )}
              </li>
            )}
          </ul>
        </div>
      </div>

      {/* confirmation to leave from page */}
      <Dialog
        className="dialogWidth400 headetPadding"
        // header="Delete"
        visible={visibleConfirmation}
        style={{ width: "50vw" }}
        onHide={() => setVisibleConfirmation(false)}
      >
        <div className="addformDialog">
          <p className="p2 mb-4 mt-0" style={{ textAlign: "left" }}>
            Are you sure you want to leave this page?
          </p>
          <div className="p2 dilogbtns">
            <Button
              className="btn btn-gray"
              onClick={() => setVisibleConfirmation(false)}
            >
              No
            </Button>
            <Button
              className="btn btn-yellow"
              onClick={() => {
                navigate(path);
                setVisibleConfirmation(false);
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>

      <Outlet />
    </>
  );
};
