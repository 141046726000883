import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.scss";
import "./App.scss";
import "./assets/scss/direction.scss";
import { useEffect } from "react";
import { Header } from "./pages/layout/header/Header";
import { Login } from "./pages/auth/Login/Login";
import { CustomerListing } from "./pages/CustomersManagement/CustomerListing";
import { ProductList } from "./pages/ProductManagement/ProductList";
import { CategoryListing } from "./pages/Categories/CategoryListing";
import { CouponCodesList } from "./pages/CouponCodes/CouponCodesList";
import OrderHistoryListing from "./pages/OrderHistory/OrderHistoryListing";
import OrderDetailNew from "./pages/OrderHistory/OrderDetailNew";
import BundleListing from "./pages/Bundle/BundleListing";
import BundletAdd from "./pages/Bundle/BundletAdd";
import RolesListing from "./pages/subadmin/RolesListing";
import Dashboard from "./pages/Dashboard/Dashboard";
import { ProductEdit } from "./pages/ProductManagement/ProductEdit";
import ShippingPolicy from "./pages/cms/ShippingPolicy";
import TermsConditions from "./pages/cms/TermsConditions";
import PrivacyPolicy from "./pages/cms/PrivacyPolicy";
import CookiePolicy from "./pages/cms/CookiePolicy";
import ReturnPolicy from "./pages/cms/ReturnPolicy";
import DeliveryPolicy from "./pages/cms/DeliveryPolicy";
import WarrantyPolicy from "./pages/cms/WarrantyPolicy";
import SocialMediaLinks from "./pages/cms/SocialMediaLinks";
import AboutUs from "./pages/cms/AboutUs";
import ContactUs from "./pages/cms/ContactUs";
import NewsletterList from "./pages/Newsletter/NewsletterList";
import { LeftSidebar } from "./pages/layout/LeftSidebar/LeftSidebar";
import { AdminPrivateRaoutes } from "./RoutHandler/SuperAdminRoutes";
import { useState } from "react";
import { Message } from "primereact/message";
import CurrencyCoversion from "./pages/Currency/CurrencyCoversion";
import addDeleteGetLocalStorage from "./globalModules/addDeleteGetLocalStorage ";
import { SubadminSetPass } from "./pages/auth/SubadminSetPass/SubadminSetPass";
import { setSnackbar, snackObj } from "./redux/reducers/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { currentLoader } from "./redux/reducers/loader";
import Loaders from "./Loaders";
import BundleEdit from "./pages/Bundle/BundleEdit";
import ShareList from "./pages/CouponCodes/ShareList";
import PickupAddress from "./pages/SystemSettings/PickupAddress";
import ContactInformation from "./pages/SystemSettings/ContactInformation";
import { decodedToken } from "./globalModules/tokenDecode";
import { NotFound404 } from "./404";
import { LOCAL_STORAGE_KEYS } from "./pages/auth/AuthRequests";
import NotifyMe from "./pages/NotifyMe/NotifyMe";

const App = () => {
  const dispatch = useDispatch();
  //for alert
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState("");
  const snackbarObj = useSelector(snackObj);
  const loader = useSelector(currentLoader);
  const setAlert = (text, type) => {
    setAlertText(text);
    setAlertType(type);
    setTimeout(() => {
      setAlertText("");
      setAlertType("");
    }, 2500);
  };

  //for session timeout
  const checkSessionTime = () => {
    const sessionTime = +addDeleteGetLocalStorage(
      LOCAL_STORAGE_KEYS?.SESSION_TIME,
      {},
      "get"
    );
    if (sessionTime) {
      const currentTime = new Date().getTime();
      if (currentTime - sessionTime > 15 * 60 * 1000) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  };

  useEffect(() => {
    checkSessionTime(); //check session time on page load
    setInterval(() => {
      checkSessionTime(); //check session time on every 1 sec
    }, 1000);
  }, []);

  // for snackbar from redux
  useEffect(() => {
    if (snackbarObj?.isOpen) {
      setAlert(snackbarObj?.message, snackbarObj?.state);
      setTimeout(() => {
        dispatch(
          setSnackbar({
            ...snackbarObj,
            isOpen: false,
          })
        );
      }, 2000);
    }
  }, [snackbarObj?.isOpen]);

  //sub-admin-permissions check
  const isPermited = (permissionName) => {
    let userToken = addDeleteGetLocalStorage(
      LOCAL_STORAGE_KEYS?.USER_TOKEN,
      {},
      "get"
    );
    const decoded = decodedToken(userToken);
    if (!decoded) return false;
    if (decoded?.access === "admin") return true;
    else if (decoded?.access === "subadmin") {
      const permissions = decoded?.rolepermissions;
      const isPermited = permissions?.includes(permissionName) ? true : false;
      return isPermited;
    }
    return false;
  };

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login setAlert={setAlert} />} />
          <Route
            exact
            path="/set-password/:token"
            element={<SubadminSetPass setAlert={setAlert} />}
          />

          <Route path="/" element={<AdminPrivateRaoutes />}>
            <Route path="/" element={<Header setAlert={setAlert} />}>
              <Route path="/" element={<LeftSidebar />}>
                {/* <Route path="/" element={<Dashboard />} /> */}
                {isPermited("dashboard") && (
                  <Route path="/dashboard" element={<Dashboard />} />
                )}
                {isPermited("customers") && (
                  <Route
                    path="/customer-listing"
                    element={<CustomerListing setAlert={setAlert} />}
                  />
                )}
                {isPermited("category") && (
                  <Route
                    path="/category-listing"
                    element={<CategoryListing setAlert={setAlert} />}
                  />
                )}
                {isPermited("product") && (
                  <>
                    <Route
                      path="/product-list"
                      element={<ProductList setAlert={setAlert} />}
                    />
                    <Route
                      path="/product-edit/:id"
                      element={<ProductEdit setAlert={setAlert} />}
                    />
                  </>
                )}
                {isPermited("order") && (
                  <>
                    <Route
                      path="/order-history-listing"
                      element={<OrderHistoryListing />}
                    />
                    <Route
                      path="/order-edit/:id"
                      element={<OrderDetailNew setAlert={setAlert} />}
                    />
                  </>
                )}
                {isPermited("coupons") && (
                  <>
                    <Route
                      path="/coupon-codes-list"
                      element={<CouponCodesList setAlert={setAlert} />}
                    />
                    <Route
                      path="/share-list/:id"
                      element={<ShareList setAlert={setAlert} />}
                    />
                  </>
                )}
                {isPermited("bundle") && (
                  <>
                    <Route
                      path="/bundle-listing"
                      element={<BundleListing setAlert={setAlert} />}
                    />
                    <Route
                      path="/bundle-add"
                      element={<BundletAdd setAlert={setAlert} />}
                    />
                    <Route
                      path="/bundle-edit/:id"
                      element={<BundleEdit setAlert={setAlert} />}
                    />
                  </>
                )}
                {isPermited("sub_admin") && (
                  <Route
                    path="/roles-listing"
                    element={<RolesListing setAlert={setAlert} />}
                  />
                )}
                {/* cms */}
                {isPermited("cms") && (
                  <>
                    <Route
                      path="/shipping-policy"
                      element={<ShippingPolicy />}
                    />
                    <Route
                      path="/terms-conditions"
                      element={<TermsConditions />}
                    />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/cookie-policy" element={<CookiePolicy />} />
                    <Route path="/return-policy" element={<ReturnPolicy />} />
                    <Route
                      path="/delivery-policy"
                      element={<DeliveryPolicy />}
                    />
                    <Route
                      path="/warranty-policy"
                      element={<WarrantyPolicy />}
                    />
                    <Route
                      path="/social-media-links"
                      element={<SocialMediaLinks />}
                    />
                    <Route path="/about-us" element={<AboutUs />} />
                  </>
                )}
                {isPermited("currency_tax") && (
                  <Route
                    path="/currency-conversion"
                    element={<CurrencyCoversion />}
                  />
                )}
                {isPermited("newsletter_subscription") && (
                  <Route path="/newsletter-list" element={<NewsletterList />} />
                )}
                {isPermited("notify_me") && (
                  <Route path="/notify-me" element={<NotifyMe />} />
                )}
                {isPermited("contact_us_list") && (
                  <Route path="/contact-us" element={<ContactUs />} />
                )}
                {isPermited("system_settings") && (
                  <>
                    <Route
                      path="/pickup-address"
                      element={<PickupAddress setAlert={setAlert} />}
                    />
                    <Route
                      path="/contact-information"
                      element={<ContactInformation />}
                    />
                  </>
                )}
              </Route>
            </Route>
          </Route>
          {/* 404 */}
          <Route path="*" element={<NotFound404 />} />
        </Routes>
      </Router>

      {/* alert & loader */}
      {alertText && alertType && (
        <Message severity={alertType} text={alertText} className="alartbox" />
      )}
      {loader ? <Loaders /> : null}
    </>
  );
};

export default App;
