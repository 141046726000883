/** @format */
import React, { useCallback, useState } from "react";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { AuthRequests } from "../auth/AuthRequests";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { useEffect } from "react";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { InputNumber } from "primereact/inputnumber";

const CurrencyCoversion = () => {
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);

  //states
  const [aedToUsd, setAedToUsd] = useState(0);
  const [usdToIqd, setUsdToIqd] = useState(0);
  const [gateway, setGateway] = useState("");
  const [conversionFee, setConversionFee] = useState(0);
  const [tax, setTax] = useState(0);

  //errors
  const [aedToUsdError, setAedToUsdError] = useState("");
  const [usdToIqdError, setUsdToIqdError] = useState("");
  const [gatewayError, setGatewayError] = useState("");
  const [conversionFeeError, setConversionFeeError] = useState("");
  const [taxError, setTaxError] = useState("");

  //get contact us list
  const getCorrencyDetails = useCallback(async () => {
    dispatch(changeLoader(true));
    await globalRequestAxios("get", {}, AuthRequests.GET_CURRENCY_DETAILS)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          let resArr = res?.data;
          resArr?.map((ele) => {
            if (ele?.type == "aed_to_usd") {
              setAedToUsd(ele?.value);
            }
            if (ele?.type == "usd_to_ikd") {
              setUsdToIqd(ele?.value);
            }
            if (ele?.type == "gateway") {
              setGateway(ele?.value);
            }
            if (ele?.type == "conversion_fee") {
              setConversionFee(ele?.value);
            }
            if (ele?.type == "tax") {
              setTax(ele?.value);
            }
          });
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  });

  useEffect(() => {
    getCorrencyDetails();
  }, []);

  //submit form
  const submitCurrencyConversion = useCallback(async () => {
    let error = false;
    if (!aedToUsd) {
      setAedToUsdError("Please enter USD value");
      error = true;
    }
    if (!usdToIqd) {
      setUsdToIqdError("Please enter IQD value");
      error = true;
    }
    if (!gateway) {
      setGatewayError("Please enter gateway");
      error = true;
    }
    if (!conversionFee) {
      setConversionFeeError("Please enter conversion fee");
      error = true;
    }
    if (!tax) {
      setTaxError("Please enter tax");
      error = true;
    }
    if (error) {
      return;
    }

    const data = {
      aed_to_usd: aedToUsd,
      usd_to_ikd: usdToIqd,
      Gateway: gateway,
      conversion_fee: conversionFee,
      tax: tax,
    };
    dispatch(changeLoader(true));
    await globalRequestAxios("put", data, AuthRequests.UPDATE_CURRENCY_DETAILS)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: res?.msg || "Currency conversion updated successfully.",
              state: "success",
            })
          );
          getCorrencyDetails();
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  });

  return (
    <>
      <section className="admin-content-wrapper currencyConversionpage">
        <div className="adminContent">
          <div className="formHeader">
            <h4 className="h4 mb-2">Currency Conversion</h4>
          </div>
          <div className="contentBox-w">
            <div className="grid">
              <div className="lg:col-10 md:col-10 sm:col-12 col-12">
                <div className="grid">
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12 pb-0">
                    <p className="p1 mb-0 mt-1">
                      Currency Conversion from AED to USD
                    </p>
                  </div>
                  <div className="lg:col-4 md:col-4 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          id="Instagram1"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={1.0}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          useGrouping={false}
                          disabled
                        />
                        <span className="ConversionName">AED</span>
                      </span>
                    </div>
                  </div>
                  <span className="block mt-4 ml-3 mr-3 to">To</span>
                  <div className="lg:col-4 md:col-4 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          id="Instagram2"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={aedToUsd}
                          onChange={(e) => {
                            setAedToUsdError("");
                            setAedToUsd(e.value);
                          }}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          useGrouping={false}
                        />
                        <span className="ConversionName">USD</span>
                        {aedToUsdError && (
                          <div className="p-error">{aedToUsdError}</div>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="grid mt-3">
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12 pb-0">
                    <p className="p1 mb-0 mt-0">
                      Currency Conversion from USD to IQD
                    </p>
                  </div>
                  <div className="lg:col-4 md:col-4 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          id="Instagram3"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={1.0}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          useGrouping={false}
                          disabled
                        />
                        <span className="ConversionName">USD </span>
                      </span>
                    </div>
                  </div>
                  <span className="block mt-4 ml-3 mr-3 to">To</span>
                  <div className="lg:col-4 md:col-4 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          id="Instagram4"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={usdToIqd}
                          onChange={(e) => {
                            setUsdToIqdError("");
                            setUsdToIqd(e.value);
                          }}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          useGrouping={false}
                        />
                        <span className="ConversionName">IQD</span>
                        {usdToIqdError && (
                          <div className="p-error">{usdToIqdError}</div>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="grid mt-3">
                  <div className="lg:col-4 md:col-4 sm:col-12 col-12">
                    <p className="p1 mb-3 mt-0">Gateway</p>
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          id="Instagram5"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={gateway}
                          onChange={(e) => {
                            setGatewayError("");
                            setGateway(e.target.value);
                          }}
                        />
                        <label htmlFor="Instagram">Name of Gateway</label>
                        {gatewayError && (
                          <div className="p-error">{gatewayError}</div>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="lg:col-4 md:col-4 sm:col-12 col-12">
                    <p className="p1 mb-3 mt-0">Conversion fee</p>
                    <div className="formField">
                      <span className="p-float-label">
                        <InputNumber
                          id="Instagram7"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={conversionFee}
                          onChange={(e) => {
                            setConversionFeeError("");
                            setConversionFee(e.value);
                          }}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          useGrouping={false}
                        />
                        <label htmlFor="Instagram">Conversion fee</label>
                        {conversionFeeError && (
                          <div className="p-error">{conversionFeeError}</div>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12 pb-0">
                    <p className="p1 mb-0 mt-1">Tax</p>
                  </div>
                  <div className="lg:col-9 md:col-9 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          id="Instagram8"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={tax}
                          onChange={(e) => {
                            let num = +e.target.value.trimStart();
                            setTaxError("");
                            if (isNaN(num)) return;
                            if (num > 100) return;
                            setTax(e.target.value.trimStart());
                          }}
                        />
                        <span className="ConversionName">%</span>
                        {taxError && <div className="p-error">{taxError}</div>}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="grid mt-2">
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12 mt-3">
                    <Button
                      className="btn btn-blue"
                      disabled={loader}
                      onClick={() => {
                        submitCurrencyConversion();
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CurrencyCoversion;
