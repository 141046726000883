// global request
import { API_KEY, url } from "../BaseUrl";
import axios from "axios";
import addDeleteGetLocalStorage from "./addDeleteGetLocalStorage ";
import { LOCAL_STORAGE_KEYS } from "../pages/auth/AuthRequests";

const globalRequestAxios = (req, userData, apiUrl) => {
  const sessionLogout = () => {
    let token = addDeleteGetLocalStorage(
      LOCAL_STORAGE_KEYS?.USER_TOKEN,
      {},
      "delete"
    );
    let location = addDeleteGetLocalStorage(
      LOCAL_STORAGE_KEYS?.DB_LOCATION,
      {},
      "delete"
    );
    if (token == undefined && location == undefined) {
      setTimeout(() => {
        // window.location.reload(false);
        localStorage.clear();
        window.location.href = "/";
      }, 500);
    }
  };

  const replaceToken = (token) => {
    addDeleteGetLocalStorage(
      LOCAL_STORAGE_KEYS?.USER_TOKEN,
      token,
      "add",
      "single"
    );
    window.location.reload();
  };

  const config = {
    headers: {
      "x-api-key": API_KEY,
      lang: "en", //should be dynamic
      location:
        addDeleteGetLocalStorage(LOCAL_STORAGE_KEYS?.DB_LOCATION, {}, "get") ==
        "dubai"
          ? "dubai"
          : "baghdad", //should be dynamic
      Authorization: addDeleteGetLocalStorage(
        LOCAL_STORAGE_KEYS?.USER_TOKEN,
        {},
        "get"
      )
        ? "Bearer " +
          addDeleteGetLocalStorage(LOCAL_STORAGE_KEYS?.USER_TOKEN, {}, "get")
        : "",
    },
  };

  return new Promise((resolve, reject) => {
    let time = new Date().getTime().toString();
    addDeleteGetLocalStorage(
      LOCAL_STORAGE_KEYS?.SESSION_TIME,
      time,
      "add",
      "single"
    );
    if (req == "get") {
      axios
        .get(`${url}/${apiUrl}`, config)
        .then((res) => {
          let response = res.data;
          if (response?.ack == 2) {
            replaceToken(response?.token);
            return;
          }
          if (res?.response?.status === 401) {
            sessionLogout();
          }
          if (res?.response?.status === 404) {
            response = { ack: 0, msg: "Page not found", data: {} };
          }
          resolve(response);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            sessionLogout();
          }
          let response = { ...err?.response?.data, data: {} };
          resolve(response);
        });
    } else if (req == "post") {
      axios
        .post(`${url}/${apiUrl}`, userData, config)
        .then((res) => {
          let response = res.data;
          if (response?.ack == 2) {
            replaceToken(response?.token);
            return;
          }
          if (res?.response?.status === 401) {
            sessionLogout();
          }
          if (res?.response?.status === 404) {
            response = { ack: 0, msg: "Page not found", data: {} };
          }
          resolve(response);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            sessionLogout();
          }

          let response = { ...err?.response?.data, data: {} };
          resolve(response);
        });
    } else if (req == "put") {
      axios
        .put(`${url}/${apiUrl}`, userData, config)
        .then((res) => {
          let response = res.data;
          if (response?.ack == 2) {
            replaceToken(response?.token);
            return;
          }
          if (res?.response?.status === 401) {
            sessionLogout();
          }
          if (res?.response?.status === 404) {
            response = { ack: 0, msg: "Page not found", data: {} };
          }
          resolve(response);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            sessionLogout();
          }

          let response = { ...err?.response?.data, data: {} };
          resolve(response);
        });
    } else if (req == "delete") {
      axios
        .delete(`${url}/${apiUrl}`, config)
        .then((res) => {
          let response = res.data;
          if (response?.ack == 2) {
            replaceToken(response?.token);
            return;
          }
          if (res?.response?.status === 401) {
            sessionLogout();
          }
          if (res?.response?.status === 404) {
            response = { ack: 0, msg: "Page not found", data: {} };
          }
          resolve(response);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            sessionLogout();
          }
          let response = { ...err?.response?.data, data: {} };
          resolve(response);
        });
    }
  });
};

export default globalRequestAxios;
