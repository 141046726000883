import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { AuthRequests } from "../AuthRequests";
import globalRequestAxios from "../../../globalModules/globalRequestAxios";
import { validatePassword } from "../../../Helpers/HelperFunctions";
import { changeLoader, currentLoader } from "../../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const SubadminSetPass = ({ setAlert }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const url_token = window.location.href.split("/").pop();
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [show_pass, setShowPass] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [show_confirm_pass, setShowConfirmPass] = useState(true);
  const [invalid_credentialErr, setInvalidCredentialErr] = useState("");
  const loader = useSelector(currentLoader);

  const handleErr = () => {
    let err = false;
    if (!validatePassword(password)) {
      setPasswordErr(
        "Password should be with uppercase, lowercase, number, symbol and least 8 characters"
      );
      err = true;
    }
    if (!confirmPassword) {
      setConfirmPasswordErr("Confirm Password is required");
      err = true;
    }
    if (!password) {
      setPasswordErr("Password is required");
      err = true;
    }
    if (confirmPassword) {
      if (password !== confirmPassword) {
        setConfirmPasswordErr("Password not match");
        err = true;
      }
      if (!password) {
        if (!validatePassword(confirmPassword) && !password) {
          setConfirmPasswordErr(
            "Password should be with uppercase, lowercase, number, symbol and least 8 characters"
          );
          err = true;
        }
      }
    }
    return err;
  };

  const resetState = () => {
    setPassword("");
    setPasswordErr("");
    setConfirmPassword("");
    setConfirmPasswordErr("");
    setInvalidCredentialErr("");
  };

  const handleSubmit = async () => {
    if (handleErr()) return;
    dispatch(changeLoader(true));
    let data = {
      password,
      confirm_password: confirmPassword,
    };
    await globalRequestAxios(
      "post",
      data,
      AuthRequests.SUB_ADMIN_SET_PASSWORD(url_token)
    )
      .then((res) => {
        if (res.ack == 1) {
          setAlert(res?.msg, "success");
          localStorage.clear();
          resetState();
          navigate("/");
        } else if (res.ack == 0) {
          setAlert(res?.msg, "error");
        }
      })
      .catch((err) => {
        setAlert("Something went wrong", "error");
        console.error("err", err);
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  };

  return (
    <>
      <div className="Login-page">
        <div className="LoginformContent setpassworpage">
          <div className="grid">
            <div
              className="lg:col-4 md:col-4 sm:col-10 col-12"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            >
              <div className="LoginForm">
                <h3 className="h3 mb-2" style={{ textAlign: "center" }}>
                  Set Password
                </h3>
                <p className="p2 mb-5" style={{ textAlign: "center" }}>
                  Please fill in all the required information
                </p>
                <div className="grid">
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          id="Password"
                          value={password}
                          style={{ borderColor: passwordErr ? "#f55e71" : "" }}
                          type={!show_pass ? "text" : "password"}
                          onChange={(e) => {
                            setPasswordErr("");
                            if (!e.target.value.trimStart().length)
                              setPasswordErr("Password is required");
                            else {
                              if (!validatePassword(e.target.value.trimStart()))
                                setPasswordErr(
                                  "Password should be with uppercase, lowercase, number, symbol and least 8 characters"
                                );
                              else setPasswordErr("");
                            }
                            if (confirmPassword) {
                              if (
                                e.target.value.trimStart() !== confirmPassword
                              )
                                setConfirmPasswordErr(
                                  "Password and Confirm Password must be same"
                                );
                              else setConfirmPasswordErr("");
                            }
                            setPassword(e.target.value.trimStart());
                          }}
                          autoComplete="off"
                        />
                        <label htmlFor="Password">Password</label>
                        <i
                          className={
                            !show_pass
                              ? "pi pi-eye passwordIcon"
                              : "pi pi-eye-slash passwordIcon"
                          }
                          onClick={() => {
                            setShowPass(!show_pass);
                          }}
                        ></i>
                        {passwordErr && (
                          <div
                            className="p-invalid"
                            style={{ position: "relative", bottom: "-3px" }}
                          >
                            {passwordErr}
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <span className="p-float-label">
                        <InputText
                          id="ConfirmPassword"
                          value={confirmPassword}
                          style={{
                            borderColor: confirmPasswordErr ? "#f55e71" : "",
                          }}
                          type={!show_confirm_pass ? "text" : "password"}
                          onChange={(e) => {
                            setConfirmPasswordErr("");
                            if (password) {
                              if (e.target.value.trimStart() !== password)
                                setConfirmPasswordErr(
                                  "Password and Confirm Password must be same"
                                );
                              else setConfirmPasswordErr("");
                            } else {
                              setPasswordErr("Password is required");
                              if (!validatePassword(e.target.value.trimStart()))
                                setConfirmPasswordErr(
                                  "Password should be with uppercase, lowercase, number, symbol and least 8 characters"
                                );
                              else setConfirmPasswordErr("");
                            }
                            if (!e.target.value.trimStart().length)
                              setConfirmPasswordErr(
                                "Confirm Password is required"
                              );

                            setConfirmPassword(e.target.value.trimStart());
                          }}
                          autoComplete="off"
                        />
                        <label htmlFor="ConfirmPassword">
                          Confirm Password
                        </label>
                        <i
                          className={
                            !show_confirm_pass
                              ? "pi pi-eye passwordIcon"
                              : "pi pi-eye-slash passwordIcon"
                          }
                          onClick={() => {
                            setShowConfirmPass(!show_confirm_pass);
                          }}
                        ></i>
                        {confirmPasswordErr && (
                          <div
                            className="p-invalid"
                            style={{ position: "relative", bottom: "-3px" }}
                          >
                            {confirmPasswordErr}
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                  {invalid_credentialErr && (
                    <div className="p-error ml-2">
                      {t(invalid_credentialErr)}
                    </div>
                  )}
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <Button
                      disabled={loader}
                      onClick={() => handleSubmit()}
                      className="btn btn-blue"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
