/** @format */
import { useState, useEffect, useCallback } from "react";
import { InputText } from "primereact/inputtext";
import React from "react";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import Checkbox from "@mui/material/Checkbox";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests } from "../auth/AuthRequests";
import { useParams } from "react-router-dom";
import sortIcon from "../../assets/images/structure/sortIcon.png";
import { changeLoader } from "../../redux/reducers/loader";
import { useDispatch } from "react-redux";

const ShareList = ({ setAlert }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total_list, setTotal_list] = useState(0);
  const [sortBy, setSortBy] = useState("email");
  const [search, setSearch] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [orderBy, setOrderBy] = useState("DESC");
  const [selectList, setSelectList] = useState({ name: "10", code: "10" });
  const [isSendToAll, setIsSendToAll] = useState(false);
  const [allUserMailId, setAllUserMailId] = useState([]);
  let [disabledBtn, setDisabledBtn] = useState(false);
  const sortFunction = (sortType) => {
    setSortBy(sortType);
    if (orderBy == "ASC") {
      setOrderBy("DESC");
    } else {
      setOrderBy("ASC");
    }
  };

  const Lists = [
    { name: "10", code: "10" },
    { name: "15", code: "15" },
    { name: "50", code: "50" },
    { name: "100", code: "100" },
  ];
  const getCustomerList = useCallback(async () => {
    let object = {
      limit: selectList.code,
      page: page,
      search: search,
      sortBy: sortBy,
      orderBy: orderBy,
    };
    const objString = "?" + new URLSearchParams(object).toString();
    dispatch(changeLoader(true));
    await globalRequestAxios(
      "get",
      {},
      AuthRequests.GET_ALL_CUSTOMERS + objString
    )
      .then((response) => {
        dispatch(changeLoader(false));
        if (response.ack == 1) {
          setCustomerList(response.data);

          if (response.data.length === 0) {
            setFirst(first - 10);
          }
          setTotal_list(response.count);
        } else {
          setCustomerList([]);
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        console.error(err);
      });
  });

  const ShareCustmorList = async () => {
    let emails = allUserMailId.map((item) => item).join(",");
    const data = {
      voucherId: id,
      email: emails,
      type: isSendToAll ? "all" : "one",
    };
    dispatch(changeLoader(true));
    await globalRequestAxios("post", data, AuthRequests.SHARE_EMAIL)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res?.ack == 1) {
          if (res.msg == "Voucher share successfully.") {
            setAlert("Voucher Share successfully", "success");
          }
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong.", "error");
      })
      .finally(() => {
        setTimeout(() => {
          window.location.reload();
        }, 800);
      });
  };

  useEffect(() => {
    setRows(selectList.code);
    getCustomerList();
  }, [page, search, sortBy, selectList, rows, orderBy]);

  const onPageChange = (event) => {
    setPage(event.page + 1);
    setFirst(event.first);
    setRows(event.rows);
  };

  ///new code for share coupons///
  useEffect(() => {
    if (isSendToAll) {
      setAllUserMailId([]);
    }
  }, [isSendToAll]);

  // function for select checkbox
  const selectUserToSendCoupon = (email) => {
    const isEmailSelected = allUserMailId.includes(email);

    if (isEmailSelected) {
      setAllUserMailId((prevList) =>
        prevList.filter((userEmail) => userEmail !== email)
      );
    } else {
      setAllUserMailId((prevList) => [...prevList, email]);
    }
  };

  //send button enable
  useEffect(() => {
    if (isSendToAll && allUserMailId?.length == 0) {
      setDisabledBtn(false);
    } else if (!isSendToAll && allUserMailId?.length > 0) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  }, [isSendToAll, allUserMailId]);

  return (
    <>
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="tablefilterheader pb-2">
            <div className="tableHeader">
              <h4 className="h4 mb-0">Share List</h4>
            </div>
          </div>
          <div className="tablefilterSubheader">
            <div className="tableHeader">
              <div className="tableFilterRow" style={{ marginLeft: "0" }}>
                <div
                  className="tableFilterCol sortList"
                  style={{ marginLeft: "0" }}
                >
                  <Dropdown
                    value={selectList}
                    onChange={(e) => {
                      setSelectList(e.value);
                      setLimit(e.value.code);
                      setRows(+e.value.code);
                      setPage(0);
                      setFirst(0);
                    }}
                    options={Lists}
                    optionLabel="name"
                    placeholder="10"
                    className="filterDropdown"
                  />
                </div>
              </div>
              <div className="tableFilterRow">
                <Button
                  className="btn btn-blue"
                  disabled={disabledBtn}
                  onClick={() => {
                    ShareCustmorList();
                  }}
                >
                  Send Email
                </Button>

                <div className="tableFilterCol">
                  <div className="p-inputgroup searchFilter">
                    <InputText
                      value={search}
                      onChange={(e) => setSearch(e.target.value.trimStart())}
                      placeholder="Search user..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid adminTableContent sharlisttable">
            <div className="tableContent userlisttable">
              <table>
                <thead>
                  <tr>
                    <th>
                      <Checkbox
                        checked={isSendToAll}
                        onChange={(e) => {
                          setIsSendToAll(!isSendToAll);
                        }}
                      />
                      <p className="tbleheading">
                        Email
                        {isSendToAll && ` (${total_list})`}
                        {allUserMailId?.length > 0 &&
                          ` (${allUserMailId?.length})`}
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("email")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th className="table-heading">First Name</th>
                    <th className="table-heading">Last Name</th>
                  </tr>
                </thead>
                <tbody>
                  {customerList.length > 0 ? (
                    customerList.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {!isSendToAll && (
                            <Checkbox
                              checked={allUserMailId?.includes(item?.email)}
                              onChange={(e) => {
                                selectUserToSendCoupon(item?.email);
                              }}
                            />
                          )}
                          {item?.email}
                        </td>
                        <td>{item?.first_name}</td>
                        <td>{item?.last_name}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center no-data">
                        No User Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {total_list > 0 && total_list > +selectList.code && (
              <Paginator
                first={first}
                rows={rows}
                onPageChange={onPageChange}
                totalRecords={total_list}
                className="mt-2"
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ShareList;
