import { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => {
    let defaultLat = 24.2016;
    let defaultLng = 53.802;
    let [mapDefault, setMapDefault] = useState({
      lat: defaultLat,
      lng: defaultLng,
    });
    let [mapCenter, setMapCenter] = useState({
      lat: defaultLat,
      lng: defaultLng,
    });
    const [markers, setMarkers] = useState([]); // [{ lat: 25.3548, lng: 51.1839 }
    const refMap = useRef(null);

    useEffect(() => {
      setMarkers(props.markers);
    }, [props.markers]);

    useLayoutEffect(() => {
      if (props.lat !== "" || props.lng !== "") {
        let latitude = props.lat !== "" ? props.lat : defaultLat;
        let longitude = props.lng !== "" ? props.lng : defaultLng;
        mapCenter = { lat: latitude, lng: longitude };
        setMapCenter(mapCenter);
      }
    }, [props]);

    return (
      <GoogleMap
        ref={refMap}
        defaultZoom={7}
        defaultCenter={mapDefault}
        center={mapCenter}
      >
        {/* <Marker position={{ lat: latitude, lng: longitude }} /> */}
        {markers?.length > 0 && (
          <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
            {markers.map((marker, index) => (
              <Marker
                key={index}
                position={{
                  lat: marker.position.lat,
                  lng: marker.position.lng,
                }}
              />
            ))}
          </MarkerClusterer>
        )}
      </GoogleMap>
    );
  })
);

export default MyMapComponent;
