/** @format */
import { useState, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import deleteIcon from "../../assets/images/structure/delete-32.png";

import editIcon1 from "../../assets/images/structure/edit.svg";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests, LOCAL_STORAGE_KEYS } from "../auth/AuthRequests";
import addDeleteGetLocalStorage from "../../globalModules/addDeleteGetLocalStorage ";
import noImage from "../../assets/images/products/noImage.webp";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";

import { image_to_base64 } from "../../Helpers/UploadIMGHelper";
import { decodedToken } from "../../globalModules/tokenDecode";

export const CategoryListing = ({ setAlert }) => {
  const dispatch = useDispatch();
  const [visibleuploadimage, setuploadimage] = useState(false);
  const [visibleSubCatuploadimage, setSubCatuploadimage] = useState(false);
  const [visibleDeleteDialog, setDeleteDialog] = useState(false);
  const [allCategory, setAllCategory] = useState([]);
  //get all category
  const getAllCategory = async () => {
    dispatch(changeLoader(true));
    let object = {
      sortBy: "id",
      orderBy: "DESC",
    };
    const objString = "?" + new URLSearchParams(object).toString();
    await globalRequestAxios(
      "get",
      {},
      AuthRequests.GET_ALL_CATEGORIES + objString
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) setAllCategory(res?.data);
        else setAllCategory([]);
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAllCategory([]);
        setAlert("Something went wrong", "error");
      });
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  ///DELETE SPECIFICATION
  const [select_id, setSelectId] = useState(null);
  const handleDelete = async () => {
    if (!isPermited()) return showNotHavePermissionAlert();
    if (!select_id) return false;
    const data = {
      specificationId: select_id,
    };
    dispatch(changeLoader(true));
    await globalRequestAxios("post", data, AuthRequests.DELETE_SPECIFICATION)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setDeleteDialog(false);
          getAllCategory();
          setAlert("Specification deleted successfully", "success");
        } else {
          setAlert("Something went wrong", "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  // Add specification
  const [specification_list, setSpecificationList] = useState([]);
  const [specification_listErr, setSpecificationListErr] = useState([]);
  const add_specification = async (id, index) => {
    let error = false;
    const data = {
      subCategoryId: id,
      name: specification_list[index],
    };
    if (!specification_list[index]) {
      let temp_err = specification_listErr;
      temp_err[index] = "Please enter specification";
      setSpecificationListErr([...temp_err]);
      error = true;
    }
    if (specification_list[index].length > 100) {
      let temp_err = specification_listErr;
      temp_err[index] = "Maximum 100 characters allowed";
      setSpecificationListErr([...temp_err]);
      error = true;
    }
    if (error) return false;
    dispatch(changeLoader(true));
    await globalRequestAxios("post", data, AuthRequests.ADD_SPECIFICATION)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          let temp = specification_list;
          temp[index] = "";
          setSpecificationList([...temp]);
          clearStates();
          getAllCategory();
          setAlert("Specification added successfully", "success");
        } else {
          if (res.msg == "Error: Data too long for column 'name' at row 1")
            setAlert("Specification field text is too long", "error");
          if (res.msg == "Specification already exist.")
            setAlert("Specialization already exist", "error");
          else setAlert("Something went wrong", "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  // Upload image
  const [upload_Image, setUpload_Image] = useState(null);
  const [uploadImg_id, setUploadImg_id] = useState(null);
  const [subCategoryImage_id, setSubCategoryImage_id] = useState(null);

  const [iconImg, setIconImg] = useState(null);
  const [thumbNailImage, setThumbNailImage] = useState(null);

  //show show existing banner and icon
  const [showBanner, setShowBanner] = useState(null);
  const [showIcon, setShowIcon] = useState(null);
  const [showThumbNailImg, setShowThumbNailImg] = useState(null);
  const [showSubCategoryImg, setShowSubCategoryImg] = useState(null);

  const handleUploadImage = async (id) => {
    if (!isPermited()) return showNotHavePermissionAlert();
    if (!upload_Image) return false;
    if (!id) return false;
    let uploadImgBase64 = upload_Image
      ? await image_to_base64(upload_Image)
      : null;
    let formData = {
      image_url: uploadImgBase64,
      subCategoryId: id,
    };
    let res = null;
    try {
      dispatch(changeLoader(true));
      res = await globalRequestAxios(
        "post",
        formData,
        AuthRequests.UPLOAD_IMAGE_SUB_CATEGORY
      );
      if (res.ack == 1) {
        dispatch(changeLoader(false));
        clearStates();
        getAllCategory();
        setAlert("Image uploaded successfully", "success");
      } else {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      }
    } catch (error) {
      console.log(error);
      dispatch(changeLoader(false));
      setAlert("Something went wrong", "error");
    }
  };

  //upload category img and icon in base64
  const uploadCategoryImgIcon = async (id) => {
    if (!isPermited()) return showNotHavePermissionAlert();
    // check existing and new image are present or not
    if (!upload_Image && !iconImg && !showBanner && !showIcon) {
      setAlert("Please select image", "error");
      return false;
    }
    let catImgBase64 = upload_Image
      ? await image_to_base64(upload_Image)
      : null;
    let iconImgBase64 = iconImg ? await image_to_base64(iconImg) : null;
    let thumbNailImageBase64 = thumbNailImage
      ? await image_to_base64(thumbNailImage)
      : null;
    let formData = {
      categoryId: id,
      iconImage: iconImgBase64,
      bannerImage: catImgBase64,
      thumbnailsImage: thumbNailImageBase64,
    };
    try {
      dispatch(changeLoader(true));
      let res = await globalRequestAxios(
        "post",
        formData,
        AuthRequests.UPLOAD_IMAGE
      );
      if (res.ack == 1) {
        dispatch(changeLoader(false));
        clearStates();
        getAllCategory();
        setAlert("Image uploaded successfully", "success");
      } else {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      }
    } catch (error) {
      console.log(error);
      dispatch(changeLoader(false));
      setAlert("Something went wrong", "error");
    }
  };

  //clear states
  const clearStates = () => {
    setuploadimage(false);
    setSubCatuploadimage(false);
    setUploadImg_id(null);
    setUpload_Image(null);
    setIconImg(null);
    setSubCategoryImage_id(null);
    setShowBanner(null);
    setShowIcon(null);
    setShowThumbNailImg(null);
    setThumbNailImage(null);
    setShowSubCategoryImg(null);
  };

  //sub-admin-permissions check
  const isPermited = () => {
    let userToken = addDeleteGetLocalStorage(
      LOCAL_STORAGE_KEYS?.USER_TOKEN,
      {},
      "get"
    );
    const decoded = decodedToken(userToken);
    if (!decoded) return false;
    if (decoded?.access === "admin") return true;
    return false;
  };

  //show not have permission alert
  const showNotHavePermissionAlert = () => {
    setAlert("You don't have permission to perform this action", "error");
  };

  return (
    <>
      <section className="admin-content-wrapper">
        <div className="adminContent" style={{ minHeight: "530px" }}>
          <div className="tablefilterheader pb-1">
            <h4 className="h4 mb-0">Manage Category</h4>
          </div>
          <div className="container-fluid adminTableContent">
            <div className="category-section">
              <Accordion activeIndex={0}>
                {allCategory?.map((item, index) => {
                  return (
                    <AccordionTab
                      className="mainCatHeader w-full-header"
                      key={index}
                      header={
                        <div
                          className="flex align-items-center"
                          onClick={() => {
                            let temp = [];
                            item?.sub_category?.map((ele) => {
                              temp.push("");
                            });
                            setSpecificationList([...temp]);
                            setSpecificationListErr([...temp]);
                          }}
                        >
                          <div className="catimagePreview featureIcon">
                            <div
                              className="uploadnewImage"
                              onClick={() => {
                                if (!isPermited())
                                  return showNotHavePermissionAlert();
                                setuploadimage(true);
                                setUploadImg_id(item?.id);
                                setShowBanner(item?.bannerImage);
                                setShowIcon(item?.iconImage);
                                setShowThumbNailImg(item?.thumbnailsImage);
                              }}
                            >
                              <img
                                src={editIcon1}
                                alt=""
                                className="uploadIcon"
                              />
                            </div>
                            <img
                              src={item?.iconImage}
                              alt=""
                              className="productImageTd"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = noImage;
                              }}
                            />
                          </div>
                          <span className="vertical-align-middle">
                            {item?.name_en}
                          </span>
                        </div>
                      }
                    >
                      <div className="subCategoryBox">
                        <>
                          {item?.sub_category.length > 0 &&
                            item?.sub_category?.map((item1, index) => {
                              if (item?.sub_category.length > 0) {
                                return (
                                  <Accordion key={index} activeIndex={null}>
                                    <AccordionTab
                                      header={
                                        <div className="subCatHeader flex align-items-center">
                                          <div className="subCatHeaderCl">
                                            <div className="catimagePreview featureIcon">
                                              <div
                                                className="uploadnewImage"
                                                onClick={() => {
                                                  if (!isPermited())
                                                    return showNotHavePermissionAlert();
                                                  setSubCatuploadimage(true);
                                                  setSubCategoryImage_id(
                                                    item1?.id
                                                  );
                                                  setShowSubCategoryImg(
                                                    item1?.image_url
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={editIcon1}
                                                  alt=""
                                                  className="uploadIcon"
                                                />
                                              </div>
                                              <img
                                                src={item1?.image_url}
                                                alt=""
                                                className="productImageTd"
                                                onError={(e) => {
                                                  e.target.onerror = null;
                                                  e.target.src = noImage;
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="subCatHeaderCl">
                                            <span className="vertical-align-middle">
                                              {item1?.name_en}
                                            </span>
                                          </div>
                                          <div className="subCatHeaderCl viewSpecCol ml-auto">
                                            <Button className="btn btn-blue viewspecification">
                                              View Specifications{" "}
                                              <i className="pi pi-chevron-right"></i>
                                            </Button>
                                          </div>
                                        </div>
                                      }
                                    >
                                      <div className="specificationsBox">
                                        <h4 className="p1 mb-3">
                                          {" "}
                                          Specifications
                                        </h4>
                                        {item1?.specification.length > 0 &&
                                          item1?.specification?.map(
                                            (item2, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="grid"
                                                >
                                                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                                                    <div className="specList">
                                                      <span className="p1 mb-2 flex align-items-center">
                                                        {item2?.name}
                                                        <div
                                                          className="specActionBtns"
                                                          style={{
                                                            marginLeft: "30px",
                                                          }}
                                                        >
                                                          <img
                                                            src={deleteIcon}
                                                            alt=""
                                                            className="AcIcon"
                                                            onClick={() => {
                                                              if (!isPermited())
                                                                return showNotHavePermissionAlert();
                                                              setSelectId(
                                                                item2?.id
                                                              );
                                                              setDeleteDialog(
                                                                true
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        {/* {item1?.specification.length==0 && <div className="grid">No specification found</div>} */}
                                        <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                                          <label className="sepLable mb-0">
                                            Name Of Specification Field
                                          </label>
                                        </div>
                                        <div className="lg:col-4 md:col-4 sm:col-12 col-12 pt-0">
                                          <div className="formField">
                                            <span className="pfloatlabel">
                                              <InputText
                                                style={{
                                                  borderColor:
                                                    specification_listErr[
                                                      index
                                                    ] !== ""
                                                      ? "#e76e6e"
                                                      : null,
                                                }}
                                                id="specification"
                                                autoComplete="off"
                                                placeholder="Enter Specification name"
                                                value={
                                                  specification_list[index]
                                                }
                                                onChange={(e) => {
                                                  let temp =
                                                    e.target.value.trimStart();
                                                  let arr = [
                                                    ...specification_list,
                                                  ];
                                                  arr[index] = temp;
                                                  setSpecificationList([
                                                    ...arr,
                                                  ]);
                                                  if (temp.length < 100) {
                                                    let temp1 = [
                                                      ...specification_listErr,
                                                    ];
                                                    temp1[index] = "";
                                                    setSpecificationListErr([
                                                      ...temp1,
                                                    ]);
                                                  }
                                                  if (temp.length > 100) {
                                                    let temp1 = [
                                                      ...specification_listErr,
                                                    ];
                                                    temp1[index] =
                                                      "Maximum 100 characters allowed";
                                                    setSpecificationListErr([
                                                      ...temp1,
                                                    ]);
                                                  }
                                                }}
                                              />
                                            </span>
                                            {specification_listErr[index] !==
                                              "" && (
                                              <small className="p-error">
                                                {specification_listErr[index]}
                                              </small>
                                            )}
                                          </div>
                                        </div>
                                        <div className="lg:col-4 md:col-4 sm:col-12 col-12 pt-0">
                                          <Button
                                            className="btn btn-blue"
                                            onClick={() =>
                                              add_specification(
                                                item1?.id,
                                                index
                                              )
                                            }
                                          >
                                            {" "}
                                            <i className="pi pi-plus"></i> Add
                                            More
                                          </Button>
                                        </div>
                                      </div>
                                    </AccordionTab>
                                  </Accordion>
                                );
                              } else if (item1?.sub_category.length == 0) {
                                return <div>No Sub Category Found</div>;
                              }
                            })}
                          {item?.sub_category.length == 0 && (
                            <div className="noDataFound">
                              <h6>No Sub Category Found</h6>
                            </div>
                          )}
                        </>
                      </div>
                    </AccordionTab>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <Dialog
        className="UploadDialog UploadDialogWidth"
        visible={visibleuploadimage}
        onHide={() => clearStates()}
        draggable={false}
      >
        <div className="uploadimagecntD">
          <div className="grid">
            <div className="lg:col-4 md:col-4 sm:col-12 col-12">
              <div>
                <Button className="btn blue-outline uploadimagebtn">
                  <i className="pi pi-cloud-upload"></i>Upload Icon
                  <input
                    type="file"
                    accept="image/jpeg, image/png"
                    onChange={(e) => {
                      let iconImgFile = e.target.files[0];
                      if (
                        iconImgFile?.type != "image/png" &&
                        iconImgFile?.type != "image/jpeg" &&
                        iconImgFile?.type != "image/jpg"
                      ) {
                        setAlert("Please select PNG or JPG file", "error");
                        return false;
                      }
                      if (iconImgFile?.size > 3 * 1024 * 1024) {
                        setAlert(
                          "Image size should be less than 3 MB",
                          "error"
                        );
                        return false;
                      }
                      setIconImg(iconImgFile);
                    }}
                  />
                </Button>
                <p className="p2 mt-2 mb-3">
                  Image Size should be less than 3 MB,<br></br> Only JPEG or PNG
                  should be uploaded
                </p>
                <div
                  style={{
                    width: "100%",
                    height: "200px",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  {iconImg ? (
                    <img
                      src={URL.createObjectURL(iconImg)}
                      style={{
                        width: "auto",
                        height: "auto",
                        maxWidth: "200px",
                        maxHeight: "200px",
                        margin: "auto",
                      }}
                    />
                  ) : (
                    <img
                      src={showIcon}
                      alt=""
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = noImage;
                      }}
                      style={{
                        width: "auto",
                        height: "auto",
                        maxWidth: "200px",
                        maxHeight: "200px",
                        margin: "auto",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="lg:col-4 md:col-4 sm:col-12 col-12">
              <Button className="btn blue-outline uploadimagebtn">
                <i className="pi pi-cloud-upload"></i> Upload Banner Image
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    //add validation for type
                    let BannerImgFile = e.target.files[0];
                    if (
                      BannerImgFile?.type != "image/png" &&
                      BannerImgFile?.type != "image/jpeg" &&
                      BannerImgFile?.type != "image/jpg"
                    ) {
                      setAlert("Please select PNG or JPG file", "error");
                      return false;
                    }
                    if (BannerImgFile?.size > 5 * 1024 * 1024) {
                      setAlert("Image size should be less than 5 MB", "error");
                      return false;
                    }
                    setUpload_Image(BannerImgFile);
                  }}
                />
              </Button>
              <p className="p2 mt-2 mb-3">
                Image Size should be less than 5 MB,<br></br> Only JPEG or PNG
                should be uploaded
              </p>
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                {upload_Image ? (
                  <img
                    src={URL.createObjectURL(upload_Image)}
                    style={{
                      width: "auto",
                      height: "auto",
                      maxWidth: "200px",
                      maxHeight: "200px",
                      margin: "auto",
                    }}
                  />
                ) : (
                  <img
                    src={showBanner}
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = noImage;
                    }}
                    style={{
                      width: "auto",
                      height: "auto",
                      maxWidth: "200px",
                      maxHeight: "200px",
                      margin: "auto",
                    }}
                  />
                )}
              </div>
            </div>
            <div className="lg:col-4 md:col-4 sm:col-12 col-12">
              <Button className="btn blue-outline uploadimagebtn">
                <i className="pi pi-cloud-upload"></i> Upload Thumbnail Image
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    let thumbNailImg = e.target.files[0];
                    console.log("imgThumbnail", thumbNailImg);
                    if (
                      thumbNailImg?.type != "image/png" &&
                      thumbNailImg?.type != "image/jpeg" &&
                      thumbNailImg?.type != "image/jpg"
                    ) {
                      setAlert("Please select PNG or JPG file", "error");
                      return false;
                    }
                    if (thumbNailImg?.size > 5 * 1024 * 1024) {
                      setAlert("Image size should be less than 5 MB", "error");
                      return false;
                    }
                    setThumbNailImage(thumbNailImg);
                  }}
                />
              </Button>
              <p className="p2 mt-2 mb-3">
                Image Size should be less than 5 MB,<br></br> Only JPEG or PNG
                should be uploaded
              </p>
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                {thumbNailImage ? (
                  <img
                    src={URL.createObjectURL(thumbNailImage)}
                    style={{
                      width: "auto",
                      height: "auto",
                      maxWidth: "200px",
                      maxHeight: "200px",
                      margin: "auto",
                    }}
                  />
                ) : (
                  <img
                    src={showThumbNailImg}
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = noImage;
                    }}
                    style={{
                      width: "auto",
                      height: "auto",
                      maxWidth: "200px",
                      maxHeight: "200px",
                      margin: "auto",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="p2 dilogbtns mt-5">
            <Button className="btn btn-gray" onClick={() => clearStates()}>
              Cancel
            </Button>
            <Button
              className="btn btn-blue"
              onClick={() =>
                uploadCategoryImgIcon(uploadImg_id, "UPLOAD_IMAGE")
              }
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>

      {/* upload img dialog */}
      <Dialog
        className="dialogWidth400 UploadDialog"
        visible={visibleSubCatuploadimage}
        style={{ width: "50vw" }}
        onHide={() => clearStates()}
      >
        <div className="uploadimagecntD">
          <Button className="btn blue-outline uploadimagebtn">
            <i className="pi pi-cloud-upload"></i> Upload Image
            <input
              type="file"
              accept="image/jpeg, image/png"
              onChange={(e) => {
                //add validation for type
                let subCatImgFile = e.target.files[0];
                if (
                  subCatImgFile?.type != "image/png" &&
                  subCatImgFile?.type != "image/jpeg" &&
                  subCatImgFile?.type != "image/jpg"
                ) {
                  setAlert("Please select PNG or JPG file", "error");
                  return false;
                }
                if (subCatImgFile?.size > 5 * 1024 * 1024) {
                  setAlert("Image size should be less than 5 MB", "error");
                  return false;
                }
                setUpload_Image(e.target.files[0]);
              }}
            />
          </Button>
          <p className="p2 mt-2">
            Image Size should be 5 MB, <br></br>Only JPEG or PNG should be
            uploaded
          </p>

          {upload_Image ? (
            <div
              style={{
                width: "100%",
                display: "inline-block !important",
              }}
            >
              <img
                src={URL.createObjectURL(upload_Image)}
                style={{
                  width: "auto",
                  height: "auto",
                  maxWidth: "200px",
                  maxHeight: "200px",
                  margin: "auto",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "inline-block !important",
              }}
            >
              <img
                src={showSubCategoryImg}
                style={{
                  width: "auto",
                  height: "auto",
                  maxWidth: "200px",
                  maxHeight: "200px",
                  margin: "auto",
                }}
              />
            </div>
          )}

          <div className="p2 dilogbtns mt-5">
            <Button
              className="btn btn-gray"
              onClick={() => {
                clearStates();
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-blue"
              onClick={() => {
                handleUploadImage(subCategoryImage_id);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>

      {/* delete confirmation */}
      <Dialog
        className="dialogWidth400 headetPadding"
        header="Delete"
        visible={visibleDeleteDialog}
        style={{ width: "50vw" }}
        onHide={() => setDeleteDialog(false)}
      >
        <div className="addformDialog">
          <p className="p2 mb-4 mt-0" style={{ textAlign: "left" }}>
            Are you sure want to delete this?
          </p>
          <div className="p2 dilogbtns">
            <Button
              className="btn btn-gray"
              onClick={() => {
                setDeleteDialog(false);
              }}
            >
              No
            </Button>
            <Button
              onClick={() => {
                handleDelete();
              }}
              className="btn btn-blue"
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
