import React from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";

export const OrderTracking = ({
  orderStatus,
  deliveryType,
  orderTrackingDates,
}) => {
  return (
    <>
      <div className="stepsview">
        {/* if order status ==>confirmed */}
        {orderStatus === "confirmed" && (
          <>
            {deliveryType === "delivery" ? (
              <ul className="flex trackingsteps">
                <li className="stepLi activeStep">
                  <span className="stepCircle"></span>
                  <Typography variant="body2" style={{ fontSize: "14px" }}>
                    <span className="capitalize">Confirmed</span>
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: "10px" }}>
                    {orderTrackingDates?.confirmed}
                  </Typography>
                </li>
                <li className="stepLi">
                  <span className="stepCircle"></span>
                  <Typography variant="body2" style={{ fontSize: "14px" }}>
                    <span className="capitalize">Shipped</span>
                  </Typography>
                </li>
                <li className="stepLi">
                  <span className="stepCircle"></span>
                  <Typography variant="body2" style={{ fontSize: "14px" }}>
                    Delivered
                  </Typography>
                </li>
              </ul>
            ) : deliveryType === "pickup" ? (
              <ul className="flex trackingsteps">
                <li className="stepLi activeStep">
                  <span className="stepCircle"></span>
                  <Typography variant="body2" style={{ fontSize: "14px" }}>
                    <span className="capitalize">Confirmed</span>
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: "10px" }}>
                    {orderTrackingDates?.confirmed}
                  </Typography>
                </li>
                <li className="stepLi">
                  <span className="stepCircle"></span>
                  <Typography variant="body2" style={{ fontSize: "14px" }}>
                    <span className="capitalize">Picked up</span>
                  </Typography>
                </li>
              </ul>
            ) : null}
          </>
        )}

        {/* if order status ==>shipped */}
        {orderStatus === "shipped" && (
          <ul className="flex trackingsteps">
            <li className="stepLi activeStep">
              <span className="stepCircle"></span>
              <Typography variant="body2" style={{ fontSize: "14px" }}>
                <span className="capitalize">Confirmed</span>
              </Typography>
              <Typography variant="body2" style={{ fontSize: "10px" }}>
                {orderTrackingDates?.confirmed}
              </Typography>
            </li>
            <li className="stepLi activeStep">
              <span className="stepCircle"></span>
              <Typography variant="body2" style={{ fontSize: "14px" }}>
                <span className="capitalize">Shipped</span>
              </Typography>
              <Typography variant="body2" style={{ fontSize: "10px" }}>
                {orderTrackingDates?.shipped}
              </Typography>
            </li>
            <li className="stepLi">
              <span className="stepCircle"></span>
              <Typography variant="body2" style={{ fontSize: "14px" }}>
                Delivered
              </Typography>
            </li>
          </ul>
        )}

        {/* if order status ==>delivered */}
        {orderStatus === "delivered" && (
          <ul className="flex trackingsteps">
            <li className="stepLi activeStep">
              <span className="stepCircle"></span>
              <Typography variant="body2" style={{ fontSize: "14px" }}>
                <span className="capitalize">Confirmed</span>
              </Typography>
              <Typography variant="body2" style={{ fontSize: "10px" }}>
                {orderTrackingDates?.confirmed}
              </Typography>
            </li>
            <li className="stepLi activeStep">
              <span className="stepCircle"></span>
              <Typography variant="body2" style={{ fontSize: "14px" }}>
                <span className="capitalize">Shipped</span>
              </Typography>
              <Typography variant="body2" style={{ fontSize: "10px" }}>
                {orderTrackingDates?.shipped}
              </Typography>
            </li>
            <li className="stepLi activeStep">
              <span className="stepCircle"></span>
              <Typography variant="body2" style={{ fontSize: "14px" }}>
                Delivered
              </Typography>
              <Typography variant="body2" style={{ fontSize: "10px" }}>
                {orderTrackingDates?.delivered}
              </Typography>
            </li>
          </ul>
        )}

        {/* if order status ==>canceled */}
        {orderStatus === "canceled" && (
          <ul className="flex trackingsteps">
            <li className="stepLi activeStep">
              <span className="stepCircle"></span>
              <Typography variant="body2" style={{ fontSize: "14px" }}>
                <span className="capitalize">Confirmed</span>
              </Typography>
              <Typography variant="body2" style={{ fontSize: "10px" }}>
                {orderTrackingDates?.confirmed}
              </Typography>
            </li>
            <li className="stepLi activeStep">
              <span className="stepCircle"></span>
              <Typography variant="body2" style={{ fontSize: "14px" }}>
                <span className="capitalize">Cancelled</span>
              </Typography>
              <Typography variant="body2" style={{ fontSize: "10px" }}>
                {orderTrackingDates?.cancelled}
              </Typography>
            </li>
          </ul>
        )}

        {/* if order status ==>returned */}
        {orderStatus === "returned" && (
          <>
            {deliveryType === "delivery" ? (
              <ul className="flex trackingsteps">
                <li className="stepLi activeStep">
                  <span className="stepCircle"></span>
                  <Typography variant="body2" style={{ fontSize: "14px" }}>
                    <span className="capitalize">Confirmed</span>
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: "10px" }}>
                    {orderTrackingDates?.confirmed}
                  </Typography>
                </li>
                <li className="stepLi activeStep">
                  <span className="stepCircle"></span>
                  <Typography variant="body2" style={{ fontSize: "14px" }}>
                    <span className="capitalize">Shipped</span>
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: "10px" }}>
                    {orderTrackingDates?.shipped}
                  </Typography>
                </li>
                <li className="stepLi activeStep">
                  <span className="stepCircle"></span>
                  <Typography variant="body2" style={{ fontSize: "14px" }}>
                    Delivered
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: "10px" }}>
                    {orderTrackingDates?.delivered}
                  </Typography>
                </li>
                <li className="stepLi activeStep">
                  <span className="stepCircle"></span>
                  <Typography variant="body2" style={{ fontSize: "14px" }}>
                    Returned
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: "10px" }}>
                    {orderTrackingDates?.returned}
                  </Typography>
                </li>
              </ul>
            ) : deliveryType === "pickup" ? (
              <ul className="flex trackingsteps">
                <li className="stepLi activeStep">
                  <span className="stepCircle"></span>
                  <Typography variant="body2" style={{ fontSize: "14px" }}>
                    <span className="capitalize">Confirmed</span>
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: "10px" }}>
                    {orderTrackingDates?.confirmed}
                  </Typography>
                </li>
                <li className="stepLi activeStep">
                  <span className="stepCircle"></span>
                  <Typography variant="body2" style={{ fontSize: "14px" }}>
                    <span className="capitalize">Picked up</span>
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: "10px" }}>
                    {orderTrackingDates?.pickup}
                  </Typography>
                </li>
                <li className="stepLi activeStep">
                  <span className="stepCircle"></span>
                  <Typography variant="body2" style={{ fontSize: "14px" }}>
                    Returned
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: "10px" }}>
                    {orderTrackingDates?.returned}
                  </Typography>
                </li>
              </ul>
            ) : null}
          </>
        )}

        {/* if order status ==> pickup */}
        {orderStatus === "pickup" && (
          <>
            <ul className="flex trackingsteps">
              <li className="stepLi activeStep">
                <span className="stepCircle"></span>
                <Typography variant="body2" style={{ fontSize: "14px" }}>
                  <span className="capitalize">Confirmed</span>
                </Typography>
                <Typography variant="body2" style={{ fontSize: "10px" }}>
                  {orderTrackingDates?.confirmed}
                </Typography>
              </li>
              <li className="stepLi activeStep">
                <span className="stepCircle"></span>
                <Typography variant="body2" style={{ fontSize: "14px" }}>
                  <span className="capitalize">Picked up</span>
                </Typography>
                <Typography variant="body2" style={{ fontSize: "10px" }}>
                  {orderTrackingDates?.pickup}
                </Typography>
              </li>
            </ul>
          </>
        )}
      </div>
    </>
  );
};

export const ReturnOrderTracking = ({ trackingLogs }) => {
  return (
    <>
      {/* if order status ==> Return */}
      <ul className="flex trackingsteps">
        {trackingLogs?.map((item, index) => {
          return (
            <li className="stepLi activeStep" key={index}>
              <span className="stepCircle"></span>
              <Typography variant="body2" style={{ fontSize: "12px" }}>
                <span className="capitalize">
                  {item?.status === "return_requested" && "Returned"}
                  {item?.status === "picked_up" && "Picked up"}
                  {item?.status === "refunded" && "Refunded"}
                  {item?.status === "return_collected" && "Return Collected"}
                  {/* {item?.status} */}
                </span>
              </Typography>
              <Typography variant="body2" style={{ fontSize: "10px" }}>
                {moment(item?.createdAt).format("DD MMM YYYY, hh:mm A")}
              </Typography>
            </li>
          );
        })}
      </ul>
    </>
  );
};
