/** @format */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { Paginator } from "primereact/paginator";
import React from "react";
import { Tooltip } from "primereact/tooltip";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
// images
import sortIcon from "../../assets/images/structure/sortIcon.png";
import editIcon from "../../assets/images/structure/edit-32.png";
import deleteIcon from "../../assets/images/structure/delete-32.png";
import eyeIcon from "../../assets/images/structure/eye-32.png";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests, LOCAL_STORAGE_KEYS } from "../auth/AuthRequests";
import addDeleteGetLocalStorage from "../../globalModules/addDeleteGetLocalStorage ";
import { ShowImage } from "../../popups/ShowImage/ShowImage";
import { InputSwitch } from "primereact/inputswitch";
import { imageBaseUrl } from "../../Helpers/HelperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import noImage from "../../assets/images/products/noImage.webp";

// Simple debounce function
let timeout1 = null;
const debounce = (func, delay) => {
  return function (...args) {
    clearTimeout(timeout1);
    timeout1 = setTimeout(() => func.apply(this, args), delay);
  };
};

export const ProductList = ({ setAlert }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  const location = addDeleteGetLocalStorage(
    LOCAL_STORAGE_KEYS?.DB_LOCATION,
    {},
    "get"
  );
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [visibleProductDetail, setVisibleProductDetail] = useState(false);
  const [visibleWholesale, setVisibleWholesale] = useState(false);
  const [visibleDeleteDialog, setDeleteDialog] = useState(false);
  const [selectList, setSelectList] = useState({ name: "10", code: "10" });
  const Lists = [
    { name: "10", code: "10" },
    { name: "15", code: "15" },
    { name: "50", code: "50" },
    { name: "100", code: "100" },
  ];
  const [selectStatus, setSelectStatus] = useState(null);
  const status = [
    { name: "ALL", code: "" },
    { name: "PUBLISH", code: "active" },
    { name: "UNPUBLISH", code: "inactive" },
    { name: "LISTED", code: "listed" },
  ];
  const [selectTodaysOffer, setSelectTodaysOffer] = useState({
    name: "All products ",
    code: "0",
  });
  const todaysOffer = [
    { name: "All products ", code: "0" },
    { name: "Today’s offer", code: "1" },
  ];

  //state for product detail
  const [ProductList, setProductList] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [product_status, setProductStatus] = useState("");
  const [sortBy, setSortBy] = useState("id");
  const [orderBy, setOrderBy] = useState("DESC");
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [wholesellPrice, setWholesellPrice] = useState([]);
  const [productDetail, setProductDetail] = useState([]);
  const [showImgUrl, setShowImgUrl] = useState("");

  const showProductDetail = async (id) => {
    dispatch(changeLoader(true));
    await globalRequestAxios("get", {}, AuthRequests.GET_SINGLE_PRODUCT + id)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setProductDetail(res?.data);
          setVisibleProductDetail(true);
        } else {
          setAlert("Something went wrong", "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  // get product list
  const getProductList = async () => {
    let data = {
      limit: limit,
      page: page,
      status: product_status,
      sortBy: sortBy,
      orderBy: orderBy,
      search: search,
      isOffer: selectTodaysOffer?.code,
    };
    dispatch(changeLoader(true));
    await globalRequestAxios("post", data, AuthRequests.GET_ALL_PRODUCTS)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setProductList(res?.data);
          setTotalRecords(res?.countall);
        } else {
          setAlert("Something went wrong", "error");
          setProductList([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  // Create a debounced version of getProductList
  const debouncedGetProductList = debounce(getProductList, 300);

  useEffect(() => {
    debouncedGetProductList();
  }, [limit, page, product_status, sortBy, orderBy, search, selectTodaysOffer]);

  //delete product
  const [deleteProductId, setDeleteProductId] = useState(null);
  const deleteProduct = async (id) => {
    if (!id) return;
    dispatch(changeLoader(true));
    await globalRequestAxios(
      "post",
      {},
      AuthRequests.DELETE_SINGLE_PRODUCT + id
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setAlert("Product deleted successfully", "success");
          setDeleteDialog(false);
          getProductList();
        } else {
          setAlert("Something went wrong", "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  //update product status
  const updateProductStatus = async (id, status) => {
    if (!id) return;
    dispatch(changeLoader(true));
    await globalRequestAxios(
      "post",
      { status: status ? "active" : "inactive", id: id },
      AuthRequests.UPDATE_PRODUCT_STATUS
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          getProductList();
          setAlert("Product status updated successfully", "success");
        } else {
          setAlert("Something went wrong", "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  //update product today's offer
  const updateTodaysOfferStatus = async (id, status) => {
    if (!id) return;
    dispatch(changeLoader(true));
    await globalRequestAxios(
      "put",
      { isOffer: status ? "1" : "0", id: id },
      AuthRequests.UPDATE_PRODUCT_TODAYS_OFFER
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          getProductList();
          setAlert("Product offer updated successfully", "success");
        } else {
          setAlert("Something went wrong", "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  //sort function
  const sortFunction = (sortType) => {
    setSortBy(sortType);
    if (orderBy == "ASC") {
      setOrderBy("DESC");
    } else {
      setOrderBy("ASC");
    }
  };

  ///pagination function
  const onPageChange = (event) => {
    setPage(event.page + 1);
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <>
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="tablefilterheader pb-1">
            <h4 className="h4 mb-0">Manage Product</h4>
          </div>
          <div className="tablefilterSubheader">
            <div className="tableHeader">
              <div className="tableFilterRow" style={{ marginLeft: "0" }}>
                <div
                  className="tableFilterCol sortList"
                  style={{ marginLeft: "0" }}
                >
                  <Dropdown
                    value={selectList}
                    onChange={(e) => {
                      setSelectList(e.value);
                      setLimit(e.value.code);
                      setPage(1);
                      setFirst(0);
                    }}
                    options={Lists}
                    optionLabel="name"
                    placeholder="10"
                    className="filterDropdown"
                  />
                </div>
              </div>
              <div className="tableFilterRow">
                <div className="tableFilterCol">
                  <Dropdown
                    value={selectStatus}
                    onChange={(e) => {
                      setSelectStatus(e.value);
                      setProductStatus(e.value.code);
                      setPage(1);
                      setFirst(0);
                    }}
                    options={status}
                    optionLabel="name"
                    placeholder="Status"
                    className="filterDropdown"
                  />
                </div>
                <div className="tableFilterCol">
                  <Dropdown
                    value={selectTodaysOffer}
                    onChange={(e) => {
                      setSelectTodaysOffer(e.value);
                      setPage(1);
                      setFirst(0);
                    }}
                    options={todaysOffer}
                    optionLabel="name"
                    placeholder="All Products"
                    className="filterDropdown"
                  />
                </div>
                <div className="tableFilterCol">
                  <div
                    className="p-inputgroup searchFilter"
                    style={{ width: "390px" }}
                  >
                    <InputText
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value.trimStart());
                        setPage(1);
                        setFirst(0);
                      }}
                      placeholder="Search by product name, model number..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid adminTableContent">
            <div className="tableContent">
              <table>
                <thead>
                  <tr>
                    <th>
                      <p className="tbleheading">
                        Product Name
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("title");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">main Category</p>
                    </th>
                    <th>
                      <p className="tbleheading">Sub Category</p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Cost Price
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("cost_price");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Selling Price
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("selling_price");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Discounted Price
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("discount_price");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>Wholesale Detail</th>
                    <th style={{ textAlign: "center" }}>Status</th>
                    <th style={{ textAlign: "center" }}>Today's Offer</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {ProductList?.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          className="tba"
                          onClick={() => {
                            showProductDetail(data.id);
                          }}
                        >
                          {" "}
                          {data.title}
                        </a>
                      </td>
                      <td>
                        {location == "dubai"
                          ? data?.category_details?.name_en
                          : data?.category_details?.name_en}
                      </td>
                      <td>
                        {location == "dubai"
                          ? data?.subcategory_details?.name_en
                          : data?.subcategory_details?.name_en}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {location == "dubai" ? "AED " : "USD "}
                        {data.cost_price.toFixed(2)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {location == "dubai" ? "AED " : "USD "}
                        {data.selling_price.toFixed(2)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {location == "dubai"
                          ? data.discount_type == "percentage"
                            ? ""
                            : "AED "
                          : data.discount_type == "percentage"
                          ? ""
                          : "USD "}
                        {data.discount_price.toFixed(2)}
                        {data.discount_type == "percentage" ? " %" : ""}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Tooltip target=".custom-target-icon" />
                        <Button
                          onClick={() => {
                            if (location == "dubai")
                              setWholesellPrice(data?.price_ranges);
                            else setWholesellPrice(data?.price_ranges);
                            setVisibleWholesale(true);
                          }}
                          className="btn addmorCircleBtn custom-target-icon featureIcon p-text-secondary p-overlay-badge"
                          data-pr-tooltip="View Wholesale Detail"
                          data-pr-position="top"
                          data-pr-at="left+15 top-30"
                          data-pr-my="center"
                        >
                          <i className="pi pi-eye uploadIcon"></i>
                        </Button>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <InputSwitch
                          checked={data.status == "active" ? true : false}
                          onChange={(e) => {
                            updateProductStatus(data.id, e.value);
                          }}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <InputSwitch
                          checked={data.is_todays_offer == "1" ? true : false}
                          onChange={(e) => {
                            updateTodaysOfferStatus(data.id, e.value);
                          }}
                        />
                      </td>
                      {/*confirmed, cancelled, delivered */}
                      <td style={{ textAlign: "center" }}>
                        <div className="tdAction">
                          <a className="TdICon">
                            {" "}
                            <img
                              src={editIcon}
                              onClick={() => {
                                navigate(`/product-edit/${data.id}`);
                              }}
                              alt=""
                              className="AcIcon"
                            />{" "}
                          </a>
                          <a
                            className="TdICon"
                            onClick={() => {
                              setDeleteDialog(true);
                              setDeleteProductId(data.id);
                            }}
                          >
                            {" "}
                            <img
                              src={deleteIcon}
                              alt=""
                              className="AcIcon"
                            />{" "}
                          </a>
                          <a
                            className="TdICon"
                            onClick={() => {
                              showProductDetail(data.id);
                            }}
                          >
                            {" "}
                            <img src={eyeIcon} alt="" className="AcIcon" />{" "}
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {ProductList?.length === 0 && !loader ? (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: "20px",
                }}
              >
                <h4 className="h4 mb-0">{"No Products Found"}</h4>
              </div>
            ) : null}
            {totalRecords > 0 && totalRecords > +selectList.code && (
              <Paginator
                first={first}
                rows={rows}
                totalRecords={totalRecords}
                className="mt-2"
                onPageChange={onPageChange}
              />
            )}
          </div>
        </div>
      </section>
      {/* wholesale details */}
      <Sidebar
        className="detailSiderbar wholesalesidebar afterClickClose"
        visible={visibleWholesale}
        position="right"
        onHide={() => setVisibleWholesale(false)}
      >
        <h3 className="h3 sidebarTitleHd">Wholesale Detail</h3>
        <div className="sidebarContentBody">
          <div className="container-fluid adminTableContent">
            <div className="tableContent mb-3">
              <table>
                <thead>
                  <tr>
                    <th>
                      Quantity<br></br> Range
                    </th>
                    <th>
                      Selling <br></br> Price
                    </th>
                    <th>
                      Discount <br></br> value
                    </th>
                    <th>
                      Wholesale<br></br> Price
                    </th>
                    <th>
                      Discount <br></br> type
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {wholesellPrice?.length > 0 &&
                    wholesellPrice?.map((ele, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {ele?.start_range} - {ele?.end_range}
                          </td>
                          <td>
                            {location == "dubai" ? "AED " : "USD "}
                            {ele?.selling_price?.toFixed(2)}
                          </td>
                          <td>
                            {ele?.discounted_type == "percentage"
                              ? ele?.discounted_price + "%"
                              : location == "dubai"
                              ? "AED" + " " + ele?.discounted_price.toFixed(2)
                              : "USD" + " " + ele?.discounted_price.toFixed(2)}
                          </td>
                          <td>
                            {location == "dubai" ? "AED " : "USD "}
                            {ele?.wholesale_price.toFixed(2)}
                          </td>
                          <td>
                            {ele?.discounted_type
                              ? ele?.discounted_type
                                  .replace(/_/g, " ")
                                  .replace(/\b\w/g, (c) => c.toUpperCase())
                              : ""}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {wholesellPrice?.length == 0 && (
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    padding: "20px",
                  }}
                >
                  <h4 className="h4 mb-0" style={{ color: "#a3acb6" }}>
                    {"No Wholesale Details Found"}
                  </h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </Sidebar>
      {/* product detail */}
      <Sidebar
        className="detailSiderbar afterClickClose"
        visible={visibleProductDetail}
        position="right"
        onHide={() => setVisibleProductDetail(false)}
      >
        <h4 className="mt-0 sidebarTitleHd">Product Detail</h4>
        <div className="sidebarContentBody">
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel ">
                  <label className="sepLable">Main Category</label>
                  <div className="show-dataonly-static">
                    {location == "dubai"
                      ? productDetail?.category_details?.name_en
                      : productDetail?.category_details?.name_en}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel ">
                  <label className="sepLable">Sub Category</label>
                  <div className="show-dataonly-static">
                    {location == "dubai"
                      ? productDetail?.subcategory_details?.name_en
                      : productDetail?.subcategory_details?.name_en}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Product Name</label>
                  <div className="show-dataonly-static">
                    {productDetail?.title}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel ">
                  <label className="sepLable">Long Description</label>
                  <div className="show-dataonly-static">
                    {productDetail?.long_description ? (
                      <div
                        className="term-cond-sec2"
                        dangerouslySetInnerHTML={{
                          __html: productDetail?.long_description,
                        }}
                      ></div>
                    ) : null}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Short Description</label>
                  <div className="show-dataonly-static">
                    {/* {productDetail?.short_description} */}
                    {productDetail?.short_description ? (
                      <div
                        className="term-cond-sec2"
                        dangerouslySetInnerHTML={{
                          __html: productDetail?.short_description,
                        }}
                      ></div>
                    ) : null}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Model Number</label>
                  <div className="show-dataonly-static">
                    {productDetail?.model_number}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Selling Price</label>
                  <div className="show-dataonly-static">
                    {location == "dubai"
                      ? "AED " + productDetail?.selling_price?.toFixed(2)
                      : "USD " + productDetail?.selling_price?.toFixed(2)}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Cost Price</label>
                  <div className="show-dataonly-static">
                    {location == "dubai"
                      ? "AED " + productDetail?.cost_price?.toFixed(2)
                      : "USD " + productDetail?.cost_price?.toFixed(2)}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Discounted Price</label>
                  <div className="show-dataonly-static">
                    {location == "dubai"
                      ? "AED " + productDetail?.discount_price?.toFixed(2)
                      : "USD " + productDetail?.discount_price?.toFixed(2)}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Weight</label>
                  <div className="show-dataonly-static">
                    {productDetail?.weight} kg
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Length</label>
                  <div className="show-dataonly-static">
                    {productDetail?.length} cm
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Height</label>
                  <div className="show-dataonly-static">
                    {productDetail?.height} cm
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Width</label>
                  <div className="show-dataonly-static">
                    {productDetail?.width} cm
                  </div>
                </span>
              </div>
            </div>

            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Is Wholesale</label>
                  <Checkbox
                    id="reccP"
                    className="checkboxOnlyRead hover-border-none"
                    checked={productDetail?.is_wholesale == 1 ? true : false}
                    style={{
                      cursor: "default",
                      backgroundColor: "transparent !important",
                      border: "none !important",
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel onlyReadForm">
                  <label className="sepLable">Status</label>
                  <div className="show-dataonly-static">
                    {productDetail?.status == "active"
                      ? "Publish"
                      : productDetail?.status == "inactive"
                      ? "Unpublish"
                      : "Listed"}
                  </div>
                </span>
              </div>
            </div>
          </div>

          {productDetail?.product_images?.length > 0 && (
            <div className="detailTxtRow" style={{ flexWrap: "wrap" }}>
              <div className="detailTxtColLeft mb-3">Product Images :</div>
              <div
                className="detailTxtColRight pl-0"
                style={{ minWidth: "100%" }}
              >
                <div className="uploadImageRow">
                  {productDetail?.product_images?.length > 0 &&
                    productDetail?.product_images.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="uploadImageCol uploadImagePreview"
                        >
                          {item?.is_featured == 1 && (
                            <>
                              <Tooltip target=".custom-target-icon" />
                              <i
                                className="custom-target-icon pi pi-star-fill featureIcon p-text-secondary p-overlay-badge"
                                data-pr-tooltip="Marked as featured"
                                data-pr-position="top"
                                data-pr-at="left+15 top-30"
                                data-pr-my="center"
                              ></i>
                            </>
                          )}
                          <img
                            onClick={() => {
                              setShowImgUrl(item?.image_url);
                            }}
                            src={item?.image_url}
                            alt=""
                            className="productImage"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = noImage;
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </div>
      </Sidebar>
      <Dialog
        className="dialogWidth400 headetPadding"
        header="Delete"
        visible={visibleDeleteDialog}
        style={{ width: "50vw" }}
        onHide={() => setDeleteDialog(false)}
      >
        <div className="addformDialog">
          <p className="p2 mb-4 mt-0" style={{ textAlign: "left" }}>
            Are you sure want to delete this?
          </p>
          <div className="p2 dilogbtns">
            <Button
              className="btn btn-gray"
              onClick={() => setDeleteDialog(false)}
            >
              No
            </Button>
            <Button
              className="btn btn-yellow"
              onClick={() => deleteProduct(deleteProductId)}
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>

      {/* show img big */}
      {showImgUrl && (
        <ShowImage
          showImgUrl={showImgUrl}
          setShowImgUrl={setShowImgUrl}
          view={true}
        />
      )}
    </>
  );
};
