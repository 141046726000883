
// import PropTypes from "prop-types";
import React from "react";

import { Navigate, Outlet} from "react-router-dom"
import addDeleteGetLocalStorage from "../globalModules/addDeleteGetLocalStorage ";
import { LOCAL_STORAGE_KEYS } from "../pages/auth/AuthRequests";

    
  export const AdminPrivateRaoutes =()=>{
          const AdminAccess = addDeleteGetLocalStorage(LOCAL_STORAGE_KEYS?.USER_TOKEN, {}, "get");
        
        return(
            <>
               {AdminAccess? <Outlet/>:<Navigate to="/"/>}
            </>
      )
      
    }