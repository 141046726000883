import { Dialog } from "primereact/dialog";

export const ShowImage = (props) => {

  return (
    <div onClick={()=>{
        props.setShowImgUrl("");
    }} >
      <Dialog
        // header="Image View"
        // style={{ width: "50vw" }}
        className="headetPadding"
        visible={props.showImgUrl?true:false}
        onHide={() => {
            props.setShowImgUrl("");
        }}
      >
        <div className="addformDialog" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
          <img
            src={props.showImgUrl}
            alt=""
            // className="productImage"
          />
        </div>
      </Dialog>
    </div>
  );
};
