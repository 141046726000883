import { useState, useEffect } from "react";
import React from "react";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import addDeleteGetLocalStorage from "../../globalModules/addDeleteGetLocalStorage ";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests, LOCAL_STORAGE_KEYS } from "../auth/AuthRequests";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";

const PickupAddress = ({ setAlert }) => {
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  let dbLocation =
    addDeleteGetLocalStorage(LOCAL_STORAGE_KEYS?.DB_LOCATION, {}, "get") ==
    "dubai"
      ? "dubai"
      : "baghdad";
  const [addObj, setAddObj] = useState({
    name: "",
    address: "",
    mobile: "",
    city: "",
    state: "",
    postalCode: "",
    nameError: "",
    addressError: "",
    mobileError: "",
    cityError: "",
    stateError: "",
    postalCodeError: "",
  });

  const GetApiRequest = async () => {
    dispatch(changeLoader(true));
    await globalRequestAxios("get", "", AuthRequests.GET_ADDRESS)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res?.ack == 1) {
          let add = res?.data[0];
          setAddObj((prev) => ({
            ...prev,
            name: add?.name,
            address: add?.address,
            mobile: add?.mobile,
            city: add?.city,
            state: add?.state,
            postalCode: add?.postal_code,
            id: add?.id,
          }));
        } else {
          setAlert(res?.msg, "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        console.error(err);
      });
  };

  useEffect(() => {
    GetApiRequest();
  }, []);

  const handleAddressErrors = () => {
    let error = false;
    let objError = { ...addObj };
    if (!addObj?.name) {
      objError.nameError = "Name is required";
      error = true;
    }
    if (!addObj?.address) {
      objError.addressError = "Address is required";
      error = true;
    }
    if (!addObj?.mobile) {
      objError.mobileError = "Mobile is required";
      error = true;
    }
    if (!addObj?.city) {
      objError.cityError = "City is required";
      error = true;
    }
    if (!addObj?.state) {
      objError.stateError = "State is required";
      error = true;
    }
    if (!addObj?.postalCode) {
      objError.postalCodeError = "Postal code is required";
      error = true;
    }
    setAddObj(objError);
    return error;
  };

  const handleUpdateAddress = async () => {
    if (handleAddressErrors()) return;

    let data = {
      name: addObj?.name,
      address: addObj?.address,
      mobile: addObj?.mobile,
      city: addObj?.city,
      state: addObj?.state,
      postalCode: addObj?.postalCode,
    };
    dispatch(changeLoader(true));
    await globalRequestAxios(
      "put",
      data,
      AuthRequests.UPDATE_ADDRESS(addObj?.id)
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res?.ack == 1) {
          setAlert(res?.msg, "success");
        } else {
          setAlert(res?.msg, "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        console.error(err);
        setAlert("Something went wrong", "error");
      });
  };

  return (
    <>
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="formHeader">
            <h4 className="h4 mb-2">Manage Pickup Address</h4>
          </div>
          <div className="contentBox-w">
            <div className="grid">
              <div className="lg:col-8 md:col-10 sm:col-12 col-12">
                <div className="grid">
                  <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                    <div className="formField">
                      <label className="frmLabel">
                        Pick up address for{" "}
                        {dbLocation == "dubai" ? "UAE" : "Iraq"}
                      </label>
                      <span className="p-float-label">
                        <InputText
                          id="Instagram"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={addObj?.name}
                          onChange={(e) => {
                            let value = e.target.value.trimStart();
                            setAddObj((prev) => ({
                              ...prev,
                              name: value,
                              nameError: value ? "" : "Name is required",
                            }));
                          }}
                        />
                        <label htmlFor="Instagram">
                          Name<span style={{ color: "red" }}>*</span>
                        </label>
                      </span>{" "}
                      <span style={{ color: "red" }}>{addObj?.nameError}</span>
                      <span
                        style={{ marginTop: "20px" }}
                        className="p-float-label"
                      >
                        <InputText
                          id="Instagram"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={addObj?.address}
                          onChange={(e) => {
                            let value = e.target.value.trimStart();
                            setAddObj((prev) => ({
                              ...prev,
                              address: value,
                              addressError: value ? "" : "Address is required",
                            }));
                          }}
                        />
                        <label htmlFor="Instagram">
                          Address<span style={{ color: "red" }}>*</span>
                        </label>
                      </span>
                      <span style={{ color: "red" }}>
                        {addObj?.addressError}
                      </span>
                      <span
                        style={{ marginTop: "20px" }}
                        className="p-float-label"
                      >
                        <InputText
                          id="Instagram"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={addObj?.mobile}
                          onChange={(e) => {
                            let value = e.target.value.trimStart();
                            if (isNaN(value)) return;
                            setAddObj((prev) => ({
                              ...prev,
                              mobile: value,
                              mobileError: value ? "" : "Mobile is required",
                            }));
                          }}
                        />
                        <label htmlFor="Instagram">
                          Mobile<span style={{ color: "red" }}>*</span>
                        </label>
                      </span>
                      <span style={{ color: "red" }}>
                        {addObj?.mobileError}
                      </span>
                      <span
                        style={{ marginTop: "20px" }}
                        className="p-float-label"
                      >
                        <InputText
                          id="Instagram"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={addObj?.city}
                          onChange={(e) => {
                            let value = e.target.value.trimStart();
                            setAddObj((prev) => ({
                              ...prev,
                              city: value,
                              cityError: value ? "" : "City is required",
                            }));
                          }}
                        />
                        <label htmlFor="Instagram">
                          City<span style={{ color: "red" }}>*</span>
                        </label>
                      </span>
                      <span style={{ color: "red" }}>{addObj?.cityError}</span>
                      <span
                        style={{ marginTop: "20px" }}
                        className="p-float-label"
                      >
                        <InputText
                          id="Instagram"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={addObj?.state}
                          onChange={(e) => {
                            let value = e.target.value.trimStart();
                            setAddObj((prev) => ({
                              ...prev,
                              state: value,
                              stateError: value ? "" : "State is required",
                            }));
                          }}
                        />
                        <label htmlFor="Instagram">
                          State<span style={{ color: "red" }}>*</span>
                        </label>
                      </span>
                      <span style={{ color: "red" }}>{addObj?.stateError}</span>
                      <span
                        style={{ marginTop: "20px" }}
                        className="p-float-label"
                      >
                        <InputText
                          id="Instagram"
                          autoComplete="off"
                          placeholder="Enter here"
                          value={addObj?.postalCode}
                          onChange={(e) => {
                            let value = e.target.value.trimStart();
                            if (isNaN(value)) return;
                            setAddObj((prev) => ({
                              ...prev,
                              postalCode: value,
                              postalCodeError: value
                                ? ""
                                : "Postal code is required",
                            }));
                          }}
                        />
                        <label htmlFor="Instagram">
                          Postal Code<span style={{ color: "red" }}>*</span>
                        </label>
                      </span>
                      <span style={{ color: "red" }}>
                        {addObj?.postalCodeError}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="grid mt-2">
                  <div
                    className="lg:col-12 md:col-12 sm:col-12 col-12 mt-3"
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      disabled={loader}
                      className="btn btn-yellow"
                      onClick={handleUpdateAddress}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PickupAddress;
