import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
// images
import dawnArrowBlack from "../../../assets/images/structure/down-arrow.svg";
import logo from "../../../assets/images/logo/logo.png";
import userIcon from "../../../assets/images/structure/user-white.svg";
// other
import "./header.scss";
import addDeleteGetLocalStorage from "../../../globalModules/addDeleteGetLocalStorage ";
import { validatePassword } from "../../../Helpers/HelperFunctions";
import globalRequestAxios from "../../../globalModules/globalRequestAxios";
import { AuthRequests, LOCAL_STORAGE_KEYS } from "../../auth/AuthRequests";
import { decodedToken } from "../../../globalModules/tokenDecode";

export const Header = ({ setAlert }) => {
  const menu = useRef(null);
  const [visibleChangePassword, setChangePassword] = useState(false);
  const [visibleLogout, setLogout] = useState(false);
  const currentUrl = window.location.href.split("/");
  const active_link = `${currentUrl[currentUrl.length - 2]}`; //edit product page to list page
  const navigate = useNavigate();
  const items = [
    {
      label: "Change Password",
      command: () => {
        visibleChangePassword
          ? setChangePassword(false)
          : setChangePassword(true);
      },
    },
    {
      label: "Logout",
      command: () => {
        visibleLogout ? setLogout(false) : setLogout(true);
      },
    },
  ];
  const location = addDeleteGetLocalStorage(
    LOCAL_STORAGE_KEYS?.DB_LOCATION,
    {},
    "get"
  );
  const [selectDatabse, setSelectDatabse] = useState(
    location == "dubai"
      ? { name: "UAE", code: "dubai" }
      : location == "baghdad"
      ? { name: "IRAQ", code: "baghdad" }
      : null
  );
  const Database = [
    { name: "UAE", code: "dubai" },
    { name: "IRAQ", code: "baghdad" },
  ];
  const [subAdminDb, setSubAdminDb] = useState(null);

  useEffect(() => {
    let userToken = addDeleteGetLocalStorage(
      LOCAL_STORAGE_KEYS?.USER_TOKEN,
      {},
      "get"
    );
    const decoded = decodedToken(userToken);
    if (!decoded) return false;
    if (decoded?.access == "subadmin" && decoded?.country !== "both") {
      setSubAdminDb(decoded?.country === "dubai" ? "UAE" : "IRAQ");
    }
  }, []);

  const handleLogout = () => {
    setTimeout(() => {
      navigate("/");
      setAlert("Logout Successfully", "success");
      addDeleteGetLocalStorage(LOCAL_STORAGE_KEYS?.USER_TOKEN, {}, "delete");
    }, 500);
  };

  ///cahnge password
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [cPasswordError, setCPasswordError] = useState("");
  //show hide password
  const [show_oPass, setShowOPass] = useState(true);
  const [show_nPass, setShowNPass] = useState(true);
  const [show_cPass, setShowCPass] = useState(true);
  ///initial state
  const makeInitialState = () => {
    setOldPassword("");
    setNewPassword("");
    setCPassword("");
    setOldPasswordError("");
    setNewPasswordError("");
    setCPasswordError("");
    setShowCPass(true);
    setShowNPass(true);
    setShowOPass(true);
  };

  const handleChangePassword = async () => {
    let err = false;
    if (!validatePassword(newPassword)) {
      setNewPasswordError(
        "Password should be with uppercase, lowercase, number, symbol and least 8 characters"
      );
      err = true;
    }
    if (!oldPassword) {
      setOldPasswordError("Old Password is required");
      err = true;
    }
    if (!newPassword) {
      setNewPasswordError("New Password is required");
      err = true;
    }
    if (cPassword) {
      if (cPassword !== newPassword) {
        setCPasswordError("Confirm Password is not match");
        err = true;
      }
      if (!newPassword) {
        if (!validatePassword(cPassword)) {
          setCPasswordError(
            "Confirm Password should be with uppercase, lowercase, number, symbol and min 8 characters"
          );
          err = true;
        }
      }
    }
    if (!cPassword) {
      setCPasswordError("Confirm Password is required");
      err = true;
    }

    if (err) return;

    let data = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    const res = await globalRequestAxios(
      "post",
      data,
      AuthRequests.ADMIN_CHANGE_PASSWORD
    );
    if (res.ack == 1) {
      setAlert("Password Changed Successfully", "success");
      setChangePassword(false);
      makeInitialState();
      localStorage.clear();
      navigate("/");
    } else {
      if (res.msg == "Invalid old password.") {
        setOldPasswordError(res.msg);
      }
    }
  };

  return (
    <>
      <div className="header-wrapper">
        <div className="header">
          <div className="container">
            <div className="header-flex">
              <div className="header-left">
                <div className="header__logo">
                  <Link to="/">
                    <img src={logo} alt="logo" className="headerlogo" />
                  </Link>
                </div>
              </div>
              <div className="header-right">
                <div className="top-toolbar-right-item">
                  <div className="headerselectdb">
                    {subAdminDb ? (
                      <span className="selectdb" style={{ color: "white" }}>
                        {subAdminDb}
                      </span>
                    ) : (
                      <Dropdown
                        value={selectDatabse}
                        onChange={(e) => {
                          setSelectDatabse(e.value);
                          addDeleteGetLocalStorage(
                            LOCAL_STORAGE_KEYS?.DB_LOCATION,
                            e.value.code,
                            "add",
                            "single"
                          );
                          if (active_link == "product-edit")
                            navigate("/product-list"); //if user is on product edit page then redirect to product list page
                          if (active_link == "order-edit")
                            navigate("/order-history-listing");
                          if (active_link == "bundle-edit")
                            navigate("/bundle-listing");
                          window.location.reload();
                        }}
                        options={Database}
                        optionLabel="name"
                        placeholder="UAE"
                        className="selectdb"
                      />
                    )}
                  </div>
                </div>

                <div className="header__user">
                  <Menu model={items} popup ref={menu} />
                  <span onClick={(e) => menu.current.toggle(e)}>
                    <span className="progileimg">
                      <img src={userIcon} alt="user" className="icon24" />
                    </span>
                    <img
                      src={dawnArrowBlack}
                      alt="arrow-down"
                      className="down-arrow"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        className="dialogWidth400"
        header="Change Password"
        visible={visibleChangePassword}
        style={{ width: "50vw" }}
        onHide={() => {
          makeInitialState();
          setChangePassword(false);
        }}
      >
        <div className="addformDialog">
          <form autoComplete="off">
            <div className="grid">
              <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                <div className="formField">
                  <span className="p-float-label">
                    <InputText
                      // className={oldPasswordError?"p-invalid":""}
                      style={{ borderColor: oldPasswordError ? "#f55e71" : "" }}
                      type={!show_oPass ? "text" : "password"}
                      value={oldPassword}
                      onChange={(e) => {
                        setOldPasswordError("");
                        setOldPassword(e.target.value.trimStart());
                      }}
                      id="Oldpassword"
                      autoComplete="off"
                    />
                    <label htmlFor="Oldpassword">
                      Old Password <span className="requiredstar">*</span>
                    </label>
                    <i
                      className={
                        !show_oPass
                          ? "pi pi-eye passwordIcon"
                          : "pi pi-eye-slash passwordIcon"
                      }
                      onClick={() => {
                        setShowOPass(!show_oPass);
                      }}
                    ></i>
                  </span>
                  {oldPasswordError && (
                    <div
                      className="p-invalid"
                      style={{ position: "relative", bottom: "-3px" }}
                    >
                      {oldPasswordError}
                    </div>
                  )}
                </div>
              </div>
              <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                <div className="formField">
                  <span className="p-float-label">
                    <InputText
                      style={{ borderColor: newPasswordError ? "#f55e71" : "" }}
                      type={!show_nPass ? "text" : "password"}
                      value={newPassword}
                      onChange={(e) => {
                        setNewPasswordError("");
                        setNewPassword(e.target.value.trimStart());
                      }}
                      id="NewPassword"
                      autoComplete="off"
                    />
                    <label htmlFor="NewPassword">
                      New Password <span className="requiredstar">*</span>
                    </label>
                    <i
                      className={
                        !show_nPass
                          ? "pi pi-eye passwordIcon"
                          : "pi pi-eye-slash passwordIcon"
                      }
                      onClick={() => {
                        setShowNPass(!show_nPass);
                      }}
                    ></i>
                  </span>
                  {newPasswordError && (
                    <div
                      className="p-invalid"
                      style={{ position: "relative", bottom: "-3px" }}
                    >
                      {newPasswordError}
                    </div>
                  )}
                </div>
              </div>
              <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                <div className="formField">
                  <span className="p-float-label">
                    <InputText
                      style={{ borderColor: cPasswordError ? "#f55e71" : "" }}
                      type={!show_cPass ? "text" : "password"}
                      value={cPassword}
                      onChange={(e) => {
                        setCPasswordError("");
                        setCPassword(e.target.value.trimStart());
                      }}
                      id="cPassword"
                      autoComplete="off"
                    />
                    <label htmlFor="cPassword">
                      Confirm Password <span className="requiredstar">*</span>
                    </label>
                    <i
                      className={
                        !show_cPass
                          ? "pi pi-eye passwordIcon"
                          : "pi pi-eye-slash passwordIcon"
                      }
                      onClick={() => {
                        setShowCPass(!show_cPass);
                      }}
                    ></i>
                  </span>
                  {cPasswordError && (
                    <div
                      className="p-invalid"
                      style={{ position: "relative", bottom: "-3px" }}
                    >
                      {cPasswordError}
                    </div>
                  )}
                </div>
              </div>
              <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                <Button
                  className="btn btn-blue"
                  onClick={(e) => {
                    e.preventDefault();
                    handleChangePassword();
                  }}
                >
                  Save Password
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Dialog>

      <Dialog
        className="dialogWidth400 headetPadding"
        header="Logout"
        visible={visibleLogout}
        style={{ width: "50vw" }}
        onHide={() => setLogout(false)}
      >
        <div className="addformDialog">
          <p className="p2 mb-4 mt-0" style={{ textAlign: "left" }}>
            Are you sure want to logout this?
          </p>
          <div className="p2 dilogbtns">
            <Button className="btn btn-gray" onClick={() => setLogout(false)}>
              No
            </Button>
            <Button className="btn btn-blue" onClick={() => handleLogout()}>
              Yes
            </Button>
          </div>
        </div>
      </Dialog>

      <Outlet />
    </>
  );
};
