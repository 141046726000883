import { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { Sidebar } from "primereact/sidebar";
import { InputSwitch } from "primereact/inputswitch";
import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "libphonenumber-js";
// images
import sortIcon from "../../assets/images/structure/sortIcon.png";
import eyeIcon from "../../assets/images/structure/eye-32.png";
import editIcon from "../../assets/images/structure/edit-32.png";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests } from "../auth/AuthRequests";
import { dateFormat, formatDate } from "../../Helpers/HelperFunctions";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";

export const CustomerListing = ({ setAlert }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const Status = [
    { name: "All", code: "" },
    { name: "Active", code: "active" },
    { name: "Inactive", code: "inactive" },
  ];
  const Status1 = [
    { name: "Active", code: "active" },
    { name: "Inactive", code: "inactive" },
  ];
  const [selectList, setSelectList] = useState({ name: "10", code: "10" });
  const Lists = [
    { name: "10", code: "10" },
    { name: "15", code: "15" },
    { name: "50", code: "50" },
    { name: "100", code: "100" },
  ];
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleUpdateCostmer, setVisibleUpdateCostmer] = useState(false);

  //state for listing customer
  const [customerList, setCustomerList] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [sortby, setSortby] = useState("");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [startdate, setStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingExportCsv, seloadingExportCsv] = useState(false);

  //get customer list
  const getCustomerList = useCallback(async () => {
    let object = {
      limit: limit,
      page: page,
      search: search,
      sortBy: sortby,
      orderBy: sortOrder,
      status: status,
      start_date: startdate ? startdate : "",
      end_date: enddate ? enddate : "",
    };
    dispatch(changeLoader(true));
    const objString = "?" + new URLSearchParams(object).toString();
    await globalRequestAxios(
      "get",
      {},
      AuthRequests.GET_ALL_CUSTOMERS + objString
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setCustomerList(res.data);
          setTotal(res?.count ? +res.count : 0);
        } else {
          setCustomerList([]);
          setTotal(0);
        }
        setHasValueChange(false);
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
        setHasValueChange(false);
      });
  }, [limit, page, search, sortby, sortOrder, status, startdate, enddate]);

  //debouncing for search
  const [hasValueChanged, setHasValueChange] = useState(false);
  const debounceTimeout = useRef(null);
  useEffect(() => {
    setHasValueChange(true);
  }, [limit, page, search, sortby, sortOrder, status, startdate, enddate]);

  useEffect(() => {
    if (hasValueChanged) {
      clearTimeout(debounceTimeout.current);
      debounceTimeout.current = setTimeout(() => {
        getCustomerList();
      }, 500);
    }
  }, [hasValueChanged, getCustomerList]);

  //sort function
  const sortFunction = (sortType) => {
    setSortby(sortType);
    if (sortOrder == "ASC") {
      setSortOrder("DESC");
    } else {
      setSortOrder("ASC");
    }
  };
  //download csv file
  const handleExportCsv = async () => {
    seloadingExportCsv(true);
    let object = {
      search: search,
      sortBy: sortby,
      orderBy: sortOrder,
      status: status,
      start_date: startdate ? startdate : "",
      end_date: enddate ? enddate : "",
    };
    dispatch(changeLoader(true));
    const objString = "?" + new URLSearchParams(object).toString();
    await globalRequestAxios(
      "get",
      {},
      AuthRequests.EXPORT_CUSTOMER_CSV + objString
    )
      .then((res) => {
        dispatch(changeLoader(false));
        seloadingExportCsv(false);
        if (res.ack == 1) {
          downloadCsv(res.data);
        } else {
          setAlert("Something went wrong", "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        seloadingExportCsv(false);
        setAlert("Something went wrong", "error");
      });
  };
  const downloadCsv = (data) => {
    // Define the keys to include in the CSV
    const keys = [
      "first_name",
      "last_name",
      "email",
      "mobile_no",
      // "role",
      "status",
      "createdAt",
      "orderTableCount",
    ];
    const ff = [
      "First name",
      "Last name",
      "Email",
      "Mobile number",
      // "role",
      "Status",
      "Registered at",
      "Total order",
    ];
    // Convert array of objects data to CSV format
    const csvString = convertToCsv(data, keys, ff);
    var universalBOM = "\uFEFF";
    var a = window.document.createElement("a");
    a.setAttribute(
      "href",
      "data:text/csv; charset=utf-8," +
        encodeURIComponent(universalBOM + csvString)
    );
    a.setAttribute("download", "Customer.csv");
    window.document.body.appendChild(a);
    a.click();
  };
  const convertToCsv = (data, keys, ff) => {
    // Define the headers for the CSV file
    const headers = ff.join(",");
    // Map over each object in the data array and convert it to a CSV row
    const rows = data.map((obj) => {
      // Extract the values for the desired keys
      // const values = keys.map(key => obj[key])
      const values = keys.map((key) => {
        if (key == "orderTableCount") {
          if (obj[key]) {
            return obj[key];
          } else {
            return "0";
          }
        }
        if (key === "createdAt") {
          // Add special condition for "created_at" key
          // const data = dateFormat(obj[key]);
          const date = formatDate(obj[key]);
          return date;
        } else {
          // Retrieve the value from the object for all other keys
          return obj[key];
        }
      });
      // Convert the values to a CSV row
      return values.join(",");
    });
    // Combine the headers and rows into a single CSV string
    return `${headers}\n${rows.join("\n")}`;
  };

  //Update customer states
  const [cust_first_name, setCust_first_name] = useState("");
  const [cust_last_name, setCust_last_name] = useState("");
  const [cust_mobile_no, setCust_mobile_no] = useState("");
  const [cust_status, setCust_status] = useState(null);
  const [cust_id, setCust_id] = useState("");
  const [customerAddress, setCustomersAddress] = useState([]);
  //state for error
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [statusErr, setStatusErr] = useState("");
  //state for customer detail show
  const [customerDetail, setCustomerDetail] = useState([]);
  //get single customer detail
  const getSingleCustomerDetail = async (id, type) => {
    dispatch(changeLoader(true));
    await globalRequestAxios(
      "get",
      {},
      AuthRequests.GET_SINGLE_CUSTOMER + `${id}`
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          if (type == "edit") {
            setCust_first_name(res?.data?.first_name);
            setCust_last_name(res?.data?.last_name);
            if (res?.data?.mobile_no && res?.data?.mobile_no != "0") {
              setCust_mobile_no("+" + res?.data?.mobile_no);
            }
            setCust_status({
              name: res?.data?.status == "active" ? "Active" : "Inactive",
              code: res?.data?.status,
            });
            setCust_id(res?.data?.id);
            // setAdd_1(res?.data?.address?.add_1);
            // setAdd_2(res?.data?.address?.add_2);
            setCustomersAddress(res?.data?.address_details || []);
            setVisibleUpdateCostmer(true);
          } else {
            setCustomerDetail(res.data);
            setVisibleRight(true);
          }
        } else {
          setCustomerDetail([]);
          setAlert("Something went wrong", "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setLoading(false);
        setAlert("Something went wrong", "error");
      });
  };

  //update customer
  const update_customer = async (customer, type) => {
    if (!type) return;
    let data = {};
    if (type == "edit") {
      let error = false;
      if (!cust_first_name) {
        setFirstNameErr("Please enter first name");
        error = true;
      }
      if (!cust_last_name) {
        setLastNameErr("Please enter last name");
        error = true;
      }
      if (cust_mobile_no) {
        if (!isValidPhoneNumber(cust_mobile_no)) {
          setMobileErr("Enter valid mobile number");
          error = true;
        }
      }
      if (cust_status == null) {
        setStatusErr("Please select status");
        error = true;
      }
      if (error) return;

      data = {
        id: cust_id.toString(),
        firstName: cust_first_name,
        lastName: cust_last_name,
        mobileNo: cust_mobile_no.substring(1), //remove + from mobile number
        status: cust_status?.code == "active" ? "active" : "inactive",
      };
    } else if (type == "status") {
      data = {
        id: customer.id.toString(),
        firstName: customer.first_name,
        lastName: customer.last_name,
        mobileNo: customer.mobile_no,
        status: customer.status == "active" ? "inactive" : "active",
      };
    }
    dispatch(changeLoader(true));
    await globalRequestAxios("post", data, AuthRequests.UPDATE_CUSTOMER_DETAILS)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          getCustomerList();
          setAlert(
            type == "edit"
              ? "Customer updated successfully"
              : "Status updated successfully",
            "success"
          );
          resetAll();
        } else {
          setAlert("Something went wrong", "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  ///reset customer states
  const resetAll = () => {
    setCust_first_name("");
    setCust_last_name("");
    setCust_mobile_no("");
    setCust_status("");
    setCust_id("");
    setFirstNameErr("");
    setLastNameErr("");
    setMobileErr("");
    setStatusErr("");
    setVisibleRight(false);
    setVisibleUpdateCostmer(false);
  };

  ///manage phone number input
  const handlePhoneNumberChange = (value) => {
    setMobileErr("");
    if (!value) {
      setCust_mobile_no("");
      return;
    }
    setCust_mobile_no(value);
  };

  ///pagination function
  const onPageChange = (event) => {
    setPage(event.page + 1);
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <>
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="tablefilterheader pb-1">
            <h4 className="h4 mb-0">Manage Customer</h4>
          </div>
          <div className="tablefilterSubheader">
            <div className="tableHeader">
              <div className="tableFilterRow" style={{ marginLeft: "0" }}>
                <div
                  className="tableFilterCol sortList"
                  style={{ marginLeft: "0" }}
                >
                  <Dropdown
                    value={selectList}
                    onChange={(e) => {
                      setSelectList(e.value);
                      setLimit(e.value.code);
                      setPage(1);
                      setFirst(0);
                    }}
                    options={Lists}
                    optionLabel="name"
                    placeholder="10"
                    className="filterDropdown"
                  />
                </div>
              </div>
              <div className="tableFilterRow">
                <div className="tableFilterCol colwidth250">
                  <Dropdown
                    value={selectedStatus}
                    onChange={(e) => {
                      setSelectedStatus(e.value);
                      setStatus(e.value.code);
                      setPage(1);
                      setFirst(0);
                    }}
                    options={Status}
                    optionLabel="name"
                    placeholder="Status"
                    className="filterDropdown"
                  />
                </div>
                <div className="tableFilterCol">
                  <div className="combDateBox">
                    <span className="c-label">Date</span>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      maxDate={enddate}
                      // minDate={new Date()}
                      value={startdate}
                      onChange={(e) => {
                        setStartDate(e.value);
                      }}
                      showIcon
                      placeholder="Start Date"
                    />
                    <span>-</span>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      minDate={startdate}
                      value={enddate}
                      onChange={(e) => {
                        setEndDate(e.value);
                      }}
                      showIcon
                      placeholder="End Date"
                    />
                  </div>
                </div>
                <div className="tableFilterCol">
                  {/* <Menu model={items} popup ref={menu} /> */}
                  <div className="exportbtn">
                    <Button
                      disabled={loadingExportCsv}
                      onClick={() => handleExportCsv()}
                      label="Export"
                      className="btn btn-blue"
                      icon="pi pi-file-excel"
                    />
                    {/* <img src={dawnArrowBlack} alt="arrow-down" className="down-arrow" /> */}
                  </div>
                </div>
                <div className="tableFilterCol">
                  <div
                    className="p-inputgroup searchFilter"
                    style={{ width: "275px" }}
                  >
                    <InputText
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value.trimStart());
                        setPage(1);
                        setFirst(0);
                      }}
                      placeholder="Search by name, email & mobile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid adminTableContent">
            <div className="tableContent">
              <table>
                <thead>
                  <tr>
                    <th>
                      <p className="tbleheading">
                        Customer
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("first_name")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Phone
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("mobile_no")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Registered date
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("createdAt")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Status
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("status")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Total order
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("orderTableCount")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>action</th>
                  </tr>
                </thead>
                <tbody>
                  {customerList?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          className="tba capitalize-first-letter"
                          onClick={() => getSingleCustomerDetail(item.id)}
                        >
                          {item?.first_name + " " + item?.last_name}
                        </a>
                        <p className="tbp mb-0">{item?.email}</p>
                      </td>
                      <td>
                        <span className="roleSpan roleBlue">
                          <i className="pi pi-phone"></i>
                        </span>{" "}
                        {item?.mobile_no && item?.mobile_no > 5
                          ? "+" + item?.mobile_no
                          : "N/A"}
                      </td>
                      <td>{dateFormat(item?.createdAt)}</td>
                      <td style={{ textAlign: "center" }}>
                        {/* <span
                          className={
                            item?.status == "active"
                              ? "chips success"
                              : "chips danger"
                          }
                        >
                          {item?.status}
                        </span> */}
                        <InputSwitch
                          checked={item?.status == "active" ? true : false}
                          onChange={(e) => update_customer(item, "status")}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item?.orderTableCount ? item?.orderTableCount : "0"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <div className="tdAction">
                          <a
                            className="TdICon"
                            onClick={() =>
                              getSingleCustomerDetail(item.id, "edit")
                            }
                          >
                            {" "}
                            <img
                              src={editIcon}
                              alt=""
                              className="AcIcon"
                            />{" "}
                          </a>
                          <a
                            className="TdICon"
                            onClick={() => getSingleCustomerDetail(item.id)}
                          >
                            {" "}
                            <img src={eyeIcon} alt="" className="AcIcon" />{" "}
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {(customerList?.length == 0  && !loader) ?   (
              <div
                style={{ textAlign: "center", width: "100%", padding: "20px" }}
              >
                <h4 className="h4 mb-0">{"No customer Found"}</h4>
              </div>
            ): null}
            {total > 0 && total > selectList.code && (
              <Paginator
                first={first}
                rows={rows}
                totalRecords={total}
                onPageChange={onPageChange}
                className="mt-2"
              />
            )}
          </div>
        </div>
      </section>
      {/* show customer details */}
      <Sidebar
        className="detailSiderbar"
        visible={visibleRight}
        position="right"
        onHide={() => resetAll()}
      >
        <h4 className="mt-0 sidebarTitleHd">Customer Detail</h4>
        <div className="sidebarContentBody">
          <div className="lg:col-12 md:col-12 sm:col-12 col-12">
            <div className="formField">
              <span className="pfloatlabel onlyReadForm">
                <label className="sepLable">Last Name</label>
                <InputText
                  id="FirstName"
                  autoComplete="off"
                  value={customerDetail?.last_name}
                />
              </span>
            </div>
          </div>
          <div className="lg:col-12 md:col-12 sm:col-12 col-12">
            <div className="formField">
              <span className="pfloatlabel onlyReadForm">
                <label className="sepLable">First Name</label>
                <InputText
                  id="LastName"
                  autoComplete="off"
                  value={customerDetail?.first_name}
                />
              </span>
            </div>
          </div>

          <div className="lg:col-12 md:col-12 sm:col-12 col-12">
            <div className="formField">
              <span className="pfloatlabel onlyReadForm">
                <label className="sepLable">Email Address</label>
                <InputText
                  id="LastName"
                  autoComplete="off"
                  value={customerDetail?.email}
                />
              </span>
            </div>
          </div>

          <div className="lg:col-12 md:col-12 sm:col-12 col-12">
            <div className="formField">
              <span className="pfloatlabel onlyReadForm">
                <label className="sepLable">PHONE</label>
                <InputText
                  id="FirstName"
                  autoComplete="off"
                  value={
                    customerDetail?.mobile_no &&
                    (customerDetail?.mobile_no).toString()?.length > 5
                      ? "+" + customerDetail?.mobile_no
                      : "N/A"
                  }
                />
              </span>
            </div>
          </div>

          <div className="lg:col-12 md:col-12 sm:col-12 col-12">
            <div className="formField">
              <span className="pfloatlabel onlyReadForm">
                <label className="sepLable">REGISTERED DATE</label>
                <InputText
                  id="FirstName"
                  autoComplete="off"
                  value={dateFormat(customerDetail?.createdAt)}
                />
              </span>
            </div>
          </div>

          <div className="lg:col-12 md:col-12 sm:col-12 col-12">
            <div className="formField">
              <span className="pfloatlabel onlyReadForm">
                <label className="sepLable">TOTAL ORDER</label>
                <InputText
                  id="LastName"
                  autoComplete="off"
                  value={
                    customerDetail?.orderTableCount
                      ? customerDetail.orderTableCount
                      : "0"
                  }
                />
              </span>
            </div>
          </div>
          <div className="lg:col-12 md:col-12 sm:col-12 col-12">
            <div className="formField">
              <span className="pfloatlabel onlyReadForm">
                <label className="sepLable">Status</label>
                <InputText
                  id="Status"
                  autoComplete="off"
                  value={customerDetail?.status}
                />
                {/* <Dropdown value={selectedStatus1} onChange={(e) => setSelectedStatus1(e.value)} options={Status1} optionLabel="name" placeholder="Active" className="filterDropdown" /> */}
              </span>
            </div>
          </div>

          {customerDetail?.address_details?.length > 0 &&
            customerDetail?.address_details?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="lg:col-12 md:col-12 sm:col-12 col-12"
                >
                  <div className="formField">
                    <span className="pfloatlabel onlyReadForm">
                      <label className="sepLable">
                        Shipping Address {index + 1}
                      </label>
                      <span className="textarea" role="textbox">
                        {item?.address ? `${item?.address}` : ""}
                        {item?.address2 ? `, ${item?.address2}` : ""}
                        {item?.city ? `, ${item?.city}` : ""}
                        {item?.state ? `, ${item?.state}` : ""}
                        {item?.country ? `, ${item?.country}` : ""}
                        {item?.postal_code ? `, ${item?.postal_code}` : ""}
                      </span>
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </Sidebar>
      {/* update customer details */}
      <Sidebar
        className="detailSiderbar"
        visible={visibleUpdateCostmer}
        position="right"
        onHide={() => resetAll()}
      >
        <h4 className="mt-0 sidebarTitleHd">Customer Edit</h4>
        <div className="sidebarContentBody">
          <div className="lg:col-12 md:col-12 sm:col-12 col-12">
            <div className="formField">
              <span className="pfloatlabel ">
                <label className="sepLable">
                  First Name <span className="requiredstar">*</span>
                </label>
                <InputText
                  style={{ borderColor: firstNameErr ? "#f55e71" : "" }}
                  id="LastName"
                  autoComplete="off"
                  value={cust_first_name}
                  onChange={(e) => {
                    setFirstNameErr("");
                    setCust_first_name(e.target.value);
                  }}
                />
                {firstNameErr && (
                  <div className="p-error">{t(firstNameErr)}</div>
                )}
              </span>
            </div>
          </div>
          <div className="lg:col-12 md:col-12 sm:col-12 col-12">
            <div className="formField">
              <span className="pfloatlabel ">
                <label className="sepLable">
                  Last Name <span className="requiredstar">*</span>
                </label>
                <InputText
                  style={{ borderColor: lastNameErr ? "#f55e71" : "" }}
                  id="FirstName"
                  autoComplete="off"
                  value={cust_last_name}
                  onChange={(e) => {
                    setLastNameErr("");
                    setCust_last_name(e.target.value);
                  }}
                />
                {lastNameErr && <div className="p-error">{t(lastNameErr)}</div>}
              </span>
            </div>
          </div>

          <div className="lg:col-12 md:col-12 sm:col-12 col-12">
            <label className="sepLable">PHONE</label>
            <PhoneInput
              style={{
                border: mobileErr ? "1px solid #f55e71" : null,
              }}
              className="sepLable"
              international
              defaultCountry="AE"
              value={cust_mobile_no}
              onChange={handlePhoneNumberChange}
              placeholder="Enter Mobile Number"
            />
            {mobileErr && <div className="p-error">{t(mobileErr)}</div>}
          </div>

          <div className="lg:col-12 md:col-12 sm:col-12 col-12">
            <div className="formField">
              <span className="pfloatlabel ">
                <label className="sepLable">
                  Status <span className="requiredstar">*</span>
                </label>
                <Dropdown
                  style={{ borderColor: statusErr ? "#f55e71" : null }}
                  value={cust_status}
                  onChange={(e) => setCust_status(e.value)}
                  options={Status1}
                  optionLabel="name"
                  className="filterDropdown"
                />
              </span>
            </div>
          </div>

          {customerAddress?.length > 0 &&
            customerAddress?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="lg:col-12 md:col-12 sm:col-12 col-12"
                >
                  <div className="formField">
                    <span className="pfloatlabel onlyReadForm">
                      <label className="sepLable">
                        Shipping Address {index + 1}
                      </label>
                      <span className="textarea" role="textbox">
                        {item?.address ? `${item?.address}` : ""}
                        {item?.address2 ? `, ${item?.address2}` : ""}
                        {item?.city ? `, ${item?.city}` : ""}
                        {item?.state ? `, ${item?.state}` : ""}
                        {item?.country ? `, ${item?.country}` : ""}
                        {item?.postal_code ? `, ${item?.postal_code}` : ""}
                      </span>
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="sidebarFooter">
          <div className="btnsRow">
            <Button className="btn btn-gray" onClick={() => resetAll()}>
              Cancel
            </Button>
            <Button
              onClick={() => update_customer({}, "edit")}
              className="btn btn-blue"
            >
              Save
            </Button>
          </div>
        </div>
      </Sidebar>
    </>
  );
};
