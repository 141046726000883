//authrquest
export const AuthRequests = {
  //authentification api's
  ADMIN_LOGIN: "auth/admin-login",
  ADMIN_FORGOT_PASSWORD: "auth/admin-forgot-password",
  ADMIN_VERIFY_OTP: "auth/admin-email-verify-otp",
  ADMIN_FORGOT_PASSWORD_CHANGE: "auth/admin-forgot-password-change",
  ADMIN_CHANGE_PASSWORD: "admin/change-password",
  ADMIN_MFA_SEND_OTP: "auth/admin-send-otp-for-mfa",
  ADMIN_MFA_VERIFY_OTP: "auth/admin-verify-otp-for-mfa",
  //admin role management api's
  GET_ALL_ROLES: "role/get-roles",
  GET_SINGEL_ROLE: "role/get-role/",
  ADD_ROLE: "role/add-role",
  EDIT_ROLE: "role/update-role",
  DELETE_SINGLE_ROLE: "role/delete/",
  //sub admin management api's
  SUB_ADMIN_SET_PASSWORD: (TOKEN) => `sub_admin/set-password/${TOKEN}`,
  GET_ALL_SUB_ADMINS: "sub_admin/get-sub-admin",
  GET_SINGEL_SUB_ADMIN: "sub_admin/get-sub-admin-by-id/",
  ADD_SINGLE_SUB_ADMIN: "sub_admin/add-sub-admin",
  EDIT_SINGLE_SUB_ADMIN: "sub_admin/update-sub-admin",
  DELETE_SINGLE_SUB_ADMIN: "sub_admin/delete/",
  //coupon management api's
  GET_ALL_COUPONS: "coupons/get-coupons",
  ADD_SINGLE_COUPON: "coupons/add-coupon",
  DELETE_SINGLE_COUPON: "coupons/delete-coupon/",
  GET_SINGLE_COUPON: "coupons/get-coupon-by-id/",
  UPDATE_SINGLE_COUPON: "coupons/update-coupon",
  //customer management api's
  GET_ALL_CUSTOMERS: "customers/get-customers",
  GET_SINGLE_CUSTOMER: "customers/get-customer-by-id/",
  UPDATE_CUSTOMER_DETAILS: "customers/update-customer",
  EXPORT_CUSTOMER_CSV: "customers/export-customers",
  //category management api's
  GET_ALL_CATEGORIES: "category/get-all-category",
  DELETE_SPECIFICATION: "category/delete-specification",
  ADD_SPECIFICATION: "category/add-specification",
  UPLOAD_IMAGE: "category/update-category-image",
  UPLOAD_IMAGE_SUB_CATEGORY: "category/update-sub-category-image",
  GET_CATEGORIES_TO_SELECT: "category/get-category-names",
  GET_SUB_CATEGORIES_BY_CATEGORY_ID: "category/get-sub-category/",
  //product management api's
  GET_ALL_PRODUCTS: "products/get-products",
  GET_SINGLE_PRODUCT: "products/get-product/",
  GET_CATEGORIES: "category/get-all-category",
  DELETE_SINGLE_PRODUCT: "products/delete-product/",
  UPDATE_PRODUCT: "products/edit-product",
  UPDATE_PRODUCT_STATUS: "products/edit-product-status",
  UPDATE_PRODUCT_TODAYS_OFFER: "products/edit-product-offer",
  //bundle management api's
  GET_BUNDLE_PRODUCTS: "bundle/get-all-bundle",
  DELETE_BUNDLE_PRODUCT: "bundle/delete-bundle/",
  GET_PRODUCTS_BY_SUBCATEGORY_ID: "products/get-products-subcategory/",
  ADD_BUNDLE_PRODUCT: "bundle/add-bundle",
  GET_BUNDLE_DETAILS: "bundle/get-bundle/",
  UPDATE_BUNDLE_PRODUCT: "bundle/update-bundle",
  UPDATE_BUNDLE_STATUS: "bundle/update-bundle-status",
  // CMS management api's
  GET_CMS_DETAILS: "cms/get-cms",
  UPDATE_CMS_DETAILS: "cms/update-cms",
  GET_SOCIAL_MEDIA_LINKS: "cms/get-social-links",
  UPDATE_SOICAL_MEDIA_LINKS: "cms/update-social-links",
  GET_CONTACT_US_LIST: "cms/get-contact-us-list",
  //news letter management api's
  GET_NEWS_LETTER_LIST: "news/get-news-latter",
  DELETE_NEWSLETTER: (id) => `news/delete-news-latter/${id ? id : ""}`,
  //notify me list api
  NOTIFY_ME_LIST: "notifyMe/notify-me",
  //orders management api's
  GET_ALL_ORDERS: (query) => `order/get-orders${query ? query : ""}`,
  GET_ORDER_DETAILS: (id) => `order/get-order-details/${id ? id : ""}`,
  UPDATE_ORDER_STATUS: (id) => `order/update-order-status/${id ? id : ""}`,
  REFUND_ORDER_STATUS: (id) => `order/refunded-order-status/${id ? id : ""}`,
  RETURN_COLLECTED: "order/update-return-request-status",
  // Share User management api's
  SHARE_EMAIL: "coupons/share-voucher",
  // Address  management api's
  GET_ADDRESS: "address/get-pickup-address",
  UPDATE_ADDRESS: (id) => `address/update-pickup-address/${id ? id : ""}`,
  //currency converssion management api's
  GET_CURRENCY_DETAILS: "currencyCoversion/get-currency-conversion",
  UPDATE_CURRENCY_DETAILS: "currencyCoversion/update-currency-conversion",
  //dashboard management api's
  GET_ORDER_COUNTS: "dashboard/count-all-order",
  GET_TOP_SOLD_PRODUCT: "dashboard/get-top-10-products",
  GET_MAP_DATA: "dashboard/get-orders-lat-long",
  //contact information management api's
  GET_CONTACT_DETAILS: "contactInformation/contact-information",
  POST_CONTACT_DETAILS: "contactInformation/contact-information",
};

//local storage keys
export const LOCAL_STORAGE_KEYS = {
  USER_TOKEN: "JFDHUIDFGBSDFGSD", //loginToken
  DB_LOCATION: "FHJKASDFJSDJFJKH", //db_Location
  SESSION_TIME: "FHJKAYDJCJSDJFKH", //session_time
};
