import jwt_decode from "jwt-decode";
import addDeleteGetLocalStorage from "./addDeleteGetLocalStorage ";
import { LOCAL_STORAGE_KEYS } from "../pages/auth/AuthRequests";

const userToken = addDeleteGetLocalStorage(LOCAL_STORAGE_KEYS?.USER_TOKEN, {}, "get");

export const decoded = () => {
  let decode = null;
  if (!userToken) {
    decode = null;
  } else {
    decode = jwt_decode(userToken);
  }
};

export const decodedToken = (token) => {
  if (!token) {
    return null;
  } else {
    return jwt_decode(token);
  }
};
