import React, { useEffect } from "react";
import { decodedToken } from "./globalModules/tokenDecode";
import { useNavigate } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "./pages/auth/AuthRequests";
import addDeleteGetLocalStorage from "./globalModules/addDeleteGetLocalStorage ";

export const NotFound404 = () => {
  const navigate = useNavigate();
  // navigate to permission first page if user is already logged in
  const navigateToPermission = (token = null) => {
    if (token) {
      const decoded = decodedToken(token);
      if (!decoded) return false;
      if (decoded?.access === "admin") navigate("/dashboard");
      else if (decoded?.access === "subadmin") {
        let permissions = decoded?.rolepermissions;
        if (permissions?.length) {
          if (permissions.includes("dashboard")) navigate("/dashboard");
          else if (permissions.includes("customers"))
            navigate("/customer-listing");
          else if (permissions.includes("category"))
            navigate("/category-listing");
          else if (permissions.includes("product")) navigate("/product-list");
          else if (permissions.includes("order"))
            navigate("/order-history-listing");
          else if (permissions.includes("coupons"))
            navigate("/coupon-codes-list");
          else if (permissions.includes("bundle")) navigate("/bundle-listing");
          else if (permissions.includes("sub_admin"))
            navigate("/roles-listing");
          else if (permissions.includes("cms")) navigate("/shipping-policy");
          else if (permissions.includes("currency_tax"))
            navigate("/currency-conversion");
          else if (permissions.includes("newsletter_subscription"))
            navigate("/newsletter-list");
          else if (permissions.includes("contact_us_list"))
            navigate("/contact-us");
          else if (permissions.includes("system_settings"))
            navigate("/pickup-address");
        }
      }
    }else{
      navigate("/")
    }
  };

  useEffect(() => {
    const AdminAccess = addDeleteGetLocalStorage(
      LOCAL_STORAGE_KEYS?.USER_TOKEN,
      {},
      "get"
    );
    navigateToPermission(AdminAccess);
  });
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span style={{ fontSize: "100px", fontWeight: 600, color: "gray" }}>
        404
      </span>
    </div>
  );
};
