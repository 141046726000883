//convert image to base64
export const image_to_base64 = async (file) => {
  try {
    const resultBase64 = await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.onerror = (error) => {
        console.error(error);
        reject(
          new Error(
            "An error occurred. Please try again. The file might be corrupt."
          )
        );
      };
      fileReader.readAsDataURL(file);
    });

    return resultBase64;
  } catch (error) {
    console.error(error);
    throw new Error("An unexpected error occurred while processing the file.");
  }
};

//compress base64 image
export const reduce_image_file_size = async (
  base64Str,
  MAX_WIDTH = 650,
  MAX_HEIGHT = 650
) => {
  let resized_base64 = await new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL()); // this will return base64 image results after resize
    };
  });
  return resized_base64;
};
