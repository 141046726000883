/** @format */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { Paginator } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
// images
import sortIcon from "../../assets/images/structure/sortIcon.png";
import editIcon from "../../assets/images/structure/edit-32.png";
import deleteIcon from "../../assets/images/structure/delete-32.png";
import eyeIcon from "../../assets/images/structure/eye-32.png";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests, LOCAL_STORAGE_KEYS } from "../auth/AuthRequests";
import { ShowImage } from "../../popups/ShowImage/ShowImage";
import { imageBaseUrl } from "../../Helpers/HelperFunctions";
import noImage from "../../assets/images/products/noImage.webp";
import { InputSwitch } from "primereact/inputswitch";
import addDeleteGetLocalStorage from "../../globalModules/addDeleteGetLocalStorage ";

const BundleListing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleDeleteDialog, setDeleteDialog] = useState(false);

  const routeCreateBundle = () => {
    let path = `/bundle-add`;
    navigate(path);
  };
  const routeEditBundle = (id) => {
    let path = `/bundle-edit/${id}`;
    navigate(path);
  };

  const [selectStatus, setSelectStatus] = useState(null);
  const status = [
    { name: "ALL", code: "" },
    { name: "PUBLISH", code: "active" },
    { name: "UNPUBLISH", code: "inactive" },
  ];

  //intigration
  //states
  const [bundleList, setBundleList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("id");
  const [orderBy, setOrderBy] = useState("DESC");
  const [search, setSearch] = useState("");
  const [bundleStatus, setBundleStatus] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [showImgUrl, setShowImgUrl] = useState("");

  //get bundle list
  const getBundleList = useCallback(async () => {
    let location = addDeleteGetLocalStorage(
      LOCAL_STORAGE_KEYS?.DB_LOCATION,
      {},
      "get"
    );
    let object = {
      limit: limit,
      page: page,
      status: bundleStatus,
      sortBy: sortBy,
      orderBy: orderBy,
      search: search,
    };
    dispatch(changeLoader(true));
    const objString = "?" + new URLSearchParams(object).toString();
    await globalRequestAxios(
      "get",
      {},
      AuthRequests.GET_BUNDLE_PRODUCTS + objString
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack === 1) {
          setTotalRecords(res?.countall);
          setBundleList(res?.data);
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "Something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
      });
  });
  useEffect(() => {
    getBundleList();
  }, [limit, page, sortBy, orderBy, bundleStatus, search]);

  //debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      const context = this;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };
  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };
  const debouncedHandleInputChange = debounce(handleInputChange, 200);

  //get single bundle details
  const [singleBundleDetails, setSingleBundleDetails] = useState([]);
  const getSingleBundleDetails = async (id) => {
    dispatch(changeLoader(true));
    await globalRequestAxios("get", {}, AuthRequests.GET_BUNDLE_DETAILS + id)
      .then((res) => {
        if (res.ack == 1) {
          dispatch(changeLoader(false));
          setVisibleRight(true);
          setSingleBundleDetails(res?.data);
        } else {
          dispatch(changeLoader(false));
          dispatch(
            setSnackbar({
              isOpen: true,
              message: res.message,
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: res.message,
            state: "error",
          })
        );
      });
  };

  //delete product
  const [deleteBundleId, setDeleteBundleId] = useState(null);
  const deleteBundle = async (id) => {
    if (!id) return;
    dispatch(changeLoader(true));
    await globalRequestAxios(
      "delete",
      {},
      AuthRequests.DELETE_BUNDLE_PRODUCT + id
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          getBundleList();
          setDeleteDialog(false);
          dispatch(
            setSnackbar({
              isOpen: true,
              message: res.msg,
              state: "success",
            })
          );
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  };

  //update product status
  const updateProductStatus = useCallback((id, status) => {
    if (!id) return;
    dispatch(changeLoader(true));
    globalRequestAxios(
      "post",
      {
        status: status ? "active" : "inactive",
        id: id,
      },
      AuthRequests.UPDATE_BUNDLE_STATUS
    )
      .then((res) => {
        if (res.ack == 1) {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "Status updated successfully",
              state: "success",
            })
          );
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      })
      .finally(() => {
        dispatch(changeLoader(false));
        getBundleList();
      });
  });

  //sort function
  const sortFunction = (sortType) => {
    setSortBy(sortType);
    if (orderBy == "ASC") {
      setOrderBy("DESC");
    } else {
      setOrderBy("ASC");
    }
  };
  ///pagination function
  const onPageChange = (event) => {
    setPage(event.page + 1);
    setFirst(event.first);
    setRows(event.rows);
  };

  return (
    <>
      <section className="admin-content-wrapper">
        <div className="adminContent">
          <div className="tablefilterheader pb-1">
            <h4 className="h4 mb-0">Manage Bundle</h4>
          </div>
          <div className="tablefilterSubheader">
            <div className="tableHeader">
              <div className="tableFilterRow">
                <div className="tableFilterCol">
                  <Dropdown
                    value={selectStatus}
                    onChange={(e) => {
                      setSelectStatus(e.value);
                      setBundleStatus(e.value.code);
                    }}
                    options={status}
                    optionLabel="name"
                    placeholder="Status"
                    className="filterDropdown"
                  />
                </div>
                <div className="tableFilterCol">
                  <div className="p-inputgroup searchFilter">
                    <InputText
                      placeholder="Search by Bundle Name"
                      onChange={(e) => {
                        debouncedHandleInputChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="tableFilterCol">
                  <Button className="btn btn-blue" onClick={routeCreateBundle}>
                    <i className="pi pi-plus mr-2"></i> Create Bundle
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid adminTableContent">
            <div className="tableContent">
              <table>
                <thead>
                  <tr>
                    <th>
                      <p className="tbleheading">
                        Bundle Name
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("title");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Bundle Description
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("short_description");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Cost Price
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("cost_price");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Bundle Price
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("selling_price");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Discounted Price
                        <span
                          className="sort-arrow-table"
                          onClick={() => {
                            sortFunction("discount_price");
                          }}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>Status</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {bundleList?.length > 0 &&
                    bundleList?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            onClick={() => {
                              getSingleBundleDetails(item?.id);
                            }}
                          >
                            {item?.title}
                          </td>
                          <td className="description">
                            {item?.short_description}
                            {/* <div
                        className="term-cond-sec2 description"
                        dangerouslySetInnerHTML={{
                          __html: item?.short_description,
                        }}
                      ></div> */}
                          </td>
                          <td>USD {item?.cost_price.toFixed(2)}</td>
                          <td>USD {item?.selling_price.toFixed(2)}</td>
                          <td>USD {item?.discount_price.toFixed(2)}</td>
                          <td style={{ textAlign: "center" }}>
                            {/* {item?.status === "active" && (
                              <span className="chips delivered">Publish</span>
                            )}
                            {item?.status === "inactive" && (
                              <span className="chips cancelled">
                                Not Publish
                              </span>
                            )} */}
                            <InputSwitch
                              checked={item?.status == "active" ? true : false}
                              onChange={(e) => {
                                updateProductStatus(item?.id, e.value);
                              }}
                            />
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="tdAction">
                              <a
                                className="TdICon"
                                onClick={() => {
                                  routeEditBundle(item?.id);
                                }}
                              >
                                {" "}
                                <img
                                  src={editIcon}
                                  alt=""
                                  className="AcIcon"
                                />{" "}
                              </a>
                              <a
                                className="TdICon"
                                onClick={() => {
                                  setDeleteDialog(true);
                                  setDeleteBundleId(item?.id);
                                }}
                              >
                                {" "}
                                <img
                                  src={deleteIcon}
                                  alt=""
                                  className="AcIcon"
                                />{" "}
                              </a>
                              <a
                                className="TdICon"
                                onClick={() => {
                                  getSingleBundleDetails(item?.id);
                                }}
                              >
                                {" "}
                                <img
                                  src={eyeIcon}
                                  alt=""
                                  className="AcIcon"
                                />{" "}
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {totalRecords == 0 && !loader && (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: "20px",
                }}
              >
                <h4 className="h4 mb-0">{"No Products Found"}</h4>
              </div>
            )}
            {totalRecords > limit && (
              <Paginator
                first={first}
                rows={rows}
                totalRecords={totalRecords}
                className="mt-2"
                onPageChange={onPageChange}
              />
            )}
          </div>
        </div>
      </section>
      {/* product details */}
      <Sidebar
        className="detailSiderbar"
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h2 className="mt-0 sidebarTitleHd">Bundle Detail</h2>
        <div className="sidebarContentBody">
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel ">
                  <label className="sepLable">Bundle Name</label>
                  <div className="show-dataonly-static">
                    {singleBundleDetails?.bundleDetails?.title}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel ">
                  <label className="sepLable">Short Description</label>
                  <div className="show-dataonly-static">
                    {singleBundleDetails?.bundleDetails?.short_description ? (
                      <div
                        className="term-cond-sec2"
                        dangerouslySetInnerHTML={{
                          __html:
                            singleBundleDetails?.bundleDetails
                              ?.short_description,
                        }}
                      ></div>
                    ) : null}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel ">
                  <label className="sepLable">Long Description</label>
                  <div className="show-dataonly-static">
                    {singleBundleDetails?.bundleDetails?.long_description ? (
                      <div
                        className="term-cond-sec2"
                        dangerouslySetInnerHTML={{
                          __html:
                            singleBundleDetails?.bundleDetails
                              ?.long_description,
                        }}
                      ></div>
                    ) : null}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel ">
                  <label className="sepLable">Bundle Price</label>
                  <div className="show-dataonly-static">
                    {singleBundleDetails?.bundleDetails?.selling_price.toFixed(
                      2
                    )}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel ">
                  <label className="sepLable">Bundle Discounted Price</label>
                  <div className="show-dataonly-static">
                    {singleBundleDetails?.bundleDetails?.discount_price.toFixed(
                      2
                    )}
                  </div>
                </span>
              </div>
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <span className="pfloatlabel ">
                  <label className="sepLable">Status</label>
                  <div className="show-dataonly-static">
                    {singleBundleDetails?.bundleDetails?.status == "active"
                      ? "Publish"
                      : "Unpublish"}
                  </div>
                </span>
              </div>
            </div>
          </div>

          {singleBundleDetails?.bundleDetails?.product_images?.length > 0 && (
            <div className="detailTxtRow" style={{ flexWrap: "wrap" }}>
              <div className="detailTxtColLeft mb-3">
                Featured Images <span>:</span>
              </div>
              <div
                className="detailTxtColRight pl-0"
                style={{ minWidth: "100%" }}
              >
                <div className="uploadImageRow">
                  {singleBundleDetails?.bundleDetails?.product_images?.length >
                    0 &&
                    singleBundleDetails?.bundleDetails?.product_images.map(
                      (item, index) => {
                        return (
                          <div
                            key={index}
                            className="uploadImageCol uploadImagePreview"
                          >
                            {item?.is_featured == 1 && (
                              <>
                                <Tooltip target=".custom-target-icon" />
                                <i
                                  className="custom-target-icon pi pi-star-fill featureIcon p-text-secondary p-overlay-badge"
                                  data-pr-tooltip="Marked as featured"
                                  data-pr-position="top"
                                  data-pr-at="left+15 top-30"
                                  data-pr-my="center"
                                ></i>
                              </>
                            )}
                            <img
                              onClick={() => {
                                setShowImgUrl(item?.image_url);
                              }}
                              src={item?.image_url}
                              alt=""
                              className="productImage"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = noImage;
                              }}
                            />
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Sidebar>

      <Dialog
        className="dialogWidth400 headetPadding"
        header="Delete"
        visible={visibleDeleteDialog}
        style={{ width: "50vw" }}
        onHide={() => setDeleteDialog(false)}
      >
        <div className="addformDialog">
          <p className="p2 mb-4 mt-0" style={{ textAlign: "left" }}>
            Are you sure want to delete this1?
          </p>
          <div className="p2 dilogbtns">
            <Button
              className="btn btn-gray"
              onClick={() => {
                setDeleteDialog(false);
              }}
            >
              No
            </Button>
            <Button
              className="btn btn-blue"
              onClick={() => {
                deleteBundle(deleteBundleId);
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>

      {/* show img big */}
      {showImgUrl && (
        <ShowImage
          showImgUrl={showImgUrl}
          setShowImgUrl={setShowImgUrl}
          view={true}
        />
      )}
    </>
  );
};

export default BundleListing;
