/** @format */
import { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { Sidebar } from "primereact/sidebar";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import React from "react";
import { Tooltip } from "primereact/tooltip";
// images
import sortIcon from "../../assets/images/structure/sortIcon.png";
import addrole from "../../assets/images/structure/bg.png";
import editIcon from "../../assets/images/structure/edit-32.png";
import deleteIcon from "../../assets/images/structure/delete-32.png";
import eyeIcon from "../../assets/images/structure/eye-32.png";
import globalRequestAxios from "../../globalModules/globalRequestAxios";
import { AuthRequests } from "../auth/AuthRequests";
import { dateFormat, validateEmail } from "../../Helpers/HelperFunctions";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";

const RolesListing = ({ setAlert }) => {
  const dispatch = useDispatch();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const loader = useSelector(currentLoader);
  const [visibleAddRole, setVisibleAddRole] = useState(false);
  const [visibleEditRole, setVisibleEditRole] = useState(false);
  const [visibleDeleteDialog, setDeleteDialog] = useState(false);
  const [visibleRightEdit, setVisibleRightEdit] = useState(false);
  const [visibleRightAdd, setVisibleRightAdd] = useState(false);
  const [view_SubAdmin, setView_SubAdmin] = useState(false);
  const [selectRole, setSelectRole] = useState(null);

  const [selectStatus, setSelectStatus] = useState(null);
  const status = [
    { name: "All", code: "" },
    { name: "Active", code: "active" },
    { name: "Inactive", code: "inactive" },
  ];
  const [selectList, setSelectList] = useState({ name: "10", code: "10" });
  const Lists = [
    { name: "10", code: "10" },
    { name: "15", code: "15" },
    { name: "50", code: "50" },
    { name: "100", code: "100" },
  ];

  const [selecteAddStatus, setSelectedAddStatus] = useState(null);
  const AddStatus = [
    { name: "Active", code: "active" },
    { name: "Inactive", code: "inactive" },
  ];
  const [dbSelection, seDbSelection] = useState({
    iraq: false,
    uae: false,
  });
  const [selectedRoleDd, setSelectedRoleDd] = useState(null);

  //api integration start
  const [rolesList, setRolesList] = useState([]);
  const [item_data, setItemData] = useState({});
  const [item_name, setItemName] = useState("");
  const [item_pages, setItemPages] = useState([]);
  //add edit rolelist
  const [add_edit_role_list, setAddEditRoleList] = useState([]);
  //errors
  const [item_name_error, setItemNameError] = useState("");

  let arrayOfRouts = [
    "dashboard",
    "customer-listing",
    "category-listing",
    "product-list",
    "order-history-listing",
    "coupon-codes-list",
    "bundle-listing",
    "roles-listing",
    "shipping-policy",
    "currency-conversion",
    "newsletter-list",
    "notify-me",
    "contact-us",
    "pickup-address",
  ];

  let r_ = [
    { id: 1, name: "Dashboard", isChecked: false, key: "dashboard" },
    { id: 2, name: "Customers", isChecked: false, key: "customers" },
    { id: 3, name: "Category", isChecked: false, key: "category" },
    { id: 4, name: "Product", isChecked: false, key: "product" },
    { id: 5, name: "Order", isChecked: false, key: "order" },
    { id: 6, name: "Coupons and Vouchers", isChecked: false, key: "coupons" },
    { id: 7, name: "Bundle", isChecked: false, key: "bundle" },
    { id: 8, name: "Sub Admin", isChecked: false, key: "sub_admin" },
    { id: 9, name: "CMS", isChecked: false, key: "cms" },
    { id: 10, name: "Currency & Tax", isChecked: false, key: "currency_tax" },
    {
      id: 11,
      name: "Newsletter subscription",
      isChecked: false,
      key: "newsletter_subscription",
    },
    {
      id: 12,
      name: "Notify Me",
      isChecked: false,
      key: "notify_me",
    },
    {
      id: 13,
      name: "Contact-Us List",
      isChecked: false,
      key: "contact_us_list",
    },
    {
      id: 14,
      name: "System Settings",
      isChecked: false,
      key: "system_settings",
    },
  ];
  const [role_list, setRole_list] = useState(r_);

  //set initial state
  const setInitialState = () => {
    setItemData({});
    setItemName("");
    setItemPages([]);
    setRole_list(r_);
    setItemNameError("");
  };

  //get all roles list
  const [select_role_list, setSelectRoleList] = useState([]);
  const get_all_roles_list = async () => {
    dispatch(changeLoader(true));
    await globalRequestAxios("get", {}, AuthRequests.GET_ALL_ROLES)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          const result = res.data.map(
            ({ id, name, pages, assignRolesCount }) => ({
              id,
              name,
              pages,
              assignRolesCount,
            })
          ); //{id: 1, name: "Admin", pages: "1,2"}
          const result1 = res.data.map(({ id, name, pages }) => ({
            id,
            name,
            pages,
          })); //{id: 1, name: "Admin", pages: "1,2"}
          setAddEditRoleList([...result1]);
          setRolesList([...result]);
          setSelectRoleList([{ id: "", name: "All", pages: "" }, ...result]);
        } else {
          dispatch(
            setSnackbar({
              isOpen: true,
              message: "something went wrong",
              state: "error",
            })
          );
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(changeLoader(false));
      });
  };
  // get single role for edit
  const get_single_role = async (id) => {
    dispatch(changeLoader(true));
    await globalRequestAxios("get", {}, AuthRequests.GET_SINGEL_ROLE + id)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setItemData(res.data);
          setItemName(res.data.name);
          let pages = res.data.pages.split(",");
          setItemPages(pages);
          let temp = [...role_list];
          temp.map((item, index) => {
            if (pages.includes(item.key.toString())) {
              item.isChecked = true;
            } else {
              item.isChecked = false;
            }
          });
          setRole_list(temp);
          setVisibleEditRole(true); //open edit dialog
        } else {
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          setSnackbar({
            isOpen: true,
            message: "something went wrong",
            state: "error",
          })
        );
      });
  };

  //add role
  const add_role = async () => {
    let error = false;
    if (item_name.length > 100) {
      setItemNameError("Role name must be less than 100 characters");
      error = true;
    }
    if (!item_name) {
      setItemNameError("Please enter role name");
      error = true;
    }
    if (role_list.filter((item) => item.isChecked).length == 0 && !error) {
      setAlert("Please select at least one role permission", "error");
      error = true;
    }
    if (error) return;
    //add selected pages
    let pages = [];
    role_list.map((item, index) => {
      if (item.isChecked) {
        pages.push(item.key.toString());
      }
    });
    let data = {
      name: item_name,
      pages: JSON.stringify(pages),
    };
    dispatch(changeLoader(true));
    await globalRequestAxios("post", data, AuthRequests.ADD_ROLE)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setAlert("Role added successfully", "success");
          setVisibleAddRole(false);
          get_all_roles_list();
        } else {
          if (res?.msg) {
            setAlert(res?.msg, "error");
          } else if (res?.errMsg?.length > 0) {
            setAlert(res?.errMsg[0]?.name, "error");
          } else {
            setAlert("Something went wrong", "error");
          }
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };
  //edit role
  const edit_role = async () => {
    if (!item_name) {
      setItemNameError("Please enter role name");
      return;
    }
    //add selected pages
    let pages = [];
    role_list.map((item, index) => {
      if (item.isChecked) {
        pages.push(item.key);
      }
    });
    let data = {
      name: item_name,
      pages: JSON.stringify(pages),
      role_id: item_data.id,
    };
    dispatch(changeLoader(true));
    await globalRequestAxios("post", data, AuthRequests.EDIT_ROLE)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setVisibleEditRole(false);
          setAlert("Role updated successfully", "success");
          get_all_roles_list();
        } else {
          if (res?.msg) {
            setAlert(res?.msg, "error");
          } else if (res?.errMsg?.length > 0) {
            setAlert(res?.errMsg[0]?.name, "error");
          } else {
            setAlert("Something went wrong", "error");
          }
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  //=====subAdmin management=====//listign states
  const [subAdminList, setSubAdminList] = useState(null);
  const [search_name, setSearchName] = useState("");
  const [get_status, setGetStatus] = useState("");
  const [get_role_id, setGetRoleId] = useState("");
  const [get_limit, setGetLimit] = useState(10);
  const [get_page, setGetPage] = useState(1);
  const [total_records, setTotalRecords] = useState(0);
  const [sort_by, setSortBy] = useState("id");
  const [sort_order, setSortOrder] = useState("DESC");

  //add sub admin states
  const [sa_name, setSaName] = useState("");
  const [sa_last_name, setSaLastName] = useState("");
  const [sa_email, setSaEmail] = useState("");
  const [sa_status, setSaStatus] = useState("");
  const [sa_role_id, setSaRoleId] = useState("");
  //errors
  const [sa_name_error, setSaNameError] = useState("");
  const [sa_last_name_error, setSaLastNameError] = useState("");
  const [sa_email_error, setSaEmailError] = useState("");
  const [sa_role_id_error, setSaRoleIdError] = useState("");
  const [db_error, setDbError] = useState("");
  const [sa_status_error, setSaStatusError] = useState("");

  //reset add sub admin states
  const resetAddSubAdminStates = () => {
    setSaName("");
    setSaLastName("");
    setSaEmail("");
    setSaStatus("");
    setSaRoleId("");
    setSaNameError("");
    setSaLastNameError("");
    setSaEmailError("");
    setSaRoleIdError("");
    setSaStatusError("");
    setSelectedAddStatus(null);
    setSelectedRoleDd(null);
    seDbSelection({
      uae: false,
      iraq: false,
    });
  };

  //common error function
  const commonError = (error) => {
    if (!sa_name) {
      setSaNameError("Please enter name");
      error = true;
    }
    if (!sa_last_name) {
      setSaLastNameError("Please enter last name");
      error = true;
    }
    if (!sa_email) {
      setSaEmailError("Please enter email");
      error = true;
    } else if (!validateEmail(sa_email)) {
      setSaEmailError("Invalid Email address");
      error = true;
    }
    if (!sa_role_id) {
      setSaRoleIdError("Please select role");
      error = true;
    }
    if (!dbSelection?.iraq && !dbSelection?.uae) {
      setDbError("Please select at least one database");
      error = true;
    }
    if (!sa_status) {
      setSaStatusError("Please select status");
      error = true;
    }
    return error;
  };

  //add sub admin
  const add_sub_admin = async () => {
    let error = false;
    error = commonError(error);
    if (error) return;
    let selectedDB =
      dbSelection?.uae && dbSelection?.iraq
        ? "both"
        : dbSelection?.uae
        ? "dubai"
        : "baghdad";
    let data = {
      first_name: sa_name,
      last_name: sa_last_name,
      email: sa_email,
      status: sa_status,
      role_id: JSON.stringify([sa_role_id.toString()]),
      country: selectedDB,
    };
    dispatch(changeLoader(true));
    await globalRequestAxios("post", data, AuthRequests.ADD_SINGLE_SUB_ADMIN)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          resetAddSubAdminStates();
          setVisibleRightAdd(false);
          get_all_sub_admin_list();
          setAlert("Sub admin added successfully", "success");
        } else {
          if (res.errMsg[0].email !== "") {
            setAlert(res.errMsg[0].email, "error");
          } else {
            setAlert("Something went wrong", "error");
          }
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  //edit sub admin
  const [subAdmin_id, setSubAdminId] = useState("");
  const edit_sub_admin = async () => {
    let error = false;
    error = commonError(error);
    if (error) return;
    let selectedDB =
      dbSelection?.uae && dbSelection?.iraq
        ? "both"
        : dbSelection?.uae
        ? "dubai"
        : "baghdad";
    let data = {
      first_name: sa_name,
      last_name: sa_last_name,
      email: sa_email,
      status: sa_status,
      role_id: JSON.stringify([sa_role_id.toString()]),
      id: subAdmin_id.toString(),
      country: selectedDB,
    };
    dispatch(changeLoader(true));
    await globalRequestAxios("post", data, AuthRequests.EDIT_SINGLE_SUB_ADMIN)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          resetAddSubAdminStates();
          setVisibleRightEdit(false);
          setVisibleRightAdd(false);
          get_all_sub_admin_list();
          get_all_roles_list();
          setAlert("Sub admin updated successfully", "success");
        } else {
          setAlert("Something went wrong", "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  //update sub admin status
  const update_sub_admin_status = async (ele, status) => {
    let data = {
      first_name: ele?.first_name,
      last_name: ele?.last_name,
      email: ele?.email,
      status: status,
      role_id: JSON.stringify([ele?.assign_roles[0]?.role_id.toString()]),
      id: ele?.id.toString(),
    };
    dispatch(changeLoader(true));
    await globalRequestAxios("post", data, AuthRequests.EDIT_SINGLE_SUB_ADMIN)
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          get_all_sub_admin_list();
          setAlert("Sub admin status updated successfully", "success");
        } else {
          setAlert("Something went wrong", "error");
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  //get single sub admin details
  const get_single_sub_admin_details = async (id, show) => {
    dispatch(changeLoader(true));
    await globalRequestAxios(
      "get",
      {},
      AuthRequests.GET_SINGEL_SUB_ADMIN + `${id}`
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res?.ack == 1) {
          setSubAdminId(res?.data?.id);
          setSaName(res?.data?.first_name);
          setSaLastName(res?.data?.last_name);
          setSaEmail(res?.data?.email);
          if (res?.data?.status == "active")
            setSelectedAddStatus({ name: "Active", code: "active" });
          else setSelectedAddStatus({ name: "Inactive", code: "inactive" });
          setSaStatus(res?.data?.status);
          setSelectedRoleDd({ ...res?.data?.assign_roles[0]?.role });
          setSaRoleId(res?.data?.assign_roles[0]?.role?.id);
          if (show == "edit") setVisibleRightEdit(true);
          else if (show == "view") setView_SubAdmin(true);
          setVisibleRightAdd(true);
          let db_ =
            res?.data?.country === "both"
              ? { uae: true, iraq: true }
              : res?.data?.country === "dubai"
              ? { uae: true, iraq: false }
              : { uae: false, iraq: true };
          seDbSelection(db_);
        } else {
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  //delete sub admin and role
  const [dele_id, setDeleId] = useState("");
  const [dele_type, setDeleType] = useState("");
  const delete_confirmation = (type, id) => {
    if (type == "role") setDeleType(type);
    else if (type == "sub_admin") setDeleType(type);
    setDeleId(id);
    setDeleteDialog(true);
  };
  const delete_sub_admin = async () => {
    dispatch(changeLoader(true));
    await globalRequestAxios(
      "delete",
      {},
      AuthRequests.DELETE_SINGLE_SUB_ADMIN + `${dele_id}`
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          get_all_sub_admin_list();
          get_all_roles_list();
          setAlert("Sub admin deleted successfully", "success");
          setDeleId("");
          setDeleteDialog(false);
        } else {
          setAlert("Something went wrong", "error");
          setDeleteDialog(false);
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };
  const delete_role = async () => {
    dispatch(changeLoader(true));
    await globalRequestAxios(
      "delete",
      {},
      AuthRequests.DELETE_SINGLE_ROLE + `${dele_id}`
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          get_all_roles_list();
          setAlert("Role deleted successfully", "success");
          setDeleId("");
          setDeleteDialog(false);
        } else {
          if (res?.msg == "Role is assigned to some user.") {
            setAlert(
              "Role cannot be deleted as sub-admin is already associated with this role",
              "error"
            );
          }
          setDeleteDialog(false);
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        setAlert("Something went wrong", "error");
      });
  };

  //get all sub admin list
  const get_all_sub_admin_list = async () => {
    let object = {
      status: get_status,
      role_id: get_role_id,
      limit: get_limit,
      page: get_page,
      search: search_name,
      sortBy: sort_by,
      orderBy: sort_order,
    };
    const objString = "?" + new URLSearchParams(object).toString();
    dispatch(changeLoader(true));
    await globalRequestAxios(
      "get",
      {},
      AuthRequests.GET_ALL_SUB_ADMINS + objString
    )
      .then((res) => {
        dispatch(changeLoader(false));
        if (res.ack == 1) {
          setSubAdminList(res.data);
          setTotalRecords(res.allTotal);
        } else {
          setSubAdminList([]);
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
      });
  };

  //onload get all roles list
  useEffect(() => {
    get_all_roles_list();
  }, []);
  useEffect(() => {
    get_all_sub_admin_list();
  }, [
    search_name,
    get_status,
    get_role_id,
    get_limit,
    get_page,
    sort_order,
    sort_by,
  ]);

  //sort function
  const sortFunction = (sortType) => {
    setSortBy(sortType);
    if (sort_order == "ASC") {
      setSortOrder("DESC");
    } else {
      setSortOrder("ASC");
    }
  };

  ///pagination function
  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setGetPage(event.page + 1);
  };

  return (
    <>
      <section className="admin-content-wrapper">
        <h4 className="h4 mt-4 "> Roles</h4>
        <div className="grid roleboxesrow">
          {rolesList.length > 0 &&
            rolesList.map((item, index) => {
              return (
                <div className="lg:col-4 md:col-4 sm:col-12 col-12" key={index}>
                  <div className="roleboxesCol">
                    <h6 className="h6">
                      Total {item?.assignRolesCount ? item.assignRolesCount : 0}{" "}
                      Sub Admin
                    </h6>
                    <Tooltip target=".custom-target-icon" />
                    <h3
                      className="h4 custom-target-icon featureIcon p-text-secondary p-overlay-badge"
                      data-pr-tooltip={item?.name}
                      data-pr-position="top"
                      data-pr-at="left+15 top-30"
                      data-pr-my="center"
                    >
                      {item?.name}
                    </h3>
                    <p
                      className="mb-0"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <a
                        onClick={() => {
                          get_single_role(item.id);
                        }}
                      >
                        Edit Role
                      </a>
                      {/* <i className="pi pi-pi-trash"></i> */}
                      <i
                        className="pi pi-trash"
                        title="Delete"
                        onClick={() => delete_confirmation("role", item?.id)}
                      ></i>
                    </p>
                  </div>
                </div>
              );
            })}
          <div className="lg:col-4 md:col-4 sm:col-12 col-12">
            <div
              className="roleboxesCol addrolebox"
              style={{
                display: "flex",
                justifyContent: "end",
                textAlign: "right",
              }}
            >
              <img src={addrole} alt="" className="addrole" />
              <div>
                <Button
                  className="btn btn-blue"
                  onClick={() => {
                    setInitialState();
                    setVisibleAddRole(true);
                  }}
                >
                  Add New Role
                </Button>
                <p className="mb-0">Add role, if it does not exist</p>
              </div>
            </div>
          </div>
        </div>

        <div className="adminContent">
          <div className="tablefilterheader pb-2">
            <div className="tableHeader">
              <h4 className="h4 mb-0">Sub Admins</h4>
              <div className="tableFilterRow">
                <div className="tableFilterCol" style={{ width: "auto" }}>
                  <Button
                    type="button"
                    className="btn btn-blue"
                    onClick={() => setVisibleRightAdd(true)}
                  >
                    Add Sub Admin
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="tablefilterSubheader">
            <div className="tableHeader">
              <div className="tableFilterRow" style={{ marginLeft: "0" }}>
                <div
                  className="tableFilterCol sortList"
                  style={{ marginLeft: "0" }}
                >
                  <Dropdown
                    value={selectList}
                    onChange={(e) => {
                      setGetLimit(+e.value.code);
                      setRows(+e.value.code);
                      setSelectList(e.value);
                      setGetPage(0);
                      setFirst(0);
                    }}
                    options={Lists}
                    optionLabel="name"
                    placeholder="10"
                    className="filterDropdown"
                  />
                </div>
              </div>
              <div className="tableFilterRow">
                <div className="tableFilterCol colwidth250">
                  <Dropdown
                    value={selectRole}
                    onChange={(e) => {
                      setGetPage(1);
                      setFirst(0);
                      setGetRoleId(e.value.id);
                      setSelectRole(e.value);
                    }}
                    options={select_role_list}
                    optionLabel="name"
                    placeholder="Role"
                    className="filterDropdown"
                  />
                </div>
                <div className="tableFilterCol colwidth250">
                  <Dropdown
                    value={selectStatus}
                    onChange={(e) => {
                      setGetPage(1);
                      setFirst(0);
                      setGetStatus(e.value.code);
                      setSelectStatus(e.value);
                    }}
                    options={status}
                    optionLabel="name"
                    placeholder="Status"
                    className="filterDropdown"
                  />
                </div>
                <div className="tableFilterCol">
                  <div className="p-inputgroup searchFilter">
                    <InputText
                      value={search_name}
                      onChange={(e) =>
                        setSearchName(e.target.value.trimStart())
                      }
                      placeholder="Search by Name and Email ID"
                    />
                  </div>
                </div>
                {/* <div className="tableFilterCol">
                      <Button className="btn btn-blue"  onClick={() => setVisibleRightAdd(true)}><i className="pi pi-plus mr-2"></i> Add Sub Admin</Button>
                    </div> */}
              </div>
            </div>
          </div>
          <div className="container-fluid adminTableContent">
            <div className="tableContent">
              <table>
                <thead>
                  <tr>
                    <th>
                      <p className="tbleheading">
                        Sub Admin
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("first_name")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Role
                        {/* <span className="sort-arrow-table">
                                    <img src={sortIcon} alt='' className="sortIconUp" />
                                    <img src={sortIcon} alt='' className="sortIconDown" />
                                </span> */}
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        CREATED DATE
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("createdAt")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th>
                      <p className="tbleheading">
                        Last Update
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("updatedAt")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>

                    <th style={{ textAlign: "center" }}>
                      <p className="tbleheading">
                        Status
                        <span
                          className="sort-arrow-table"
                          onClick={() => sortFunction("status")}
                        >
                          <img src={sortIcon} alt="" className="sortIconUp" />
                          <img src={sortIcon} alt="" className="sortIconDown" />
                        </span>
                      </p>
                    </th>
                    <th style={{ textAlign: "center" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {subAdminList?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <a className="tba">
                            {item?.first_name + " " + item.last_name}
                          </a>
                          <p className="tbp mb-0">{item?.email}</p>
                        </td>
                        <td
                          onClick={() => {
                            get_single_sub_admin_details(item.id, "view");
                          }}
                        >
                          <span className="roleSpan roleOrange">
                            <i className="pi pi-users"></i>
                          </span>{" "}
                          {item?.assign_roles[0]?.role?.name}{" "}
                          {/*changable classes ==> roleOrange, roleBlue,rolskyblue,rolskygreen */}
                        </td>
                        <td>{dateFormat(item?.createdAt)}</td>
                        <td>{dateFormat(item?.updatedAt)} </td>
                        <td style={{ textAlign: "center" }}>
                          {/* <span className={item?.status=="active"?"chips success":"chips danger"}>{item?.status}</span> */}
                          <InputSwitch
                            checked={item?.status == "active" ? true : false}
                            onChange={(e) => {
                              update_sub_admin_status(
                                item,
                                item?.status == "active" ? "inactive" : "active"
                              );
                            }}
                          />
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <div className="tdAction">
                            <a
                              className="TdICon"
                              onClick={() => {
                                get_single_sub_admin_details(item?.id, "edit");
                              }}
                            >
                              {" "}
                              <img
                                src={editIcon}
                                alt=""
                                className="AcIcon"
                              />{" "}
                            </a>
                            <a
                              className="TdICon"
                              onClick={() =>
                                delete_confirmation("sub_admin", item?.id)
                              }
                            >
                              {" "}
                              <img
                                src={deleteIcon}
                                alt=""
                                className="AcIcon"
                              />{" "}
                            </a>
                            <a
                              className="TdICon"
                              onClick={() => {
                                get_single_sub_admin_details(item.id, "view");
                              }}
                            >
                              {" "}
                              <img
                                src={eyeIcon}
                                alt=""
                                className="AcIcon"
                              />{" "}
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {subAdminList?.length == 0 && !loader ? (
              <div
                style={{ textAlign: "center", width: "100%", padding: "20px" }}
              >
                <h4 className="h4 mb-0">{"No Sub Admin Found"}</h4>
              </div>
            ) : null}
            {total_records > 0 && selectList.code < total_records && (
              <Paginator
                first={first}
                rows={rows}
                totalRecords={total_records}
                onPageChange={onPageChange}
                className="mt-2"
              />
            )}
          </div>
        </div>
      </section>

      {/* add role */}
      <Sidebar
        className="detailSiderbar"
        visible={visibleAddRole}
        position="right"
        onHide={() => {
          setInitialState();
          setVisibleAddRole(false);
        }}
      >
        <h4 className="sidebarTitleHd">Add Role</h4>
        <div className="sidebarContentBody">
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <div className="pfloatlabel">
                  <label className="sepLable">
                    Role Name<span className="requiredstar">*</span>
                  </label>
                  <InputText
                    //  className={item_name_error ? 'p-invalid' : ''}
                    style={{ borderColor: item_name_error ? "#ff6b68" : "" }}
                    id="rolename"
                    value={item_name}
                    onChange={(e) => {
                      setItemNameError("");
                      setItemName(e.target.value.trimStart());
                      if (e.target.value.trimStart().length > 100) {
                        setItemNameError(
                          "Role name must be less than 100 characters"
                        );
                      }
                    }}
                    autoComplete="off"
                    placeholder="Enter a role name"
                  />
                  {item_name_error && (
                    <div className="p-invalid">{item_name_error}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid adminTableContent">
            <label className="sepLable mt-3 mb-3">
              Role Permissions<span className="requiredstar">*</span>
            </label>
            <div className="tableContent mb-3 addrpleTable">
              <table>
                <thead>
                  <tr>
                    <th>Role </th>
                    <th style={{ textAlign: "center" }}>Select</th>
                  </tr>
                </thead>
                <tbody>
                  {role_list.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td style={{ textAlign: "center" }}>
                          <Checkbox
                            id="role1"
                            onChange={(e) => {
                              let temp = [...role_list];
                              temp[index].isChecked = e.checked;
                              setRole_list(temp);
                            }}
                            checked={item.isChecked}
                          ></Checkbox>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="sidebarFooter">
          <div className="btnsRow">
            <Button
              className="btn btn-gray"
              onClick={() => {
                setInitialState();
                setVisibleAddRole(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-blue"
              onClick={() => {
                add_role();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Sidebar>
      {/* edit role  */}
      <Sidebar
        className="detailSiderbar"
        visible={visibleEditRole}
        position="right"
        onHide={() => {
          setInitialState();
          setVisibleEditRole(false);
        }}
      >
        <h4 className="sidebarTitleHd">Edit Role</h4>
        <div className="sidebarContentBody">
          <div className="grid">
            <div className="lg:col-12 md:col-12 sm:col-12 col-12">
              <div className="formField">
                <div className="pfloatlabel">
                  <label className="sepLable">
                    Role Name<span className="requiredstar">*</span>
                  </label>
                  <InputText
                    style={{ borderColor: item_name_error ? "#ff6b68" : "" }}
                    id="rolename"
                    value={item_name}
                    onChange={(e) => {
                      setItemName(e.target.value.trimStart());
                    }}
                    autoComplete="off"
                    placeholder="Enter a role name"
                  />
                  {item_name_error && (
                    <div className="p-invalid">{item_name_error}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid adminTableContent">
            <label className="sepLable mt-3 mb-3">
              Role Permissions<span className="requiredstar">*</span>
            </label>
            <div className="tableContent mb-3 addrpleTable">
              <table>
                <thead>
                  <tr>
                    <th>Role </th>
                    <th style={{ textAlign: "center" }}>Select</th>
                  </tr>
                </thead>
                <tbody>
                  {role_list.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td style={{ textAlign: "center" }}>
                          <Checkbox
                            id="role1"
                            onChange={(e) => {
                              let temp = [...role_list];
                              temp[index].isChecked = e.checked;
                              setRole_list(temp);
                            }}
                            checked={item.isChecked}
                          ></Checkbox>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="sidebarFooter">
          <div className="btnsRow">
            <Button
              className="btn btn-gray"
              onClick={() => {
                setInitialState();
                setVisibleEditRole(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-blue"
              onClick={() => {
                edit_role();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Sidebar>
      {/* add/edit admin */}
      <Sidebar
        className="detailSiderbar"
        visible={visibleRightAdd}
        position="right"
        onHide={() => {
          resetAddSubAdminStates();
          setVisibleRightAdd(false);
          setVisibleRightEdit(false);
          setView_SubAdmin(false);
        }}
      >
        <h4 className="mt-0 sidebarTitleHd">
          {visibleRightEdit
            ? "Edit Sub Admin"
            : view_SubAdmin
            ? "Sub Admin Details"
            : "Add Sub Admin"}
        </h4>
        <div className="sidebarContentBody">
          <div className="grid">
            <div
              className="lg:col-12 md:col-12 sm:col-12 col-12"
              style={{ pointerEvents: view_SubAdmin ? "none" : null }}
            >
              <div className="grid">
                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  <div className="formField">
                    <span className="pfloatlabel">
                      <label className="sepLable">
                        First Name
                        <span className="requiredstar">
                          {!view_SubAdmin && "*"}
                        </span>
                      </label>
                      <InputText
                        style={{
                          borderColor: sa_name_error ? "#f55e71" : "#e5e5e5",
                        }}
                        value={sa_name}
                        onChange={(e) => {
                          setSaNameError(false);
                          setSaName(e.target.value.trimStart());
                        }}
                        id="FirstName"
                        autoComplete="off"
                        placeholder="Enter First Name"
                      />
                    </span>
                    {sa_name_error && (
                      <div className="p-invalid">{sa_name_error}</div>
                    )}
                  </div>
                </div>
                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  <div className="formField">
                    <span className="pfloatlabel">
                      <label className="sepLable">
                        Last Name
                        <span className="requiredstar">
                          {!view_SubAdmin && "*"}
                        </span>
                      </label>
                      <InputText
                        style={{
                          borderColor: sa_last_name_error
                            ? "#f55e71"
                            : "#e5e5e5",
                        }}
                        value={sa_last_name}
                        onChange={(e) => {
                          setSaLastNameError(false);
                          setSaLastName(e.target.value.trimStart());
                        }}
                        id="LastName"
                        autoComplete="off"
                        placeholder="Enter Last Name"
                      />
                    </span>
                    {sa_last_name_error && (
                      <div className="p-invalid">{sa_last_name_error}</div>
                    )}
                  </div>
                </div>
                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  <div className="formField">
                    <span className="pfloatlabel">
                      <label className="sepLable">
                        Email
                        <span className="requiredstar">
                          {!view_SubAdmin && "*"}
                        </span>
                      </label>
                      <InputText
                        style={{
                          borderColor: sa_email_error ? "#f55e71" : "#e5e5e5",
                        }}
                        value={sa_email}
                        onChange={(e) => {
                          setSaEmailError(false);
                          setSaEmail(e.target.value.trimStart());
                        }}
                        id="Email"
                        autoComplete="off"
                        placeholder="Enter Email"
                      />
                    </span>
                    {sa_email_error && (
                      <div className="p-invalid">{sa_email_error}</div>
                    )}
                  </div>
                </div>
                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  <div className="formField">
                    <span className="pfloatlabel">
                      <label className="sepLable">
                        Select Status
                        <span className="requiredstar">
                          {!view_SubAdmin && "*"}
                        </span>
                      </label>
                      <Dropdown
                        value={selecteAddStatus}
                        onChange={(e) => {
                          setSaStatusError(false);
                          setSaStatus(e.value.code);
                          setSelectedAddStatus(e.value);
                        }}
                        options={AddStatus}
                        optionLabel="name"
                        placeholder="Select Status"
                        className={
                          sa_status_error
                            ? "w-full md:w-20rem error-border"
                            : "w-full md:w-20rem"
                        }
                      />
                    </span>
                    {sa_status_error && (
                      <div className="p-invalid">{sa_status_error}</div>
                    )}
                  </div>
                </div>
                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  <div className="formField mselect">
                    <span className="pfloatlabel">
                      <label className="sepLable">
                        Select Role Name Permissions
                        <span className="requiredstar">
                          {!view_SubAdmin && "*"}
                        </span>
                      </label>
                      <Dropdown
                        value={selectedRoleDd}
                        onChange={(e) => {
                          setSaRoleIdError(false);
                          setSaRoleId(e.value.id);
                          setSelectedRoleDd(e.value);
                        }}
                        options={add_edit_role_list}
                        optionLabel="name"
                        placeholder="Select Role"
                        maxselectedlabels={3}
                        className={
                          sa_role_id_error
                            ? "w-full md:w-20rem error-border"
                            : "w-full md:w-20rem"
                        }
                      />
                    </span>
                    {sa_role_id_error && (
                      <div className="p-invalid">{sa_role_id_error}</div>
                    )}
                  </div>
                </div>
                <div className="lg:col-12 md:col-12 sm:col-12 col-12">
                  <div className="formField mselect">
                    <span className="pfloatlabel">
                      <label className="sepLable">
                        Select Country
                        <span className="requiredstar">
                          {!view_SubAdmin && "*"}
                        </span>
                      </label>
                      <div>
                        <span className="mr-2">
                          <Checkbox
                            id="reccP"
                            className="hover-border-none"
                            checked={dbSelection?.iraq}
                            onChange={() => {
                              setDbError("");
                              seDbSelection({
                                ...dbSelection,
                                iraq: !dbSelection?.iraq,
                              });
                            }}
                            style={{
                              backgroundColor: "transparent !important",
                              border: "none !important",
                              marginRight: "10px",
                            }}
                          ></Checkbox>
                          Iraq
                        </span>
                        <span className="m-2">
                          <Checkbox
                            id="reccP"
                            className="hover-border-none"
                            checked={dbSelection?.uae}
                            onChange={() => {
                              setDbError("");
                              seDbSelection({
                                ...dbSelection,
                                uae: !dbSelection?.uae,
                              });
                            }}
                            style={{
                              backgroundColor: "transparent !important",
                              border: "none !important",
                              marginRight: "10px",
                            }}
                          ></Checkbox>
                          UAE
                        </span>
                      </div>
                    </span>
                    {db_error && <div className="p-invalid">{db_error}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebarFooter">
          <div className="btnsRow">
            <Button
              className="btn btn-gray"
              onClick={() => {
                resetAddSubAdminStates();
                setVisibleRightAdd(false);
                setVisibleRightEdit(false);
                setView_SubAdmin(false);
              }}
            >
              Cancel
            </Button>
            {!view_SubAdmin && (
              <Button
                disabled={loader}
                className="btn btn-blue"
                onClick={() => {
                  if (visibleRightEdit) {
                    edit_sub_admin();
                  } else {
                    add_sub_admin();
                  }
                }}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </Sidebar>
      {/* delete confirmation */}
      <Dialog
        className="dialogWidth400 headetPadding"
        header="Delete"
        visible={visibleDeleteDialog}
        style={{ width: "50vw" }}
        onHide={() => setDeleteDialog(false)}
      >
        <div className="addformDialog">
          <p className="p2 mb-4 mt-0" style={{ textAlign: "left" }}>
            Are you sure want to delete this?
          </p>
          <div className="p2 dilogbtns">
            <Button
              className="btn btn-gray"
              onClick={() => {
                setDeleteDialog(false);
              }}
            >
              No
            </Button>
            <Button
              onClick={() => {
                if (dele_type === "role") {
                  delete_role();
                } else {
                  delete_sub_admin();
                }
              }}
              className="btn btn-blue"
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default RolesListing;
